import {
  Box,
  Center,
  chakra,
  Divider,
  Grid,
  Stack,
  Progress,
  Text,
} from '@chakra-ui/react'

import { useLocale } from '../../hooks/useLocale'
import BookButton from '../../utils/BookButton'

export const UserProgress: React.FC<any> = ({
  userProgress,
  progress,
  statusText,
  onClickBook,
}: any) => {
  const { t } = useLocale()

  if (!userProgress) {
    return null
  }

  return (
    <Grid gap="10px" bg={'white'} rounded={'lg'}>
      <Box w={'full'} px={6} pt={4} pb={0}>
        <Text fontWeight="700" fontSize="22px">
          {onClickBook ? t('my_progress') : t('user_progress')}
        </Text>
      </Box>
      <Divider />
      <Grid
        gap="10px"
        w={'full'}
        bg={'white'}
        px={6}
        py={3}
        rounded={'lg'}
        mb={5}
      >
        <Stack spacing={5} mb={progress ? 5 : 0}>
          <Text fontSize="md">
            {t('sessions_completed')}:{' '}
            <chakra.span fontSize="md" color="gray">
              {userProgress?.completed ?? userProgress?.completedSessions}
            </chakra.span>
          </Text>
          {/* <Text fontSize="md">
            {t('sessions_recommended')}:{' '}
            <chakra.span fontSize="md" color="gray">
              {userProgress?.recommended ?? userProgress?.recommendedSessions}
            </chakra.span>
          </Text> */}
          <Text fontSize="md">
            {t('total_sessions_available')}:{' '}
            <chakra.span fontSize="md" color="gray">
              {Math.round(
                userProgress?.available ?? userProgress?.availableSessions ?? 0
              )}
            </chakra.span>
          </Text>
        </Stack>

        {progress !== 0 && (
          <Progress
            size="lg"
            value={Math.round(progress * 100)}
            colorScheme={statusText?.color}
            mb={4}
          />
        )}

        {statusText && (
          <Text fontSize="md" mt={5}>
            {t('status')}:{' '}
            <chakra.span fontWeight="bold" color={statusText?.color}>
              {statusText?.status}
            </chakra.span>
          </Text>
        )}
        {onClickBook && (
          <>
            <Text my="5" textAlign="center">
              {statusText?.description}
            </Text>

            {statusText?.status === t('off_track') && (
              <Center maxW={'300px'} mx={'auto'}>
                <BookButton onClick={onClickBook} />
              </Center>
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
}
