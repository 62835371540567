import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { usePostHog } from 'posthog-js/react'
import { useMutation } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { useToast } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { WretchError } from 'wretch/resolver'
import { ExclamationCircleIcon, EnvelopeIcon } from '@heroicons/react/20/solid'

import { NextButton } from './NextButton'
import { useOnboardingContext } from '~/context/onboardingContext'
import { api } from '~/app/api'
import { useAppDispatch, useAppSelector } from '~/app/hooks'
import { AlertDialog } from '~/components/alert-dialog'
import {
  setCoachEmail,
  setCompanyId,
  setDepartmentId,
  setUserEmail,
  usesAuth0,
} from '~/store/onboarding.slice'
import { ONBOARDING_EMAIL_FRIENDLY_ID } from '~/app/constants'

type Props = {
  id: string
  optional?: boolean
}

const buildSchema = (optional?: boolean) =>
  z.object({
    email: z
      .string()
      // prettier-ignore
      .email({ message: 'Invalid email' })[optional ? 'optional' : 'nonempty'](),
  })

type FormValues = z.infer<ReturnType<typeof buildSchema>>

export const Email: React.FC<Props> = (props) => {
  const { id, optional } = props
  const posthog = usePostHog()
  const { loginWithRedirect } = useAuth0()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const defaultUserEmail = useAppSelector((state) => state.onboardingV2.email)
  const {
    onNextQuestion,
    companyId,
    currentQuestionIndex,
    postOnboardingInfoAsync,
  } = useOnboardingContext()
  const [modalOpen, setModalOpen] = useState(false)
  const toast = useToast()
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    defaultValues: {
      email: id === ONBOARDING_EMAIL_FRIENDLY_ID ? defaultUserEmail : '',
    },
    resolver: zodResolver(buildSchema(optional)),
  })

  const closeModal = () => {
    clearErrors()
    setModalOpen(false)
  }

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ['user', 'allowed'],
    mutationFn(email: string) {
      return api
        .url(`/onboarding/user_allowed/${email}/${companyId}`)
        .get()
        .json<{
          companyId: string
          allowlistUser?: {
            company: { _id: string; authProvider: string }
            department?: { _id: string }
            coach?: { email: string }
          }
          authProvider?: string
        }>()
    },
    onError(error) {
      if (error instanceof WretchError) {
        if (error.json.action === 'go_to_login') {
          setModalOpen(true)
        } else {
          toast({
            status: 'error',
            title: 'Error',
            description: error.json?.message || 'An error occurred',
            isClosable: true,
          })
        }
      }
    },
    onSuccess(data, email) {
      // Check if the user is allowlisted
      if (data.allowlistUser) {
        const { company, department, coach } = data.allowlistUser
        if (company) {
          dispatch(setCompanyId(company._id))
        }

        if (department) {
          dispatch(setDepartmentId(department._id))
        }

        if (coach) {
          dispatch(setCoachEmail(coach.email))
        }

        posthog.capture('onboarding_profile_email_allowlisted', {
          questionId: id,
          companyId: company._id,
          departmentId: department?._id,
        })
      }

      if (
        data.authProvider === 'auth0' ||
        data.allowlistUser?.company.authProvider === 'auth0'
      ) {
        dispatch(usesAuth0(true))

        const search = new URLSearchParams({
          questionIndex: `${currentQuestionIndex + 1}`,
          fromAuth0: 'true',
        })

        const returnTo = encodeURIComponent(
          `/v2/onboarding/en/${companyId}/user/${email}?${search.toString()}`
        )

        const redirectUri = new URL(window.location.origin)
        redirectUri.searchParams.set('returnTo', returnTo)

        posthog.capture('onboarding_profile_email_input', {
          questionId: id,
          usesAuth0: true,
        })

        loginWithRedirect({
          appState: { returnTo },
          authorizationParams: {
            redirect_uri: redirectUri.toString(),
            login_hint: email,
            company_id: companyId,
          },
        })
      } else {
        onNextQuestion()
      }
    },
  })

  const onContinue = handleSubmit(async ({ email }) => {
    try {
      if (id === ONBOARDING_EMAIL_FRIENDLY_ID) {
        if (!email) {
          toast({
            status: 'error',
            title: 'Error',
            description: 'Email is required',
            isClosable: true,
          })
          return
        }
        dispatch(setUserEmail(email))
        await mutateAsync(email)
      } else {
        await postOnboardingInfoAsync({
          index: currentQuestionIndex,
          answers: { [id]: email },
        })
        posthog.capture('onboarding_email_input', { questionId: id })
        onNextQuestion()
      }
    } catch {
      console.log('Error')
    }
  })

  return (
    <>
      <div className="w-3/4">
        <label htmlFor={id} className="sr-only">
          Email
        </label>
        <div className="relative mt-2 rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <EnvelopeIcon
              aria-hidden="true"
              className="h-5 w-5 text-gray-400"
            />
          </div>
          <input
            id={id}
            type="email"
            placeholder="you@example.com"
            aria-invalid={errors.email?.message ? 'true' : 'false'}
            aria-describedby="email-error"
            className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
            {...register('email', {
              required: !optional,
            })}
          />
          {errors.email?.message && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                aria-hidden="true"
                className="h-5 w-5 text-red-500"
              />
            </div>
          )}
        </div>
        {errors.email?.message && (
          <p id="email-error" className="mt-2 text-sm text-red-600">
            {errors.email.message}
          </p>
        )}
      </div>

      <AlertDialog
        title="User already exists"
        description="User already exists. Please login to continue."
        confirmText="Login"
        isOpen={modalOpen}
        onClose={closeModal}
        onConfirm={() => {
          navigate('/login', { replace: true })
        }}
      />

      <NextButton
        disabled={!isValid || isPending}
        isLoading={isPending}
        onClick={onContinue}
        manualNext
      />
    </>
  )
}
