import { SmallCloseIcon } from '@chakra-ui/icons'
import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Link,
} from '@chakra-ui/react'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import { useLocale } from '../../../hooks/useLocale'
import OnboardingFooter from '../components/OnboardingFooter'
import OnboardingHeader from '../components/OnboardingHeader'
import OnboardingContainer from '../OnboardingContainer'
import {
  setName,
  setPassword,
  setTimezone,
  setUserEmail,
} from '../onboardingSlice'
import TitleDescription from './TitleDescription'

const SetupProfile: FC<any> = ({ index, onSubmit, submitting = false }) => {
  const { t } = useLocale()
  const onboarding = useSelector((state: RootState) => state.onboarding)
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const form = useForm({
    reValidateMode: 'onSubmit',
    mode: 'all',
  })

  const dispatch = useDispatch()
  const question = onboarding.questions[index]
  const isLast = index === onboarding.questions.length - 1

  const handleSubmit = async (values: any) => {
    const { name, email, password } = values
    dispatch(setName(name))
    dispatch(setPassword(password))
    dispatch(setTimezone(timezone))
    dispatch(setUserEmail(email))
    onSubmit()
  }

  const requirements = [
    { re: /.{10}/, label: t('password_requirements_length') },
    { re: /[A-Z]/, label: t('password_must_contain_uppercase_letter') },
    { re: /[a-z]/, label: t('password_must_contain_lowercase_letter') },
    // { re: /[0-9]/, label: t('password_must_contain_number') },
    {
      re: /^(?=.*?[" !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"])/,
      label: t('password_must_contain_special_character'),
    },
  ]

  return (
    <>
      <OnboardingHeader
        currentStep={index}
        totalSteps={onboarding.questions.length - 1}
      />
      <TitleDescription title={question.title} />
      <OnboardingContainer>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <FormControl
            style={{ width: '100%' }}
            isInvalid={!!form.formState.errors.name}
            mb={3}
          >
            <FormLabel>{t('name')}</FormLabel>
            <Input
              placeholder={t('name')}
              {...form.register('name', { required: true })}
            />
            <FormErrorMessage>{t('name_required_field')}</FormErrorMessage>
          </FormControl>

          <FormControl
            style={{ width: '100%' }}
            isInvalid={!!form.formState.errors.email}
            mb={3}
          >
            <FormLabel>{t('email')}</FormLabel>
            <Input
              disabled={true}
              placeholder={t('email')}
              {...form.register('email', {
                required: { value: true, message: t('email_required_field') },
                pattern: {
                  value: /^\S+@\S+\.\S+$/gi,
                  message: t('please_enter_valid_email'),
                },
                value: onboarding.email,
              })}
            />
            {form.formState.errors.email && (
              <FormErrorMessage>
                {form.formState.errors.email.message?.toString() ?? ''}
              </FormErrorMessage>
            )}
          </FormControl>

          <FormControl
            style={{ width: '100%' }}
            isInvalid={!!form.formState.errors.password}
            mb={3}
          >
            <FormLabel>{t('password')}</FormLabel>
            <Input
              type="password"
              placeholder={t('password')}
              {...form.register('password', {
                required: {
                  value: true,
                  message: t('password_required_field'),
                },
                minLength: {
                  value: 10,
                  message: t('password_requirements_length'),
                },
                validate: {
                  hasUppercase: (value: string) =>
                    /[A-Z]/.test(value) ||
                    (t('password_must_contain_uppercase_letter') as string),
                  hasLowercase: (value: string) =>
                    /[a-z]/.test(value) ||
                    (t('password_must_contain_lowercase_letter') as string),

                  hasSpecialChar: (value: string) =>
                    /^(?=.*?[" !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"])/.test(
                      value
                    ) ||
                    (t('password_must_contain_special_character') as string),
                },
              })}
            />
            <FormHelperText>
              {!!form.watch('password', false) &&
                requirements.map(({ re, label }, index) => {
                  const isRequirementMet = re.test(
                    form.watch('password', false)
                  )
                  return (
                    <Flex display={'flex'} key={index}>
                      {!isRequirementMet && (
                        <>
                          <SmallCloseIcon color="red.500" />
                          <Box color={'red.500'} ml={1.5}>
                            {label}
                          </Box>
                        </>
                      )}
                    </Flex>
                  )
                })}
            </FormHelperText>
          </FormControl>

          <FormControl>
            <Checkbox
              {...form.register('termsOfService', { required: true })}
              isInvalid={!!form.formState.errors.termsOfService}
              required={true}
              data-testid="terms-of-service"
            >
              {t('agree_to_hupo')}
            </Checkbox>{' '}
            <Link
              href="https://hupo.co/core/user-agreement"
              target="_blank"
              rel="noreferrer"
              color="primary.500"
            >
              {t('terms_of_service')}
            </Link>{' '}
            {t('and')}{' '}
            <Link
              href="https://hupo.co/privacy-policy"
              target="_blank"
              rel="noreferrer"
              color="primary.500"
            >
              {t('privacy_policy')}
            </Link>
            <span> {t('terms_of_service_end')}</span>
            <FormErrorMessage>
              {form.formState.errors.termsOfService?.message?.toString() ?? ''}
            </FormErrorMessage>
          </FormControl>

          <Box mt={14}>
            <OnboardingFooter
              submitting={submitting}
              type="submit"
              isLastStep={isLast}
              submitText={t('next')}
            />
          </Box>
        </form>
      </OnboardingContainer>
      <div
        data-testid="onboarding-flow-test-hidden"
        data-onboarding-flow-test-type={'profile-setup'}
        data-onboarding-flow-test-index={index}
        data-onboarding-flow-test-id={question.friendlyID}
        data-onboarding-flow-test-islast={isLast}
        style={{ display: 'none' }}
      />
    </>
  )
}

export default SetupProfile
