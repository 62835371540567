import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  useToast,
  FormErrorMessage,
  Box,
  FormHelperText,
} from '@chakra-ui/react'
import { SmallCloseIcon } from '@chakra-ui/icons'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { usePasswordResetMutation } from '../../app/services/api'
import { PasswordResetRequest } from '../../types/api'

import { useLocale } from '../../hooks/useLocale'
import { useMixpanel } from '../../utils/MixpanelContext'

const PasswordReset: any = (): JSX.Element => {
  // Get reset token from params
  const { token, email } = useParams() as any
  const { t } = useLocale()
  const navigate = useNavigate()
  const toast = useToast()
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const mixpanel = useMixpanel()
  const [passwordReset, { isLoading }] = usePasswordResetMutation()

  const [formState, setFormState] = useState<PasswordResetRequest>({
    email,
    password: '',
    confirmPassword: '',
    token,
  })
  const [isPasswordMatchError, setIsPasswordMatchError] = useState(false)
  const [isPasswordValid, setIsPasswordValid] = useState(false)

  useEffect(() => {
    // Disable button if form is not filled
    return setIsButtonDisabled(
      !(
        formState?.password &&
        formState?.confirmPassword &&
        isPasswordValid &&
        formState?.password === formState?.confirmPassword
      )
    )
  }, [formState, isPasswordMatchError, isPasswordValid])

  const onSubmit = useCallback(
    async (event: any) => {
      event.preventDefault()
      if (isButtonDisabled) {
        return
      }
      try {
        setIsButtonDisabled(true)
        await passwordReset(formState).unwrap()
        setIsButtonDisabled(false)
        toast({
          title: t('password_reset_success'),
          description: t('password_reset_success_description'),
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
        mixpanel.track('password_reset_success')
        navigate('/login')
      } catch (err: any) {
        toast({
          status: 'error',
          title: t('error'),
          description: err?.data?.message || t('there_was_an_error'),
          isClosable: true,
        })
        mixpanel.track('password_reset_fail', { message: err?.data?.message })
      }
    },
    [formState, isButtonDisabled, passwordReset, t, toast, mixpanel, navigate]
  )

  const requirements = [
    { re: /.{10}/, label: t('password_requirements_length') },
    { re: /[A-Z]/, label: t('password_must_contain_uppercase_letter') },
    { re: /[a-z]/, label: t('password_must_contain_lowercase_letter') },
    //{ re: /[0-9]/, label: t('password_must_contain_number') },
    {
      re: /^(?=.*?[" !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"])/,
      label: t('password_must_contain_special_character'),
    },
  ]

  const handleChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) => {
    const results = requirements.map(({ re }) => re.test(value))
    console.log('results', results)
    console.log('res', !results.includes(false))
    setIsPasswordValid(!results.includes(false))
    console.log('state', isPasswordValid)

    if (name === 'confirmPassword') {
      const isMatch = formState?.password === value
      setIsPasswordMatchError(!isMatch)
    }
    setFormState((prev) => ({ ...prev, [name]: value }))
  }

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <form onSubmit={onSubmit}>
        <Stack
          spacing={4}
          w={'full'}
          maxW={'md'}
          bg={useColorModeValue('white', 'gray.700')}
          rounded={'xl'}
          boxShadow={'lg'}
          p={6}
          my={12}
        >
          <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
            {t('reset_your_password')}
          </Heading>
          <FormControl id="email" isRequired>
            <FormLabel>{t('email_address')}</FormLabel>
            <Input
              value={email}
              _placeholder={{ color: 'gray.500' }}
              type="email"
              disabled={true}
            />
          </FormControl>

          <FormControl id="password" isRequired mt={2}>
            <FormLabel>{t('password')}</FormLabel>
            <Input type="password" name="password" onChange={handleChange} />
            <FormHelperText>
              {!!formState.password &&
                requirements.map(({ re, label }, index) => {
                  const isRequirementMet = re.test(formState?.password)
                  return (
                    <Flex display={'flex'} key={index}>
                      {!isRequirementMet && (
                        <>
                          <SmallCloseIcon color="red.500" />
                          <Box color={'red.500'} ml={1.5}>
                            {label}
                          </Box>
                        </>
                      )}
                    </Flex>
                  )
                })}
            </FormHelperText>
          </FormControl>

          <FormControl
            id="confirmPassword"
            isRequired
            isInvalid={isPasswordMatchError}
          >
            <FormLabel>{t('confirm_password')}</FormLabel>
            <Input
              type="password"
              name="confirmPassword"
              onChange={handleChange}
            />
            <FormErrorMessage>
              {isPasswordMatchError && 'Confirm password does not match'}
            </FormErrorMessage>
          </FormControl>

          <Stack spacing={6}>
            <Button
              bg={'primary.400'}
              color={'white'}
              _hover={{
                bg: 'primary.500',
              }}
              type="submit"
              isLoading={isLoading}
              isDisabled={isButtonDisabled}
            >
              {t('reset_password')}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Flex>
  )
}

export default PasswordReset
