import { Fragment, useRef } from 'react'
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useToast } from '@chakra-ui/react'
import { usePostHog } from 'posthog-js/react'
import {
  CheckBadgeIcon,
  PowerIcon,
  SignalIcon,
  ArrowPathIcon,
  ArrowPathRoundedSquareIcon,
  GlobeAltIcon,
} from '@heroicons/react/24/outline'
import { cn } from '~/utils/cn'
import { store } from '~/app/store'
import { logout } from '~/features/auth/authSlice'

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
}

const items = (
  toast: ReturnType<typeof useToast>,
  posthog: ReturnType<typeof usePostHog>
) => [
  {
    title: 'Check network connection',
    description:
      'Ensure you have a stable internet connection for the application to function properly.',
    icon: SignalIcon,
    background: 'bg-green-500',
    action() {
      if (navigator.onLine) {
        toast({
          title: 'Network Connection',
          description: 'You are connected to the internet.',
          status: 'success',
        })
      } else {
        toast({
          title: 'Network Connection',
          description: 'You are not connected to the internet.',
          status: 'error',
        })
      }

      posthog.capture('Check network connection')
    },
  },
  {
    title: 'Update software',
    description:
      'Install the latest updates and patches to ensure optimal performance and security.',
    icon: ArrowPathIcon,
    background: 'bg-indigo-500',
    action() {
      // registerSW({
      //   immediate: true,
      //   onRegisteredSW() {
      //     toast({
      //       title: 'Software Update',
      //       description: 'The application has been updated successfully.',
      //       status: 'success',
      //     })
      //     posthog.capture('Update software [troubleshoot]')
      //   },
      //   onRegisterError() {
      //     toast({
      //       title: 'Software Update',
      //       description: 'An error occurred while updating the application',
      //       status: 'error',
      //     })
      //     posthog.capture('Update software error [troubleshoot]')
      //   },
      // })
    },
  },
  {
    title: 'Use latest version browsers. Preferably Chrome.',
    description:
      'We recommend using the latest version of Google Chrome for the best experience, but we support all modern browsers.',
    icon: GlobeAltIcon,
    background: 'bg-yellow-500',
    action() {
      if (navigator.userAgent.indexOf('Chrome') != -1) {
        toast({
          status: 'success',
          title: 'Chrome',
          description: 'You are using Google Chrome. Awesome!',
        })

        posthog.capture('Uses Chrome [troubleshoot]')
      } else {
        const browserName = navigator.userAgent.split(' ')[0]
        toast({
          title: browserName,
          description: `You are using ${browserName}. We recommend using Google Chrome for the best experience.`,
          status: 'warning',
        })
        posthog.capture('Uses non-chrome browser [troubleshoot]')
      }
    },
  },
  {
    title: 'Check browser settings',
    description:
      'Ensure your browser settings are configured correctly for the application.',
    icon: CheckBadgeIcon,
    background: 'bg-purple-500',
    action() {
      toast({
        title: 'Browser Settings',
        description: 'Your browser settings are configured correctly.',
        status: 'success',
      })
    },
  },
  {
    title: 'Restart the application',
    description:
      'Closing and reopening the application can often resolve temporary glitches or freezes.',
    icon: ArrowPathRoundedSquareIcon,
    background: 'bg-blue-500',
    action() {
      posthog.capture('Restart the application [troubleshoot]')
      location.reload()
    },
  },
  {
    title: 'Reset the cache',
    description:
      'Clear your browser cache to resolve potential issues with outdated data.',
    icon: PowerIcon,
    background: 'bg-red-500',
    async action() {
      const confirmed = confirm(
        'Are you sure you want to clear the cache? You have to login again.'
      )

      if (confirmed) {
        posthog.capture('Reset the cache [troubleshoot]')

        store.dispatch(logout())

        // Unregister service workers
        if ('serviceWorker' in navigator) {
          const registrations = await navigator.serviceWorker.getRegistrations()
          for (const registration of registrations) {
            await registration.unregister()
          }
        }
      }
    },
  },
]

const onChatSupportClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
  e.preventDefault()
  window._chatlio.open()
}

export const TroubleshootDialog: React.FC<Props> = (props) => {
  const { open, setOpen } = props
  const posthog = usePostHog()
  const toast = useToast()
  const cancelButtonRef = useRef<HTMLButtonElement>(null)

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    ref={cancelButtonRef}
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus-within:ring-primary focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <Dialog.Title
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900"
                  >
                    Common Troubleshooting Steps
                  </Dialog.Title>
                  <p className="mt-1 text-sm text-gray-500">
                    If you're experiencing issues with the application, try the
                    following troubleshooting steps to resolve the problem.
                  </p>
                  <ul
                    role="list"
                    className="mt-6 grid grid-cols-1 gap-6 border-b border-t border-gray-200 py-6 sm:grid-cols-2"
                  >
                    {items(toast, posthog).map((item, index) => (
                      <li key={index} className="flow-root">
                        <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-primary hover:bg-gray-50">
                          <div
                            className={cn(
                              item.background,
                              'flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg'
                            )}
                          >
                            <item.icon
                              className="h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-gray-900">
                              <button
                                className="focus:outline-none"
                                onClick={item.action}
                              >
                                <span
                                  className="absolute inset-0"
                                  aria-hidden="true"
                                />
                                <span>{item.title}</span>
                              </button>
                            </h3>
                            <p className="mt-1 text-sm text-gray-500">
                              {item.description}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div className="mt-4 flex">
                    <a
                      href="#"
                      onClick={onChatSupportClick}
                      className="text-sm font-medium text-gray-700 rounded-sm focus:outline-none focus:ring-2 focus-within:ring-primary focus:ring-offset-2"
                    >
                      If none of the above steps resolve your issue,{' '}
                      <span className="underline text-primary-600">
                        click here to open our live chat support
                      </span>
                      .
                      <br />
                      <strong>We'll get back to you in no time!</strong>
                    </a>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
