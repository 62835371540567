import { Box, Text } from '@chakra-ui/react'
import { DyteChat } from '@dytesdk/react-ui-kit'
import { useDyteMeeting } from '@dytesdk/react-web-core'
import { X } from 'react-feather'
import { useLocale } from '../../../hooks/useLocale'

function ChatWindow({
  setDisplayChat,
}: {
  setDisplayChat: (displayChat: boolean) => void
}) {
  const { t } = useLocale()
  const { meeting } = useDyteMeeting()

  return (
    <Box
      flex={1}
      ml={{ base: 0, md: 2 }}
      pb={12}
      bgColor="#181818"
      borderRadius={12}
      border="1px solid #1F1F1F"
    >
      <Box p={'12px'} display="flex" justifyContent="space-between">
        <X
          onClick={() => setDisplayChat(false)}
          style={{ color: '#E3E3E3', cursor: 'pointer' }}
        />
        <Text fontSize="15px" color="white">
          {t('chat')}
        </Text>
        <Box w={'24px'} />
      </Box>
      <DyteChat
        meeting={meeting}
        style={{
          height: '100%',
          width: '100%',
        }}
      />
    </Box>
  )
}

export default ChatWindow
