import { useMemo } from 'react'
import format from 'date-fns/format'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import Linkify from 'react-linkify'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { DocumentIcon, UserCircleIcon } from '@heroicons/react/24/outline'
import {
  ArrowDownTrayIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/20/solid'

import { cn } from '~/utils/cn'
import { PdfIcon } from '~/assets/pdf-icon'

type Props = {
  side: 'left' | 'right'
  user: {
    name?: string
    picture?: string
  }
  date: Date
  message: string

  type: 'text' | 'file' | 'image'
  file: File
}

interface File {
  url?: string
  name?: string
  type?: string
  size?: number
}

const bytesToMegabytes = (bytes: number) => {
  return (bytes / 1024 ** 2).toFixed(2)
}

const bytesToKilobytes = (bytes: number) => {
  return (bytes / 1000).toFixed(2)
}

export const ChatBubble: React.FC<Props> = (props) => {
  const { side, user, date, message, file } = props
  const isLeft = side === 'left'

  const isImage = useMemo(() => file.type?.includes('image'), [file.type])
  const type: Props['type'] = isImage ? 'image' : props.type
  const fileName = useMemo(() => file.name ?? message, [file.name, message])
  const extension = useMemo(
    () => fileName.split('.').pop()?.toUpperCase(),
    [fileName]
  )
  const fileSize = useMemo(() => {
    if (!file.size) return ''
    if (file.size > 1024 ** 2) {
      return `${bytesToMegabytes(file.size)} MB`
    }
    return `${bytesToKilobytes(file.size)} KB`
  }, [file?.size])

  let content
  switch (type) {
    case 'text':
      content = (
        <p className="text-sm font-normal py-2.5 text-gray-900 dark:text-white">
          <Linkify
            componentDecorator={(href, text, key) => (
              <a
                key={key}
                href={href}
                className="text-blue-700"
                target="_blank"
                rel="noreferrer noopener"
              >
                {text}
              </a>
            )}
          >
            {message}
          </Linkify>
        </p>
      )
      break
    case 'file':
      content = (
        <div className="flex items-start justify-between my-2.5 bg-gray-50 dark:bg-gray-600 rounded-xl p-2">
          <div className="me-2">
            <span className="flex break-words break-all hyphens-auto items-center gap-2 text-sm font-medium text-gray-900 dark:text-white pb-2">
              {file.type === 'application/pdf' ? (
                <PdfIcon />
              ) : (
                <DocumentIcon className="w-5 h-5 flex-shrink-0" />
              )}
              {fileName}
            </span>
            <span className="flex text-xs font-normal text-gray-500 dark:text-gray-400 gap-2">
              {fileSize} • {extension}
            </span>
          </div>
          <div className="inline-flex self-center items-center">
            <button
              className="inline-flex self-center items-center p-2 text-sm font-medium text-center text-gray-900 bg-gray-50 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-600 dark:hover:bg-gray-500 dark:focus:ring-gray-600"
              type="button"
              onClick={() => window.open(file.url)}
            >
              <ArrowDownTrayIcon className="w-5 h-5" />
            </button>
          </div>
        </div>
      )
      break
    case 'image':
      content = (
        <>
          <p className="text-sm font-normal text-gray-900 dark:text-white">
            {message}
          </p>
          <div className="group relative my-2.5">
            <div className="absolute w-full h-full bg-gray-900/50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg flex items-center justify-center">
              <a
                href={file.url}
                className="inline-flex items-center justify-center rounded-full h-10 w-10 bg-white/30 hover:bg-white/50 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50"
                target="_blank"
                rel="noreferrer noopener"
              >
                <ArrowDownTrayIcon className="w-5 h-5 text-white" />
              </a>
            </div>
            <img src={file.url} className="rounded-lg" alt={fileName} />
          </div>
        </>
      )
      break
  }

  return (
    <div
      className={cn(
        'flex gap-2.5',
        isLeft ? 'flex-row items-start' : 'flex-row-reverse items-end'
      )}
    >
      {user.picture ? (
        <img
          className="w-8 h-8 rounded-full"
          src={user.picture}
          alt={user.name}
        />
      ) : (
        <UserCircleIcon className="w-8 h-8 rounded-full" />
      )}
      <div
        className={cn(
          'flex flex-col w-full max-w-[326px] leading-1.5 p-4 border-gray-200 bg-gray-100 dark:bg-gray-700',
          isLeft ? 'rounded-e-xl rounded-es-xl' : 'rounded-s-xl rounded-se-xl'
        )}
      >
        <div
          className={cn(
            'flex items-center space-x-2 rtl:space-x-reverse',
            type === 'image' && 'mb-2'
          )}
        >
          <span className="text-sm font-semibold text-gray-900 dark:text-white">
            {user.name}
          </span>
          <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
            {format(date, 'HH:mm')}
          </span>
        </div>
        {content}
        <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
          {formatDistanceToNow(date, { addSuffix: true })}
        </span>
      </div>

      <Menu as="div" className="relative flex-none">
        <MenuButton className="inline-flex self-center items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-900 dark:hover:bg-gray-800 dark:focus:ring-gray-600">
          <span className="sr-only">Open options</span>
          <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" />
        </MenuButton>
        <MenuItems
          transition
          className={cn(
            'absolute z-50 text-xs overflow-hidden mt-2 origin-top-rightpy-2 ring-1 ring-gray-900/5 transition focus:outline-none',
            isLeft ? 'left-0' : 'right-0',
            'bg-white divide-y divide-gray-100 rounded-lg shadow w-40 dark:bg-gray-700 dark:divide-gray-600',
            'data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in'
          )}
        >
          {/* <MenuItem>
            <button
              type="button"
              className="w-full text-start px-2.5 py-1.5 data-[focus]:bg-gray-100 dark:data-[focus]:bg-gray-600 dark:data-[focus]:text-white"
            >
              Reply
            </button>
          </MenuItem> */}
          {/* <MenuItem>
            <button
              type="button"
              className="w-full text-start px-2.5 py-1.5 data-[focus]:bg-gray-100 dark:data-[focus]:bg-gray-600 dark:data-[focus]:text-white"
            >
              Forward
            </button>
          </MenuItem> */}
          <MenuItem>
            <button
              type="button"
              className="w-full text-start px-2.5 py-1.5 data-[focus]:bg-gray-100 dark:data-[focus]:bg-gray-600 dark:data-[focus]:text-white"
            >
              Copy
            </button>
          </MenuItem>
          {/* <MenuItem>
            <button
              type="button"
              className="w-full text-start px-2.5 py-1.5 data-[focus]:bg-gray-100 dark:data-[focus]:bg-gray-600 dark:data-[focus]:text-white"
            >
              Report
            </button>
          </MenuItem> */}
          <MenuItem>
            <button
              type="button"
              className="w-full bg-red-500 text-white text-start px-2.5 py-1.5 data-[focus]:bg-red-600 dark:data-[focus]:bg-gray-600 dark:data-[focus]:text-white"
            >
              Delete
            </button>
          </MenuItem>
        </MenuItems>
      </Menu>
    </div>
  )
}
