import { Button } from '@chakra-ui/react'
import { FC } from 'react'
import { useLocale } from '../../../hooks/useLocale'

interface IOnboardingFooter {
  onSubmit?: any
  submitText: string
  type?: 'button' | 'submit'
  submitting?: boolean
  isLastStep?: boolean
}

const OnboardingFooter: FC<IOnboardingFooter> = ({
  onSubmit,
  submitText,
  type = 'button',
  isLastStep = false,
  submitting = false,
}) => {
  const { t } = useLocale()
  return (
    <div
      style={{
        padding: '10px 0',
        position: 'sticky',
        bottom: 0,
        height: 'fit-content',
        backgroundColor: '#fff',
        marginTop: 20,
      }}
    >
      <Button
        onClick={onSubmit && onSubmit}
        colorScheme="primary"
        style={{ borderRadius: 100 }}
        width="100%"
        display="block"
        margin={'0 auto'}
        height="50px"
        isLoading={submitting}
        type={type}
        fontSize="20px"
        data-testid="onboarding-footer-button"
      >
        {isLastStep ? (submitting ? t('submitting') : t('submit')) : submitText}
      </Button>
    </div>
  )
}

export default OnboardingFooter
