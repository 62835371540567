import { Input, Text, useToast } from '@chakra-ui/react'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import OnboardingFooter from '../../onboarding/components/OnboardingFooter'
import OnboardingHeader from '../../onboarding/components/OnboardingHeader'
import OnboardingContainer from '../../onboarding/OnboardingContainer'
import TitleDescription from '../../onboarding/questionTypes/TitleDescription'
import { itemSelect } from '../assessmentSlice'
import * as yup from 'yup'
import { useLocale } from '../../../hooks/useLocale'

const EmailQuestion: FC<any> = ({ progress, question, answer, onSubmit }) => {
  const dispatch = useDispatch()
  const toast = useToast()
  const { t } = useLocale()

  const [value, setValue] = useState(answer ?? '')
  const user = useSelector((state: RootState) => state.assessment.user)
  const emailValidation = yup
    .string()
    .email(t('please_enter_valid_email'))
    .required(t('email_is_required'))

  const onLocalSubmit = () => {
    try {
      emailValidation.validateSync(value)
      dispatch(
        itemSelect({
          questionId: question.friendlyID,
          answers: value?.toLowerCase(),
        })
      )
      onSubmit()
    } catch (validationError: any) {
      toast({
        status: 'error',
        title: 'Error',
        description: validationError.errors,
        isClosable: true,
      })
    }
  }

  return (
    <>
      <OnboardingHeader
        currentStep={progress.currentStep}
        totalSteps={progress.totalSteps}
        isAssessment
      />
      <OnboardingContainer>
        <TitleDescription
          title={question.title.replaceAll('{{username}}', user)}
          description={question.description?.replaceAll('{{username}}', user)}
        />
        {question.prefix ? (
          <Text
            fontWeight="400"
            maxWidth={600}
            lineHeight={1.3}
            fontSize={['md']}
            textAlign="center"
            mb={4}
            mt={4}
          >
            {question.prefix}
          </Text>
        ) : null}
        <Input
          placeholder={question.placeholder}
          onChange={(event) => setValue(event.target.value)}
          value={value}
          onSubmit={onLocalSubmit}
        />
        <OnboardingFooter onSubmit={onLocalSubmit} submitText={'Next'} />
      </OnboardingContainer>
    </>
  )
}

export default EmailQuestion
