import { FC } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import { useLocale } from '../../../hooks/useLocale'
import OnboardingFooter from '../components/OnboardingFooter'
import OnboardingHeader from '../components/OnboardingHeader'
import VerticalProgressComponent from '../components/VerticalProgressComponent'
import OnboardingContainer from '../OnboardingContainer'
import TitleDescription from './TitleDescription'

const StepInfo: FC<any> = ({ index, onSubmit }) => {
  const { t } = useLocale()
  const onboardingQuestions = useSelector(
    (state: RootState) => state.onboarding.questions
  )
  const onboardingSteps = useSelector(
    (state: RootState) => state.onboarding.steps
  )
  const question = onboardingQuestions[index]

  return (
    <>
      <OnboardingHeader
        currentStep={index}
        totalSteps={onboardingQuestions.length - 1}
      />
      <OnboardingContainer
        direction="column"
        alignItems="center"
        justifyContent="center"
        py={5}
        px={2}
      >
        <TitleDescription
          title={question.title}
          description={question.description}
        />
        <VerticalProgressComponent
          items={onboardingSteps as any}
          currentStep={question.step}
        />
        <OnboardingFooter submitText={t('next')} onSubmit={onSubmit} />
      </OnboardingContainer>
      <div
        data-testid="onboarding-flow-test-hidden"
        data-onboarding-flow-test-type={'step'}
        data-onboarding-flow-test-index={index}
        data-onboarding-flow-test-id={question.friendlyID}
        data-onboarding-flow-test-islast={false}
        style={{ display: 'none' }}
      />
    </>
  )
}

export default StepInfo
