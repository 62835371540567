import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { Message } from '../../features/chat/chatSlice'
import { Event } from '../../features/scheduler/schedulerSlice'
import {
  AsessmentInsights,
  AssessmentResults,
  Company,
  CreateBlockEventRequest,
  CreateEventRequest,
  DeleteEventRequest,
  DeleteEventResponse,
  DeleteMessageResponse,
  EventsWithNotes,
  GetAssessmentQuestionsRequest,
  GetAvailabilityRequest,
  GetAvailabilityResponse,
  GetCoachBioResponse,
  GetCompaniesResponse,
  GetCompanyRequest,
  GetEventRequest,
  GetExcludedDatesRequest,
  GetExcludedDatesResponse,
  GetInvitationRequest,
  GetManagerPeerAssessmentQuestionsRequest,
  GetMeResponse,
  GetOnboardingCoachOptionsRequest,
  GetRTCTokenRequest,
  GetRTCTokenResponse,
  GetScheduleRequest,
  GetScheduleResponse,
  GetUnifiedLinkRequest,
  GetUserProfileResponse,
  GetUserResponse,
  GetUsersResponse,
  HighlightSummary,
  Invitation,
  ListResponse,
  LoginRequest,
  MeetingRequest,
  MeetingResponse,
  MessagesRequest,
  Note,
  OnboardingCoachSelectionRequest,
  PasswordForgotRequest,
  PasswordForgotResponse,
  PasswordResetRequest,
  PasswordResetResponse,
  PeerAssessment,
  PostProfileRequest,
  PostReviewRequest,
  PostScheduleRequest,
  PostSessionNoteRequest,
  PostSessionSummaryFeedbackRequest,
  PostSessionUserFeedbackRequest,
  Resource,
  SessionNote,
  SignupRequest,
  UpdateEventRequest,
  UpdateSessionNoteRequest,
  User,
  UserNote,
  UserNoteResponse,
  UserProgress,
  UserResponse,
} from '../../types/api'
import { API_ENDPOINT } from '../constants'
import { RootState } from '../store'

export const api = createApi({
  tagTypes: [
    'userNotes',
    'userProfile',
    'coachProfile',
    'sessionNotes',
    'plans',
    'coachEvents',
    'coachBlockEvents',
    'coachOpenSlots',
    'messages',
    'assessment',
    'coach',
    'sessionFeedback',
    'events',
    'assessmentInsights',
    'credits',
    'coach-selection',
    'hh',
    'peerAssessment',
    'respondentStatus',
  ],
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token
      if (
        !headers.has('Authorization') &&
        !headers.has('authorization') &&
        token
      ) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('os', 'web')
      return headers
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation<UserResponse, LoginRequest>({
      query: (credentials) => ({
        url: 'login',
        method: 'POST',
        body: credentials,
      }),
    }),
    signup: builder.mutation<UserResponse, SignupRequest>({
      query: (credentials) => ({
        url: 'signup',
        method: 'POST',
        body: credentials,
      }),
    }),
    passwordForgot: builder.mutation<
      PasswordForgotResponse,
      PasswordForgotRequest
    >({
      query: (body) => ({
        url: '/password_forgot',
        method: 'POST',
        body,
      }),
    }),
    passwordReset: builder.mutation<
      PasswordResetResponse,
      PasswordResetRequest
    >({
      query: (body) => ({
        url: '/password_reset',
        method: 'POST',
        body,
      }),
    }),
    getCoach: builder.query<any, void>({
      query: () => ({
        url: 'users/coach',
        method: 'GET',
      }),
      providesTags: ['coach'],
    }),
    fetchMessages: builder.query<ListResponse<Message>, MessagesRequest>({
      query: (user) => ({
        url: 'chat/messages',
        method: 'GET',
        params: user,
      }),
      providesTags: ['messages'],
    }),
    deleteMessage: builder.mutation<DeleteMessageResponse, string>({
      query: (id) => ({
        url: `chat/messages/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['messages'],
    }),
    postMessagesRead: builder.mutation<Message, string>({
      query: (id) => ({
        url: `chat/messages/${id}/read`,
        method: 'POST',
      }),
    }),
    getEvents: builder.query<ListResponse<Event>, void>({
      query: () => ({
        url: 'scheduler/events',
        method: 'GET',
      }),
      providesTags: ['events'],
    }),
    getAllEvents: builder.query<ListResponse<Event>, void>({
      query: () => ({
        url: 'scheduler/events/all',
        method: 'GET',
      }),
      providesTags: ['events'],
    }),
    getEvent: builder.mutation<Event, GetEventRequest>({
      query: ({ eventId, date }: GetEventRequest) => ({
        url: 'scheduler/event',
        method: 'GET',
        params: { eventId, date },
      }),
    }),
    deleteEvent: builder.mutation<DeleteEventResponse, DeleteEventRequest>({
      query: (event) => ({
        url: 'scheduler/event',
        method: 'DELETE',
        body: event,
      }),
      invalidatesTags: ['sessionNotes', 'events', 'coachEvents'],
    }),
    createEvent: builder.mutation<Event, CreateEventRequest>({
      query: (event) => ({
        url: 'scheduler/events',
        method: 'POST',
        body: event,
      }),
      invalidatesTags: ['sessionNotes', 'events'],
    }),
    updateEvent: builder.mutation<Event, UpdateEventRequest>({
      query: (event) => ({
        url: 'scheduler/events',
        method: 'POST',
        body: event,
      }),
      invalidatesTags: ['sessionNotes', 'events'],
    }),
    createBlockEvent: builder.mutation<any, CreateBlockEventRequest>({
      query: (event) => ({
        url: 'scheduler/block',
        method: 'POST',
        body: event,
      }),
      invalidatesTags: ['sessionNotes', 'coachBlockEvents'],
    }),
    createOpenSlot: builder.mutation<any, CreateBlockEventRequest>({
      query: (event) => ({
        url: 'scheduler/open',
        method: 'POST',
        body: event,
      }),
      invalidatesTags: ['sessionNotes', 'coachOpenSlots'],
    }),
    getSchedulerConfig: builder.query<any, void>({
      query: () => ({
        url: 'scheduler/config',
        method: 'GET',
      }),
    }),
    getCompany: builder.query<Company, GetCompanyRequest>({
      query: (companyId) => ({
        url: 'company',
        method: 'GET',
        params: { companyId },
      }),
    }),
    getInvitation: builder.mutation<Invitation, GetInvitationRequest>({
      query: (invitationId) => ({
        url: 'invitation',
        method: 'GET',
        params: { invitationId },
      }),
    }),
    getCompanies: builder.mutation<ListResponse<GetCompaniesResponse>, void>({
      query: () => ({
        url: 'coaches/companies',
        method: 'GET',
      }),
    }),
    getUsers: builder.query<ListResponse<GetUsersResponse>, void>({
      query: () => ({
        url: 'coaches/users',
        method: 'GET',
      }),
    }),
    getUserProfile: builder.query<GetUserProfileResponse, void>({
      query: () => ({
        url: 'users/profile',
        method: 'GET',
      }),
      providesTags: ['userProfile'],
    }),
    postUserProfile: builder.mutation<User, PostProfileRequest>({
      query: (body) => ({
        url: 'users/profile',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['userProfile'],
    }),
    deleteUser: builder.mutation({
      query: () => ({
        url: 'user/',
        method: 'DELETE',
      }),
      invalidatesTags: ['userProfile'],
    }),
    getUser: builder.mutation<GetUserResponse, string>({
      query: (userId) => ({
        url: 'coaches/user',
        method: 'GET',
        params: { userId },
      }),
    }),
    getUserInfo: builder.query({
      query: () => ({
        url: '/user',
        method: 'GET',
      }),
      providesTags: ['userProfile'],
    }),
    fetchUser: builder.query<GetUserResponse, string>({
      query: (userId) => ({
        url: 'coaches/user',
        method: 'GET',
        params: { userId },
      }),
    }),
    getRTCToken: builder.mutation<GetRTCTokenResponse, GetRTCTokenRequest>({
      query: (params) => ({
        url: '/call/token',
        method: 'GET',
        params: params,
      }),
    }),
    getAvailability: builder.mutation<
      GetAvailabilityResponse,
      GetAvailabilityRequest
    >({
      query: (params) => ({
        url: '/scheduler/availability',
        method: 'GET',
        params,
      }),
    }),
    getSchedule: builder.mutation<GetScheduleResponse, GetScheduleRequest>({
      query: (params) => ({
        url: '/scheduler/schedule',
        method: 'GET',
        params,
      }),
    }),
    getExcludedDates: builder.mutation<
      GetExcludedDatesResponse,
      GetExcludedDatesRequest
    >({
      query: (params) => ({
        url: '/scheduler/excluded_dates',
        method: 'GET',
        params,
      }),
    }),
    getUnifiedLink: builder.query<any, GetUnifiedLinkRequest>({
      query: (params) => ({
        url: '/links/unified',
        method: 'GET',
        params,
      }),
    }),
    getOnboardingLink: builder.query<any, GetUnifiedLinkRequest>({
      query: (params) => ({
        url: '/links/onboarding',
        method: 'GET',
        params,
      }),
    }),
    postSchedule: builder.mutation<GetScheduleResponse, PostScheduleRequest>({
      query: (body) => ({
        url: '/scheduler/schedule',
        method: 'POST',
        body,
      }),
    }),
    getNotes: builder.mutation<Note[], string>({
      query: (userId) => ({
        url: '/notes',
        method: 'GET',
        params: { userId },
      }),
    }),
    postNote: builder.mutation<Note, any>({
      query: (body) => ({
        url: '/notes',
        method: 'POST',
        body,
      }),
    }),
    updateNote: builder.mutation<Note, any>({
      query: ({ id, body }) => ({
        url: `/notes/${id}`,
        method: 'PUT',
        body: { body },
      }),
    }),
    deleteNote: builder.mutation<Note, string>({
      query: (id) => ({
        url: `/notes/${id}`,
        method: 'DELETE',
      }),
    }),
    getUserSessionNotes: builder.query<any[], string>({
      query: () => ({
        url: '/user/session-notes',
        method: 'GET',
      }),
      providesTags: ['sessionNotes'],
    }),
    getUserHH: builder.query<any, string>({
      query: () => ({
        url: '/user/session-summaries',
        method: 'GET',
      }),
      providesTags: ['hh'],
    }),
    getSessionNotes: builder.query<SessionNote[], any>({
      query: ({ userId, eventId, coachId }) => ({
        url: '/session-notes',
        method: 'GET',
        params: { userId, eventId, coachId },
      }),
      providesTags: ['sessionNotes'],
    }),
    postSessionNote: builder.mutation<Note, PostSessionNoteRequest>({
      query: (body) => ({
        url: '/session-notes',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['sessionNotes'],
    }),
    updateSessionNote: builder.mutation<Note, UpdateSessionNoteRequest>({
      query: ({ id, body }) => ({
        url: `/session-notes/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['sessionNotes'],
    }),
    deleteSessionNote: builder.mutation<Note, string>({
      query: (id) => ({
        url: `/session-notes/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['sessionNotes'],
    }),
    getEventsWithNotes: builder.query<EventsWithNotes, any>({
      query: (userId) => ({
        url: 'scheduler/events_notes',
        method: 'GET',
        params: { userId },
      }),
      providesTags: ['sessionFeedback', 'sessionNotes'],
    }),
    getSessionStats: builder.query<UserProgress, void>({
      query: () => ({
        url: '/user/session-stats',
        method: 'GET',
      }),
    }),
    getCallToolList: builder.query<any, void>({
      query: () => ({
        url: '/user/call-tool-options',
        method: 'GET',
      }),
    }),
    postReview: builder.mutation<any, PostReviewRequest>({
      query: (body) => ({
        url: '/reviews',
        method: 'POST',
        body,
      }),
    }),
    postSessionUserFeedback: builder.mutation<
      any,
      PostSessionUserFeedbackRequest
    >({
      query: (body) => ({
        url: '/feedback/user',
        method: 'POST',
        body,
      }),
    }),
    postSessionSummaryFeedback: builder.mutation<
      any,
      PostSessionSummaryFeedbackRequest
    >({
      query: (body) => ({
        url: '/feedback/session-summary',
        method: 'POST',
        body,
      }),
    }),
    postMatch: builder.mutation<UserResponse, string>({
      query: (coachId) => ({
        url: '/users/match',
        method: 'POST',
        body: { coachId },
      }),
    }),
    getCoachBio: builder.query<GetCoachBioResponse, void>({
      query: () => ({
        url: '/user/coach_bio',
        method: 'GET',
      }),
      providesTags: ['coach'],
    }),
    getUserNotes: builder.query<UserNoteResponse[], void>({
      query: () => ({
        url: 'user/note',
        method: 'GET',
      }),
      providesTags: ['userNotes'],
    }),
    postUserNote: builder.mutation<UserNoteResponse, UserNote>({
      query: (body) => ({
        url: 'user/note',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['userNotes'],
    }),
    deleteUserNote: builder.mutation<UserNoteResponse, string>({
      query: (noteId) => ({
        url: `user/note/${noteId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['userNotes'],
    }),
    updateUserNote: builder.mutation<UserNoteResponse, any>({
      query: ({ id, body }) => ({
        url: `user/note/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['userNotes'],
    }),
    getMeeting: builder.query<MeetingResponse, MeetingRequest>({
      query: (params) => ({
        url: '/meetings',
        method: 'GET',
        params,
      }),
    }),
    getOnboardingQuestions: builder.query<any, any>({
      query: (params) => ({
        url: '/onboarding/questions',
        method: 'GET',
        params,
      }),
    }),
    getOnboardingFeedbackQuestions: builder.query<any, any>({
      query: ({ companyId, language }) => ({
        url: `/onboarding/feedback/questions?companyId=${companyId}&lang=${language}`,
        method: 'GET',
      }),
    }),
    getSurveyAnswers: builder.query<any, string>({
      query: (userId) => ({
        url: '/onboarding/answers',
        method: 'GET',
        params: { userId },
      }),
    }),
    getUserAssessmentAnswers: builder.query<any, any>({
      query: (userId) => ({
        url: '/assessment/results/user',
        method: 'GET',
        params: { userId },
      }),
    }),
    getManagerAssessmentAnswers: builder.query<any, any>({
      query: (userId) => ({
        url: '/assessment/results/manager',
        method: 'GET',
        params: { userId },
      }),
    }),
    getPeerAssessmentAnswers: builder.query<any, any>({
      query: (userId) => ({
        url: '/assessment/results/peer',
        method: 'GET',
        params: { userId },
      }),
    }),
    getAssessmentValueScores: builder.query({
      query: () => ({
        url: '/assessment/value-scores',
        method: 'GET',
      }),
      providesTags: ['assessment'],
    }),
    getAssessmentStatus: builder.query({
      query: (params) => ({
        url: '/assessment/status',
        method: 'GET',
        params,
      }),
      providesTags: ['assessment'],
    }),
    getMe: builder.mutation<GetMeResponse, any>({
      query: (userId) => ({
        url: `/user?userId=${userId}`,
        method: 'GET',
      }),
    }),
    postOnboardingData: builder.mutation<any, any>({
      query: (body) => ({
        url: '/onboarding',
        method: 'POST',
        body,
      }),
    }),
    postOnboardingProfileData: builder.mutation({
      query: (body) => ({
        url: '/onboarding/profile',
        method: 'POST',
        body,
      }),
    }),
    postOnboardingInfo: builder.mutation({
      query: (body) => ({
        url: '/onboarding/info',
        method: 'POST',
        body,
      }),
    }),
    postOnboardingFeedbackData: builder.mutation<any, any>({
      query: (body) => ({
        url: '/onboarding/feedback',
        method: 'POST',
        body,
      }),
    }),
    postManagerAssessmentData: builder.mutation<any, any>({
      query: (body) => ({
        url: '/manager/assessment',
        method: 'POST',
        body,
      }),
    }),
    postPeerAssessmentData: builder.mutation<any, any>({
      query: (body) => ({
        url: '/peer/assessment',
        method: 'POST',
        body,
      }),
    }),
    postReporteeAssessmentData: builder.mutation<any, any>({
      query: (body) => ({
        url: '/reportee/assessment',
        method: 'POST',
        body,
      }),
    }),
    postShareManagerAssessmentWithUser: builder.mutation<any, any>({
      query: (body) => ({
        url: '/manager/assessment/share_with_user',
        method: 'POST',
        body,
      }),
    }),
    userExists: builder.query<any, any>({
      query: (email) => ({
        url: `/user_exists?email=${encodeURIComponent(email)}`,
        method: 'GET',
      }),
    }),
    getPlans: builder.query<any, any>({
      query: (userId) => ({
        url: 'plan/plans',
        method: 'GET',
        params: { userId },
      }),
      providesTags: ['plans'],
    }),
    getCredits: builder.query<any, void>({
      query: () => ({
        url: 'user/credits',
        method: 'GET',
      }),
      providesTags: ['credits'],
    }),
    createPlan: builder.mutation<any, any>({
      query: (data) => ({
        url: 'plan/plans',
        body: data,
        method: 'POST',
      }),
      invalidatesTags: ['plans'],
    }),
    updatePlan: builder.mutation<any, any>({
      query: ({ data, planId }) => ({
        url: `plan/plans/${planId}`,
        body: data,
        method: 'PUT',
      }),
      invalidatesTags: ['plans'],
    }),
    deletePlan: builder.mutation<any, any>({
      query: (planId: string) => ({
        url: `plan/plans/${planId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['plans'],
    }),
    isUserAllowed: builder.query({
      query: (params) => ({
        url: `/onboarding/user_allowed/${params.email}/${params.companyId}`,
        method: 'GET',
        params,
      }),
    }),
    getOnboardingCoachOptions: builder.query<
      any,
      GetOnboardingCoachOptionsRequest
    >({
      query: (params: GetOnboardingCoachOptionsRequest) => ({
        url: '/onboarding/coach_options',
        method: 'GET',
        params,
      }),
      providesTags: ['coach', 'coach-selection'],
    }),
    postOnboardingCoachSelection: builder.mutation<
      any,
      OnboardingCoachSelectionRequest
    >({
      query: (body: OnboardingCoachSelectionRequest) => ({
        url: '/onboarding/coach_selection',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['coach-selection'],
    }),
    matchWithCoach: builder.mutation<any, any>({
      query: ({ coachId }) => ({
        url: '/users/match',
        method: 'POST',
        body: { coachId },
      }),
      invalidatesTags: ['coach', 'userProfile'],
    }),
    getAssessmentQuestions: builder.query<any, GetAssessmentQuestionsRequest>({
      query: (params: GetAssessmentQuestionsRequest) => ({
        url: '/assessment/questions',
        method: 'GET',
        params,
      }),
    }),
    getReporteeAssessmentQuestions: builder.query({
      query: (params: GetManagerPeerAssessmentQuestionsRequest) => ({
        url: '/reportee/assessment/questions',
        method: 'GET',
        params,
      }),
    }),
    getManagerAssessmentQuestions: builder.query({
      query: (params: GetManagerPeerAssessmentQuestionsRequest) => ({
        url: '/manager/assessment/questions',
        method: 'GET',
        params,
      }),
    }),
    getPeerAssessmentQuestions: builder.query({
      query: (params: GetManagerPeerAssessmentQuestionsRequest) => ({
        url: '/peer/assessment/questions',
        method: 'GET',
        params,
      }),
    }),
    postAssessmentAnswers: builder.mutation({
      query: (body) => ({
        url: '/assessment',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['assessment'],
    }),
    saveAssessmentAnswer: builder.mutation({
      query: (body) => ({
        url: '/assessment/answer',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['assessment'],
    }),
    getAssessmentDates: builder.query<any, void>({
      query: () => ({
        url: '/assessment/dates',
        method: 'GET',
      }),
      providesTags: ['assessment'],
    }),
    getUserPlans: builder.query<any, void>({
      query: () => ({
        url: '/progress/plans',
        method: 'GET',
      }),
    }),
    getUserActivities: builder.query<any, void>({
      query: () => ({
        url: '/progress/activities',
        method: 'GET',
      }),
    }),
    getSessionFeedback: builder.mutation<any[], any>({
      query: ({ userId, eventId, coachId }) => ({
        url: '/session-feedback',
        method: 'GET',
        params: { userId, eventId, coachId },
      }),
    }),
    postSessionFeedback: builder.mutation<Note, PostSessionNoteRequest>({
      query: (body) => ({
        url: '/session-feedback',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['sessionFeedback'],
    }),
    updateSessionFeedback: builder.mutation<Note, UpdateSessionNoteRequest>({
      query: ({ id, body }) => ({
        url: `/session-feedback/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['sessionFeedback'],
    }),
    getAssessmentInsights: builder.query<AsessmentInsights[], any>({
      query: () => ({
        url: '/assessment/insights',
      }),
      providesTags: ['assessmentInsights'],
    }),
    getAssessmentResults: builder.query<AssessmentResults, string>({
      query: (userId) => ({
        url: '/assessment/results',
        method: 'GET',
        params: { userId },
      }),
    }),
    getSurvey: builder.query<any, any>({
      query: (id) => ({
        url: `feedback-survey/${id}`,
        method: 'GET',
      }),
    }),
    postSurveyAnswers: builder.mutation<any, any>({
      query: (body) => ({
        url: `/feedback-survey/${body.sessionId}`,
        method: 'POST',
      }),
    }),
    answerSurveyQuestion: builder.mutation<any, any>({
      query: (body) => {
        const { sessionId, questionId, ...rest } = body

        return {
          url: `/feedback-survey/${sessionId}/${questionId}`,
          method: 'POST',
          body: rest,
        }
      },
    }),
    getBookingConfig: builder.query({
      query: () => ({
        url: '/scheduler/config',
        method: 'GET',
      }),
    }),
    getUserMidpointCheckinRequired: builder.query({
      query: (userId) => ({
        url: '/coaches/user/midpoint-checkin-required',
        method: 'GET',
        params: { userId },
      }),
    }),
    getCoachFocusAreas: builder.query({
      query: () => ({
        url: '/coaches/focus_areas',
        method: 'GET',
      }),
    }),
    getCoachIndustries: builder.query({
      query: () => ({
        url: '/coaches/industries',
        method: 'GET',
      }),
    }),
    userReport: builder.mutation({
      query: (body) => ({
        url: '/user/report',
        method: 'POST',
        body,
      }),
    }),
    userBooking: builder.mutation<any, any>({
      query: (body) => ({
        url: '/user/booking_request',
        method: 'POST',
        body,
      }),
    }),
    getCoachBioById: builder.query<GetCoachBioResponse, string>({
      query: (coachId) => ({
        url: `/coaches/bio/${coachId}`,
        method: 'GET',
      }),
    }),
    postVerifyHupoHighlightsSummary: builder.mutation({
      query: (body) => ({
        url: '/session-summary/verify',
        method: 'POST',
        body,
      }),
    }),
    getHupoHighlightsSummary: builder.query({
      query: ({ eventId }) => ({
        url: `/session-summary`,
        method: 'GET',
        params: { eventId },
      }),
    }),
    postHupoHighlightsSummary: builder.mutation({
      query: (body) => ({
        url: '/session-summary/',
        method: 'POST',
        body,
      }),
    }),
    getMeetingInfo: builder.query({
      query: ({ eventId, participant }) => ({
        url: `/dyte/meeting/`,
        method: 'GET',
        params: { eventId, participant },
      }),
    }),
    enableSessionSummaries: builder.mutation({
      query: () => ({
        url: '/user/session-summaries/enable',
        method: 'POST',
      }),
    }),

    getPeerAssessment: builder.query<PeerAssessment, Record<string, string>>({
      query: (params) => ({
        params,
        url: '/v2/peer/assessment/questions',
        method: 'GET',
      }),
      providesTags: ['peerAssessment'],
    }),
    postPeerAssessment: builder.mutation<any, any>({
      query: (body) => ({
        body,
        url: '/v2/peer/assessment',
        method: 'POST',
      }),
      invalidatesTags: ['peerAssessment'],
    }),
    savePeerAssessmentAnswer: builder.mutation<any, any>({
      query: (body) => ({
        body,
        url: '/v2/peer/assessment/answer',
        method: 'POST',
      }),
      invalidatesTags: ['peerAssessment'],
    }),
    getRespondentStatus: builder.query<any, any>({
      query: (params) => ({
        url: '/v2/user/assessment/respondent-status',
        method: 'GET',
        params,
      }),
      providesTags: ['respondentStatus'],
    }),
    nudgePeerAssessment: builder.mutation<any, any>({
      query: (params) => ({
        url: '/v2/user/assessment/nudge-peer',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['respondentStatus'],
    }),
    updateRespondent: builder.mutation<any, any>({
      query: (params) => ({
        url: '/v2/user/assessment/update-respondent',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['respondentStatus'],
    }),
    getLeadershipValueScores: builder.query<any, any>({
      query: () => ({
        url: '/v2/user/assessment/leadership-value-scores',
        method: 'GET',
      }),
      providesTags: ['assessment', 'respondentStatus'],
    }),
    getLeadershipValueScoresForReport: builder.query<any, any>({
      query: (userId) => ({
        url: `/v2/user/${userId}/assessment/leadership-value-scores-report`,
        method: 'GET',
      }),
    }),
    getAvailableRecurringDates: builder.mutation<any, any>({
      query: (params: any) => ({
        url: '/scheduler/available-recurring-dates',
        method: 'GET',
        params,
      }),
    }),
    getResources: builder.query<Resource[], void>({
      query: () => '/user/resources',
      transformResponse: (response: { data: Resource[] }) => response.data,
    }),
    getResourceRecommendations: builder.query<any, void>({
      query: () => '/user/resources/recommendations',
      transformResponse: (response: { data: any }) => response.data,
    }),
    pinResource: builder.mutation<void, string>({
      query: (resourceId) => ({
        url: `/user/resources/${resourceId}/pin`,
        method: 'POST',
      }),
    }),
    unpinResource: builder.mutation<void, string>({
      query: (resourceId) => ({
        url: `/user/resources/${resourceId}/unpin`,
        method: 'POST',
      }),
    }),
    getHighlights: builder.query<
      {
        sections: string[]
        summaries: HighlightSummary[]
      },
      void
    >({
      query: () => ({
        url: `/user/notes/session-summaries`,
      }),
    }),
  }),
})

export const {
  useGetMeMutation,
  useLoginMutation,
  useSignupMutation,
  useLazyGetCoachQuery,
  usePasswordForgotMutation,
  usePasswordResetMutation,
  useDeleteMessageMutation,
  usePostMessagesReadMutation,
  useGetEventsQuery,
  useGetAllEventsQuery,
  useGetEventMutation,
  useDeleteEventMutation,
  useCreateEventMutation,
  useUpdateEventMutation,
  useCreateBlockEventMutation,
  useCreateOpenSlotMutation,
  useGetSchedulerConfigQuery,
  useGetCompanyQuery,
  useGetInvitationMutation,
  useGetCompaniesMutation,
  useGetUsersQuery,
  useLazyGetUserProfileQuery,
  usePostUserProfileMutation,
  useDeleteUserMutation,
  useGetUserMutation,
  useGetRTCTokenMutation,
  useGetAvailabilityMutation,
  useGetScheduleMutation,
  usePostScheduleMutation,
  useGetExcludedDatesMutation,
  useGetNotesMutation,
  usePostNoteMutation,
  useUpdateNoteMutation,
  useDeleteNoteMutation,
  useGetSessionNotesQuery,
  useLazyGetSessionNotesQuery,
  usePostSessionNoteMutation,
  useUpdateSessionNoteMutation,
  useDeleteSessionNoteMutation,
  useGetEventsWithNotesQuery,
  usePostReviewMutation,
  usePostSessionUserFeedbackMutation,
  usePostMatchMutation,
  useLazyGetCoachBioQuery,
  useGetUserNotesQuery,
  usePostUserNoteMutation,
  useDeleteUserNoteMutation,
  useUpdateUserNoteMutation,
  useGetSurveyAnswersQuery,
  usePostOnboardingDataMutation,
  usePostManagerAssessmentDataMutation,
  usePostPeerAssessmentDataMutation,
  usePostReporteeAssessmentDataMutation,
  useGetPlansQuery,
  useGetCreditsQuery,
  useCreatePlanMutation,
  useUpdatePlanMutation,
  useDeletePlanMutation,
  useGetOnboardingQuestionsQuery,
  useGetOnboardingCoachOptionsQuery,
  useLazyGetManagerAssessmentQuestionsQuery,
  useLazyGetPeerAssessmentQuestionsQuery,
  useLazyGetAssessmentQuestionsQuery,
  useLazyGetReporteeAssessmentQuestionsQuery,
  usePostAssessmentAnswersMutation,
  usePostShareManagerAssessmentWithUserMutation,
  usePostOnboardingFeedbackDataMutation,
  useGetAssessmentDatesQuery,
  useGetUserPlansQuery,
  useGetUserActivitiesQuery,
  useFetchUserQuery,
  useFetchMessagesQuery,
  useGetUserAssessmentAnswersQuery,
  useGetManagerAssessmentAnswersQuery,
  useGetPeerAssessmentAnswersQuery,
  useMatchWithCoachMutation,
  useGetSessionFeedbackMutation,
  usePostSessionFeedbackMutation,
  useUpdateSessionFeedbackMutation,
  useGetAssessmentInsightsQuery,
  useGetAssessmentResultsQuery,
  useGetAssessmentValueScoresQuery,
  useGetSurveyQuery,
  usePostSurveyAnswersMutation,
  useAnswerSurveyQuestionMutation,
  useGetBookingConfigQuery,
  useLazyGetUserMidpointCheckinRequiredQuery,
  useGetMeetingQuery,
  useGetUserSessionNotesQuery,
  useGetSessionStatsQuery,
  useGetCallToolListQuery,
  useGetCoachFocusAreasQuery,
  useGetCoachIndustriesQuery,
  usePostOnboardingCoachSelectionMutation,
  useUserReportMutation,
  useUserBookingMutation,
  usePostSessionSummaryFeedbackMutation,
  useLazyGetCoachBioByIdQuery,
  usePostVerifyHupoHighlightsSummaryMutation,
  useGetHupoHighlightsSummaryQuery,
  usePostHupoHighlightsSummaryMutation,
  useGetMeetingInfoQuery,
  useGetUnifiedLinkQuery,
  useGetOnboardingLinkQuery,
  useGetUserHHQuery,
  useEnableSessionSummariesMutation,
  useGetPeerAssessmentQuery,
  usePostPeerAssessmentMutation,
  useSavePeerAssessmentAnswerMutation,
  useSaveAssessmentAnswerMutation,
  useGetAssessmentStatusQuery,
  usePostOnboardingProfileDataMutation,
  usePostOnboardingInfoMutation,
  useGetRespondentStatusQuery,
  useNudgePeerAssessmentMutation,
  useUpdateRespondentMutation,
  useGetLeadershipValueScoresQuery,
  useGetLeadershipValueScoresForReportQuery,
  useGetAvailableRecurringDatesMutation,
  useGetResourcesQuery,
  useGetResourceRecommendationsQuery,
  usePinResourceMutation,
  useUnpinResourceMutation,
  useGetHighlightsQuery,
  useGetUserInfoQuery,
} = api
