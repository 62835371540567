import { styled } from "@mui/material";

export const APWrapper = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  gap: "16px",
  padding: "12px",
  alignItems: "center",
  height: "70px",
  background: theme.palette.background.paper,
  ".progressContent": {
    position: "relative",
    background: theme.palette.grey[200],
    height: "10px",
    overflow: "hidden",
    borderRadius: "100px",
    ".progressBar": {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      background: "var(--color)",
      width: "var(--progress-width)",
      transition: "all .7s",
    },
  },
  "&.noBack": {
    gridTemplateColumns: "1fr",
  },
}));
