import type { LoaderFunction } from 'react-router-dom'
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { api } from '~/app/api'

export interface Response {
  available: any
  completed: number
  recommended: number
  allAccess: boolean
  progressStatus: string
}

const fetchData = (params: string) =>
  queryOptions({
    queryKey: ['session_stats'],
    queryFn() {
      return api.url('/user/session-stats').query(params).get().json<Response>()
    },
  })

export const loader = (queryClient: QueryClient): LoaderFunction => (args) => {
  const url = new URL(args.request.url)
  return queryClient.fetchQuery(fetchData(url.searchParams.toString()))
}
