// import { darken } from '@material-ui/core'
import { styled, darken } from '@mui/material'

export const QuestionWrapper = styled('div')(() => ({
  display: 'grid',
  gridTemplateRows: 'auto auto 1fr auto',
  height: '100%',
  background: 'rgba(255,255,255,.93)',
  '@media (max-width: 600px)': {
    height: 'auto',
  },
}))

export const QuestionHeader = styled('div')(() => ({
  padding: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '6px',
  maxWidth: '600px',
  margin: '0 auto',
  '@media (max-width: 600px)': {
    maxWidth: '100%',
    padding: '12px 24px',
  },
  h1: {
    textAlign: 'center',
    font: '600 24px Poppins, sans-serif',
    lineHeight: '30px',
    '@media (max-width: 600px)': {
      fontSize: '20px',
      lineHeight: '28px',
    },
  },
}))

export const QuestionFooter = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '12px',
  '@media (max-width: 600px)': {
    padding: '12px 24px',
  },
  button: {
    minWidth: '70%',
    height: '50px',
    fontSize: '18px',
    '@media (max-width: 600px)': {
      minWidth: '100%',
      fontSize: '16px',
    },
  },
}))

export const ListQuestionItemsWrapper = styled('div')(({ theme }) => ({
  maxWidth: '600px',
  margin: '0 auto',
  display: 'grid',
  gap: '12px',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  marginTop: '16px',
  '@media (max-width: 600px)': {
    maxWidth: '100%',
    padding: '0 24px',
  },
  div: {
    background: theme.palette.grey[100],
    padding: '16px',
    textAlign: 'center',
    borderRadius: '8px',
    font: '400 16px Poppins, sans-serif',
    userSelect: 'none',
  },
}))

export const ValueScoreList = styled('div')(({ theme }) => ({
  width: '600px',
  margin: '0 auto',
  display: 'grid',
  gap: '12px',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  marginTop: '16px',
  '@media (max-width: 600px)': {
    width: '100%',
    padding: '0 24px',
  },
  div: {
    background: theme.palette.grey[200],
    padding: '16px',
    textAlign: 'center',
    borderRadius: '12px',
    font: '400 16px Poppins, sans-serif',
    userSelect: 'none',
    cursor: 'pointer',
    ':hover': {
      background: theme.palette.grey[100],
    },
    ':active': {
      background: darken(theme.palette.primary.light, 0.1),
    },
    '&.active': {
      background: theme.palette.primary.light,
      color: theme.palette.primary.main,
      fontWeight: '500',
    },
  },
}))

export const LeadershipValueItem = styled('div')(({ theme }) => ({
  background: theme.palette.grey[100],
  display: 'grid',
  borderRadius: '12px',
  padding: '12px',
  border: `1px solid ${theme.palette.divider}`,
  '@media (max-width: 600px)': {
    padding: '12px 24px',
  },
  '.lvTitle': {
    font: '400 18px Poppins, sans-serif',
    textAlign: 'center',
    padding: '16px',
    '@media (max-width: 600px)': {
      fontSize: '16px',
    },
    '.borderFull': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  '.lvContent': {
    padding: '0px 16px 16px 16px',
  },
}))
