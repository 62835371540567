import { Box, Flex, Text } from '@chakra-ui/react'
import { FC } from 'react'
import { BiCheck } from 'react-icons/bi'

export interface VPCItem {
  _id: string
  title: string
  step: number
  description: string
}

export interface VPCInterface {
  items: []
  currentStep: number
}

const VerticalProgressComponent: FC<VPCInterface> = ({
  currentStep,
  items,
}) => {
  return (
    <Flex direction="column" justify="flex-start" align="flex-start">
      {items.map((item: VPCItem, index: number) => (
        <Flex
          key={item._id}
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Flex
            gridColumnGap={5}
            justify="flex-start"
            alignItems="center"
            style={{
              opacity: currentStep - 1 < index ? 0.4 : 1,
            }}
          >
            <Box
              style={{
                borderRadius: '100px',
                backgroundColor: 'var(--chakra-colors-primary-500)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              w={['50px', '60px']}
              h={['50px', '60px']}
            >
              <Box
                w={['40px', '50px']}
                h={['40px', '50px']}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor:
                    currentStep - 1 > index
                      ? 'var(--chakra-colors-primary-500)'
                      : '#fff',
                  borderRadius: 100,
                }}
              >
                {currentStep - 1 > index ? (
                  <BiCheck color="#fff" fontSize="40px" />
                ) : (
                  <Text fontSize={['2xl', '3xl']} fontWeight="600">
                    {index + 1}
                  </Text>
                )}
              </Box>
            </Box>
            <Flex direction="column">
              <Text fontSize={['lg', 'lg', 'xl']} fontWeight="600">
                {item.title}
              </Text>
              <Text fontSize={['md', 'md', 'lg']}> {item.description} </Text>
            </Flex>
          </Flex>
          {index < items.length - 1 && (
            <Box
              w={'5px'}
              h={['40px', '60px']}
              ml={['22px', '32px']}
              style={{
                backgroundColor: 'var(--chakra-colors-primary-500',
                opacity: currentStep - 2 < index ? 0.4 : 1,
              }}
            />
          )}
        </Flex>
      ))}
    </Flex>
  )
}

export default VerticalProgressComponent
