import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Spinner,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import { usePostReviewMutation } from '../../app/services/api'
import { useLocale } from '../../hooks/useLocale'
import Rating from './Rating'

const REDIRECT_SECONDS = 3
const HOST = `${window.location.protocol}//${window.location.host}`

const SessionReview = () => {
  const { eventId, coachName, rating: ratingParam } = useParams() as any
  const [rating, setRating] = useState(ratingParam)
  const [comment, setComment] = useState('')
  const [isRedirecting, setIsRedirecting] = useState(false)
  const toast = useToast()
  const { t } = useLocale()

  const [postReview, { isLoading }] = usePostReviewMutation()

  const onSubmit = useCallback(
    async (event: any) => {
      event.preventDefault()
      try {
        await postReview({ eventId, rating, comment })
        toast({
          title: t('review.success'),
          description: t('review.success_description'),
          status: 'success',
        })
      } catch (err: any) {
        console.log(err)
        toast({
          status: 'error',
          title: t('error'),
          description: err?.data?.message || t('there_was_an_error'),
          isClosable: true,
        })
      }
      setIsRedirecting(true)
      setTimeout(() => {
        window.location.href = HOST
      }, REDIRECT_SECONDS * 1000)
    },
    [eventId, postReview, comment, rating, t, toast]
  )

  if (isLoading || isRedirecting) {
    return (
      <Center h="200px" color="white">
        <Heading color="gray.500">{t('redirecting')}</Heading>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Center>
    )
  }

  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading
        display="inline-block"
        as="h5"
        size="lg"
        bgColor={
          rating >= 4 ? 'green.400' : rating >= 2 ? 'yellow.400' : 'red.400'
        }
        backgroundClip="text"
      >
        {t('review.title', { coachName })}
      </Heading>
      <Rating
        size={48}
        icon="star"
        scale={5}
        fillColor="gold"
        strokeColor="grey"
        rating={rating}
        setRating={setRating}
      />
      <Flex width="100%" justifyContent="center" mb={5}>
        <Textarea
          fontSize={['sm', 'md', 'lg']}
          placeholder={t('review.comment')}
          mt={4}
          borderColor="gray.200"
          borderWidth="1px"
          borderRadius="md"
          width="330px"
          height="100px"
          resize="none"
          value={comment}
          onChange={(event) => setComment(event.target.value)}
        />
      </Flex>
      <Button onClick={onSubmit} disabled={isLoading}>
        {t('submit')}
      </Button>
    </Box>
  )
}

export default SessionReview
