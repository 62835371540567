import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { useLocale } from '../../hooks/useLocale'
import { useMixpanel } from '../../utils/MixpanelContext'
import { Link } from 'react-router-dom'

export const AskPopUp: React.FC<any> = ({
  isOpen,
  keepLookingAndClose,
  triedDates,
  setMergeSlots,
  setRefetchAvailability,
}) => {
  const [bookASAP, setBookASAP] = useState(false)
  const [bookWCurrentCoach, setBookWCurrentCoach] = useState(false)
  const { t } = useLocale()
  const mixpanel = useMixpanel()

  useEffect(() => {
    mixpanel.track('appointment_time_preference_modal_shown', {
      datesTried: triedDates,
    })
  }, [triedDates, mixpanel])

  useEffect(() => {
    return () => {
      setBookASAP(false)
      setBookWCurrentCoach(false)
    }
  }, [])

  const onBookASAP = () => {
    mixpanel.track('appointment_time_preference_selected', {
      option: 'book_asap',
    })
    setBookASAP(true)
    setMergeSlots(true)
    setRefetchAvailability((prev: boolean) => !prev)
    keepLookingAndClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={keepLookingAndClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('cant_find_preferred_slot')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            gap="5"
            justifyContent="center"
          >
            {!bookASAP && !bookWCurrentCoach && (
              <>
                <Box display="flex" flexDirection="column">
                  <Text pt={10}>1. {t('talk_with_coach_find_slot')}</Text>
                  <Button
                    as={Link}
                    to="/chat"
                    variant="outline"
                    borderColor="primary.500"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop={2}
                    onClick={() => {
                      mixpanel.track('appointment_time_preference_selected', {
                        option: 'book_with_current_coach',
                      })
                    }}
                  >
                    {t('find_slot_with_my_coach')}
                  </Button>
                  <Text mt={6}>
                    2. {t('change_coach_and_find_nearest_time')}
                  </Text>
                  <Text color="primary.500" fontSize={13}>
                    {t('change_coach_and_find_nearest_time_coach_switch')}
                  </Text>
                  <Button
                    variant="outline"
                    borderColor="primary.500"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop={2}
                    onClick={onBookASAP}
                  >
                    {t('view_all_available_coach_slots')}
                  </Button>
                </Box>

                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      localStorage.setItem(
                        'hidePopupForBookingAttempts',
                        'true'
                      )
                    } else {
                      localStorage.removeItem('hidePopupForBookingAttempts')
                    }
                  }}
                >
                  {t('dont_show_again')}
                </Checkbox>
              </>
            )}
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mb="10"
          >
            {bookASAP && (
              <>
                <Text mb={4}>{t('you_can_change_coach')}</Text>
                <Button as={Link} to="/change-coach" colorScheme="primary">
                  {t('change_coach')}
                </Button>
              </>
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
