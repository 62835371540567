import { alpha, createTheme } from "@mui/material";

export const appTheme = createTheme({
  palette: {
    primary: {
      main: "#ff480b",
      contrastText: "#ffffff",
      dark: "#cb2800",
      light: "#fde9e6",
      "50": "#fde9e6",
      "100": "#ffcbb9",
      "200": "#ffa98d",
      "300": "#ff875e",
      "400": "#ff6b39",
      "500": "#ff4e10",
      "600": "#ff480b",
      "700": "#f14004",
      "800": "#e33800",
      "900": "#cb2800",
    },
    background: {
      paper: "#ffffff",
      default: "#F7F8F9",
    },
  },
  typography: {
    allVariants: {
      fontFamily: "Poppins, sans-serif",
    },
  },
  transitions: {
    duration: {
      enteringScreen: 0,
      leavingScreen: 0,
      complex: 0,
      short: 0,
      shorter: 0,
      shortest: 0,
      standard: 0,
    },
  },
  shape: {
    borderRadius: 12,
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          userSelect: "none",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          fontFamily: "Poppins, sans-serif",
        },
      },
      variants: [
        {
          props: {
            className: "circular",
          },
          style: {
            borderRadius: "100px",
          },
        },
        {
          props: {
            variant: "soft",
            color: "primary",
          },
          style: ({ theme }) => ({
            background: theme.palette.primary.light,
            color: theme.palette.primary.main,
            ":hover": {
              background: theme.palette.primary.light,
              filter: "brightness(95%)",
            },
            ":disabled": {
              background: theme.palette.grey[200],
            },
          }),
        },
        {
          props: {
            variant: "soft",
            color: "error",
          },
          style: ({ theme }) => ({
            background: alpha(theme.palette.error.light, 0.2),
            color: theme.palette.error.main,
            ":hover": {
              background: alpha(theme.palette.error.light, 0.2),
              filter: "brightness(115%)",
            },
          }),
        },
      ],
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          font: "400 14px Poppins, sans-serif",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: ({ theme }) => ({
          boxShadow: theme.shadows[3],
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#E5E5E5",
        },
      },
    },
    MuiFormControl: {
      variants: [
        // Override filled input
        {
          props: {
            variant: "filled",
          },
          style: ({ theme }) => ({
            font: "500 14px Poppins, sans-serif",
            ".MuiInputBase-root": {
              borderRadius: "12px",
              background: alpha(theme.palette.grey[50], 0.8),
              border: `1px solid ${theme.palette.grey[300]}`,
              "&.Mui-focused": {
                border: `1px solid ${theme.palette.primary.main}`,
              },
              "&:hover,&.Mui-focused": {
                background: theme.palette.grey[50],
                border: `1px solid ${theme.palette.grey[500]}`,
              },
              "&.Mui-disabled": {
                background: theme.palette.grey[200],
                border: `1px solid ${theme.palette.grey[300]}`,
              },
              button: {
                font: "500 12px Poppins, sans-serif",
                color: theme.palette.grey[700],
              },
              "&.Mui-error": {
                background: alpha(theme.palette.error.light, 0.05),
                border: `1px solid ${theme.palette.error.main}`,
                button: {
                  color: theme.palette.error.main,
                },
              },
            },
            ".MuiFormHelperText-root": {
              font: "400 12px Poppins, sans-serif",
              marginLeft: 0,
            },
          }),
        },
        // Override standard input
        {
          props: {
            variant: "standard",
          },
          style: ({ theme }) => ({
            ".MuiInputBase-root": {
              borderRadius: "0 !important",
              border: "none",
              borderBottom: `1px solid ${theme.palette.grey[400]}`,
              "&:hover,&.Mui-focused": {
                borderBottom: `1px solid ${theme.palette.grey[600]}`,
              },
              button: {
                font: "500 12px Poppins, sans-serif",
                color: theme.palette.grey[700],
              },
              "&.Mui-error": {
                borderBottom: `1px solid ${theme.palette.error.main}`,
                button: {
                  color: theme.palette.error.main,
                },
              },
            },
            ".MuiFormHelperText-root": {
              font: "400 12px Poppins, sans-serif",
              marginLeft: 0,
            },
          }),
        },
        // Filled input sizes
        {
          props: {
            size: "medium",
            variant: "filled",
          },
          style: ({ theme }) => ({
            ".MuiSelect-select,input": {
              padding: "18px 12px 4px 12px",
              font: "500 14px Poppins, sans-serif",
            },
            "&.phoneField input": {
              padding: "18px 32px 4px 0px",
            },
            ".MuiInputAdornment-root p": {
              font: "500 14px Poppins, sans-serif",
              color: theme.palette.grey[900],
            },
            label: {
              transition: ".05s",
              transform: "translate(12px,14px)",
              font: "500 14px Poppins, sans-serif",
              ["&[data-shrink=true]"]: {
                transform: "translate(12px, 4px) scale(0.75)",
                color: theme.palette.grey[800],
              },
            },
            [":has(input[type=color]) label"]: {
              transform: "translate(12px, 4px) scale(0.75)",
              color: theme.palette.grey[800],
            },
          }),
        },
        {
          props: {
            size: "small",
            variant: "filled",
          },
          style: ({ theme }) => ({
            ".MuiSelect-select, input": {
              padding: "16px 32px 2px 10px !important",
              font: "500 14px Poppins, sans-serif",
            },
            "&.phoneField input": {
              padding: "16px 32px 2px 10px !important",
            },
            ".MuiInputAdornment-root p": {
              font: "500 14px Poppins, sans-serif",
              color: theme.palette.grey[900],
            },
            label: {
              transition: ".05s",
              transform: "translate(14px,10px)",
              font: "500 14px Poppins, sans-serif",
              ["&[data-shrink=true]"]: {
                transform: "translate(12px, 2px) scale(0.75)",
                color: theme.palette.grey[800],
              },
            },
          }),
        },
        {
          props: {
            size: "mini",
            variant: "filled",
          },
          style: ({ theme }) => ({
            ".MuiSelect-select, input": {
              padding: "2px 4px 2px 4px !important",
              font: "500 14px Poppins, sans-serif",
            },
            "&.phoneField input": {
              padding: "2px 4px 2px 4px !important",
            },
            ".MuiInputAdornment-root p": {
              font: "500 10px Poppins, sans-serif",
              color: theme.palette.grey[900],
            },
            ".MuiFormHelperText-root": {
              font: "600 8px Poppins, sans-serif",
              whiteSpace: "initial",
            },
            label: {
              transition: ".05s",
              transform: "translate(14px,10px)",
              font: "500 12px Poppins, sans-serif",
              ["&[data-shrink=true]"]: {
                transform: "translate(12px, 2px) scale(0.75)",
                color: theme.palette.grey[800],
              },
            },
          }),
        },

        // Standard input sizes
        {
          props: {
            size: "medium",
            variant: "standard",
          },
          style: ({ theme }) => ({
            ".MuiSelect-select,input": {
              font: "500 14px Poppins, sans-serif",
            },
            ".MuiInputAdornment-root p": {
              font: "500 14px Poppins, sans-serif",
              color: theme.palette.grey[900],
            },
            label: {
              transition: ".05s",
              transform: "translate(0,14px)",
              font: "500 14px Poppins, sans-serif",
              ["&[data-shrink=true]"]: {
                transform: "translate(0, 4px) scale(0.75)",
                color: theme.palette.grey[800],
              },
            },
          }),
        },
        {
          props: {
            size: "small",
            variant: "standard",
          },
          style: ({ theme }) => ({
            ".MuiSelect-select, input": {
              font: "500 14px Poppins, sans-serif",
            },
            ".MuiInputAdornment-root p": {
              font: "500 14px Poppins, sans-serif",
              color: theme.palette.grey[900],
            },
            label: {
              transition: ".05s",
              transform: "translate(0,10px)",
              font: "500 14px Poppins, sans-serif",
              ["&[data-shrink=true]"]: {
                transform: "translate(0, 2px) scale(0.75)",
                color: theme.palette.grey[800],
              },
            },
          }),
        },
      ],
    },

    MuiFormControlLabel: {
      variants: [
        {
          props: {
            className: "form-checkbox-label",
          },
          style: {
            display: "flex",
            alignItems: "center",
            gap: "8px",
            margin: 0,
            ".MuiTypography-root": {
              font: "500 12px Poppins, sans-serif",
              userSelect: "none",
            },
            ".MuiButtonBase-root": {
              padding: 0,
            },
          },
        },
      ],
    },

    MuiDialog: {
      variants: [
        {
          props: {
            className: "confirmDialog",
          },
          style: {
            ".MuiPaper-root": {
              borderRadius: "12px",
              boxShadow: "none",
              maxWidth: "350px",
            },
          },
        },
      ],
      /* styleOverrides: {
        container: {
          transition: ".1s",
        },
        paper: {
          transition: ".1s",
        },
        paperFullScreen: {
          transition: ".1s",
        },
        root: {
          transition: ".1s",
        },
        paperFullWidth: {
          transition: ".1s",
        },
        scrollBody: {
          transition: ".1s",
        },
      }, */
    },
    MuiTab: {
      styleOverrides: {
        root: {
          font: "500 14px Poppins, sans-serif",
          textTransform: "none",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: ({ theme }) => ({
          borderRadius: "100px",
          height: 0,
          borderWidth: "1px",
          borderColor: theme.palette.primary.main,
        }),
      },
    },
  },
});
