import {
  Box,
  Center,
  Divider,
  Flex,
  Grid,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { FC } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { useGetAssessmentInsightsQuery } from '../../app/services/api'
import { getRatingPercentage } from '../../utils/getRatingpercentage'

const GrowthArea: FC = () => {
  const { data, isFetching } = useGetAssessmentInsightsQuery(null)
  const { t } = useTranslation()
  return isFetching || !data ? (
    <Box minHeight="200px" w={'full'} bg={'white'} rounded={'lg'} p={6}>
      <Center>
        <Spinner />
      </Center>
    </Box>
  ) : (
    <Grid gap="10px" bg={'white'} rounded={'lg'}>
      <Box w={'full'} px={6} pt={4} pb={2}>
        <Text fontWeight="700" fontSize="22px">
          {t('focus_areas')}
        </Text>
      </Box>
      <Divider />
      <Grid
        maxWidth="100%"
        gap="10px"
        templateColumns={['1fr', '1fr', '1fr', '1fr 1fr 1fr']}
      >
        {data.map((growthArea, index) => (
          <Box
            overflow="hidden"
            maxWidth="100%"
            transition={'.2s'}
            _hover={{ shadow: 'lg' }}
            key={`ga_${index}`}
            w={'full'}
            bg={'white'}
            // rounded={'lg'}
            p={6}
            borderRight="1px solid #E2E8F0"
          >
            <Flex
              height="100%"
              direction="column"
              justifyContent="space-between"
            >
              <ReactApexChart
                options={{
                  chart: {
                    height: 200,
                    type: 'radialBar',
                    redrawOnParentResize: true,
                    redrawOnWindowResize: true,
                  },
                  plotOptions: {
                    radialBar: {
                      hollow: {
                        size: '60%',
                      },
                      dataLabels: {
                        name: {
                          show: false,
                        },
                        value: {
                          offsetY: 5,
                          fontWeight: '600',
                          fontSize: '20',
                        },
                      },
                    },
                  },
                }}
                series={[getRatingPercentage(growthArea)]}
                type="radialBar"
                height={200}
              />

              <Text fontSize="16px" align="center" fontWeight="600">
                {data.length > 0 && growthArea.focusArea}
              </Text>
            </Flex>
          </Box>
        ))}
      </Grid>
    </Grid>
  )
}

export default GrowthArea
