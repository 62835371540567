import { Spinner, Stack, useDisclosure } from '@chakra-ui/react'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  useGetOnboardingCoachOptionsQuery,
  usePostOnboardingCoachSelectionMutation,
} from '../../../app/services/api'
import { RootState } from '../../../app/store'
import { useAuth } from '../../../hooks/useAuth'
import { useLocale } from '../../../hooks/useLocale'
import { useMixpanel } from '../../../utils/MixpanelContext'
import CoachInfo from '../../coaches/CoachInfo'
import OnboardingFooter from '../components/OnboardingFooter'
import OnboardingHeader from '../components/OnboardingHeader'
import OnboardingContainer from '../OnboardingContainer'
import { itemSelect } from '../onboardingSlice'
import { CoachSelectCard } from './CoachSelectionQuestion'
import TitleDescription from './TitleDescription'

const CoachPreselectedQuestion: FC<any> = ({
  index,
  onSubmit,
  submitting = false,
}) => {
  const { user } = useAuth()
  const onboardingQuestions = useSelector(
    (state: RootState) => state.onboarding.questions
  )
  const onboardingUserEmail = useSelector(
    (state: RootState) => state.onboarding.email
  )
  const onboardingInterfaceLanguage = useSelector(
    (state: RootState) => state.onboarding.language
  )
  const preselectedCoachEmail = useSelector(
    (state: RootState) => state.onboarding.preselectedCoachEmail
  )
  const departmentId = useSelector(
    (state: RootState) => state.onboarding.departmentId
  )
  const [currentCoach, setCurrentCoach] = useState<any>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const question = onboardingQuestions[index]
  const { language, companyId } = useParams() as any
  const dispatch = useDispatch()
  const { t } = useLocale()
  const mixpanel = useMixpanel()
  const { data: coachBio, isLoading } = useGetOnboardingCoachOptionsQuery({
    language,
    companyId,
    departmentId,
    coachEmail: preselectedCoachEmail,
  })
  const [coachSelected] = usePostOnboardingCoachSelectionMutation()

  const onLocalSubmit = async () => {
    dispatch(
      itemSelect({
        questionId: question.friendlyID,
        answers: preselectedCoachEmail,
      })
    )
    try {
      await coachSelected({
        userEmail: user ? user.email : onboardingUserEmail,
        coachEmail: preselectedCoachEmail,
        language: onboardingInterfaceLanguage,
      }).unwrap()
    } catch (err) {
      console.log('CoachPreselectedQuestion, err:', err)
      // We allow users to continue even if coach exceeds capacity
      // because in this case we already decided to preselect this coach and only this coach
    }
    mixpanel.track('onboarding_coach_preselected', {
      coach: preselectedCoachEmail,
    })
    onSubmit()
  }

  return (
    <>
      {isOpen && (
        <CoachInfo isOpen={isOpen} onClose={onClose} coach={currentCoach} />
      )}
      <OnboardingHeader
        currentStep={index}
        totalSteps={onboardingQuestions.length - 1}
      />
      <OnboardingContainer>
        <TitleDescription title={t('our_recommended_coach')} />
        <Stack px={2} py={10} gridRowGap={5}>
          {isLoading ? (
            <Spinner />
          ) : (
            <CoachSelectCard
              coachBio={coachBio}
              key={`coach-option=0`}
              onReadMore={(coach: any) => {
                setCurrentCoach(coach)
                onOpen()
              }}
              selectable={false}
            />
          )}
        </Stack>
        <OnboardingFooter
          onSubmit={onLocalSubmit}
          submitText={t('next')}
          submitting={submitting}
          isLastStep={index === onboardingQuestions.length - 1}
        />
      </OnboardingContainer>
    </>
  )
}

export default CoachPreselectedQuestion
