import { Box, Select, Text } from '@chakra-ui/react'
import { useLocale } from '../../../hooks/useLocale'
import moment from 'moment'
import { MdDateRange } from 'react-icons/md'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { X } from 'react-feather'

interface Props {
  session: any
  index: number
  timezone: string
  handleRemoveRecurringSession: (index: number) => void
  handleChangeRecurringSession: any
}

export const RecurringSessionItem = ({
  session,
  index,
  timezone,
  handleRemoveRecurringSession,
  handleChangeRecurringSession,
}: Props) => {
  const { t } = useLocale()

  return (
    <Box
      py={session.isAvailable ? 1 : 2}
      bg={session.isAvailable ? 'white' : '#FEF4F3'}
      borderRadius={8}
      my={session.isAvailable ? 0 : 1}
    >
      <Box display="flex">
        <Box display="flex" justifyContent="space-between" flex={1} mr={1}>
          <Box display="flex" mr={3}>
            <Text fontSize="23px" color={session.isAvailable ? '#000' : 'red'}>
              <MdDateRange color={session.isAvailable ? '#000' : 'red'} />
            </Text>
            <Text
              fontSize="md"
              pl={2}
              color={session.isAvailable ? '#000' : 'red'}
            >
              {moment(session.sessionTime).tz(timezone).format('MMM Do')}
            </Text>
          </Box>
          <Box display="flex">
            <Text fontSize="23px">
              <AiOutlineClockCircle
                color={session.isAvailable ? '#000' : 'red'}
              />
            </Text>
            <Text
              fontSize="md"
              pl={2}
              color={session.isAvailable ? '#000' : 'red'}
            >
              {moment(session.sessionTime)
                .tz(timezone)
                .format('HH:mm ([GMT]Z)')}
            </Text>
          </Box>
        </Box>
        <X
          color={session.isAvailable ? '#000' : 'red'}
          onClick={() => handleRemoveRecurringSession(index)}
          style={{ cursor: 'pointer', width: 20 }}
        />
      </Box>
      {session.options?.length > 0 ? (
        <Box textAlign="left" mt={1}>
          {!session.isAvailable ? (
            <Text fontSize={13} color="red">
              {t('choose_different_slot')}
            </Text>
          ) : null}
          <Box bg="white" borderRadius={8} mt={1}>
            <Select
              value={session.sessionTime}
              onChange={(e) =>
                handleChangeRecurringSession(
                  e.target.value,
                  session.sessionTime
                )
              }
            >
              {session.options?.map((option: any) =>
                option.slots?.map((time: any) => (
                  <option key={time} value={time}>
                    {moment(time).tz(timezone).format('MMM Do HH:mm ([GMT]Z)')}
                  </option>
                ))
              )}
            </Select>
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}
