import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import TimezoneSelect from 'react-timezone-select'
import { DEFAULT_CALL_TOOL } from '../../app/constants'

import {
  useDeleteUserMutation,
  useGetCallToolListQuery,
  useLazyGetUserProfileQuery,
  usePostUserProfileMutation,
} from '../../app/services/api'
import { updateUser } from '../../features/auth/authSlice'
import { useAuth } from '../../hooks/useAuth'
import { maybeChangeLocale, useLocale } from '../../hooks/useLocale'
import { useLogout } from '../../hooks/useLogout'
import { PostProfileRequest, User } from '../../types/api'
import { useMixpanel } from '../../utils/MixpanelContext'
import { useFlags } from 'flagsmith/react'
import { useFeatureFlagEnabled } from 'posthog-js/react'

const DISPLAY_NEW_LANGUAGE_OPTIONS_FLAG_KEY = 'display_new_language_options'

const UserProfile: React.FC = () => {
  const { user } = useAuth()
  if (!user) throw new Error('User is not defined')
  const { t, i18n } = useLocale()
  const [triggerGetUserProfile, { data: userProfileData }] =
    useLazyGetUserProfileQuery()
  const [postUserProfile] = usePostUserProfileMutation()
  const { data: callToolList } = useGetCallToolListQuery()
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)
  const [selectedTimezone, setSelectedTimezone] = useState<any>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )
  const [_selectedCallToolIndex, setSelectedCallToolIndex] = useState<any>(null)
  const mixpanel = useMixpanel()
  const toast = useToast()
  const dispatch = useDispatch()
  const [deleteUser] = useDeleteUserMutation()
  const {
    isOpen: isDeleteAlertOpen,
    onOpen: onDeleteAlertOpen,
    onClose: onDeleteAlertClose,
  } = useDisclosure()
  const onLogout = useLogout()

  useEffect(() => {
    mixpanel.track('profile_edit_open')
    triggerGetUserProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [formState, setFormState] = useState<PostProfileRequest>({
    name: '',
    timezone: '',
    language: '',
    callTool: '',
  })

  const { display_new_language_options } = useFlags([
    DISPLAY_NEW_LANGUAGE_OPTIONS_FLAG_KEY,
  ])

  const displayNewLanguageOptionsPhEnabled = useFeatureFlagEnabled(
    DISPLAY_NEW_LANGUAGE_OPTIONS_FLAG_KEY
  )

  useEffect(() => {
    setIsButtonDisabled(
      !(
        formState?.name &&
        formState?.timezone &&
        formState?.language &&
        formState?.callTool
      )
    )
  }, [formState])

  useEffect(() => {
    if (callToolList && userProfileData) {
      const selectedCallTool = userProfileData.callTool || callToolList.default
      const idx = callToolList.options.findIndex(
        (callTool: any) => callTool.value === selectedCallTool
      )

      // Update the selectedCallToolIndex state
      setSelectedCallToolIndex(idx)

      // Update the callTool field in the formState
      setFormState((prevState) => ({
        ...prevState,
        callTool: callToolList.options[idx].value,
      }))
    }
  }, [callToolList, userProfileData])

  const onReset = useCallback(() => {
    const changes = {
      name: userProfileData?.profile.name,
      timezone: userProfileData?.timezone ?? selectedTimezone,
      language: userProfileData?.language,
      callTool: userProfileData?.callTool ?? DEFAULT_CALL_TOOL,
    }
    Object.entries(changes).forEach(([key, value]) =>
      setFormState((prev: any) => ({ ...prev, [key]: value }))
    )
    if (userProfileData?.timezone) {
      setSelectedTimezone(userProfileData?.timezone)
    }
    if (userProfileData?.callTool && callToolList) {
      const idx = callToolList.options.findIndex(
        (callTool: any) => callTool.value === userProfileData?.callTool
      )
      setSelectedCallToolIndex(idx)
    }
  }, [userProfileData, selectedTimezone, callToolList])

  const onDelete = async () => {
    try {
      await deleteUser({}).unwrap()
      toast({
        status: 'success',
        title: t('success'),
        description: t('account_delete_success'),
        isClosable: true,
      })
      mixpanel.track('account_delete_success')
      onLogout()
    } catch (error: any) {
      mixpanel.track('account_delete_fail', { error: JSON.stringify(error) })
      reportError(error)
      toast({
        status: 'error',
        title: t('error'),
        description: error?.data?.message || t('there_was_an_error'),
        isClosable: true,
      })
    }
  }

  useEffect(() => {
    onReset()
  }, [onReset])

  const handleChange = (event: any) => {
    const {
      target: { name, value },
    } = event
    setFormState((prev) => ({ ...prev, [name]: value }))
  }

  const handleTimezoneChange = (timezone: any) => {
    const name = 'timezone'
    const value = timezone.value
    setFormState((prev) => ({ ...prev, [name]: value }))
  }

  const onSubmit = useCallback(
    async (event: any) => {
      event.preventDefault()
      try {
        const user = await postUserProfile(formState).unwrap()
        toast({
          status: 'success',
          title: t('success'),
          description: t('your_profile_is_updated'),
          isClosable: true,
        })
        maybeChangeLocale(user.language!, i18n.language)
        dispatch(updateUser(user as User))
        mixpanel.track('profile_edit_success')
      } catch (err: any) {
        toast({
          status: 'error',
          title: t('error'),
          description: err?.data?.message || t('there_was_an_error'),
          isClosable: true,
        })
        mixpanel.track('profile_edit_fail', { message: err?.data?.message })
      }
    },
    [formState, postUserProfile, t, dispatch, mixpanel, toast, i18n]
  )

  if (!userProfileData) return null

  console.log({
    display_new_language_options,
    displayNewLanguageOptionsPhEnabled,
  })

  return (
    <>
      <form id="profile-form" onSubmit={onSubmit}>
        <Flex minH={'50vh'} align={'top'} justify={'center'} bg={'gray.50'}>
          <Stack
            spacing={4}
            w={'full'}
            maxW={'md'}
            bg={'white'}
            rounded={'xl'}
            boxShadow={'lg'}
            p={6}
            my={12}
          >
            <Heading lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl' }}>
              {t('edit_profile')}
            </Heading>
            <FormControl id="name">
              <FormLabel>{t('name')}</FormLabel>
              <Input
                value={formState.name}
                name="name"
                _placeholder={{ color: 'gray.500' }}
                type="text"
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="email">
              <FormLabel>{t('email')}</FormLabel>
              <Input type="text" disabled value={user.email} />
            </FormControl>
            <FormControl id="timezone">
              <FormLabel>{t('timezone')}</FormLabel>
              <TimezoneSelect
                name="timezone"
                value={formState.timezone}
                onChange={handleTimezoneChange}
              />
            </FormControl>
            <FormControl id="language">
              <FormLabel>{t('language')}</FormLabel>
              <Select
                name="language"
                value={formState.language}
                onChange={handleChange}
              >
                <option value="en">{t('english')}</option>
                <option value="ko">{t('korean')}</option>
                {display_new_language_options.enabled ? (
                  <option value="ja">{t('jp')}</option>
                ) : null}
                {display_new_language_options.enabled ? (
                  <option value="zh">{t('zh_CN')}</option>
                ) : null}
              </Select>
            </FormControl>
            {callToolList && callToolList.options && (
              <FormControl id="callTool">
                <FormLabel>{t('call_tool_preference')}</FormLabel>
                <Select
                  name="callTool"
                  value={formState.callTool}
                  onChange={handleChange}
                >
                  {callToolList?.options.map((callTool: any, index: number) => (
                    <option key={index} value={callTool.value}>
                      {callTool.label}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}

            <Link
              as={RouterLink}
              to="/password_forgot"
              color="blue.500"
              textDecor="underline"
            >
              {t('forgot_password')}
            </Link>

            <Stack spacing={6} direction={['column', 'row']}>
              <Button colorScheme="red" w="full" onClick={onReset}>
                {t('reset')}
              </Button>
              <Button
                colorScheme="green"
                w="full"
                disabled={isButtonDisabled}
                onClick={onSubmit}
              >
                {t('save')}
              </Button>
            </Stack>
            <VStack>
              <Divider mb={4} />
              <FormLabel>{t('delete_account_description')}</FormLabel>
              <Box style={{ paddingTop: 10 }}>
                <Button
                  onClick={onDeleteAlertOpen}
                  bg={'primary.500'}
                  color={'white'}
                >
                  {t('delete_account')}
                </Button>
              </Box>
            </VStack>
          </Stack>
        </Flex>
      </form>
      <Modal isCentered isOpen={isDeleteAlertOpen} onClose={onDeleteAlertClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> {t('account_deletion')} </ModalHeader>
          <Divider />
          <ModalBody>
            <Text fontSize="18px">{t('delete_account_confirmation')}</Text>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onDeleteAlertClose} mr={5}>
              {t('cancel')}
            </Button>
            <Button onClick={onDelete} colorScheme="primary" type="submit">
              {t('confirm_deletion')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default UserProfile
