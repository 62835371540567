import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import moment from 'moment-timezone'
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import { useNavigate } from 'react-router'

import {
  useDeleteEventMutation,
  useGetAllEventsQuery,
  useGetBookingConfigQuery,
} from '../../app/services/api'
import { useLocale } from '../../hooks/useLocale'
import { useMixpanel } from '../../utils/MixpanelContext'
import { PostSessionFeedback } from '../feedback/PostSessionFeedback'
import CoachAvatar from '../profile/CoachAvatar'
import Book from '../scheduler/Book'
import UnifiedCallLink from '../scheduler/UnifiedCallLink'
import { LateModifyAlert } from './components/LateModifyAlert'
import { useE2Etest } from '../../hooks/useE2Etest'
import { useAuth } from '../../hooks/useAuth'

const COLOR_CODES = {
  completed: 'transparent',
  late_cancellation: '#ffe8e0',
  canceled: '#ffe8e0',
  no_show: '#ffe8e0',
} as const

export const Booking = ({ event, config, user, coach, isPast }: any) => {
  const { t } = useLocale()
  const [guest, setGuest] = useState<any>(null)
  const [modifyType, setModifyType] = useState<'reschedule' | 'delete' | null>(
    null
  )
  const {
    isOpen: isLateModifyingAlertOpen,
    onOpen: onLateModifyingAlertOpen,
    onClose: onLateModifyingAlertClose,
  } = useDisclosure()

  const toast = useToast()
  const [deleteEvent] = useDeleteEventMutation()
  const cancelRef = useRef() as MutableRefObject<HTMLInputElement>
  const {
    isOpen: isCancelAlertOpen,
    onOpen: onCancelAlertOpen,
    onClose: onCancelAlertClose,
  } = useDisclosure()
  const {
    isOpen: isRescheduleModalOpen,
    onOpen: onRescheduleModalOpen,
    onClose: onRescheduleModalClose,
  } = useDisclosure()
  const {
    isOpen: isFeedbackModalOpen,
    onOpen: onFeedbackModalOpen,
    onClose: onFeedbackModalClose,
  } = useDisclosure()
  const mixpanel = useMixpanel()
  const isE2Etest = useE2Etest()

  useEffect(() => {
    if (user) {
      mixpanel.track('appointment_screen_open')
    }
  }, [user, mixpanel])

  const isLateModifying = useCallback(() => {
    if (!config) return false

    return (
      config.policyCancellationHours >
      moment(new Date(event.start)).diff(moment(), 'hours')
    )
  }, [config, event])

  const onEventUpdate = useCallback(() => {
    onRescheduleModalClose()
    toast({
      status: 'success',
      title: t('success'),
      description: t('the_session_is_successfully_updated'),
      isClosable: true,
    })
  }, [onRescheduleModalClose, toast, t])

  const onCancel = useCallback(async () => {
    try {
      await deleteEvent({
        eventId: event.id || event._id,
        isE2Etest,
      }).unwrap()
      toast({
        status: 'success',
        title: t('success'),
        description: t('session_is_successfully_cancelled'),
        isClosable: true,
      })
      if (user) {
        mixpanel.track('appointment_cancel_success')
      }
    } catch (err: any) {
      toast({
        status: 'error',
        title: t('error'),
        description: err?.data?.message || t('there_was_an_error'),
        isClosable: true,
      })
      if (user) {
        mixpanel.track('appointment_cancel_fail')
      }
    }
  }, [event, deleteEvent, mixpanel, toast, user, t, isE2Etest])

  useEffect(() => {
    const guest = event.bookedByUser ? event.coach : event.user
    if (guest && guest.profile) setGuest(guest)
  }, [event, user])

  const onClickCancel = useCallback(() => {
    console.log('isLateModifying', isLateModifying())
    if (isLateModifying()) {
      setModifyType('delete')
      onLateModifyingAlertOpen()
    } else {
      onCancelAlertOpen()
    }
  }, [onCancelAlertOpen, isLateModifying, onLateModifyingAlertOpen])

  const onClickReschedule = useCallback(() => {
    if (isLateModifying()) {
      setModifyType('reschedule')
      onLateModifyingAlertOpen()
    } else {
      onRescheduleModalOpen()
    }
  }, [onRescheduleModalOpen, isLateModifying, onLateModifyingAlertOpen])

  const onResetLateModify = useCallback(() => {
    setModifyType(null)
  }, [])

  const parseEventTime = (start: Date, end: Date) => {
    let timezone = user?.timezone
    if (!timezone) {
      timezone = 'Asia/Singapore'
    }
    const startMoment = moment(start).tz(timezone)
    const endMoment = moment(end).tz(timezone)
    return `${startMoment.format('dddd, MMMM D ⋅ h:mm')} - ${endMoment.format(
      'h:mma'
    )}`
  }
  let guestName = `${guest?.profile && guest.profile.name}`
  if (coach) {
    guestName = `${coach.profile.name}`
  }

  const statusColor = COLOR_CODES[event.status as keyof typeof COLOR_CODES]

  return (
    <Flex
      bg={isPast ? statusColor : undefined}
      border={isPast ? `1px solid #dcc` : undefined}
      borderRadius="md"
      mb={isPast ? '2' : undefined}
      pb="0px"
    >
      <LateModifyAlert
        isOpen={isLateModifyingAlertOpen}
        onClose={onLateModifyingAlertClose}
        type={modifyType}
        onResume={() => {
          switch (modifyType) {
            case 'delete':
              onCancel() // Cancel the event, to avoid showing second alert we cancel here
              break
            case 'reschedule':
              onRescheduleModalOpen()
              break
          }

          onLateModifyingAlertClose()
        }}
      />
      <Box p="0">
        <HStack spacing="24px" align="stretch">
          {coach && coach?.profile?.picture && (
            <Box
              textAlign={{
                base: 'center',
                sm: 'center',
                md: 'center',
                lg: 'center',
              }}
              verticalAlign="center"
              pl="5"
              pt="5"
            >
              <CoachAvatar
                src={coach?.profile.picture}
                hideUpload
                videoUrl={coach?.profile.video}
                email={coach?.email}
                size="lg"
              />
            </Box>
          )}
          <VStack align="stretch">
            <Heading pt="5" mb="0" as="h4" size="md">
              {guestName}
            </Heading>
            <Text ml="5" mt="1" mb="1" fontSize="sm">
              {parseEventTime(event.start, event.end)}
            </Text>
            {event.managerInvited && (
              <Text fontSize="md" ml="5" pb={2}>
                ({t('manager_invited')})
              </Text>
            )}
            {!isPast && (
              <Flex mt={2} ml={5}>
                <UnifiedCallLink
                  event={event}
                  isCoach={false}
                  referrer={'app-bookings-page'}
                  buttonSize={'md'}
                />
              </Flex>
            )}
          </VStack>
        </HStack>
      </Box>
      <Spacer />
      {!isPast && (
        <Box p="5" alignContent={'right'}>
          <Button colorScheme="red" mr={2} onClick={onClickCancel}>
            {t('cancel')}
          </Button>
          <Button
            onClick={onClickReschedule}
            bg={'blue.400'}
            color={'white'}
            _hover={{
              bg: 'blue.500',
            }}
          >
            {t('reschedule')}
          </Button>
        </Box>
      )}
      <Center>
        {isPast &&
          (event.rating ? (
            <Box display={'flex'} mt="2" alignItems={'center'} p="5">
              {Array(5)
                .fill('')
                .map((_, i) => (
                  <AiFillStar
                    key={i}
                    size={'1.3rem'}
                    style={{ color: i < event.rating ? '#FF4B0A' : '#CBD5E0' }}
                  />
                ))}
            </Box>
          ) : (
            <Box p="5">
              <Button
                leftIcon={<AiOutlineStar size={'1.5rem'} />}
                onClick={onFeedbackModalOpen}
                disabled={event.rated}
              >
                {t('rate_session')}
              </Button>
            </Box>
          ))}
      </Center>
      <PostSessionFeedback
        isOpen={isFeedbackModalOpen}
        onClose={onFeedbackModalClose}
        eventId={event._id ?? event.id}
        coachName={coach?.profile?.name}
        isFirstSession={event.isFirstSession}
      />
      <AlertDialog
        isOpen={isCancelAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onCancelAlertClose()
          onResetLateModify()
        }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('cancel_session')}
            </AlertDialogHeader>

            <AlertDialogBody>{t('are_you_sure_warning')}</AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef as any}
                onClick={() => {
                  onCancelAlertClose()
                  onResetLateModify()
                }}
              >
                {t('go_back')}
              </Button>
              <Button colorScheme="red" onClick={onCancel} ml={3}>
                {t('proceed')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal
        isOpen={isRescheduleModalOpen}
        onClose={() => {
          onRescheduleModalClose()
          onResetLateModify()
        }}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('reschedule')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Book
              user={user}
              coach={coach}
              event={event}
              onEventUpdate={onEventUpdate}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

const Bookings: React.FC = () => {
  const { user } = useAuth()
  if (!user) throw new Error('User not found')
  const { t } = useLocale()
  const navigate = useNavigate()
  const [tabIndex, setTabIndex] = useState(0)
  const [futureEvents, setFutureEvents] = useState<any>([])
  const [pastEvents, setPastEvents] = useState<any>([])
  const { data } = useGetAllEventsQuery()
  const { data: bookingConfig } = useGetBookingConfigQuery('config')

  const now = useMemo(() => new Date().toISOString(), [])

  useEffect(() => {
    if (Array.isArray(data)) {
      const futureEvents = data.filter(
        (event: any) => event.end >= now && !event.reason
      )
      const pastEvents = data
        .filter((event: any) => event.end < now || event.reason)
        .sort((a: any, b: any) => {
          if (a.end < b.end) {
            return 1
          }
          if (a.end > b.end) {
            return -1
          }
          return 0
        })
      setFutureEvents(futureEvents)
      setPastEvents(pastEvents)
    }
  }, [data, now])

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    const tab = query.get('tab')
    if (tab === 'past') {
      setTabIndex(1)
    }
  }, [])

  const onClickBook = useCallback(() => {
    navigate('/book')
  }, [navigate])

  return (
    <Box bg="white" borderRadius={'10px'}>
      <Tabs
        index={tabIndex}
        onChange={setTabIndex}
        minH="100vh"
        isFitted
        colorScheme="primary"
        overflowY="auto"
      >
        <TabList mb="1em">
          <Tab>{t('upcoming')}</Tab>
          <Tab>{t('past')}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel mx={0} px={6}>
            <Box pt={5} pb={10}>
              {futureEvents &&
                futureEvents.map((event: any) => (
                  <Booking
                    key={event._id}
                    config={bookingConfig?.config}
                    event={event}
                    user={user}
                    coach={event.coach}
                  />
                ))}
              {!futureEvents ||
                (futureEvents.length === 0 && (
                  <Center>
                    <VStack mt={6} spacing={4} align="stretch">
                      <Flex>
                        <Heading size="md">
                          {t('you_dont_have_any_sessions_coming_up')}
                        </Heading>
                      </Flex>
                      <Center>
                        <Button
                          onClick={onClickBook}
                          // flex={1}
                          fontSize={'sm'}
                          rounded={'full'}
                          bg={'primary.500'}
                          color={'white'}
                          boxShadow={
                            '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px var(--chakra-colors-primary-400)'
                          }
                          _hover={{
                            bg: 'primary.400',
                          }}
                          _focus={{
                            bg: 'primary.600',
                          }}
                        >
                          {t('make_appointment')}
                        </Button>
                      </Center>
                    </VStack>
                  </Center>
                ))}
            </Box>
          </TabPanel>
          <TabPanel>
            {pastEvents &&
              pastEvents.length > 0 &&
              pastEvents.map((event: any) => (
                <Booking
                  key={event._id}
                  config={bookingConfig?.config}
                  event={event}
                  user={user}
                  coach={event.coach}
                  isPast
                />
              ))}

            {pastEvents?.length === 0 && (
              <Text textAlign="center">{t('no_past_sessions')}</Text>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default Bookings
