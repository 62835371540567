import { alpha, IconButton, styled } from '@mui/material'

export const PeerAssessmentContainer = styled('div')(() => ({
  height: '100dvh',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  overflowY: 'auto',
  '@media (max-width: 600px)': {
    gridTemplateColumns: '1fr',
    position: 'relative',
  },
}))

export const PeerAssessmentMobileContainer = styled('div')(() => ({
  height: '100dvh',
  overflowY: 'auto',
  '@media (max-width: 600px)': {
    height: 'auto',
    overflowY: 'visible',
  },
}))

export const PeerAssessmentHeader = styled('div')(() => ({
  height: '70px',
  display: 'flex',
  alignItems: 'center',
  padding: '16px',
  img: {
    height: '40px',
  },
}))

export const PeerAssessmentUsersList = styled('div')(({ theme }) => ({
  width: '300px',
  borderRight: `1px solid ${theme.palette.divider}`,
  overflowY: 'auto',
  background: alpha(theme.palette.primary.light, 0.98),
  padding: '0 12px',
  gap: '5px',
  display: 'grid',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  '@media (max-width: 600px)': {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    width: '300px',
    zIndex: theme.zIndex.drawer,
    transform: 'translateX(-100%)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '&.open': {
      transform: 'translateX(0)',
    },
  },
  '.MuiButtonBase-root': {
    borderRadius: '5px',
    padding: '6px',
    '.MuiListItemText-primary': {
      font: '400 14px Poppins, sans-serif',
    },
    '.MuiListItemText-secondary': {
      font: '400 12px Poppins, sans-serif',
    },
  },
}))

export const NoAuthorWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '100dvh',
  background: theme.palette.grey[100],
  gap: '12px',
  '@media (max-width: 600px)': {
    padding: '24px',
    minHeight: '100vh',
  },
  '.lottie': {
    pointerEvents: 'none',
    '@media (max-width: 600px)': {
      position: 'fixed',
      top: '10%',
    },
  },
  '.info': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0 24px',
    pointerEvents: 'none',
    userSelect: 'none',
    gap: '12px',
    h1: {
      font: '600 18px Poppins, sans-serifs',
      textAlign: 'center',
      pointerEvents: 'none',
    },
    p: {
      font: '400 14px Poppins, sans-serifs',
      textAlign: 'center',
      pointerEvents: 'none',
      color: theme.palette.grey[500],
    },
    maxWidth: '500px',
    fontWeight: 400,
    '@media (max-width: 600px)': {
      position: 'fixed',
      top: '40%',
    },
  },
}))

export const PeerAssessmentAreaWrapper = styled('div')(() => ({
  backgroundImage: 'url(/connect.png)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  '@media (max-width: 600px)': {
    minHeight: '100vh',
  },
}))

export const MobileMenuButton = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  top: '6px',
  zIndex: theme.zIndex.appBar,
  display: 'none',
  '@media (max-width: 600px)': {
    // Display on right side
    left: 'auto',
    // right: theme.spacing(2),
    display: 'block',
  },
}))
