import { createSlice } from '@reduxjs/toolkit'

interface AssessmentState {
  questions: any[]
  answers: any
  ratings: any
  manager: any
  user: any
  shareWithUser: boolean
  otherAnswer: any
  values: any[]
  skipQuestions: string[]
}

export const initialState: AssessmentState = {
  questions: [],
  answers: {},
  ratings: {},
  manager: null,
  user: null,
  shareWithUser: true,
  otherAnswer: null,
  values: [],
  skipQuestions: [],
}

const slice = createSlice({
  name: 'assessment',
  initialState: initialState,
  reducers: {
    setAssessmentQuestions: (state, { payload }) => {
      state.questions = payload.questions
      state.answers = payload.answers ?? {}
      state.ratings = payload.ratings ?? {}
      state.manager = payload.manager
      state.user = payload.user
      state.values = payload.values
    },
    itemSelect: (state, { payload }) => {
      if (payload.otherAnswer) {
        state.answers[payload.questionId] = {
          other: payload.otherAnswer,
          answers: payload.answers,
        }
      } else {
        state.answers[payload.questionId] = payload.answers
        state.otherAnswer = payload.otherAnswer
      }
    },

    setRating: (state, { payload }) => {
      state.ratings[payload.focusArea] = payload.scale
    },
    setShareWithUser: (state, { payload }) => {
      state.shareWithUser = payload
    },
    setSkipQuestion: (state, { payload }) => {
      if (state.skipQuestions) {
        state.skipQuestions = [...state.skipQuestions, payload]
      } else {
        state.skipQuestions = [payload]
      }
    },
    clearAssessmentData: (state) => {
      Object.assign(state, initialState)
    },
  },
})

export default slice.reducer

export const {
  itemSelect,
  setRating,
  clearAssessmentData,
  setAssessmentQuestions,
  setShareWithUser,
  setSkipQuestion,
} = slice.actions
