import type { LoaderFunction } from 'react-router-dom'
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { api } from '~/app/api'

type Query = {
  language: string
  companyId?: string
  departmentId?: string
  email?: string
}

export interface OnboardingResponse {
  questions: Question[]
  companies: string[]
  departments: any[]
  languages: string[]
  steps: Step[]
  singlePage: boolean
  _id: string
  phoneNumberAsked: boolean
  name: string
  description: string
  type: string
  status: string
  version: number
  createdAt: string
  updatedAt: string
  __v: number
  askPhoneNumber: boolean
  coachBatching: boolean
  userInfo: UserInfo
}

interface Question {
  optional: boolean
  hasOtherText: boolean
  options: any[]
  _id: string
  title: string
  friendlyID: string
  description?: string
  type: string
  step?: number
  minSelectionLimit?: number
  maxSelectionLimit?: number
  otherTextPlaceholder?: string
  placeholder?: string
}

interface Step {
  _id: string
  title: string
  step: number
  description: string
  createdAt: string
  updatedAt: string
  __v: number
}

interface UserInfo {
  token?: string
  email?: string
  name?: string
  password?: string
  accountExists?: boolean
  preselectedCoachEmail?: string
  user?: any
}

const fetchOnboardingQuestions = (params: Query) =>
  queryOptions({
    queryKey: ['onboarding', 'questions'],
    queryFn() {
      return api.url('/onboarding/questions').query(params).get().json<OnboardingResponse>()
    },
    gcTime: 1000 * 60 * 5,  // 5 minutes
  })

export const loader = (queryClient: QueryClient): LoaderFunction => (args) => {
  const {
    language = 'en',
    companyId,
    departmentId,
    email,
  } = args.params

  return queryClient.ensureQueryData(fetchOnboardingQuestions({
    language,
    companyId,
    departmentId,
    email,
  }))
}
