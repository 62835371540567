import {
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'

import { APP_NAME } from '../../app/constants'
import { usePasswordForgotMutation } from '../../app/services/api'
import { useLocale } from '../../hooks/useLocale'
import { PasswordForgotRequest } from '../../types/api'
import { useMixpanel } from '../../utils/MixpanelContext'

const PasswordForgot = (): JSX.Element => {
  const { t } = useLocale()
  const toast = useToast()
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const mixpanel = useMixpanel()

  const [passwordForgot, { isLoading }] = usePasswordForgotMutation()

  const [formState, setFormState] = useState<PasswordForgotRequest>({
    email: '',
  })

  useEffect(() => {
    setIsButtonDisabled(!formState?.email)
  }, [formState])

  const onSubmit = useCallback(
    async (event: any) => {
      event.preventDefault()
      if (isButtonDisabled) {
        return
      }
      try {
        setIsButtonDisabled(true)
        await passwordForgot(formState).unwrap()
        setIsButtonDisabled(false)
        toast({
          title: t('password_forgot_success'),
          description: t('password_forgot_success_description'),
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
        mixpanel.track('password_reset_request_success')
      } catch (err: any) {
        toast({
          status: 'error',
          title: t('error'),
          description: err?.data?.message || t('there_was_an_error'),
          isClosable: true,
        })
        mixpanel.track('password_reset_request_fail', {
          message: err?.data?.message,
        })
      }
    },
    [formState, isButtonDisabled, passwordForgot, t, toast, mixpanel]
  )

  const handleChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) =>
    setFormState((prev) => ({ ...prev, [name]: value }))

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <form onSubmit={onSubmit}>
        <Stack spacing={2} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
            <Heading fontSize={'4xl'}>{APP_NAME}</Heading>
            <Heading fontSize={'2xl'}>{t('reset_your_password')}</Heading>
          </Stack>
          <Stack
            spacing={4}
            w={'full'}
            maxW={'md'}
            bg={useColorModeValue('white', 'gray.700')}
            rounded={'xl'}
            boxShadow={'lg'}
            p={6}
            my={12}
          >
            <Heading
              lineHeight={1.1}
              fontSize={{ base: '2xl', md: '3xl' }}
            ></Heading>
            <Text
              fontSize={{ base: 'sm', sm: 'md' }}
              color={useColorModeValue('gray.800', 'gray.400')}
            >
              {t('youll_get_an_email_with_a_reset_link')}
            </Text>
            <FormControl id="email">
              <Input
                name="email"
                onChange={handleChange}
                placeholder="your-email@example.com"
                _placeholder={{ color: 'gray.500' }}
                type="email"
              />
            </FormControl>
            <Stack spacing={6}>
              <Button
                bg={'primary.400'}
                color={'white'}
                _hover={{
                  bg: 'primary.500',
                }}
                type="submit"
                isLoading={isLoading}
                isDisabled={isButtonDisabled}
              >
                {t('send_reset_link')}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </Flex>
  )
}

export default PasswordForgot
