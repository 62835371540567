import { useState } from 'react'
import { useLoaderData, useRevalidator } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { useMutation } from '@tanstack/react-query'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import parseISO from 'date-fns/parseISO'
import { CalendarIcon } from '@heroicons/react/24/outline'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { useToast } from '@chakra-ui/react'

import type { Response } from './my.loader'
import { cn } from '~/utils/cn'
import { AlertDialog } from '~/components/alert-dialog'
import { api } from '~/app/api'
import { MyNoteModal } from './_components/my-note-modal'
import { parseText } from '~/utils/string'

export const MyNotes: React.FC = () => {
  const { t } = useTranslation()
  const notes = useLoaderData() as Response
  const toast = useToast()
  const { revalidate } = useRevalidator()
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [showActionModal, setShowActionModal] = useState(false)
  const [selectedNote, setSelectedNote] = useState({
    id: '',
    body: '',
  })

  const { mutate: deleteNote } = useMutation({
    mutationFn(id: string) {
      return api.url(`/user/note/${id}`).delete().json()
    },
    onSuccess() {
      toast({
        status: 'success',
        description: 'The note is successfully deleted',
        isClosable: true,
      })
      revalidate()
    },
    onError() {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Error while saving a note',
        isClosable: true,
      })
    },
    onSettled() {
      setSelectedNote({ id: '', body: '' })
      setShowDeleteAlert(false)
    },
  })

  let content
  if (notes.length === 0) {
    content = (
      <div className="h-full px-4 py-5 space-y-4 sm:px-6 sm:py-2">
        <p className="text-center">{t('no_notes_yet')}</p>
      </div>
    )
  } else {
    content = (
      <div className="rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:p-6 divide-y divide-gray-200">
          {notes.map((note) => (
            <div key={note._id} className="flex justify-between gap-x-6 py-5">
              <div>
                <div className="flex justify-between">
                  <p className="flex items-center gap-2 text-sm text-gray-600">
                    <CalendarIcon className="h-5" />
                    {formatDistanceToNow(parseISO(note.createdAt), {
                      addSuffix: true,
                    })}
                  </p>
                </div>

                <div
                  className={cn(
                    'mb-4 mt-2',
                    !note.body && 'flex items-center justify-between'
                  )}
                >
                  <p className="whitespace-normal text-gray-800">
                    {parseText(!note.body ? 'No notes' : note.body.text)}
                  </p>
                </div>
              </div>

              <div className="flex shrink-0 items-center gap-x-6">
                <Menu as="div" className="relative flex-none">
                  <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon
                      aria-hidden="true"
                      className="h-5 w-5"
                    />
                  </MenuButton>
                  <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    <MenuItem>
                      <button
                        type="button"
                        className="w-full text-left px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                        onClick={() => {
                          setSelectedNote({
                            id: note._id,
                            body: note.body.text,
                          })
                          setShowActionModal(true)
                        }}
                      >
                        Edit <span className="sr-only">note</span>
                      </button>
                    </MenuItem>
                    <MenuItem>
                      <button
                        type="button"
                        className="w-full text-left px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                        onClick={() => {
                          setSelectedNote({
                            id: note._id,
                            body: note.body.text,
                          })
                          setShowDeleteAlert(true)
                        }}
                      >
                        Delete <span className="sr-only">note</span>
                      </button>
                    </MenuItem>
                  </MenuItems>
                </Menu>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="ml-auto">
        <button
          type="button"
          className="rounded bg-primary px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
          onClick={() => setShowActionModal(true)}
        >
          Add Note
        </button>
      </div>

      {content}

      <AlertDialog
        isOpen={showDeleteAlert}
        onClose={() => setShowDeleteAlert(false)}
        title={t('are_you_sure')}
        description={t('are_you_sure_warning')}
        cancelText={t('cancel')}
        confirmText={t('continue')}
        onConfirm={() => deleteNote(selectedNote.id)}
      />

      <MyNoteModal
        isOpen={showActionModal}
        onClose={() => {
          setShowActionModal(false)
          setSelectedNote({ id: '', body: '' })
        }}
        defaultValues={{
          noteId: selectedNote.id,
          body: selectedNote.body,
        }}
      />
    </>
  )
}
