import {
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Box,
  Text,
  Textarea,
  Button,
} from '@chakra-ui/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Rating } from 'react-simple-star-rating'
import {
  firstSessionOptions,
  lowOptions,
  midOptions,
  highOptions,
} from '../../app/constants'
import { usePostSessionUserFeedbackMutation } from '../../app/services/api'
import { useLocale } from '../../hooks/useLocale'

interface Props {
  eventId: string
  coachName: string
  isFirstSession?: boolean
  isOpen: boolean
  onClose: () => void
}

export const PostSessionFeedback = ({
  isOpen,
  onClose,
  coachName,
  eventId,
  isFirstSession,
}: Props) => {
  const { t } = useLocale()
  const toast = useToast()
  const [submitting, setSubmitting] = useState(false)
  const [rating, setRating] = useState(0)
  const [answers, setAnswers] = useState(new Set<string>())
  const otherTextRef = useRef('')
  const [postSessionUserFeedback] = usePostSessionUserFeedbackMutation()

  const getFollowUpQuestionTitle = useCallback(
    (rating: any) => {
      if (rating === 1 || rating === 2) {
        return t('session_review_followup_title_rating_low')
      } else if (rating === 3 || rating === 4) {
        return t('session_review_followup_title_rating_mid')
      } else if (rating === 5) {
        return t('session_review_followup_title_rating_high')
      }
      return ''
    },
    [t]
  )

  useEffect(() => {
    return () => {
      setAnswers(new Set<string>())
    }
  }, [])

  const onSetStarRating = async (rating: number) => {
    setRating(rating)
    try {
      await postSessionUserFeedback({
        eventId,
        rating,
      }).unwrap()
    } catch (err) {
      console.log(err)
    }
  }

  const optionsHandler = (rate: number) => {
    if (isFirstSession) {
      return firstSessionOptions
    }
    if (rate === 5) {
      return highOptions
    }
    if (rate === 3 || rate === 4) {
      return midOptions
    }
    if (rate === 1 || rate === 2) {
      return lowOptions
    }

    return []
  }

  const onSubmit = async () => {
    const answersArray = Array.from(answers)
    if (
      answersArray.length === 0 ||
      (answersArray.length === 1 &&
        answersArray[0] === 'other' &&
        !otherTextRef.current)
    ) {
      toast({
        title: t('session_review_select_at_least_one_or_fill_other'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      return
    }
    try {
      setSubmitting(true)
      await postSessionUserFeedback({
        eventId,
        rating,
        answers: Array.from(answers),
        other: otherTextRef.current,
      }).unwrap()

      toast({
        title: t('thanks_for_session_feedback'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (err) {
      toast({
        title: t('session_review_submit_error'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    } finally {
      setSubmitting(false)
      onClose()
    }
  }

  const renderOption = (child: string) => {
    return (
      <Box
        key={child}
        display="flex"
        alignItems="center"
        onClick={() => {
          const value = child
          if (answers.has(value)) {
            answers.delete(value)
          } else {
            answers.add(value)
          }
          setAnswers(new Set(answers))
        }}
        cursor="pointer"
        bg={answers.has(child) && child !== 'other' ? 'primary.500' : 'white'}
        color={answers.has(child) && child !== 'other' ? 'white' : 'black'}
        borderRadius="xl"
        border={
          answers.has(child) || child === 'other' ? 'none' : '1px solid #ccc'
        }
        py="2"
        px="4"
        p={child === 'other' ? 0 : undefined}
        mb={2}
      >
        {child !== 'other' && t(`${child}`)}

        {child === 'other' && (
          <Box w="100%">
            <Text my="2" textAlign="center">
              {t(
                isFirstSession
                  ? 'other_feedback_label_first_session'
                  : 'other_feedback_label_multiple_sessions'
              )}
            </Text>
            <Textarea
              w="100%"
              placeholder={t('other_feedback')}
              onChange={(e) => {
                otherTextRef.current = e.target.value
              }}
            />
          </Box>
        )}
      </Box>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pt="10">
          {isFirstSession
            ? t('first_session_review_title', { coach: coachName })
            : t('session_review_title', { coach: coachName })}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!isFirstSession && (
            <Box display="flex" justifyContent="center">
              <Rating
                onClick={onSetStarRating}
                size={50}
                transition
                SVGstyle={{ display: 'inline-block' }}
              />
            </Box>
          )}

          <Box mt={4}>
            {rating >= 1 && rating <= 5 && !isFirstSession ? (
              <Box style={{ alignItems: 'center' }}>
                <Text
                  as="h6"
                  fontSize="lg"
                  fontWeight="bold"
                  textAlign="center"
                  mb={2}
                >
                  {getFollowUpQuestionTitle(rating)}
                </Text>
                {/* <Text>
                  {t('select_all_options_that_apply')}
                </Text> */}
                <Box>
                  {optionsHandler(rating).map((option) => renderOption(option))}
                </Box>
              </Box>
            ) : null}

            {isFirstSession && (
              <Box style={{ alignItems: 'center' }}>
                {/* <Text>{t('select_up_to_two_options')}</Text> */}
                <Box>
                  {optionsHandler(6).map((option) => renderOption(option))}
                </Box>
              </Box>
            )}
          </Box>
        </ModalBody>

        <ModalFooter hidden={answers.size === 0 && otherTextRef.current === ''}>
          <Button
            w="100%"
            colorScheme="primary"
            onClick={onSubmit}
            disabled={submitting}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
