import { Suspense, useState } from 'react'
import { UserCircleIcon } from '@heroicons/react/24/outline'

import { COMPANY_NAME, DEFAULT_COACH_TITLE, USING_V2 } from '~/app/constants'
import { useAppSelector } from '~/app/hooks'
import { cn } from '~/utils/cn'
import { CoachBioModal } from '~/components/coach-bio-modal'
import { Await, Link, useLoaderData } from 'react-router-dom'
import { VideoModal } from '~/components/video-modal'
import { FocusArea } from './_components/focus-area'
import { SessionNotes } from './_components/session-notes'
import { UpcomingSessions } from './_components/upcoming-sessions'
import { Credits } from './_components/credits'

const year = new Date().getFullYear()

type Loader = {
  bio: any
  credits: any
  insights: any
  nextSession: any
  sessionNotes: any
}

export const Home: React.FC = () => {
  const data = useLoaderData() as Loader
  const user = useAppSelector((state) => state.auth.user)
  const [showCoachDetails, setShowCoachDetails] = useState(false)
  const [showCoachVideo, setShowCoachVideo] = useState(false)

  return (
    <>
      <div className="pb-8">
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <h1 className="sr-only">Profile</h1>
          {/* Main 3 column grid */}
          <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
            {/* Left column */}
            <div className="grid grid-cols-1 gap-4 lg:col-span-2">
              {/* Welcome panel */}
              <section aria-labelledby="profile-overview-title">
                <div className="overflow-hidden rounded-lg bg-white shadow-md">
                  <h2 id="profile-overview-title" className="sr-only">
                    Profile Overview
                  </h2>
                  <div className="bg-white p-6">
                    <div className="sm:flex sm:items-center sm:justify-between">
                      <div className="sm:flex sm:space-x-5">
                        <div className="flex-shrink-0">
                          {user?.coach.profile.picture ? (
                            <img
                              className={cn(
                                'mx-auto h-20 w-20 object-cover rounded-full',
                                user.coach.profile.video
                                  ? 'ring-2 ring-primary cursor-pointer'
                                  : 'border border-solid border-gray-300'
                              )}
                              src={user.coach.profile.picture}
                              alt={user?.coach.profile.name}
                              onClick={() =>
                                user.coach.profile.video &&
                                setShowCoachVideo(true)
                              }
                            />
                          ) : (
                            <UserCircleIcon
                              className="h-20 w-20 text-gray-400"
                              aria-hidden="true"
                            />
                          )}
                        </div>
                        <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                          <p className="text-sm font-medium text-gray-600">
                            My coach,
                          </p>
                          <p className="text-xl font-bold text-gray-900 sm:text-2xl">
                            {user?.coach.profile.name}
                          </p>
                          <p className="text-sm font-medium text-gray-600">
                            {user?.coach.profile.title ?? DEFAULT_COACH_TITLE}
                          </p>
                        </div>
                      </div>
                      <div className="mt-5 flex justify-center sm:mt-0">
                        <button
                          type="button"
                          className="flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={() => setShowCoachDetails(true)}
                        >
                          View bio
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 divide-y divide-gray-200 border-t border-gray-200 bg-gray-50 sm:grid-cols-3 sm:divide-x sm:divide-y-0">
                    <div className="px-6 py-5 text-center text-sm font-medium">
                      <Link
                        to={USING_V2 ? '/v2/chat' : '/chat'}
                        className="text-gray-800 hover:underline"
                      >
                        Chat
                      </Link>
                    </div>
                    <div className="px-6 py-5 text-center text-sm font-medium">
                      <Link
                        to={USING_V2 ? '/v2/book' : '/book'}
                        className="text-gray-800 hover:underline"
                      >
                        Book a sesssion
                      </Link>
                    </div>
                    <div className="px-6 py-5 text-center text-sm font-medium">
                      <Link
                        to={USING_V2 ? '/v2/notes' : '/notes'}
                        className="text-gray-800 hover:underline"
                      >
                        Notes
                      </Link>
                    </div>
                  </div>
                </div>
              </section>

              <section aria-labelledby="growth-area">
                <Suspense fallback={null}>
                  <Await resolve={data.insights}>
                    <FocusArea />
                  </Await>
                </Suspense>
              </section>

              <section aria-labelledby="session-notes">
                <Suspense fallback={null}>
                  <Await resolve={data.sessionNotes}>
                    <SessionNotes />
                  </Await>
                </Suspense>
              </section>
            </div>

            {/* Right column */}
            <div className="grid grid-cols-1 gap-4">
              {/* Upcoming sessions */}
              <section aria-labelledby="upcoming-sessions-title">
                <Suspense fallback={null}>
                  <Await resolve={data.nextSession} errorElement={<></>}>
                    <UpcomingSessions />
                  </Await>
                </Suspense>
              </section>

              {/* Credits */}
              <section aria-labelledby="credits-indicator-title">
                <Suspense>
                  <Await resolve={data.credits}>
                    <Credits />
                  </Await>
                </Suspense>
              </section>
            </div>
          </div>
        </div>
      </div>

      <footer>
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="border-t border-gray-200 py-8 text-center text-sm text-gray-500 sm:text-left">
            <span className="block sm:inline">
              &copy; {year} {COMPANY_NAME}
            </span>
            <span className="block sm:inline">&nbsp;All rights reserved.</span>
          </div>
        </div>
      </footer>

      <Suspense>
        <Await resolve={data.bio}>
          {({ bio }) => (
            <CoachBioModal
              open={showCoachDetails}
              onClose={() => setShowCoachDetails(false)}
              bio={bio}
              coach={user?.coach}
            />
          )}
        </Await>
      </Suspense>

      <VideoModal
        open={showCoachVideo}
        onClose={() => setShowCoachVideo(false)}
        src={user?.coach.profile.video ?? ''}
      />
    </>
  )
}
