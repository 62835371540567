import type { LoaderFunction } from 'react-router-dom'
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { api } from '~/app/api'

export interface Response {
  dyte?: Dyte
  userShowedUp: boolean
  managerInvited?: boolean
  _id: string
  user: User
  coach: Coach
  start: string
  end: string
  createdAt: string
  zoomLink?: string
  status: string
  title?: string
  isFirstSession: boolean
  rated: boolean
  zoomPasscode?: string
  meetLink?: string
  rating?: number
  teamsMeetingLink?: string
  reason?: string
}

export interface Dyte {
  attendees: Attendee[]
  title?: string
  meetingId?: string
  recordOnStart?: boolean
  useStt?: boolean
  coachVerification?: boolean
}

export interface Attendee {
  _id: string
  email: string
  token: string
  presetId: string
  name?: string
  participantId?: string
}

export interface User {
  profile: Profile
  _id: string
}

export interface Profile {
  name: string
  gender: any
  picture: any
  phone: any
  fullPhone: any
  nickName: any
}

export interface Coach {
  profile: Profile2
  _id: string
}

export interface Profile2 {
  name: string
  title?: string
  picture: string
  videos: any[]
  video?: string
  businessName?: string
}

const fetchOnboardingQuestions = (params: string) =>
  queryOptions({
    queryKey: ['sessions', params],
    queryFn() {
      return api.url('/v2/user/sessions').query(params).get().json<Response[]>()
    },
  })

export const loader = (queryClient: QueryClient): LoaderFunction => (args) => {
  const url = new URL(args.request.url)

  if (!url.searchParams.has('type')) {
    url.searchParams.set('type', 'upcoming')
  }

  return queryClient.fetchQuery(fetchOnboardingQuestions(url.searchParams.toString()))
}
