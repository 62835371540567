import { defer, type LoaderFunction } from 'react-router-dom'
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { api } from '~/app/api'

interface Bio {
  summary: string
  full: string
  licenses: string[]
  background: string[]
  focusArea: FocusArea[]
  industry: Industry[]
  languages: string[]
  style: string[]
  philosophy: string
}

interface FocusArea {
  language: string
  _id: string
  focusArea: string
  friendlyID: string
  __v: number
}

interface Industry {
  language: string
  _id: string
  friendlyID: string
  industry: string
  __v: number
}

interface Profile {
  name: string
  picture: string
  video: string
  videos: any[]
  email: string
}


const fetchCoachBio = () =>
  queryOptions({
    queryKey: ['coach_bio'],
    queryFn() {
      return api.url('/user/coach_bio').get().json<{
        bio: Bio
        profile: Profile
      }>()
    },
  })

const fetchSessionNotes = () =>
  queryOptions({
    queryKey: ['session_notes'],
    queryFn() {
      return api.url('/user/session-notes').get().json()
    },
  })

const fetchCredits = () =>
  queryOptions({
    queryKey: ['credits'],
    queryFn() {
      return api.url('/user/credits').get().json<{ total?: number; used?: number }>()
    },
  })

const fetchInsights = () =>
  queryOptions({
    queryKey: ['insights'],
    queryFn() {
      return api.url('/assessment/insights').get().json()
    },
  })

const fetchNextSession = (date: string) =>
  queryOptions({
    queryKey: ['next_session'],
    queryFn() {
      return api.url('/scheduler/event').query({ date }).get().json()
    },
  })

export const loader = (queryClient: QueryClient): LoaderFunction => async () => {
  const coachBioPromise = queryClient.fetchQuery(fetchCoachBio())
  const creditsPromise = queryClient.fetchQuery(fetchCredits())
  const insightsPromise = queryClient.fetchQuery(fetchInsights())
  const nextSessionPromise = queryClient.fetchQuery(fetchNextSession(new Date().toISOString()))
  const sessionNotesPromise = queryClient.fetchQuery(fetchSessionNotes())

  return defer({
    bio: coachBioPromise,
    credits: creditsPromise,
    insights: insightsPromise,
    nextSession: nextSessionPromise,
    sessionNotes: sessionNotesPromise,
  })
}
