import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Spinner,
  Text,
  Textarea,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Stack,
  ListItem,
  List,
  Link,
} from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  useGetHupoHighlightsSummaryQuery,
  usePostHupoHighlightsSummaryMutation,
} from '../../app/services/api'
import { useLocale } from '../../hooks/useLocale'
import { Logo } from '../../utils/Logo'
import { ResourceCard } from '../library/ResourceCard'
import { ThemeProvider } from '@mui/material'
import { appTheme } from '../../theme/theme'
import { ResourcesList } from '../library/resource.style'

const HupoHighlightsEdit: FC<any> = () => {
  const { t } = useLocale()
  const { eventId } = useParams<{ eventId: string }>()
  console.log('hupohighlights-edit', eventId)
  const [formState, setFormState] = useState({
    body: '',
    resources: [],
  })
  const [removedRecommendationIds, setRemovedRecommendationIds] = useState<
    string[]
  >([])
  const {
    isOpen: isPreviewOpen,
    onOpen: onPreviewOpen,
    onClose: onPreviewClose,
  } = useDisclosure()

  const [saving, setSaving] = useState(false)
  const { data, isLoading } = useGetHupoHighlightsSummaryQuery({ eventId })
  const [postHupoHighlightsSummary] = usePostHupoHighlightsSummaryMutation()

  const handleChange = (e: any) => {
    setFormState({
      ...formState,
      body: e.target.value,
    })
  }

  const handleRemove = (id: any) => {
    const recommendation: any = formState.resources.find(
      (r: any) => r.resource._id === id
    )
    setFormState({
      ...formState,
      resources: formState.resources.filter((r: any) => r.resource._id !== id),
    })
    setRemovedRecommendationIds([
      ...removedRecommendationIds,
      recommendation?._id,
    ])
  }

  useEffect(() => {
    if (data) {
      setFormState({
        body: data.content,
        resources: data.resourceRecommendations,
      })
    }
  }, [data])

  const onSubmit = async () => {
    try {
      setSaving(true)
      await postHupoHighlightsSummary({
        eventId,
        content: formState.body,
        removedRecommendationIds,
      }).unwrap()

      window.location.href = '/session-summary/edit-success'
      setSaving(false)
    } catch (error) {
      console.log('error', error)
    }
  }
  return (
    <Box flexDirection={'column'} textAlign="center" px={6} pb={10}>
      <Flex
        px={{ base: 4, md: 4 }}
        height="20"
        borderBottomWidth="1px"
        alignItems="center"
        justifyContent="center"
      >
        <Logo md={5} />
      </Flex>

      <Flex
        alignItems="center"
        justifyContent="center"
        padding={10}
        direction="column"
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <Heading
              display="inline-block"
              as="h4"
              size="lg"
              textAlign={'center'}
              mb={4}
              px={24}
            >
              {t('hupo-highlights')}
            </Heading>
            <Heading
              display="inline-block"
              as="h4"
              size="md"
              textAlign={'center'}
              mb={4}
              px={24}
            >
              {t(
                formState.resources.length > 0
                  ? 'hupo-highlights-edit-with-resources'
                  : 'hupo-highlights-edit',
                { coachee: data?.coacheeName }
              )}
            </Heading>
            <Text textAlign={'center'}>
              {t(
                formState.resources.length > 0
                  ? 'hupo-highlights-edit-subtitle-with-resources'
                  : 'hupo-highlights-edit-subtitle'
              )}
            </Text>

            <FormControl mt={5}>
              <Heading display="inline-block" as="h4" size="md" mb={4} px={24}>
                {t('summary')}
              </Heading>
              <Textarea
                value={formState.body}
                placeholder={`Highlights of the session`}
                name="body"
                onChange={handleChange}
                size="md"
                rows={14}
              />
            </FormControl>
            {formState.resources.length > 0 ? (
              <Box>
                <Heading
                  display="inline-block"
                  as="h4"
                  size="md"
                  mb={4}
                  mt={8}
                  px={24}
                >
                  {t('recommended_resources')}
                </Heading>
                <Box textAlign="left">
                  <ThemeProvider theme={appTheme}>
                    <ResourcesList>
                      {formState.resources.map((r: any) => (
                        <ResourceCard
                          key={r.id}
                          data={r.resource}
                          reason={r.reason}
                          chapters={r.chapters}
                          loading={false}
                          onRemove={handleRemove}
                          isEditMode={true}
                          type="coach_edit"
                        />
                      ))}
                    </ResourcesList>
                  </ThemeProvider>
                </Box>
              </Box>
            ) : null}
            <Button onClick={onPreviewOpen} colorScheme="primary" mt={6}>
              {t('preview')}
            </Button>
          </>
        )}
        <Modal
          isOpen={isPreviewOpen}
          onClose={() => {
            onPreviewClose()
          }}
          size="lg"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t('preview-summary')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Flex
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Heading
                  display="inline-block"
                  as="h4"
                  size="md"
                  mb={3}
                  px={24}
                >
                  {t('summary')}
                </Heading>
                <TextComponent
                  text={formState.body}
                  highlightedSections={data?.sections}
                />
                {formState.resources.length > 0 ? (
                  <Heading
                    display="inline-block"
                    as="h4"
                    size="md"
                    mb={3}
                    mt={6}
                    px={24}
                  >
                    {t('recommended_resources')}
                  </Heading>
                ) : null}
                <List spacing={4}>
                  {formState.resources.map((r: any) => (
                    <ListItem key={r.resource._id}>
                      <Text as="b">{r.resource.name}</Text>{' '}
                      <Text as="i">by {r.resource.author}</Text> -{' '}
                      <Link href={r.resource.link}>{r.resource.link}</Link>
                      <br />
                      <Text as="b">{t('description')}: </Text>
                      {r.reason}
                      <br />
                      <Text as="b">{t('chapters_to_focus_on')} </Text>
                      {r.chapters}
                    </ListItem>
                  ))}
                </List>
                <Stack spacing={4} direction="row" mt={6}>
                  <Button
                    onClick={onSubmit}
                    colorScheme="primary"
                    disabled={saving}
                  >
                    {saving
                      ? t('save-and-share-in-progress')
                      : t('save-and-share')}
                  </Button>
                  <Button onClick={onPreviewClose}>
                    {t('continue-editing')}
                  </Button>
                </Stack>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>
    </Box>
  )
}

function TextComponent({ text, highlightedSections }: any) {
  let htmlText = text.replace(/\n/g, '<br />')
  if (highlightedSections) {
    for (const section of highlightedSections) {
      htmlText = htmlText.replace(section, `<strong>${section}</strong>`)
    }
  }
  return <div dangerouslySetInnerHTML={{ __html: htmlText }} />
}

export default HupoHighlightsEdit
