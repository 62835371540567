import { Outlet } from 'react-router-dom'
import { Auth0ProviderWithRedirectCallback } from '~/Auth0ProviderWithRedirectCallback'

export const AppLayout: React.FC = () => {
  return (
    <Auth0ProviderWithRedirectCallback>
      <Outlet />
    </Auth0ProviderWithRedirectCallback>
  )
}
