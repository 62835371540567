import { useLoaderData, useRevalidator } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { UserCircleIcon } from '@heroicons/react/24/outline'

import type { Response } from './account.loader'
import { useMutation } from '@tanstack/react-query'
import { api } from '~/app/api'
import { useToast } from '@chakra-ui/react'
import { AlertDialog } from '~/components/alert-dialog'
import { TimeZoneSelect } from '~/components/TimeZoneSelect'
import { useAppDispatch, useAppSelector } from '~/app/hooks'
import { setTimeZone } from '~/store/preferences.slice'

type ProfileData = {
  name: string
  timeZone: string
}

type Preferences = {
  callTool: string
  language: string
}

export const AccountSettings: React.FC = () => {
  // const { authenticatedWith } = useAppSelector((state) => state.auth)
  const data = useLoaderData() as Response
  const { i18n, t } = useTranslation()
  const { revalidate } = useRevalidator()
  const selectedTimeZone = useAppSelector((state) => state.preferences.timeZone)
  const dispatch = useAppDispatch()
  const photoInput = useRef<HTMLInputElement>(null)
  const toast = useToast()
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false)
  const [photoPreviewURL, setPhotoPreviewURL] = useState<string | null>(
    data.profile.picture
  )

  const form = useForm<ProfileData>({
    defaultValues: {
      name: data.profile.name,
      timeZone: data.timezone,
    },
  })

  const preferenceForm = useForm<Preferences>({
    defaultValues: {
      callTool: data.callTool,
      language: i18n.language,
    },
  })

  const callTool = preferenceForm.watch('callTool')
  const language = preferenceForm.watch('language')

  const onPhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return

    const reader = new FileReader()
    reader.onload = () => {
      setPhotoPreviewURL(reader.result as string)
    }
    reader.readAsDataURL(file)

    // uploadAvatar(file)
  }

  const { mutate } = useMutation({
    mutationKey: ['account'],
    mutationFn(data: {
      name: string
      timezone: string
      language: string
      callTool: string
    }) {
      return api.url('/users/profile').post(data).json()
    },
    onSuccess() {
      toast({
        status: 'success',
        title: t('success'),
        description: t('your_profile_is_updated'),
        isClosable: true,
      })
      revalidate()
    },
  })

  useEffect(() => {
    if (selectedTimeZone !== data.timezone) {
      dispatch(setTimeZone(data.timezone))
    }
  }, [dispatch, data, selectedTimeZone])

  const { mutate: deleteAccount } = useMutation({
    mutationKey: ['delete_account'],
    mutationFn() {
      return api.url('/user/').delete().json()
    },
    onSuccess() {
      toast({
        status: 'success',
        title: t('success'),
        description: t('account_delete_success'),
        isClosable: true,
      })
    },
  })

  const onSubmit = form.handleSubmit((data) => {
    mutate({
      name: data.name,
      timezone: data.timeZone,
      language,
      callTool,
    })
  })

  // const passwordForm = useForm<Passwords>({
  //   resolver(data) {
  //     if (data.newPassword !== data.confirmNewPassword) {
  //       return {
  //         errors: {
  //           confirmNewPassword: {
  //             type: 'passwordMismatch',
  //             message: 'Passwords do not match',
  //           },
  //         },
  //         values: {},
  //       }
  //     }

  //     return { errors: {}, values: data }
  //   },
  // })

  return (
    <>
      <h1 className="sr-only">Account Settings</h1>

      <div className="divide-y divide-black/5">
        <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Personal Information
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-400">
              Use a permanent address where you can receive mail.
            </p>
          </div>

          <form className="md:col-span-2" onSubmit={onSubmit}>
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
              <div className="col-span-full flex items-center gap-x-8">
                {photoPreviewURL ? (
                  <img
                    alt={data.profile.name}
                    src={photoPreviewURL}
                    className="h-24 w-24 flex-none rounded-lg bg-gray-800 object-cover"
                  />
                ) : (
                  <UserCircleIcon
                    className="h-20 w-20 text-gray-400"
                    aria-hidden="true"
                  />
                )}
                <input
                  id="photo"
                  ref={photoInput}
                  type="file"
                  name="photo"
                  accept="image/*"
                  onChange={onPhotoChange}
                  hidden
                />
                <div>
                  <button
                    type="button"
                    className="rounded-md bg-white ring-1 ring-inset ring-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-50"
                    onClick={() => photoInput.current?.click()}
                  >
                    Change avatar
                  </button>
                  <p className="mt-2 text-xs leading-5 text-gray-400">
                    JPG, GIF or PNG. 1MB max.
                  </p>
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="full-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Name
                </label>
                <div className="mt-2">
                  <input
                    id="full-name"
                    type="text"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                    {...form.register('name')}
                  />
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
                    disabled
                    placeholder="you@example.com"
                    defaultValue={data.email}
                  />
                </div>
              </div>

              <div className="col-span-full">
                <TimeZoneSelect
                  defaultValue={data.timezone}
                  onChange={(tz) => form.setValue('timeZone', tz)}
                />
              </div>
            </div>

            <div className="mt-8 flex">
              <button
                type="submit"
                className="rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
              >
                Save
              </button>
            </div>
          </form>
        </div>

        <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Preferences
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-400">
              Personalize your experience.
            </p>
          </div>

          <form className="md:col-span-2" onSubmit={onSubmit}>
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
              <div className="col-span-full">
                <label
                  htmlFor="call-tool"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Call tool
                </label>
                <div className="mt-2">
                  <select
                    id="call-tool"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6"
                    {...preferenceForm.register('callTool')}
                  >
                    <option value="meet">Google meet</option>
                    <option value="teams">Microsoft teams</option>
                    <option value="zoom">Zoom</option>
                  </select>
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="language"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Language
                </label>
                <div className="mt-2">
                  <select
                    id="language"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6"
                    {...preferenceForm.register('language')}
                  >
                    {i18n.languages.map((lang) => (
                      <option key={lang} value={lang}>
                        {t(lang)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="mt-8 flex">
              <button
                type="submit"
                className="rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
              >
                Save
              </button>
            </div>
          </form>
        </div>

        {false && (
          <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Change password
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-400">
                Update your password associated with your account.
              </p>
            </div>

            <form className="md:col-span-2">
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                <div className="col-span-full">
                  <label
                    htmlFor="current-password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Current password
                  </label>
                  <div className="mt-2">
                    <input
                      id="current-password"
                      name="current_password"
                      type="password"
                      autoComplete="current-password"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="col-span-full">
                  <label
                    htmlFor="new-password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    New password
                  </label>
                  <div className="mt-2">
                    <input
                      id="new-password"
                      name="new_password"
                      type="password"
                      autoComplete="new-password"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="col-span-full">
                  <label
                    htmlFor="confirm-password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Confirm password
                  </label>
                  <div className="mt-2">
                    <input
                      id="confirm-password"
                      name="confirm_password"
                      type="password"
                      autoComplete="new-password"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              <div className="mt-8 flex">
                <button
                  type="submit"
                  className="rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        )}

        <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Delete account
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-400">
              No longer want to use our service? You can request to delete your
              account here.
            </p>
          </div>

          <form
            className="flex items-start md:col-span-2"
            onClick={(e) => {
              e.preventDefault()
              setIsDeleteAlertOpen(true)
            }}
          >
            <button
              type="submit"
              className="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400"
            >
              Yes, delete my account
            </button>
          </form>
        </div>
      </div>

      <AlertDialog
        isOpen={isDeleteAlertOpen}
        onClose={() => setIsDeleteAlertOpen(false)}
        title="Delete Account"
        description="Are you sure you want to delete your account? This action cannot be undone."
        onConfirm={deleteAccount}
        confirmText="Yes, delete my account"
        // cancelText="No, keep my account"
      />
    </>
  )
}
