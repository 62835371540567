import { useToast } from '@chakra-ui/react'
import { StarIcon } from '@heroicons/react/24/solid'
import { useMutation } from '@tanstack/react-query'
import { Fragment, useCallback, useId, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRevalidator } from 'react-router-dom'
import { api } from '~/app/api'
import {
  firstSessionOptions,
  highOptions,
  lowOptions,
  midOptions,
} from '~/app/constants'
import { Modal } from '~/components/modal'

type Props = {
  wasFirstSession?: boolean
  sessionId: string
  isOpen: boolean
  onClose: () => void
  coachName: string
}

type Payload = {
  eventId: string
  other?: string
  rating: number
  answers: string[]
}

export const RatingModal: React.FC<Props> = (props) => {
  const { wasFirstSession, sessionId, coachName, isOpen, onClose } = props
  const id = useId()
  const { t } = useTranslation()
  const { revalidate } = useRevalidator()
  const toast = useToast()
  const [rating, setRating] = useState(0)
  const otherRef = useRef<HTMLTextAreaElement>(null)

  const followUpQuestionTitleKey = useMemo(() => {
    switch (rating) {
      case 1:
      case 2:
        return 'session_review_followup_title_rating_low'
      case 3:
      case 4:
        return 'session_review_followup_title_rating_mid'
      case 5:
        return 'session_review_followup_title_rating_high'
      default:
        return ''
    }
  }, [rating])

  const additionalQuestions = useMemo(() => {
    if (wasFirstSession) return firstSessionOptions

    switch (rating) {
      case 1:
      case 2:
        return lowOptions.filter((question) => question !== 'other')
      case 3:
      case 4:
        return midOptions.filter((question) => question !== 'other')
      case 5:
        return highOptions.filter((question) => question !== 'other')
      default:
        return []
    }
  }, [wasFirstSession, rating])

  const clearAdditionalQuestions = useCallback((id: string) => {
    document
      .querySelectorAll(`input[name^="${id}/additional"]`)
      .forEach((input) => {
        if (input instanceof HTMLInputElement) {
          input.checked = false
        }
      })
  }, [])

  const { mutate } = useMutation({
    mutationFn(payload: Payload) {
      return api.url('/feedback/user').post(payload).json()
    },
    onSuccess() {
      toast({
        title: t('thanks_for_session_feedback'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      })

      revalidate()
    },
    onError() {
      toast({
        title: t('session_review_submit_error'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    },
    onSettled() {
      onCloseModal()
    },
  })

  const onCloseModal = () => {
    onClose()
    setTimeout(() => {
      setRating(0)
      clearAdditionalQuestions(id)
    }, 300)
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    const otherText = otherRef.current?.value ?? ''
    const answers = Array.from(formData.getAll(`${id}/additional`) as string[])

    if (otherText?.length > 0) {
      answers.push('other')
    }

    mutate({
      eventId: sessionId,
      other: otherText,
      rating,
      answers,
    })
  }

  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      title={
        wasFirstSession
          ? t('first_session_review_title', { coach: coachName })
          : t('session_review_title', { coach: coachName })
      }
      description="Please rate your session so we can improve our service"
      confirmText="Submit"
      formId={id}
      confirmDisabled={rating === 0}
    >
      <form id={id} onSubmit={onSubmit}>
        <div className="flex flex-row-reverse justify-center items-center">
          {!wasFirstSession &&
            [5, 4, 3, 2, 1].map((value) => {
              const inputId = `${id}/rating/${value}`
              const inputName = `${id}/rating`

              return (
                <Fragment key={value}>
                  <input
                    id={inputId}
                    type="radio"
                    className="peer -ms-7 size-8 bg-transparent border-0 text-transparent cursor-pointer appearance-none checked:bg-none focus:bg-none focus:ring-0 focus:ring-offset-0"
                    name={inputName}
                    value={value}
                    onChange={() => {
                      setRating(value)
                      clearAdditionalQuestions(id)
                    }}
                  />
                  <label
                    htmlFor={inputId}
                    className="peer-checked:text-primary text-gray-300 pointer-events-none"
                  >
                    <StarIcon className="shrink-0 size-8" />
                  </label>
                </Fragment>
              )
            })}
        </div>

        {((!wasFirstSession && rating > 0) || wasFirstSession) && (
          <>
            <fieldset className="mt-4">
              <legend className="text-base text-center font-semibold leading-6 text-gray-900">
                {t(followUpQuestionTitleKey)}
              </legend>
              <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
                {additionalQuestions.map((question) => (
                  <div
                    key={question}
                    className="relative flex items-start py-4"
                  >
                    <div className="min-w-0 flex-1 text-sm leading-6">
                      <label
                        htmlFor={question}
                        className="select-none font-medium text-gray-900"
                      >
                        {t(question)}
                      </label>
                    </div>
                    <div className="ml-3 flex h-6 items-center">
                      <input
                        id={question}
                        name={`${id}/additional`}
                        value={question}
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </fieldset>

            <div className="mt-3">
              <label
                htmlFor={`${id}/other`}
                className="block text-sm text-center font-medium leading-6 text-gray-900"
              >
                {t(
                  wasFirstSession
                    ? 'other_feedback_label_first_session'
                    : 'other_feedback_label_multiple_sessions'
                )}
              </label>
              <div className="mt-2">
                <textarea
                  ref={otherRef}
                  id={`${id}/others`}
                  name={`${id}/other`}
                  rows={3}
                  placeholder="Please provide your feedback here"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </>
        )}
      </form>
    </Modal>
  )
}
