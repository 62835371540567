import { PeerAssessmentOption } from '../../../../types/api'
import { Animated } from './Animated'
import { ListQuestionItemsWrapper } from './question-types.style'

export function ListQuestionItems({
  options,
}: {
  options: PeerAssessmentOption[]
}) {
  return (
    <Animated key={options[0]._id}>
      <ListQuestionItemsWrapper>
        {options.map((option) => (
          <div key={option.value}>{option.label}</div>
        ))}
      </ListQuestionItemsWrapper>
    </Animated>
  )
}
