import { Box, Checkbox } from '@chakra-ui/react'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState, store } from '../../../app/store'
import { useLocale } from '../../../hooks/useLocale'
import OnboardingContainer from '../../onboarding/OnboardingContainer'
import OnboardingFooter from '../../onboarding/components/OnboardingFooter'
import OnboardingHeader from '../../onboarding/components/OnboardingHeader'
import TitleDescription from '../../onboarding/questionTypes/TitleDescription'
import { initialState, setShareWithUser } from '../assessmentSlice'

const InfoQuestion: FC<any> = ({
  progress,
  question,
  onSubmit,
  isLast,
  isManager,
}) => {
  const manager = useSelector((state: RootState) => state.assessment.manager)
  const user = useSelector((state: RootState) => state.assessment.user)
  const [checked, setChecked] = useState(false)
  const { t } = useLocale()
  return (
    <>
      <OnboardingHeader
        currentStep={progress.currentStep}
        totalSteps={progress.totalSteps}
        isAssessment
      />
      <OnboardingContainer>
        <TitleDescription
          title={question.title
            .replaceAll('{{manager}}', manager)
            .replaceAll('{{username}}', user)}
          description={question.description
            ?.replaceAll('{{manager}}', manager)
            .replaceAll('{{username}}', user)}
        />
        {isManager && isLast ? (
          <Box justifyContent={'center'} display={'flex'} mt={4}>
            <Checkbox
              checked={checked}
              defaultChecked={initialState.shareWithUser}
              onChange={(e) => {
                setChecked(e.target.checked)
                store.dispatch(setShareWithUser(e.target.checked))
              }}
            >
              {t('share_with_user', { user })}
            </Checkbox>
          </Box>
        ) : (
          <></>
        )}
        <OnboardingFooter
          onSubmit={onSubmit}
          submitText={isLast ? t('finish') : t('continue')}
        />
      </OnboardingContainer>
    </>
  )
}

export default InfoQuestion
