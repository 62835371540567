import { Image } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { updateMomentLocale } from '../hooks/useLocale'

export const Logo = (props: any) => {
  const isCoach = useSelector((state: any) => !!state.auth.coach)

  useEffect(() => {
    const lang = localStorage.getItem('i18nextLng') || 'en'
    console.log('Logo, locale:', lang)
    updateMomentLocale(lang)
  }, [])

  return (
    <Link to={isCoach ? '/coach' : '/'}>
      <Image
        src="https://dopmo1eihgbgm.cloudfront.net/646fa927d47205798c223b6d/hupo_logo_.png"
        alt="Hupo logo"
        width="80px"
        {...props}
      />
    </Link>
  )
}
