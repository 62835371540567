import { Input, Stack, Text, useToast } from '@chakra-ui/react'
import { FC, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { RootState } from '../../../app/store'
import { useLocale } from '../../../hooks/useLocale'
import { useMixpanel } from '../../../utils/MixpanelContext'
import OnboardingContainer from '../OnboardingContainer'
import OnboardingFooter from '../components/OnboardingFooter'
import OnboardingHeader from '../components/OnboardingHeader'
import { itemSelect } from '../onboardingSlice'
import TitleDescription from './TitleDescription'

const MultiEmailQuestion: FC<any> = ({
  index,
  onSubmit,
  submitting = false,
}) => {
  const { t } = useLocale()
  const onboardingQuestions = useSelector(
    (state: RootState) => state.onboarding.questions
  )
  const companyId = useSelector(
    (state: RootState) => state.onboarding.companyId
  )

  const language = useSelector((state: RootState) => state.onboarding.language)
  console.log('EmailQuestion, companyId:', companyId, ', language:', language)
  const isLast = index === onboardingQuestions.length - 1
  const mixpanel = useMixpanel()

  const question = onboardingQuestions[index]
  const emailsCount = question.maxSelectionLimit ?? 1
  const minEmailsCount = question.minSelectionLimit ?? emailsCount
  const inputRef = useRef<string[]>(new Array(emailsCount).fill(''))
  const emailValidationError = useRef<string[]>(new Array(emailsCount).fill(''))
  const [dummyUpdate, setDummyUpdate] = useState(0)

  const dispatch = useDispatch()

  const toast = useToast()

  const emailValidation = yup
    .string()
    .email(t('please_enter_valid_email'))
    .required(t('email_is_required'))

  const onLocalSubmit = async () => {
    const emails = inputRef.current
      ?.map((email) => email.trim()?.toLowerCase())
      .filter((email) => email.length > 0)
    if (question.optional && emails.length === 0) {
      onSubmit()
      return
    }

    try {
      for (const email of emails) {
        emailValidation.validateSync(email)
      }

      if (emails.length < minEmailsCount) {
        if (question.minSelectionLimit) {
          toast({
            title: 'Error',
            description: t('min_emails_required', { minEmailsCount }),
            status: 'error',
            isClosable: true,
          })
        } else {
          toast({
            title: 'Error',
            description: t('emails_required'),
            status: 'error',
            isClosable: true,
          })
        }
        return
      }

      dispatch(
        itemSelect({
          questionId: question.friendlyID,
          answers: emails.join(','),
        })
      )
      onSubmit()
    } catch (validationError: any) {
      toast({
        title: 'Error',
        description: validationError.errors,
        status: 'error',
        isClosable: true,
      })
      mixpanel.track('onboarding_emails_step_fail', {
        error: JSON.stringify(validationError.errors ?? validationError),
        emails,
      })
    }
  }

  return (
    <>
      <OnboardingHeader
        currentStep={index}
        totalSteps={onboardingQuestions.length - 1}
      />
      <OnboardingContainer direction="column" alignItems="center">
        <Stack gridRowGap={1} py={10}>
          <TitleDescription
            title={question.title}
            description={question.description}
          />
          {new Array(emailsCount).fill(null).map((_, index) => (
            <>
              <Input
                key={'colleague' + index}
                placeholder={t('colleague_n_email', { orderNum: index + 1 })}
                onChange={(event) =>
                  (inputRef.current[index] = event.target.value)
                }
                autoFocus={index === 0}
                onBlur={() => {
                  const email = inputRef.current[index].trim().toLowerCase()
                  emailValidationError.current[index] = ''
                  if (email) {
                    try {
                      emailValidation.validateSync(email)
                    } catch (validationError: any) {
                      emailValidationError.current[index] =
                        validationError.errors
                    }
                  }
                  setDummyUpdate(dummyUpdate + 1)
                }}
                onSubmit={index === emailsCount - 1 ? onLocalSubmit : () => {}}
              />
              {emailValidationError.current[index] && (
                <Text
                  //status="danger"
                  //style={styles.errors}
                  style={{
                    color: 'red',
                    fontSize: '12px',
                    marginBottom: '5px',
                  }}
                  key={'colleage-email-error' + index}
                >
                  {emailValidationError.current[index]}
                </Text>
              )}
            </>
          ))}
        </Stack>
        <OnboardingFooter
          onSubmit={onLocalSubmit}
          submitText={t('next')}
          submitting={submitting}
          isLastStep={isLast}
        />
      </OnboardingContainer>
      <div
        data-testid="onboarding-flow-test-hidden"
        data-onboarding-flow-test-type={question.type}
        data-onboarding-flow-test-index={index}
        data-onboarding-flow-test-id={question.friendlyID}
        data-onboarding-flow-test-islast={isLast}
        style={{ display: 'none' }}
      />
    </>
  )
}

export default MultiEmailQuestion
