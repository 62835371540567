import type { PayloadAction } from '@reduxjs/toolkit'
import type { AppThunk } from '~/app/store'
import Cookies from 'js-cookie'
import { createAppSlice } from '~/app/createAppSlice'
import { LOCAL_TIMEZONE } from '~/app/constants'


export interface OnboardingSliceState {
  isInProgress: boolean
  language: string
  companyId: string
  departmentId: string | null
  email: string
  coachEmail: string
  timeZone: string
  usesAuth0: boolean
}

const initialState: OnboardingSliceState = {
  isInProgress: Cookies.get('onboarding_in_progress') === 'true',
  language: 'en',
  companyId: '',
  departmentId: null,
  email: '',
  coachEmail: '',
  timeZone: LOCAL_TIMEZONE,
  usesAuth0: false,
}

export const onboardingSlice = createAppSlice({
  name: 'v2/onboarding',
  initialState,
  reducers: (create) => ({
    startOnboarding: create.reducer((state) => {
      state.isInProgress = true
    }),
    setLanguage: create.reducer((state, action: PayloadAction<string>) => {
      state.language = action.payload
    }),
    setCompanyId: create.reducer((state, action: PayloadAction<string>) => {
      state.companyId = action.payload
    }),
    setDepartmentId: create.reducer((state, action: PayloadAction<string>) => {
      state.departmentId = action.payload
    }),
    setUserEmail: create.reducer((state, action: PayloadAction<string>) => {
      state.email = action.payload
    }),
    setCoachEmail: create.reducer((state, action: PayloadAction<string>) => {
      state.coachEmail = action.payload
    }),
    usesAuth0: create.reducer((state, action: PayloadAction<boolean>) => {
      state.usesAuth0 = action.payload
    }),
    clearData: create.reducer(() => initialState),
  }),
})

export const startOnboarding = (): AppThunk => (dispatch) => {
  Cookies.set('onboarding_in_progress', 'true')
  dispatch(onboardingSlice.actions.startOnboarding())
}

export const cleanUpOnboarding = (): AppThunk => (dispatch) => {
  Cookies.remove('onboarding_in_progress')
  dispatch(onboardingSlice.actions.clearData())
}

export const {
  setCompanyId,
  setDepartmentId,
  setLanguage,
  setUserEmail,
  setCoachEmail,
  usesAuth0,
} = onboardingSlice.actions
