import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Heading,
  IconButton,
  Image,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
  VStack,
  Wrap,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HiArrowLeft } from 'react-icons/hi'

import { useLocale } from '../../hooks/useLocale'

interface IProps {
  question: any
  onProceed: (step?: number) => void
  itemSelect: (item: any) => void
  errorMessage: string
  setErrorMessage: (message: string) => void
  showError: boolean
  setShowError: (show: boolean) => void
  canGoBack?: boolean
  isSinglePage?: boolean
  type?: 'survey'
}

const processText = (text: string) => text.replace('(', '\n(')
const decideFlexDirection = (label: string) => {
  if (label === 'No') return 'column'
  return label.length < 3 ? 'row' : 'column'
}

const CheckBoxItem = ({
  option,
  checkedBoxes,
  setCheckedBoxes,
  question,
  itemSelect,
  setIsOtherSelected,
  type,
}: any) => {
  const dispatch = useDispatch()
  if (option.children.length > 0) {
    return (
      <VStack py={2} alignItems={'flex-start'}>
        <Text fontWeight={'bold'}>{processText(option.label)}</Text>
        {option.children.map((childOption: any) => (
          <CheckBoxItem
            option={childOption}
            question={question}
            key={childOption._id}
            checkedBoxes={checkedBoxes}
            setCheckedBoxes={setCheckedBoxes}
            itemSelect={itemSelect}
            setIsOtherSelected={setIsOtherSelected}
          />
        ))}
      </VStack>
    )
  } else {
    return (
      <Box my={2}>
        <Checkbox
          // checked={checkedBoxes.has(option.value)}
          isChecked={checkedBoxes.has(option._id)}
          onChange={(event: React.ChangeEvent<any>) => {
            const answers = new Set(checkedBoxes)
            if (option.value === 'other') {
              setIsOtherSelected(event.target.checked)
            }

            if (event.target.checked) {
              answers.add(option._id)
            } else {
              answers.delete(option._id)
            }
            dispatch(
              itemSelect({
                questionId:
                  type === 'onboarding' ? question.friendlyID : question._id,
                answers: Array.from(answers),
              })
            )
            setCheckedBoxes(answers)
          }}
        >
          {processText(option.label)}
        </Checkbox>
      </Box>
    )
  }
}

const SurveyQuestion = ({
  question,
  itemSelect,
  // otherOption,
  setOtherOption,
  isSinglePage,
}: any) => {
  const { t } = useLocale()
  const dispatch = useDispatch()
  const type = question.type
  const options = question.options
    .slice()
    .sort((a: any, b: any) => a.order - b.order)

    const oldAnswer = useSelector(
      ({ survey }: any) => survey.questions[question._id]
    )

  const [isOtherSelected, setIsOtherSelected] = useState(false)
  const [selectedRadioValue, setSelectedRadioValue] = useState(
    oldAnswer?.answers.length > 0 ? oldAnswer.answers[0] : ''
  )
  const [checkedBoxes, setCheckedBoxes] = useState(new Set(oldAnswer?.answers || []))

  let optionsElement = <></>
  if (type === 'multiselect') {
    optionsElement = (
      <>
        {options.map((option: any) => (
          <CheckBoxItem
            key={option._id}
            option={option}
            question={question}
            checkedBoxes={checkedBoxes}
            setCheckedBoxes={setCheckedBoxes}
            itemSelect={itemSelect}
            setIsOtherSelected={setIsOtherSelected}
          />
        ))}
        {isOtherSelected && (
          <Input
            placeholder={'Please specify'}
            defaultValue={oldAnswer?.other || ''}
            onChange={(event: React.ChangeEvent<any>) => {
              const answer = event.target.value.trim()
              setOtherOption(answer)
              dispatch(
                itemSelect({
                  questionId: question._id,
                  answer: answer,
                  answers: Array.from(checkedBoxes),
                })
              )
            }}
          />
        )}
      </>
    )
  } else if (type === 'select') {
    optionsElement = (
      <>
        <RadioGroup
          key={question._id}
          defaultValue={selectedRadioValue}
          onChange={(value) => {
            const option = options.find((option: any) => option._id === value)
            const isOther = option.value === 'other'

            setIsOtherSelected(isOther)
            dispatch(
              itemSelect({
                questionId: question._id,
                answers: [value],
              })
            )
            setSelectedRadioValue(value)
          }}
        >
          <Stack direction={decideFlexDirection(options[0].label)}>
            {options.map((option: any) => (
              <Box
                key={option._id}
                // my={3}
                m={options[0].label.length < 4 ? 0 : undefined}
              >
                <Radio value={option._id} name={option.value}>
                  {processText(option.label)}
                </Radio>
              </Box>
            ))}
          </Stack>
        </RadioGroup>
        {isOtherSelected && (
          <Input
            mt={2}
            placeholder={'Please specify'}
            defaultValue={oldAnswer?.other || ''}
            onChange={(event: React.ChangeEvent<any>) => {
              const answer = event.target.value.trim()
              setOtherOption(answer)
              dispatch(
                itemSelect({
                  questionId: question._id,
                  answer: answer,
                  answers: [],
                  other: true,
                })
              )
            }}
          />
        )}
      </>
    )
  } else if (type === 'textarea') {
    optionsElement = (
      <Box>
        {!isSinglePage && <Text>{t('your_answer')}:</Text>}
        <Textarea
        mb={isSinglePage ? 8 : 0}
          minW={450}
          minH={150}
          placeholder={t('your_answer_placeholder')}
          defaultValue={oldAnswer?.other || ''}
          onChange={(event: React.ChangeEvent<any>) => {
            const answer = event.target.value
            dispatch(
              itemSelect({
                questionId: question._id,
                answer: answer,
                answers: [],
                other: true,
              })
            )
          }}
        />
      </Box>
    )
  } else if (type === 'text') {
    optionsElement = (
      <Box w={isSinglePage ? { base: '72', lg: '25vw' } : undefined}>
        {!isSinglePage && <Text>Your answer:</Text>}
        <Input
          type="text"
          placeholder="Type your answer here"
          defaultValue={oldAnswer?.other || ''}
          onChange={(event: React.ChangeEvent<any>) => {
            const answer = event.target.value
            dispatch(
              itemSelect({
                questionId: question._id,
                answer: answer,
                answers: [],
                other: true,
              })
            )
          }}
        />
      </Box>
    )
  }
  return (
    <Flex flexDir={'column'} m={2}>
      {optionsElement}
    </Flex>
  )
}

export const Question: React.FC<IProps> = ({
  itemSelect,
  onProceed,
  question,
  errorMessage,
  setErrorMessage,
  showError,
  setShowError,
  canGoBack,
  isSinglePage,
}) => {
  const { t } = useLocale()
  const [otherOption, setOtherOption] = useState('')

  let descriptionElement = <></>
  if (question.attachment) {
    descriptionElement = <Image src={question.attachment} maxW={'lg'} />
  } else if (question.description) {
    descriptionElement = (
      <Text py={2} fontSize={'lg'} color={'gray.600'} align={'center'}>
        {question.description}
      </Text>
    )
  }

  const proceed = () => {
    if (otherOption.length > 0) {
      setOtherOption('')
    }
    onProceed()
  }

  return (
    <Flex
      flexDir={'column'}
      align={'center'}
      h={isSinglePage ? 'auto' : '100vh'}
    >
      <Box maxW={'2xl'}>
        <VStack my={isSinglePage ? 3 : 6} mx={isSinglePage ? 3 : 12}>
          <Heading fontSize={isSinglePage ? 'xl' : '2xl'} textAlign={'center'}>
            {question.title}
            {question.required && (
              <Text as="span" color={'red.700'}>
                *
              </Text>
            )}
          </Heading>
          {descriptionElement}
        </VStack>
        <Center>
          <Flex
            maxW={'lg'}
            flexDir={'column'}
            bg={useColorModeValue('gray.50', 'gray.800')}
          >
            <Stack spacing={4} mx={'auto'} maxW={'lg'} px={6}>
              <SurveyQuestion
                key={question._id}
                itemSelect={itemSelect}
                question={question}
                setErrorMessage={setErrorMessage}
                setShowError={setShowError}
                otherOption={otherOption}
                setOtherOption={setOtherOption}
                isSinglePage={isSinglePage}
              />
            </Stack>
            {isSinglePage && errorMessage && showError && (
              <Text color={'red.600'}>{errorMessage}</Text>
            )}
            {!isSinglePage && (
              <Stack spacing={10} pt={10} pb={6} align={'center'}>
                {errorMessage && showError ? (
                  <Text color={'red.600'}>{errorMessage}</Text>
                ) : null}
                <Wrap>
                  {canGoBack && (
                    <IconButton
                      aria-label="Back"
                      variant="outline"
                      colorScheme="primary"
                      onClick={() => onProceed(-1)}
                      icon={<HiArrowLeft />}
                    />
                  )}
                  <Button
                    colorScheme="primary"
                    onClick={proceed}
                    minW={'sm'}
                    type="submit"
                  >
                    {t('next')}
                  </Button>
                </Wrap>
              </Stack>
            )}
          </Flex>
        </Center>
      </Box>
    </Flex>
  )
}
