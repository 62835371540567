import { Button } from '@chakra-ui/react'
import { BiPhoneCall } from 'react-icons/bi'

import { useLocale } from '../hooks/useLocale'

const BookButton = ({ event, onClickBook, onClickReschedule }: any) => {
  const { t } = useLocale()

  return (
    <Button
      leftIcon={<BiPhoneCall />}
      onClick={event ? onClickReschedule : onClickBook}
      flex={1}
      fontSize={'md'}
      fontWeight={400}
      bg={'primary.500'}
      color={'white'}
      border="1px"
      _hover={{
        bg: 'primary.300',
        color: 'primary.500',
        border: '1px',
      }}
      _active={{
        bg: 'primary.400',
        color: 'white',
      }}
      _focus={{
        bg: 'primary.400',
      }}
    >
      {event ? t('reschedule') : t('make_appointment')}
    </Button>
  )
}

export default BookButton
