import { DyteSimpleGrid } from '@dytesdk/react-ui-kit'
import { useDyteSelector } from '@dytesdk/react-web-core'

import { VStack } from '@chakra-ui/react'
import clsx from 'clsx'
import { useLocale } from '../../../../hooks/useLocale'

export const Grid = ({ meeting, displayScreenShareView }: any) => {
  const participants = useDyteSelector((m) => m.participants.active)

  const displayedParticipants: any = displayScreenShareView
    ? [...participants.toArray(), meeting.self]
    : participants.toArray()

  const { t } = useLocale()

  return (
    <div
      className={clsx(
        'relative flex place-items-center justify-center flex-1 overflow-hidden',
        displayScreenShareView ? '' : '-m-2'
      )}
    >
      <VStack>
        {participants.size === 0 && !displayScreenShareView && (
          <p className="text-2xl">{t('no_participants_in_call')}</p>
        )}
      </VStack>
      {displayedParticipants.length > 0 && (
        <DyteSimpleGrid
          participants={displayedParticipants}
          gap={8}
          meeting={meeting}
        />
      )}
    </div>
  )
}
