import { Input } from '@chakra-ui/react'
import { useId } from 'react'
import { forwardRef, ReactElement, ReactNode, Ref } from 'react'
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form'

export function Label(props: JSX.IntrinsicElements['label']) {
  return <label {...props}>{props.children}</label>
}

export function InputLeading(props: JSX.IntrinsicElements['div']) {
  return <span>{props.children}</span>
}

type InputFieldProps = {
  label?: ReactNode
  addOnLeading?: ReactNode
} & React.ComponentProps<typeof Input> & {
    labelProps?: React.ComponentProps<typeof Label>
  }

export const TextField = forwardRef<HTMLInputElement, InputFieldProps>(
  function TextField(props, ref) {
    return <Input ref={ref} {...props} />
  }
)

export const PasswordField = forwardRef<HTMLInputElement, InputFieldProps>(
  function PasswordField(props, ref) {
    return (
      <Input type="password" placeholder="•••••••••••••" ref={ref} {...props} />
    )
  }
)

export const EmailInput = forwardRef<HTMLInputElement, InputFieldProps>(
  function EmailInput(props, ref) {
    return (
      <Input
        ref={ref}
        type="email"
        autoCapitalize="none"
        autoComplete="email"
        autoCorrect="off"
        inputMode="email"
        {...props}
      />
    )
  }
)

export const EmailField = forwardRef<HTMLInputElement, InputFieldProps>(
  function EmailField(props, ref) {
    return (
      <Input
        ref={ref}
        type="email"
        autoCapitalize="none"
        autoComplete="email"
        autoCorrect="off"
        inputMode="email"
        {...props}
      />
    )
  }
)

type TextAreaProps = Omit<JSX.IntrinsicElements['textarea'], 'name'> & {
  name: string
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function TextAreaInput(props, ref) {
    return <textarea ref={ref} {...props} />
  }
)

type TextAreaFieldProps = {
  label?: ReactNode
} & React.ComponentProps<typeof TextArea> & {
    labelProps?: React.ComponentProps<typeof Label>
  }

export const TextAreaField = forwardRef<
  HTMLTextAreaElement,
  TextAreaFieldProps
>(function TextField(props, ref) {
  const id = useId()
  const {
    label = props.name as string,
    labelProps,
    placeholder = '',
    ...passThrough
  } = props
  return (
    <div>
      {!!props.name && (
        <Label htmlFor={id} {...labelProps}>
          {label}
        </Label>
      )}
      <TextArea ref={ref} placeholder={placeholder} {...passThrough} />
      {/* {methods?.formState?.errors[props.name] && (
        <Alert
          severity="error"
          message={methods.formState.errors[props.name].message}
        />
      )} */}
    </div>
  )
})

type FormProps<T extends FieldValues> = {
  form: UseFormReturn<T>
  handleSubmit: SubmitHandler<T>
} & Omit<JSX.IntrinsicElements['form'], 'onSubmit'>

const PlainForm = <T extends FieldValues>(
  props: FormProps<T>,
  ref: Ref<HTMLFormElement>
) => {
  const { form, handleSubmit, ...passThrough } = props

  return (
    <FormProvider {...form}>
      <form
        ref={ref}
        onSubmit={(event) => {
          form.handleSubmit(handleSubmit)(event)
          // .catch((err) => {
          //   showToast(`${getErrorFromUnknown(err).message}`, 'error')
          // })
        }}
        {...passThrough}
      >
        {props.children}
      </form>
    </FormProvider>
  )
}

export const Form = forwardRef(PlainForm) as <T extends FieldValues>(
  p: FormProps<T> & { ref?: Ref<HTMLFormElement> }
) => ReactElement

export function FieldsetLegend(props: JSX.IntrinsicElements['legend']) {
  return <legend {...props}>{props.children}</legend>
}

export function InputGroupBox(props: JSX.IntrinsicElements['div']) {
  return <div {...props}>{props.children}</div>
}
