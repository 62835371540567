import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'
import { useRef } from 'react'
import { useLocale } from '../../../hooks/useLocale'

interface Props {
  isOpen: boolean
  type: 'delete' | 'reschedule' | null
  onClose: () => void
  onResume: () => void
}

export const LateModifyAlert: React.FC<Props> = ({
  isOpen,
  onClose,
  type,
  onResume,
}) => {
  const cancelRef = useRef(null)
  const { t } = useLocale()
  const isDeleting = type === 'delete'

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t('warning')}
          </AlertDialogHeader>

          <AlertDialogBody>
            {t(
              isDeleting
                ? 'delete_booking_confirmation_late_cancellation'
                : 'reschedule_booking_confirmation_late_cancellation'
            )}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              {t('cancel')}
            </Button>
            <Button colorScheme="red" onClick={onResume} ml={3}>
              {t('proceed')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
