import { Box, Flex } from '@chakra-ui/react'

interface Props {
  currentPage: number
  totalPages: number
}

const ProgressComponent = (props: Props) => {
  const progress = Math.round((props.currentPage / props.totalPages) * 100)
  const remaining = 100 - progress
  const leftWidth = progress + '%'
  const rightWidth = remaining + '%'
  return (
    <Flex
      width={'full'}
      alignItems="center"
      height={3}
      borderRadius={10}
      overflow="hidden"
      mr={3}
    >
      <Box
        bgColor={
          props.currentPage >= props.totalPages ? 'green.500' : 'primary.500'
        }
        height={3}
        width={leftWidth}
      ></Box>
      <Box height={3} bgColor={'primary.100'} width={rightWidth}></Box>
    </Flex>
  )
}

export default ProgressComponent
