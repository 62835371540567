import { useTranslation } from 'react-i18next'
import { Link, NavLink, Outlet, useLoaderData } from 'react-router-dom'

import type { Response } from './hupo-perspectives/hupo-perspectives.loader'
import { cn } from '~/utils/cn'

const tabs = [
  { name: 'peer_response_management', href: './respondent-management' },
  { name: 'results', href: './results' },
]

export const HupoPerspectives: React.FC = () => {
  const { t } = useTranslation()
  const data = useLoaderData() as Response

  if (data.scores && Object.keys(data.scores).length === 0) {
    return (
      <div>
        <h2>{t('complete_hupo_perspectives_before_viewing_insights')}</h2>

        <Link to="/assessment">{t('complete_hupo_perspectives')}</Link>
      </div>
    )
  }

  return (
    <>
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            // defaultValue={tabs.find((tab) => tab.current)?.name}
            className="block w-full rounded-md border-gray-300 focus:border-primary-400 focus:ring-primary-400"
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav aria-label="Tabs" className="-mb-px flex">
              {tabs.map((tab) => (
                <NavLink
                  key={tab.name}
                  to={tab.href}
                  className={({ isActive }) =>
                    cn(
                      'w-full border-b-2 px-1 py-4 text-center text-sm font-medium',
                      isActive
                        ? 'border-primary-400 text-primary'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                    )
                  }
                >
                  {t(tab.name)}
                </NavLink>
              ))}
            </nav>
          </div>
        </div>
      </div>

      <div className="mt-5 flex flex-col gap-5">
        <Outlet context={data} />
      </div>
    </>
  )
}
