import { useMemo } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFeatureFlagEnabled } from 'posthog-js/react'

import type { Response } from './hupo-perspectives.loader'
import { DocumentIcon } from '@heroicons/react/24/outline'

export const HupoPerspectivesResults: React.FC = () => {
  const data = useOutletContext() as Response
  const showHpReport = useFeatureFlagEnabled('show_hp_report')
  const showLiveResults = useFeatureFlagEnabled('show_hp_results')
  const { t } = useTranslation()

  const canShowHpReport = useMemo(
    () => (showHpReport ? data?.reportUrl : false),
    [showHpReport, data]
  )
  const canShowLiveResults = useMemo(
    () => (showLiveResults ? data.canShowLiveResults : false),
    [showLiveResults, data]
  )

  if (!canShowLiveResults && !canShowHpReport) {
    return (
      <div>
        <p className="text-center">
          {data.resultsPendingLabel ?? t('report_pending_on_hr')}
        </p>
      </div>
    )
  }

  if (canShowHpReport) {
    return (
      <button
        type="button"
        className="inline-flex items-center gap-x-1.5 rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
        aria-label="View detailed report"
        onClick={() => window.open(data.reportUrl, '_blank')}
        disabled={!data.reportUrl}
      >
        <DocumentIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
        {t('view_detailed_report')}
      </button>
    )
  }

  return <>WIP</>
}
