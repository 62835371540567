import { createContext, useContext, useMemo } from 'react'
import mixpanel, { OverridedMixpanel } from 'mixpanel-browser'

const MixpanelContext = createContext<OverridedMixpanel>(mixpanel)

mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
  debug: import.meta.env.DEV,
})

export const useMixpanel = () => {
  const mixpanel = useContext(MixpanelContext)
  return useMemo(() => {
    return mixpanel
  }, [mixpanel])
}

const MixpanelProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <MixpanelContext.Provider value={mixpanel}>
      {children}
    </MixpanelContext.Provider>
  )
}

export default MixpanelProvider
