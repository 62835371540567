type Params = {
  eventId: string
  isCoach: boolean
  referrer?: string
  skipInApp?: boolean
}

export const getUnifiedCallLink = (param: Params) => {
  const { eventId, isCoach, referrer, skipInApp } = param
  const baseUrl = `${import.meta.env.VITE_API_ENDPOINT}event/join/${eventId}`
  const link = new URL(baseUrl)
  link.searchParams.append('coach', isCoach ? '1' : '0')
  if (skipInApp) {
    link.searchParams.append('skipinapp', '1')
  }
  if (referrer) {
    link.searchParams.append('ref', referrer)
  }
  return link.toString()
}
