/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Box,
  Center,
  Divider,
  Flex,
  Grid,
  Heading,
  Image,
  ListItem,
  OrderedList,
  Stack,
  Tag,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { Fragment, useMemo } from 'react'
import Lottie from 'react-lottie'
import { useLocation, useParams } from 'react-router-dom'

import { useGetLeadershipValueScoresForReportQuery } from '../../app/services/api'
import noDataAnimation from '../../assets/lottie/no-data.json'
import { useHideChatlio } from '../../hooks/useHideChatlio'
import { useLocale } from '../../hooks/useLocale'
import { Logo } from '../../utils/Logo'
import { LeadershipValueChart } from '../hupoperspectives/LeadershipValueChart'

const CHARACTER_LIMIT_PAGE = 1350
const CHARACTER_LIMIT_PAGE_SOME = 1400

const CHARACTER_LIMIT_PER_CARD = 200
const LINE_HEIGHT = 22
const PAGE_HEIGHT = 210 * 3.78
const PAGE_PADDING = 50 * 3.78 * 2
const CARD_PADDING = 22
const CARD_MARGIN = 16

const calculateCardHeight = (comment: string) => {
  const lines = Math.ceil(comment.length / CHARACTER_LIMIT_PER_CARD)
  return lines * LINE_HEIGHT + CARD_PADDING * 2 + CARD_MARGIN
}

const splitComments = (scoreComments: {
  [key: string]: { [key: string]: { [key: string]: string[] } }
}): { [key: string]: { [key: string]: { [key: string]: string[] } } }[] => {
  const result: {
    [key: string]: { [key: string]: { [key: string]: string[] } }
  }[] = []
  let currentPage: {
    [key: string]: { [key: string]: { [key: string]: string[] } }
  } = {}
  let currentPageHeight = 0

  const addCommentToPage = (
    focusArea: string,
    respondentType: string,
    rating: string,
    comment: string
  ) => {
    const cardHeight = calculateCardHeight(comment)

    if (currentPageHeight + cardHeight > PAGE_HEIGHT - PAGE_PADDING) {
      result.push(currentPage)
      currentPage = {}
      currentPageHeight = 0
    }

    if (!currentPage[focusArea]) {
      currentPage[focusArea] = {}
    }
    if (!currentPage[focusArea][respondentType]) {
      currentPage[focusArea][respondentType] = {}
    }
    if (!currentPage[focusArea][respondentType][rating]) {
      currentPage[focusArea][respondentType][rating] = []
    }

    currentPage[focusArea][respondentType][rating].push(comment)
    currentPageHeight += cardHeight
  }

  scoreComments &&
    Object.entries(scoreComments).forEach(([focusArea, respondentTypes]) => {
      Object.entries(respondentTypes).forEach(([respondentType, ratings]) => {
        Object.entries(ratings).forEach(([rating, commentList]) => {
          commentList.forEach((comment) => {
            addCommentToPage(focusArea, respondentType, rating, comment)
          })
        })
      })
    })

  if (Object.keys(currentPage).length > 0) {
    result.push(currentPage)
  }

  return result
}

const splitContent = (
  content: { [key: string]: string[] },
  characterLimit: number
): { [key: string]: string[] }[] => {
  const result: { [key: string]: string[] }[] = []
  let currentPage: { [key: string]: string[] } = {}
  let currentPageCharacterCount = 0

  Object.entries(content).forEach(([key, items]) => {
    items.forEach((item) => {
      if (currentPageCharacterCount + item.length > characterLimit) {
        result.push(currentPage)
        currentPage = {}
        currentPageCharacterCount = 0
      }
      if (!currentPage[key]) {
        currentPage[key] = []
      }
      currentPage[key].push(item)
      currentPageCharacterCount += item.length
    })
  })

  if (Object.keys(currentPage).length > 0) {
    result.push(currentPage)
  }

  return result
}

const splitContentPage2 = (
  content: { question: string; responses: string[] }[],
  characterLimit: number
): { question: string; responses: string[] }[][] => {
  const result: { question: string; responses: string[] }[][] = []
  let currentPage: { question: string; responses: string[] }[] = []
  let currentPageCharacterCount = 0

  content.forEach(({ question, responses }) => {
    const questionLength = question.length
    const responsesLength = responses.reduce(
      (total, response) => total + response.length,
      0
    )

    if (
      currentPageCharacterCount + questionLength + responsesLength >
      characterLimit
    ) {
      result.push(currentPage)
      currentPage = []
      currentPageCharacterCount = 0
    }

    currentPage.push({ question, responses })
    currentPageCharacterCount += questionLength + responsesLength
  })

  if (currentPage.length > 0) {
    result.push(currentPage)
  }

  return result
}

const PageContainer = ({ children, company, currentPage, totalPages }: any) => (
  <Box
    sx={{
      width: '210mm',
      height: '280mm',
      pageBreakAfter: 'always',
      padding: '10mm',
      boxSizing: 'border-box',
      fontFamily: 'Poppins, sans-serif',
      position: 'relative',
    }}
  >
    <Flex justify="space-between" align="center" mb={10}>
      <Image
        src={company.logo}
        alt={`${company.name} logo`}
        height={company.logoHeight || '40px'}
      />
      <Logo height={'40px'} width="auto" />
    </Flex>
    {children}
    {totalPages > 1 && (
      <Flex
        position="absolute"
        bottom="10mm"
        left="50%"
        transform="translateX(-50%)"
        align="center"
        fontSize="sm"
        color="gray.500"
      >
        <Text>
          Page {currentPage} of {totalPages}
        </Text>
      </Flex>
    )}
  </Box>
)

const AssessmentReportPDF = () => {
  const { userId } = useParams<{ userId: string }>()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const isComplete = searchParams.get('complete') !== 'false'
  const { t } = useLocale()
  const {
    data: leadershipValueScoresReport,
    isLoading,
    error,
  } = useGetLeadershipValueScoresForReportQuery(userId)

  useHideChatlio()

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error.toString()}</div>
  }

  const {
    user,
    company,
    coach,
    scores,
    scoreComments,
    answers,
    valuesToFocus,
    recommendations,
    values,
    groupedValues,
    respondentStats,
    selfAnswers,
    date,
    aiSuggestions,
  } = leadershipValueScoresReport

  if (!user || !company || !coach || !scores) {
    return <div>No data available.</div>
  }

  const scoreCommentsDivided: any = {}
  Object.keys(groupedValues).forEach((groupKey) => {
    scoreCommentsDivided[groupKey] = Object.entries(scoreComments).reduce(
      (acc, [focusArea, respondentTypes]: any) => {
        if (
          groupedValues[groupKey] &&
          groupedValues[groupKey].includes(focusArea)
        ) {
          acc[focusArea] = respondentTypes
        }
        return acc
      },
      {} as { [key: string]: { [key: string]: { [key: string]: string[] } } }
    )
  })

  const totalPages =
    1 + // Page1
    splitContentPage2(selfAnswers || [], CHARACTER_LIMIT_PAGE_SOME).length + // Page2
    Object.keys(groupedValues).length + // Page3 for each group value
    Object.values(scoreCommentsDivided).reduce(
      (total: number, comments: any) => total + splitComments(comments).length,
      0
    ) + // Page3Comments for each group value
    splitContent(recommendations, CHARACTER_LIMIT_PAGE_SOME).length + // Page6
    splitContent(
      answers.find(
        (answer: any) => answer.questionID === 'what-are-username-strengths'
      )?.responses || {},
      CHARACTER_LIMIT_PAGE
    ).length + // Page7
    splitContent(
      answers.find(
        (answer: any) =>
          answer.questionID === 'what-username-to-develop-further'
      )?.responses || {},
      CHARACTER_LIMIT_PAGE
    ).length + // Page8
    (aiSuggestions ? 1 : 0) // Page9

  return (
    <VStack bg="#EAEDEF">
      <PageContainer company={company} currentPage={1} totalPages={totalPages}>
        <Page1 user={user} coach={coach} date={date} isComplete={isComplete} />
      </PageContainer>
      <Fragment>
        {splitContentPage2(selfAnswers || [], CHARACTER_LIMIT_PAGE).map(
          (page, pageIndex) => (
            <PageContainer
              key={pageIndex}
              company={company}
              currentPage={2 + pageIndex}
              totalPages={totalPages}
            >
              <Page2
                t={t}
                user={user}
                selfAnswers={page}
                values={values}
                respondentStats={respondentStats}
                company={company}
                groupedValues={groupedValues}
              />
            </PageContainer>
          )
        )}
      </Fragment>
      {Object.entries(groupedValues).map(([group, groupValues], index) => {
        const previousPages = Object.entries(groupedValues)
          .slice(0, index)
          .reduce(
            (total, [prevGroup]) =>
              total + 1 + splitComments(scoreCommentsDivided[prevGroup]).length,
            0
          )

        const page3CurrentPage =
          2 +
          splitContentPage2(selfAnswers || [], CHARACTER_LIMIT_PAGE_SOME)
            .length +
          previousPages

        const page3CommentsPages = splitComments(scoreCommentsDivided[group])

        return (
          <Fragment key={group}>
            <PageContainer
              company={company}
              currentPage={page3CurrentPage}
              totalPages={totalPages}
            >
              <Page3
                user={user}
                scores={scores}
                values={values}
                groupedValues={groupValues as any[]}
                group={group}
              />
            </PageContainer>
            {scoreComments &&
              Object.keys(scoreComments).length > 0 &&
              page3CommentsPages.map((page, pageIndex) => (
                <PageContainer
                  key={pageIndex}
                  company={company}
                  currentPage={page3CurrentPage + 1 + pageIndex}
                  totalPages={totalPages}
                >
                  <Page3Comments
                    values={values}
                    scoreComments={page}
                    group={group}
                  />
                </PageContainer>
              ))}
          </Fragment>
        )
      })}
      <Fragment>
        {splitContent(recommendations, CHARACTER_LIMIT_PAGE_SOME).map(
          (page, pageIndex) => {
            const previousPages =
              2 +
              splitContentPage2(selfAnswers || [], CHARACTER_LIMIT_PAGE_SOME)
                .length +
              Object.entries(groupedValues).reduce(
                (total, [group, _groupValues]) =>
                  total + 1 + splitComments(scoreCommentsDivided[group]).length,
                0
              )

            return (
              <PageContainer
                key={pageIndex}
                company={company}
                currentPage={previousPages + pageIndex}
                totalPages={totalPages}
              >
                <Page6
                  user={user}
                  valuesToFocus={valuesToFocus}
                  recommendations={page}
                  company={company}
                />
              </PageContainer>
            )
          }
        )}
      </Fragment>

      <Fragment>
        {splitContent(
          answers.find(
            (answer: any) => answer.questionID === 'what-are-username-strengths'
          )?.responses || {},
          CHARACTER_LIMIT_PAGE
        ).map((page, pageIndex) => {
          const previousPages =
            2 +
            splitContentPage2(selfAnswers || [], CHARACTER_LIMIT_PAGE_SOME)
              .length +
            Object.entries(groupedValues).reduce(
              (total, [group, _groupValues]) =>
                total + 1 + splitComments(scoreCommentsDivided[group]).length,
              0
            ) +
            splitContent(recommendations, CHARACTER_LIMIT_PAGE_SOME).length

          return (
            <PageContainer
              key={pageIndex}
              company={company}
              currentPage={previousPages + pageIndex}
              totalPages={totalPages}
            >
              <Page7 user={user} answers={page} company={company} />
            </PageContainer>
          )
        })}
      </Fragment>

      <Fragment>
        {splitContent(
          answers.find(
            (answer: any) =>
              answer.questionID === 'what-username-to-develop-further'
          )?.responses || {},
          CHARACTER_LIMIT_PAGE
        ).map((page, pageIndex) => {
          const previousPages =
            2 +
            splitContentPage2(selfAnswers || [], CHARACTER_LIMIT_PAGE_SOME)
              .length +
            Object.entries(groupedValues).reduce(
              (total, [group, _groupValues]) =>
                total + 1 + splitComments(scoreCommentsDivided[group]).length,
              0
            ) +
            splitContent(recommendations, CHARACTER_LIMIT_PAGE_SOME).length +
            splitContent(
              answers.find(
                (answer: any) =>
                  answer.questionID === 'what-are-username-strengths'
              )?.responses || {},
              CHARACTER_LIMIT_PAGE
            ).length

          return (
            <PageContainer
              key={pageIndex}
              company={company}
              currentPage={previousPages + pageIndex}
              totalPages={totalPages}
            >
              <Page8 user={user} answers={page} company={company} />
            </PageContainer>
          )
        })}
      </Fragment>

      {aiSuggestions && (
        <PageContainer
          company={company}
          currentPage={totalPages}
          totalPages={totalPages}
        >
          <Page9 user={user} coach={coach} aiSuggestions={aiSuggestions} />
        </PageContainer>
      )}
    </VStack>
  )
}

const Page1 = ({ user, date, isComplete }: any) => {
  const formattedDate = new Date(date).toLocaleString('default', {
    month: 'long',
    year: 'numeric',
  })

  return (
    <Flex position="relative">
      {!isComplete && (
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%) rotate(45deg)"
          opacity={0.2}
          fontSize="128px"
          fontWeight="bold"
          color="grey.300"
          textAlign="center"
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          DRAFT
        </Box>
      )}
      <Box flex={1} mr={[5, 8]}>
        <Heading size="md" mb={4}>
          {formattedDate}
        </Heading>
        <Heading size="lg" color="#FF4B0A" mb={2}>
          {user.name}
        </Heading>
        <Text mb={2} fontWeight="bold">
          Role: {user.role}
        </Text>
        <Text mb={4} fontSize="lg" fontWeight="bold">
          Your Hupo Perspectives 360 Report
        </Text>

        <Text mb={4}>
          This report contains the collated feedback from several of your
          colleagues as well as your own reflections. It's intended to support
          your personal growth and ongoing development as a leader.
        </Text>

        <Text mb={4}>
          When reviewing the content, please remember that you have asked your
          colleagues for their honest, frank feedback about how they perceive
          your behaviour, in relation to the Leadership DNA and Values set forth
          and championed by your organisation. Internalize the feedback, holding
          both positive and negative comments in balance.
        </Text>

        <Text>
          Pay attention to the broader themes in the feedback, as well as
          general trends, and even discrepancies. Different perceptions are
          normal, since different stakeholders see you in different contexts.
          Ask yourself: "why might different individuals perceive me
          differently?" Let's dive in.
        </Text>
      </Box>

      <Box flex={1}>
        <Image
          src="https://d11dx3d8ky7l52.cloudfront.net/images/report_main.webp"
          alt="Group meeting"
          mb={8}
        />
      </Box>
    </Flex>
  )
}

const Page2 = ({
  t,
  user,
  selfAnswers,
  values,
  respondentStats,
  company,
  groupedValues,
}: any) => (
  <Grid templateColumns="40% 60%" gap={3}>
    <Box maxW={'60mm'}>
      <ReportHeading user={user} isSelf={true} />

      <Heading size="sm" mt={4} mb={2}>
        Leadership Values
      </Heading>
      <Text mb={4} sx={{ fontStyle: 'italic' }}>
        Based on the set of Leadership Values defined by the {company.name}, and
        co-designed with Hupo.
      </Text>

      <Box mb={4}>
        {Object.entries(groupedValues).map(
          ([group, values_]: any, groupIndex: any) => (
            <Box key={groupIndex} mb={4}>
              {group && group !== 'undefined' && (
                <Heading size="sm" mb={2}>
                  {t(group)}
                </Heading>
              )}
              <UnorderedList spacing={2} fontSize="sm">
                {values_.map((_value: any, index: any) => (
                  <ListItem key={index}>{values[values_[index]]}</ListItem>
                ))}
              </UnorderedList>
            </Box>
          )
        )}
      </Box>

      <Box
        borderWidth={1}
        borderColor="primary.500"
        p={2}
        borderStyle="dotted"
        borderRadius={5}
        mt={0}
      >
        <Text fontWeight="bold" mb={2}>
          Reviewed by:
        </Text>
        <UnorderedList>
          <ListItem>
            Manager {respondentStats.managers.reviewed}/
            {respondentStats.managers.total}
          </ListItem>
          <ListItem>
            Peers {respondentStats.peers.reviewed}/{respondentStats.peers.total}
          </ListItem>
          <ListItem>
            Direct Reports {respondentStats.reportees.reviewed}/
            {respondentStats.reportees.total}
          </ListItem>
        </UnorderedList>
      </Box>
    </Box>
    <Box>
      {selfAnswers.map(({ question, responses }: any, index: any) => (
        <Box key={index} mb={8}>
          <Heading size="sm" mb={4}>
            {question}
          </Heading>
          <Text fontSize="sm">{responses[0]}</Text>
        </Box>
      ))}
      {selfAnswers.length === 0 && (
        <Center>
          <Stack>
            <Heading size="md" mb={4} color="gray.500" textAlign={'center'}>
              {t('no_self_answers')}
            </Heading>
            <Lottie
              options={{
                animationData: noDataAnimation,
                autoplay: true,
                loop: true,
              }}
              height={300}
            />
          </Stack>
        </Center>
      )}
    </Box>
  </Grid>
)

interface Page3Props {
  user: any
  scores: {
    [key: string]: {
      [key: string]: number
    }
  }
  values: {
    [key: string]: string
  }
  groupedValues: any[]
  group: string
}

const Page3 = ({ user, scores, values, groupedValues, group }: Page3Props) => {
  const { t } = useLocale()

  const data = useMemo(() => {
    const filteredScores = Object.entries(scores).reduce(
      (acc, [key, value]) => {
        acc[key] = Object.entries(value).reduce(
          (scoreAcc, [scoreKey, scoreValue]) => {
            if (groupedValues.includes(scoreKey)) {
              scoreAcc[scoreKey] = scoreValue
            }
            return scoreAcc
          },
          {} as { [key: string]: number }
        )
        return acc
      },
      {} as { [key: string]: { [key: string]: number } }
    )

    const filteredValues = Object.entries(values).reduce(
      (acc, [key, value]) => {
        if (groupedValues.includes(key)) {
          acc[key] = value
        }
        return acc
      },
      {} as { [key: string]: string }
    )

    return {
      scores: filteredScores,
      values: filteredValues,
    }
  }, [scores, values, groupedValues])

  return (
    <Box>
      <Grid templateColumns="35% 65%" gap={3}>
        <Box maxWidth={'60mm'}>
          <ReportHeading user={user} mb={10} />
          <Box mb={8} fontSize="sm">
            <Text mb={2} fontWeight="bold">
              Legend:
            </Text>
            <OrderedList spacing={2}>
              <ListItem>N.A. (No opportunity)</ListItem>
              <ListItem>Rarely seen</ListItem>
              <ListItem>Seen a few times</ListItem>
              <ListItem>Seen numerous times</ListItem>
              <ListItem>Consistently demonstrates</ListItem>
              <ListItem>Admired as a role model</ListItem>
            </OrderedList>
          </Box>
        </Box>
        <Box>
          {group && group !== 'undefined' && (
            <Heading size="md" mb={8}>
              Values of {t(group)}
            </Heading>
          )}
          <LeadershipValueChart
            leadershipValueScores={data}
            showTable={false}
            chartWidth={480}
            forPDF={true}
          />
        </Box>
      </Grid>
      <LeadershipValueChart
        leadershipValueScores={data}
        showTable={true}
        forPDF={true}
      />
    </Box>
  )
}

interface Page3CommentsProps {
  values: { [key: string]: string }
  scoreComments: {
    [key: string]: { [key: string]: { [key: string]: string[] } }
  }
  group: string
}

const Page3Comments: React.FC<Page3CommentsProps> = ({
  values,
  scoreComments,
  group,
}) => {
  const { t } = useLocale()

  const respondentColors: any = {
    self: 'blue',
    manager: 'green',
    peer: 'orange',
    reportee: 'purple',
  }
  const respondentNames: any = {
    self: 'SELF',
    manager: 'MANAGER',
    peer: 'PEERS',
    reportee: 'DIRECT REPORTS',
  }

  return (
    <Box mr="3">
      {group && group !== 'undefined' && (
        <Heading size="md" mb={8}>
          Values of {t(group)}
        </Heading>
      )}
      {scoreComments && Object.keys(scoreComments).length > 0 && (
        <Box mt={8}>
          {Object.entries(scoreComments).map(([focusArea, respondentTypes]) => {
            const hasComments = Object.values(respondentTypes).some((ratings) =>
              Object.values(ratings).some(
                (commentList) => commentList.length > 0
              )
            )

            if (!hasComments) {
              return null
            }

            return (
              <Box key={focusArea} mb={4}>
                <Heading size="sm" mb={2}>
                  {values[focusArea]}
                </Heading>
                <Divider mb={4} />
                {Object.entries(respondentTypes).map(
                  ([respondentType, ratings]) => {
                    const hasRatings = Object.values(ratings).some(
                      (commentList) => commentList.length > 0
                    )

                    if (!hasRatings) {
                      return null
                    }

                    return (
                      <Box key={respondentType} mb={6}>
                        {Object.entries(ratings).map(
                          ([rating, commentList]) => {
                            if (commentList.length === 0) {
                              return null
                            }

                            return (
                              <Box key={rating} mb={4} position="relative">
                                <Card
                                  style={{
                                    borderRadius: '10px',
                                    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
                                    borderBottomRightRadius: '0',
                                  }}
                                >
                                  <CardContent>
                                    <Flex
                                      justify="space-between"
                                      align="center"
                                      mb={2}
                                    >
                                      <Flex align="center">
                                        <Typography variant="body2">
                                          Score: {rating} / 6
                                        </Typography>
                                      </Flex>
                                    </Flex>
                                    <UnorderedList spacing={2}>
                                      {commentList.map(
                                        (comment, commentIndex) => (
                                          <ListItem key={commentIndex}>
                                            {comment}
                                          </ListItem>
                                        )
                                      )}
                                    </UnorderedList>
                                  </CardContent>
                                </Card>
                                <Tag
                                  colorScheme={respondentColors[respondentType]}
                                  position="absolute"
                                  bottom="-10px"
                                  right="10px"
                                  borderTopLeftRadius="0"
                                  borderBottomRightRadius="10px"
                                  borderTopRightRadius="10px"
                                  size="lg"
                                >
                                  {respondentNames[respondentType]}
                                </Tag>
                              </Box>
                            )
                          }
                        )}
                      </Box>
                    )
                  }
                )}
              </Box>
            )
          })}
        </Box>
      )}
    </Box>
  )
}

interface Page6Props {
  user: {
    name: string
  }
  valuesToFocus: {
    value: string
    label: string
    count: number
  }[]
  recommendations: {
    [key: string]: string[]
  }
  company: {
    name: string
    logo: string
  }
}

const Page6: React.FC<Page6Props> = ({
  user,
  valuesToFocus,
  recommendations,
}) => (
  <Grid templateColumns="35% 65%" gap={8}>
    {
      <Box>
        <ReportHeading user={user} mb={8} />

        <Heading size="md" mb={4} mt={8}>
          Your scores reflect that these leadership areas have the greatest room
          for growth and improvement
        </Heading>

        <Text mb={4}>
          In brackets are the number of '3-or-lower' ratings received, and on
          the right are additional comments & suggestions:
        </Text>

        {valuesToFocus && valuesToFocus.length > 0 ? (
          <UnorderedList spacing={2} mb={8} sx={{ fontStyle: 'italic' }}>
            {valuesToFocus.map(({ value, label, count }) => (
              <ListItem key={value}>
                {label} (x{count})
              </ListItem>
            ))}
          </UnorderedList>
        ) : (
          <Text>No responses available.</Text>
        )}
      </Box>
    }

    <Box>
      {
        <Text mb={8} fontSize="lg" fontWeight="bold">
          {user.name} can consider some of these perspectives and practical
          recommendations on what and how to improve as a leader.
        </Text>
      }

      {['manager', 'peer', 'reportee'].map((role) => {
        const recommendationList = recommendations[role]
        if (!recommendationList || recommendationList.length === 0) {
          return null
        }
        const formattedRole = {
          manager: 'MANAGER',
          peer: 'PEERS',
          reportee: 'DIRECT REPORTS',
        }[role]
        return (
          <Box key={role} mb={4}>
            <Heading size="sm" mb={2}>
              {formattedRole}
            </Heading>
            <UnorderedList spacing={2}>
              {recommendationList.map((recommendation, index) => (
                <ListItem key={index}>{recommendation}</ListItem>
              ))}
            </UnorderedList>
          </Box>
        )
      })}

      {['manager', 'peer', 'reportee'].every(
        (role) => !recommendations[role] || recommendations[role].length === 0
      ) && (
        <Box textAlign="center" mt={8}>
          <Text fontSize="lg" color="gray.500">
            No responses available.
          </Text>
        </Box>
      )}
    </Box>
  </Grid>
)

interface Page7Props {
  user: {
    name: string
  }
  answers: {
    [key: string]: string[]
  }
  company: {
    name: string
    logo: string
  }
}

const Page7: React.FC<Page7Props> = ({ user, answers }) => (
  <Box mr={3}>
    <Grid templateColumns="50% 50%" gap={8}>
      <Box>
        <ReportHeading user={user} />
      </Box>
      <AdditionalCommentsBox>
        What does {user.name} do really well & should continue to do?
        <br />
        What are {user.name}'s strengths?
      </AdditionalCommentsBox>
    </Grid>
    <Box>
      {['managers', 'peers', 'reportees'].map((role) => {
        const strengthList = answers[role]
        if (!strengthList || strengthList.length === 0) {
          return null
        }
        const formattedRole = {
          managers: 'MANAGER',
          peers: 'PEERS',
          reportees: 'DIRECT REPORTS',
        }[role]
        return (
          <Box key={role} mb={8}>
            <Heading size="sm" mb={2}>
              {formattedRole}
            </Heading>
            <UnorderedList spacing={2}>
              {strengthList.map((strength: any, index: any) => (
                <ListItem key={index}>{strength}</ListItem>
              ))}
            </UnorderedList>
          </Box>
        )
      })}

      {['managers', 'peers', 'reportees'].every(
        (role) => !answers[role] || answers[role].length === 0
      ) && (
        <Box textAlign="center" mt={8}>
          <Text fontSize="lg" color="gray.500">
            No responses available.
          </Text>
        </Box>
      )}
    </Box>
  </Box>
)

interface Page8Props {
  user: {
    name: string
  }
  answers: {
    [key: string]: string[]
  }
  company: {
    name: string
    logo: string
  }
}

const Page8: React.FC<Page8Props> = ({ user, answers }) => (
  <Box mr={3}>
    <Grid templateColumns="50% 50%" gap={8}>
      <Box>
        <ReportHeading user={user} mb={8} />
      </Box>
      <AdditionalCommentsBox>
        What should {user.name} do more of?
        <br />
        What could {user.name} develop further?
      </AdditionalCommentsBox>
    </Grid>
    <Box>
      {['managers', 'peers', 'reportees'].map((role) => {
        const improveList = answers[role]
        if (!improveList || improveList.length === 0) {
          return null
        }
        const formattedRole = {
          managers: 'MANAGER',
          peers: 'PEERS',
          reportees: 'DIRECT REPORTS',
        }[role]
        return (
          <Box key={role} mb={8}>
            <Heading size="sm" mb={2}>
              {formattedRole}
            </Heading>
            <UnorderedList spacing={2}>
              {improveList.map((improve: any, index: any) => (
                <ListItem key={index}>{improve}</ListItem>
              ))}
            </UnorderedList>
          </Box>
        )
      })}

      {['managers', 'peers', 'reportees'].every(
        (role) => !answers[role] || answers[role].length === 0
      ) && (
        <Box textAlign="center" mt={8}>
          <Text fontSize="lg" color="gray.500">
            No responses available.
          </Text>
        </Box>
      )}
    </Box>
  </Box>
)

const Page9 = ({ aiSuggestions }: any) => {
  if (!aiSuggestions) {
    return <div>No AI suggestions available.</div>
  }

  return (
    <Box mr="3">
      {aiSuggestions && aiSuggestions.suggestions && (
        <Box>
          <Heading size="md" mb={4}>
            AI-Generated Improvement Suggestions
          </Heading>
          <Text mb={8}>{aiSuggestions.description}</Text>
          <Box>
            {Object.entries(aiSuggestions.suggestions).map(
              ([value, suggestions]: any, index: any) => (
                <Box key={index} mb={8}>
                  <Heading size="sm" mb={2}>
                    {value}
                  </Heading>
                  <UnorderedList spacing={2}>
                    {suggestions.map((suggestion: any, index: any) => (
                      <ListItem key={index}>{suggestion}</ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              )
            )}
          </Box>
        </Box>
      )}
    </Box>
  )
}

const ReportHeading = ({ user, isSelf, ...props }: any) => (
  <Box {...props}>
    <Heading size="md" mb={4}>
      {isSelf ? 'SELF' : 'PEER'} INPUTS -{' '}
      {new Date().toLocaleString('default', { month: 'long', year: 'numeric' })}
    </Heading>

    <Heading size="lg" color="#FF4B0A" mb={2}>
      {user.name}
    </Heading>
    <Text mb={2} fontWeight="bold">
      Role: {user.role}
    </Text>
  </Box>
)

const AdditionalCommentsBox = (props: any) => (
  <Box mb={8} textAlign="right" {...props}>
    <Heading size="md" mb={4}>
      Additional comments
    </Heading>
    <Text mb={4} sx={{ fontStyle: 'italic', fontWeight: 'bold' }}>
      {props.children}
    </Text>
  </Box>
)

export default AssessmentReportPDF
