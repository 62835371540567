import { Textarea, useToast } from '@chakra-ui/react'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, store } from '../../../app/store'
import { getAnswer } from '../../../utils/text'
import OnboardingFooter from '../../onboarding/components/OnboardingFooter'
import OnboardingHeader from '../../onboarding/components/OnboardingHeader'
import OnboardingContainer from '../../onboarding/OnboardingContainer'
import TitleDescription from '../../onboarding/questionTypes/TitleDescription'
import {
  LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_1_QUESTION_ID,
  LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_2_QUESTION_ID,
  LEADERSHIP_VALUES_WHAT_WORKS_WELL_1_QUESTION_ID,
  LEADERSHIP_VALUES_WHAT_WORKS_WELL_2_QUESTION_ID,
  PEER_LEADERSHIP_VALUES_TO_FOCUS,
} from '../AssessmentRouter'
import { itemSelect } from '../assessmentSlice'

const TextAreaQuestion: FC<any> = ({
  progress,
  question,
  answer,
  onSubmit,
}) => {
  const dispatch = useDispatch()
  const toast = useToast()
  const [value, setValue] = useState(getAnswer(answer) ?? '')
  const user: any = useSelector((state: RootState) => state.assessment.user)
  const upToDateAssessmentAnswers = store.getState().assessment.answers
  const values = store.getState().assessment.values

  let questionTitle = question.title.replaceAll('{{username}}', user)
  let leadershipValue: any = null
  if (
    question.friendlyID ===
      LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_1_QUESTION_ID ||
    question.friendlyID === LEADERSHIP_VALUES_WHAT_WORKS_WELL_1_QUESTION_ID
  ) {
    const leadershipValuesToFocus =
      upToDateAssessmentAnswers[PEER_LEADERSHIP_VALUES_TO_FOCUS]
    const orderedValues = values.filter((value: any) =>
      leadershipValuesToFocus.includes(value.value)
    )
    if (leadershipValuesToFocus && leadershipValuesToFocus.length > 0) {
      questionTitle = questionTitle.replaceAll(
        '{{leadershipvalue}}',
        orderedValues[0].label
      )
      leadershipValue = orderedValues[0]
    }
  }
  if (
    question.friendlyID ===
      LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_2_QUESTION_ID ||
    question.friendlyID === LEADERSHIP_VALUES_WHAT_WORKS_WELL_2_QUESTION_ID
  ) {
    const leadershipValuesToFocus =
      upToDateAssessmentAnswers[PEER_LEADERSHIP_VALUES_TO_FOCUS]
    const orderedValues = values.filter((value) =>
      leadershipValuesToFocus.includes(value.value)
    )
    if (leadershipValuesToFocus.length > 1) {
      questionTitle = questionTitle.replaceAll(
        '{{leadershipvalue}}',
        orderedValues[1].label
      )
      leadershipValue = orderedValues[1]
    }
  }
  const questionDescription = question.description?.replaceAll(
    '{{username}}',
    user
  )

  const onLocalSubmit = () => {
    if (value.length > 0) {
      if (leadershipValue) {
        dispatch(
          itemSelect({
            questionId: question.friendlyID,
            answers: [leadershipValue.value],
            otherAnswer: value,
          })
        )
      } else {
        dispatch(
          itemSelect({
            questionId: question.friendlyID,
            answers: value,
          })
        )
      }
      onSubmit()
    } else {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Please enter a value to the input',
        isClosable: true,
      })
    }
  }

  return (
    <>
      <OnboardingHeader
        currentStep={progress.currentStep}
        totalSteps={progress.totalSteps}
        isAssessment
      />
      <OnboardingContainer>
        <TitleDescription
          title={questionTitle}
          description={questionDescription}
        />
        <Textarea
          value={value}
          onChange={(event) => setValue(event.target.value)}
          placeholder={question.placeholder}
          height="200px"
        />
        <OnboardingFooter onSubmit={onLocalSubmit} submitText={'Next'} />
      </OnboardingContainer>
    </>
  )
}

export default TextAreaQuestion
