import { useCallback } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { logout } from '../features/auth/authSlice'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { usePostHog } from 'posthog-js/react'

export const useLogout = () => {
  const { authenticatedWith } = useAppSelector((state) => state.auth)
  const { logout: auth0Logout } = useAuth0()
  const dispatch = useAppDispatch()
  const posthog = usePostHog()

  return useCallback(() => {
    dispatch(logout())
    if (authenticatedWith === 'auth0') {
      auth0Logout()
    }
    posthog.reset(true)
  }, [dispatch, auth0Logout, authenticatedWith])
}
