import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import TimezoneSelect from 'react-timezone-select'

import { usePostUserProfileMutation } from '../../app/services/api'
import { useAuth } from '../../hooks/useAuth'
import { useLocale } from '../../hooks/useLocale'
import { User } from '../../types/api'
import { updateUser } from '../auth/authSlice'
import { useE2Etest } from '~/hooks/useE2Etest'

const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

const UserTimezoneDialog: React.FC = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { user } = useAuth()
  const isE2Etest = useE2Etest()
  const toast = useToast()
  const { t } = useLocale()
  const dispatch = useDispatch()
  const [update, { isLoading }] = usePostUserProfileMutation()
  const [data, setData] = useState({
    name: user?.profile.name,
    language: user?.language,
    timezone: currentTimezone,
  })

  const handleSave = useCallback(async () => {
    try {
      const updatedUser: User = await update(data as any).unwrap()
      toast({
        status: 'success',
        title: t('success'),
        description: t('your_profile_is_updated'),
        isClosable: true,
      })
      if (user) {
        dispatch(updateUser(updatedUser as User))
      }
      onClose()
    } catch (err: any) {
      toast({
        status: 'error',
        title: t('error'),
        description: err?.data?.message || t('there_was_an_error'),
        isClosable: true,
      })
    }
  }, [data, dispatch, t, toast, update, onClose, user])

  useEffect(() => {
    if (user && user.timezone !== currentTimezone) {
      if (isE2Etest) {
        handleSave()
      } else {
        onOpen()
      }
    }
  }, [user, onOpen, handleSave, isE2Etest])

  if (!currentTimezone) {
    return null
  }

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('timezone_detected')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>
            {t('timezone_detected_description', { timezone: currentTimezone })}
          </p>
          <br />
          {currentTimezone && (
            <TimezoneSelect
              isDisabled={isLoading}
              value={currentTimezone}
              onChange={({ value }) => setData({ ...data, timezone: value })}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={handleSave}
            isLoading={isLoading}
            width="100%"
            colorScheme="blue"
          >
            {t('save')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UserTimezoneDialog
