import { Avatar, Box, Link, useDisclosure } from '@chakra-ui/react'
import { useCallback } from 'react'
import { theme } from '../../utils/theme'
import { VideoModal } from '../onboarding/components/VideoModal'
import { useMixpanel } from '../../utils/MixpanelContext'

const CoachAvatar = (props: any) => {
  const { src, size, videoUrl, styles, email } = props
  const mixpanel = useMixpanel()
  const {
    isOpen: isVideoModalOpen,
    onClose: onVideoModalClose,
    onOpen: onVideoModalOpen,
  } = useDisclosure()

  const playVideo = useCallback(() => {
    onVideoModalOpen()
    mixpanel.track('coach_profile_video_play', { coach: email })
  }, [onVideoModalOpen, email, mixpanel])

  const avatarComponent = (
    <>
      <Avatar
        size={size ? size : '2xl'}
        src={src}
        mb={4}
        borderStyle="solid"
        borderWidth={videoUrl ? '3px' : '1px'}
        borderColor={videoUrl ? theme.colors.primary[600] : 'GrayText'}
        pos={'relative'}
        style={styles}
        // _after={{
        //   content: '""',
        //   w: 4,
        //   h: 4,
        //   bg: 'green.300',
        //   border: '2px solid white',
        //   rounded: 'full',
        //   pos: 'absolute',
        //   bottom: 0,
        //   right: 3,
        // }}
      />
    </>
  )

  return (
    <Box pos="relative">
      {videoUrl ? (
        <>
          <Link onClick={playVideo}>{avatarComponent}</Link>
          <VideoModal
            url={videoUrl}
            onClose={onVideoModalClose}
            isOpen={isVideoModalOpen}
          />
        </>
      ) : (
        <>{avatarComponent}</>
      )}
    </Box>
  )
}

export default CoachAvatar
