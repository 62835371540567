import { useEffect, useMemo } from 'react'
import {
  NavLink,
  Outlet,
  useLoaderData,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { cn } from '~/utils/cn'

import type { Response } from './assessment-results/assessment-results.loader'

export const AssessmentResults: React.FC = () => {
  const data = useLoaderData() as Response
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { tab } = useParams<{ tab: keyof Response }>()

  const tabs = useMemo(
    () =>
      Object.keys(data).map((key) => ({
        name: t(key),
        href: `./${key}`,
      })),
    [t, data]
  )

  useEffect(() => {
    if (!tab) {
      return navigate(tabs[0].href)
    }
  }, [navigate, tabs, tab])

  return (
    <>
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            // defaultValue={tabs.find((tab) => tab.current)?.name}
            className="block w-full rounded-md border-gray-300 focus:border-primary-400 focus:ring-primary-400"
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav aria-label="Tabs" className="-mb-px flex">
              {tabs.map((tab) => (
                <NavLink
                  key={tab.name}
                  to={tab.href}
                  className={({ isActive }) =>
                    cn(
                      'w-full border-b-2 px-1 py-4 text-center text-sm font-medium',
                      isActive
                        ? 'border-primary-400 text-primary'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                    )
                  }
                >
                  {tab.name}
                </NavLink>
              ))}
            </nav>
          </div>
        </div>
      </div>

      <div className="mt-5 flex flex-col gap-5">
        <Outlet context={data} />
      </div>
    </>
  )
}
