import { Box, Flex, Text, useToast } from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { useLocale } from '../../../hooks/useLocale'
import { ratingScale } from '../../../utils/ratings'
import OnboardingFooter from '../../onboarding/components/OnboardingFooter'
import OnboardingHeader from '../../onboarding/components/OnboardingHeader'
import OnboardingContainer from '../../onboarding/OnboardingContainer'
import TitleDescription from '../../onboarding/questionTypes/TitleDescription'
import { setRating } from '../assessmentSlice'

const SelectOption: FC<any> = ({ rating, selected, onItemSelected }) => {
  const isItemSelected = selected?.scale === rating.scale
  return (
    <Box
      key={rating.level}
      boxShadow="0 0px 5px rgba(0,0,0,0.15)"
      mb={5}
      borderRadius={20}
      padding={7}
      bg={isItemSelected ? 'var(--chakra-colors-primary-400)' : '#fff'}
      cursor="pointer"
      onClick={() => onItemSelected(rating)}
    >
      <Flex direction={'column'} alignItems="center">
        <Text
          color={isItemSelected ? '#fff' : '#000'}
          textAlign="center"
          fontWeight="600"
          fontSize={'xl'}
          mb={3}
        >
          {rating.level}
        </Text>
        {rating.description.map((descriptionText: any) => (
          <Text
            color={isItemSelected ? '#fff' : '#000'}
            textAlign={'center'}
            fontSize={['sm', 'md']}
            key={descriptionText}
          >
            {' - '} {descriptionText}
          </Text>
        ))}
      </Flex>
    </Box>
  )
}

const RatingQuestion: FC<any> = ({
  progress,
  question,
  onSubmit,
  focusArea,
}) => {
  const [selected, setSelected] = useState(null)
  const dispatch = useDispatch()
  const { t } = useLocale()
  const user = useSelector((state: RootState) => state.assessment.user)
  const toast = useToast()

  const onItemSelected = (rating: any) => {
    setSelected(rating)
    console.log('onItemSelected rating:', rating, ', focusArea:', focusArea)

    dispatch(
      setRating({
        focusArea: focusArea.value,
        scale: rating.scale,
      })
    )
  }

  console.log('Rating, focusArea:', focusArea)

  const onLocalSubmit = () => {
    if (!selected) {
      toast({
        status: 'error',
        title: 'Error',
        description: t('select_option'),
        isClosable: true,
      })
      return
    }
    onSubmit()
  }

  const ratingScaleInstance = ratingScale(t)

  useEffect(() => {
    setSelected(null)
  }, [])

  return (
    <>
      <OnboardingHeader
        currentStep={progress.currentStep}
        totalSteps={progress.totalSteps}
        isAssessment
      />
      <OnboardingContainer>
        <TitleDescription
          title={question.title.replaceAll('{{username}}', user)}
          description={
            <Text textAlign="center" fontSize={'xl'} fontStyle="italic">
              {focusArea.label}
              {'?'}
            </Text>
          }
        />
        <Box my={3}>
          {ratingScaleInstance.map((rating) => (
            <SelectOption
              key={'rating#' + rating.scale}
              rating={rating}
              selected={selected}
              onItemSelected={onItemSelected}
            />
          ))}
        </Box>
        <OnboardingFooter onSubmit={onLocalSubmit} submitText={t('next')} />
      </OnboardingContainer>
    </>
  )
}

export default RatingQuestion
