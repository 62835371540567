import { useToast } from '@chakra-ui/react'
import { Button } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useSavePeerAssessmentAnswerMutation } from '../../../app/services/api'
import {
  PeerAssessmentItem,
  PeerAssessmentQuestion,
  PeerAssessmentUser,
  PeerAssessmentValue,
} from '../../../types/api'
import { hasWords } from '../../../utils/text'
import {
  LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_1_QUESTION_ID,
  LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_2_QUESTION_ID,
  LEADERSHIP_VALUES_WHAT_WORKS_WELL_1_QUESTION_ID,
  LEADERSHIP_VALUES_WHAT_WORKS_WELL_2_QUESTION_ID,
  PEER_LEADERSHIP_VALUES_TO_FOCUS,
  SUGGESTIONS_TO_IMPROVE_LOW_SCORES,
} from '../../assessment/AssessmentRouter'
import {
  QuestionFooter,
  QuestionWrapper,
} from '../question-types/components/question-types.style'
import { InfoQuestion } from '../question-types/InfoQuestion'
import { ListQuestion } from '../question-types/ListQuestion'
import { MultiSelectQuestion } from '../question-types/MultiSelectQuestion'
import { RatingQuestion } from '../question-types/RatingQuestion'
import { TextAreaQuestion } from '../question-types/TextAreaQuestion'
import { ValueScoreQuestion } from '../question-types/ValueScoreQuestion'
import { AssessmentProgress } from './assessment-progress/AssessmentProgress'
import { PeerAssessmentAreaWrapper } from './peer-assessment.style'

type TAnswers = Record<'ratings' | 'answers' | 'toSkip' | 'ratingComments', any>

export interface QuestionProps {
  data: PeerAssessmentQuestion
  user: PeerAssessmentUser
  index: number
  answers: TAnswers
  values?: any[]
  setAnswers: React.Dispatch<React.SetStateAction<TAnswers>>
}

const RenderQuestion = ({
  question,
  user,
  index,
  answers,
  setAnswers,
  name,
  values,
}: {
  question: PeerAssessmentQuestion
  user: PeerAssessmentUser
  index: number
  answers: TAnswers
  setAnswers: React.Dispatch<React.SetStateAction<TAnswers>>
  name: string
  values: PeerAssessmentValue[]
}) => {
  switch (question.type) {
    case 'info':
      return (
        <InfoQuestion
          data={question}
          user={user}
          index={index}
          answers={answers}
          setAnswers={setAnswers}
        />
      )
    case 'list':
      return (
        <ListQuestion
          data={question}
          user={user}
          index={index}
          answers={answers}
          setAnswers={setAnswers}
        />
      )
    case 'textarea':
      return (
        <TextAreaQuestion
          data={question}
          user={user}
          index={index}
          answers={answers}
          values={values}
          setAnswers={setAnswers}
        />
      )
    case 'value-score':
      return (
        <ValueScoreQuestion
          data={question}
          user={user}
          index={index}
          answers={answers}
          setAnswers={setAnswers}
          name={name}
          values={values}
        />
      )
    case 'rating':
      return (
        <RatingQuestion
          data={question}
          user={user}
          index={index}
          answers={answers}
          setAnswers={setAnswers}
        />
      )
    case 'multiselect':
      return (
        <MultiSelectQuestion
          data={question}
          user={user}
          index={index}
          answers={answers}
          setAnswers={setAnswers}
          values={values}
        />
      )
    default:
      return <h1>Unknown question type</h1>
  }
}

const satisfiesWordCount = (text: string, minWordLimit: number) => {
  if (!text) return false
  // calculate word count of text
  const words = text.split(/\s+/)
  return words.length >= minWordLimit
}

export function PeerAssessmentArea({
  data,
  values,
  isMobileMenuOpen,
}: {
  data: PeerAssessmentItem
  values: PeerAssessmentValue[]
  isMobileMenuOpen: boolean
}) {
  const { question, author, user } = useParams<{
    author: string
    user: string
    question: string
  }>()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<TAnswers>({
    answers: {},
    ratings: {},
    toSkip: [],
    ratingComments: {},
  })
  const [savePeerAssessmentAnswer, { isLoading }] =
    useSavePeerAssessmentAnswerMutation()
  const surveyCompleted = data?.status === 'COMPLETED'

  const currentQuestion = question
    ? data?.questions.find((item) => item._id === question)
    : data?.questions[surveyCompleted ? data?.questions.length - 1 : 0]

  const currentQuestionIndex = question
    ? data?.questions.findIndex((item) => item._id === question)
    : surveyCompleted
    ? data?.questions.length - 1
    : 0

  const isLastQuestion = currentQuestionIndex === data!.questions.length - 1
  const nextQuestion = isLastQuestion
    ? undefined
    : data?.questions[currentQuestionIndex + 1]

  const toast = useToast()
  const [, , , index] = currentQuestion?.friendlyID
    ? currentQuestion?.friendlyID.split('-')
    : ([0, 0, 0, 1] as any)
  const currentName = values[index - 1]?.value

  useEffect(() => {
    setAnswers({
      answers: data.answers ?? {},
      ratings: data.ratings ?? {},
      toSkip: [],
      ratingComments: data.ratingComments ?? {},
    })
  }, [user])

  const isValid = () => {
    if (currentQuestion) {
      console.log(
        'answers.answers[currentQuestion.friendlyID]:',
        answers.answers[currentQuestion.friendlyID]
      )
    }
    if (
      !currentQuestion?.optional &&
      currentQuestion?.type === 'textarea' &&
      (!answers.answers[currentQuestion.friendlyID] ||
        !hasWords(answers.answers[currentQuestion.friendlyID]))
    ) {
      toast({
        status: 'error',
        description: t('please_enter_value_field'),
        position: 'top-right',
      })
      return false
    }
    if (
      !currentQuestion?.optional &&
      currentQuestion?.type === 'textarea' &&
      currentQuestion?.minWordLimit &&
      !satisfiesWordCount(
        answers.answers[currentQuestion.friendlyID],
        currentQuestion.minWordLimit
      )
    ) {
      toast({
        status: 'error',
        description: t('please_enter_at_least_words', {
          wordCount: currentQuestion.minWordLimit,
        }),
        position: 'top-right',
      })
      return false
    }
    if (
      !currentQuestion?.optional &&
      currentQuestion?.type === 'value-score' &&
      !answers.ratings[currentName]
    ) {
      toast({
        status: 'error',
        description: t('select_option'),
        position: 'top-right',
      })
      return false
    }
    if (
      !currentQuestion?.optional &&
      currentQuestion?.type === 'rating' &&
      !answers.ratings[currentQuestion.friendlyID]
    ) {
      toast({
        status: 'error',
        description: t('select_option'),
        position: 'top-right',
      })
      return false
    }
    if (
      !currentQuestion?.optional &&
      currentQuestion?.type === 'multiselect' &&
      (answers.answers[currentQuestion.friendlyID]?.length <
        currentQuestion?.minSelectionLimit ||
        answers.answers[currentQuestion.friendlyID]?.length >
          currentQuestion.maxSelectionLimit ||
        !answers.answers[currentQuestion.friendlyID])
    ) {
      toast({
        status: 'error',
        description: t('betweenValues', {
          from: currentQuestion.minSelectionLimit,
          to: currentQuestion.maxSelectionLimit,
        }),
        position: 'top-right',
      })
      return false
    }
    return true
  }

  const checkSpecialCaseForLeadearshipValues = () => {
    if (currentQuestion?.friendlyID === PEER_LEADERSHIP_VALUES_TO_FOCUS) {
      const leadershipScores = answers.ratings
      const upToDateAssessmentAnswers = answers.answers
      const leadershipValuesToFocus =
        upToDateAssessmentAnswers[PEER_LEADERSHIP_VALUES_TO_FOCUS]
      const orderedValues = values.filter((value) =>
        leadershipValuesToFocus.includes(value.value)
      )

      if (orderedValues.length === 1) {
        // skip second value questions
        answers.toSkip.push(
          LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_2_QUESTION_ID
        )
        answers.toSkip.push(LEADERSHIP_VALUES_WHAT_WORKS_WELL_2_QUESTION_ID)
        if (leadershipScores[orderedValues[0].value] === 5) {
          answers.toSkip.push(
            LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_1_QUESTION_ID
          )
        } else {
          answers.toSkip.push(LEADERSHIP_VALUES_WHAT_WORKS_WELL_1_QUESTION_ID)
        }
      } else if (orderedValues.length === 2) {
        if (leadershipScores[orderedValues[0].value] === 5) {
          answers.toSkip.push(
            LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_1_QUESTION_ID
          )
        } else {
          answers.toSkip.push(LEADERSHIP_VALUES_WHAT_WORKS_WELL_1_QUESTION_ID)
        }
        if (leadershipScores[orderedValues[1].value] === 5) {
          answers.toSkip.push(
            LEADERSHIP_VALUES_IMPROVEMENT_TO_FOCUS_2_QUESTION_ID
          )
        } else {
          answers.toSkip.push(LEADERSHIP_VALUES_WHAT_WORKS_WELL_2_QUESTION_ID)
        }
      }
    }
  }

  const getAssessmentAnswer = () => {
    if (
      currentQuestion?.friendlyID &&
      answers.answers[currentQuestion.friendlyID]
    )
      return {
        friendlyID: currentQuestion.friendlyID,
        answer: answers.answers[currentQuestion.friendlyID],
      }
    return
  }

  const getAssessmentRating = () => {
    if (currentName && answers.ratings[currentName]) {
      const rating: any = {}
      if (answers.ratings[currentName]) {
        rating.focusArea = currentName
        rating.score = answers.ratings[currentName]
      }
      if (answers.ratingComments[currentName]) {
        rating.comment = answers.ratingComments[currentName]
      }
      return rating
    }
    return
  }

  const handleNext = useCallback(async () => {
    if (
      data &&
      typeof currentQuestionIndex !== 'undefined' &&
      currentQuestionIndex < data.questions.length - 1
    ) {
      if (!isValid()) {
        return
      }
      // console.clear()
      console.log('answers.answers: ', answers.answers)

      if (
        currentQuestion?.type === 'info' ||
        currentQuestion?.type === 'list'
      ) {
        navigate(
          `/peer-assessment/${author}/${user}/${
            data.questions[currentQuestionIndex + 1]._id
          }`
        )
        return
      }
      if (
        currentQuestion?.type === 'value-score' &&
        nextQuestion?.friendlyID === SUGGESTIONS_TO_IMPROVE_LOW_SCORES
      ) {
        const lowScores = Object.keys(answers.ratings).filter(
          (key) => answers.ratings[key] <= 3
        )
        if (lowScores.length === 0) {
          answers.toSkip.push(SUGGESTIONS_TO_IMPROVE_LOW_SCORES)
        }
      }
      // Special case for leadership values to focus: need to skip some questions
      checkSpecialCaseForLeadearshipValues()

      let nextQuestionIndex = currentQuestionIndex + 1
      while (
        nextQuestionIndex < data.questions.length - 1 &&
        answers.toSkip.includes(data?.questions[nextQuestionIndex].friendlyID)
      ) {
        nextQuestionIndex = nextQuestionIndex + 1
      }

      const backData = {
        userId: data.user._id,
        type: 'peer-assessment',
        surveySessionId: data.surveySessionId,
        authorEmail: atob(author!),
        language: 'en',
        isFinal: currentQuestionIndex === data.questions.length - 2,
        answer: getAssessmentAnswer(),
        rating: getAssessmentRating(),
        questionIndex: nextQuestionIndex,
      }

      try {
        const response = await savePeerAssessmentAnswer(backData).unwrap()
        console.log(response)
        navigate(
          `/peer-assessment/${author}/${user}/${data.questions[nextQuestionIndex]._id}`
        )
      } catch (error: any) {
        console.log(error)
      }
    }
  }, [
    answers,
    author,
    currentQuestion,
    currentQuestionIndex,
    data,
    navigate,
    t,
    toast,
    user,
    savePeerAssessmentAnswer,
    currentName,
    values,
  ])

  const handleBack = useCallback(() => {
    if (
      data &&
      typeof currentQuestionIndex !== 'undefined' &&
      currentQuestionIndex > 0
    ) {
      navigate(
        `/peer-assessment/${author}/${user}/${
          data.questions[currentQuestionIndex - 1]._id
        }`
      )
    }
  }, [author, currentQuestionIndex, data, navigate, user])

  return (
    <PeerAssessmentAreaWrapper>
      {currentQuestion &&
        typeof currentQuestionIndex !== 'undefined' &&
        currentQuestionIndex > -1 && (
          <QuestionWrapper>
            <AssessmentProgress
              percent={
                ((currentQuestionIndex + 1 || 1) / data.questions.length) * 100
              }
              hideBack={currentQuestionIndex === 0 || surveyCompleted}
              onBack={handleBack}
              isMobileMenuOpen={isMobileMenuOpen}
            />
            <RenderQuestion
              question={currentQuestion}
              user={data.user}
              index={currentQuestionIndex}
              answers={answers}
              name={currentName}
              setAnswers={setAnswers}
              values={values}
            />
            {!isLastQuestion && (
              <QuestionFooter>
                <Button
                  disableRipple
                  disabled={isLoading}
                  onClick={handleNext}
                  size="large"
                  variant="contained"
                >
                  {t('next')}
                </Button>
              </QuestionFooter>
            )}
          </QuestionWrapper>
        )}
    </PeerAssessmentAreaWrapper>
  )
}
