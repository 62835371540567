import { useAuth0 } from '@auth0/auth0-react'
import { useMutation } from '@tanstack/react-query'
import { usePostHog } from 'posthog-js/react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { api } from '~/app/api'
import { useAppDispatch, useAppSelector } from '~/app/hooks'
import { saveUserToken } from '~/features/auth/authSlice'
import { useE2Etest } from '~/hooks/useE2Etest'
import {
  cleanUpOnboarding,
  setCompanyId,
  setDepartmentId,
  setLanguage,
  setUserEmail,
} from '~/store/onboarding.slice'

type Value = {
  language: string
  companyId: string | null
  departmentId: string | null
  email: string | null

  lastQuestionIndex: number
  currentQuestionIndex: number
  currentStep: number
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
  setLastQuestionIndex: React.Dispatch<React.SetStateAction<number>>
  setSkipList: React.Dispatch<React.SetStateAction<Set<number>>>
  onNextQuestion: () => void
  onPrevQuestion: () => void
  postOnboardingInfo: (answers: any) => void
  postOnboardingInfoAsync: (answers: any) => Promise<boolean>
}

const defaultValue: Value = {
  language: 'en',
  companyId: null,
  departmentId: null,
  email: null,

  lastQuestionIndex: -1,
  currentQuestionIndex: -1,
  currentStep: -1,
  setCurrentStep: () => {},
  setSkipList: () => {},
  setLastQuestionIndex: () => {},
  onNextQuestion: () => {},
  onPrevQuestion: () => {},
  postOnboardingInfo: () => {},
  postOnboardingInfoAsync: () => Promise.resolve(false),
}

const OnboardingContext = createContext<Value>(defaultValue)

export const useOnboardingContext = () => {
  const context = useContext(OnboardingContext)
  if (!context)
    throw new Error(
      'Onboarding context must be used inside OnboardingContextProvider'
    )

  return context
}

export const OnboardingContextProvider: React.FC<React.PropsWithChildren> = (
  props
) => {
  const posthog = usePostHog()
  const { children } = props
  const {
    language = 'en',
    companyId = null,
    departmentId = null,
    email = null,
  } = useParams<{
    language: string
    companyId: string
    departmentId: string
    email: string
  }>()

  const user = useAppSelector((state) => state.auth.user)
  const dispatch = useAppDispatch()
  const { getAccessTokenSilently } = useAuth0()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [lastQuestionIndex, setLastQuestionIndex] = useState(-1)
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [currentStep, setCurrentStep] = useState(0)
  const [skipList, setSkipList] = useState(new Set<number>())
  const fromAuth0 = useMemo(() => searchParams.get('fromAuth0'), [searchParams])
  const isE2Etest = useE2Etest()

  useEffect(() => {
    if (fromAuth0 === 'true') {
      getAccessTokenSilently().then((token) => {
        dispatch(saveUserToken(token, 'auth0'))
        posthog.capture('auth0_login')
      })
    }
  }, [dispatch, getAccessTokenSilently, fromAuth0])

  useEffect(() => {
    if (language !== 'en') {
      dispatch(setLanguage(language))
    }

    if (companyId) {
      dispatch(setCompanyId(companyId))
    }

    if (departmentId) {
      dispatch(setDepartmentId(departmentId))
    }

    if (email) {
      dispatch(setUserEmail(email))
    }
  }, [dispatch, language, companyId, departmentId, email])

  useEffect(() => {
    const questionIndex = searchParams.get('questionIndex')
    const index = questionIndex ? parseInt(questionIndex) : NaN

    if (!Number.isNaN(index)) {
      setCurrentQuestion(index)
    }
  }, [searchParams])

  useEffect(() => {
    if (currentQuestion < 0 || currentQuestion > lastQuestionIndex) {
      return
    }

    navigate({
      search: `?questionIndex=${currentQuestion}`,
    })
  }, [navigate, currentQuestion, lastQuestionIndex])

  const { mutate: postOnboardingInfo, mutateAsync: postOnboardingInfoAsync } =
    useMutation({
      async mutationFn({ index, answers }: any) {
        const isFinalStep = index === lastQuestionIndex
        await api
          .url('/onboarding/info')
          .post({
            userId: user?._id,
            questionIndex: currentQuestion,
            isFinalStep,
            answers,
            isE2Etest,
          })
          .json<any>()

        return isFinalStep
      },
      onSuccess(wasFinalStep) {
        if (wasFinalStep) {
          dispatch(cleanUpOnboarding())
          navigate('/', { replace: true })
          posthog.capture('onboarding_completed')
        } else {
          posthog.capture('onboarding_step_completed', {
            step: currentQuestion,
          })
        }
      },
    })

  const prev = useCallback(() => {
    setCurrentQuestion((prev) => {
      if (prev <= 0) return prev
      let prevQuestion = prev - 1
      while (skipList.has(prevQuestion) && prevQuestion >= 0) {
        prevQuestion--
      }

      return prevQuestion
    })
  }, [lastQuestionIndex, skipList])

  const next = useCallback(() => {
    setCurrentQuestion((prev) => {
      if (prev >= lastQuestionIndex) return prev
      let nextQuestion = prev + 1
      while (skipList.has(nextQuestion) && nextQuestion <= lastQuestionIndex) {
        nextQuestion++
      }

      return nextQuestion
    })
  }, [lastQuestionIndex, skipList])

  return (
    <OnboardingContext.Provider
      value={{
        language,
        companyId,
        departmentId,
        email,

        lastQuestionIndex,
        currentQuestionIndex: currentQuestion,
        currentStep,
        setCurrentStep,
        setSkipList,
        setLastQuestionIndex,
        onPrevQuestion: prev,
        onNextQuestion: next,
        postOnboardingInfo,
        postOnboardingInfoAsync,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  )
}
