import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import parseISO from 'date-fns/parseISO'
import { usePostHog } from 'posthog-js/react'
import {
  PaperAirplaneIcon,
  PaperClipIcon,
  TrashIcon,
} from '@heroicons/react/20/solid'
import { useToast } from '@chakra-ui/react'

import { api } from '~/app/api'
import { ChatBubble } from './chat/_components/chat-bubble'
import { useAppDispatch, useAppSelector } from '~/app/hooks'
import { socket } from '~/hooks/socket'
import { updateSocket } from '~/features/connection/socketSlice'
import { addMessage, setMessages } from '~/features/chat/chatSlice'
import { MAX_FILE_SIZE } from '~/app/constants'
import { playSound } from '~/utils/audio'

interface Response {
  data: Daum[]
}

interface Daum {
  id: string
  message: string
  user: string
  coach: string
  sentByUser: boolean
  dateSent: string
  read: string
  file: DataFile
}

interface DataFile {
  url?: string
  name?: string
  type?: string
  size?: number
}

export const Chat: React.FC = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.auth.user)
  const socketId = useAppSelector((state) => state.socket.socketId)
  const messages = useAppSelector((state) => state.chat.messages)
  const posthog = usePostHog()
  const [isCoachTyping, setIsCoachTyping] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const { t } = useTranslation()
  const toast = useToast()
  const formRef = useRef<HTMLFormElement>(null)
  const endOfChatRef = useRef<HTMLSpanElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const chatRoomId = useMemo(() => `${user?.coach._id}_${user?._id}`, [user])

  const {
    data: { data },
    refetch,
    isSuccess,
  } = useQuery({
    initialData: { data: [] },
    queryKey: ['messages'],
    queryFn() {
      return api
        .url('/chat/messages')
        .query({ userId: user?.coach._id })
        .get()
        .json<Response>()
    },
  })

  const scrollToBottom = useCallback(() => {
    if (!endOfChatRef?.current) return
    endOfChatRef.current.scrollIntoView({ behavior: 'auto' })
  }, [])

  useEffect(() => {
    if (!socket.connected) {
      socket.connect()
    } else if (!socketId && socket.id) {
      dispatch(updateSocket(socket.id))
    }

    const [coachId, userId] = chatRoomId.split('_')

    const newMessageListener = (data: any) => {
      const dateSent = new Date().toISOString()
      const newMessage = {
        id: data.id,
        coach: data.coach,
        user: data.user,
        sentByUser: data.sentByUser,
        message: data.text,
        dateSent: dateSent,
        read: data.read,
        file: {
          url: data.fileUrl,
          name: data.fileName,
          type: data.fileType,
          size: data.fileSize,
        },
      }
      dispatch(addMessage(newMessage))

      playSound()
    }

    const refreshListener = (data: any) => {
      if (data.senderId === coachId) {
        refetch()
      }
    }

    const typingListener = (data: any) => {
      if (data.senderId !== userId) {
        setIsCoachTyping(Boolean(data.typing))
      }
    }

    socket.on('message', newMessageListener)
    socket.on('typing', typingListener)
    socket.on('refresh', refreshListener)
    return () => {
      socket.off('message', newMessageListener)
      socket.off('typing', typingListener)
      socket.off('refresh', refreshListener)
    }
  }, [dispatch, refetch, scrollToBottom, chatRoomId, socketId])

  useEffect(() => {
    if (!socketId) return
    const [_, userId] = chatRoomId.split('_')
    socket.emit('room:join', { socketId, userId, chatRoomId })
  }, [socketId, chatRoomId])

  useEffect(() => {
    if (isSuccess) {
      dispatch(setMessages(data))
    }
  }, [dispatch, data, isSuccess])

  useEffect(() => {
    scrollToBottom()
  }, [messages, scrollToBottom])

  const handleFile = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]
      if (!file) return

      if (file.size > MAX_FILE_SIZE) {
        toast({
          title: t('file_too_large'),
          description: t('file_too_large_description'),
          status: 'error',
          duration: 9000,
          isClosable: true,
        })

        posthog.capture('chat_file_is_too_large')
        return
      }

      setSelectedFile(file)
    },
    [toast, t, posthog]
  )

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      const form = event.target as HTMLFormElement
      const formData = new FormData(form)
      const message = formData.get('message')?.toString().trim()

      const [coachId] = chatRoomId.split('_')

      if (!selectedFile && typeof message === 'string' && message.length > 0) {
        socket.emit('room:chat', {
          socketId,
          receiverId: coachId,
          text: message,
        })

        posthog.capture('chat_message_sent')
      }

      if (selectedFile) {
        socket.emit('room:file', {
          socketId,
          receiverId: coachId,
          text: message,
          file: {
            name: selectedFile.name,
            type: selectedFile.type,
            size: selectedFile.size,
            data: selectedFile,
          },
        })

        posthog.capture('chat_file_sent')
      }

      setSelectedFile(null)
      form.reset()
    },
    [posthog, chatRoomId, refetch, scrollToBottom, selectedFile]
  )

  const dispatchSubmit = useCallback(() => {
    if (!formRef.current) return

    formRef.current.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true })
    )
  }, [])

  return (
    <>
      <div className="absolute z-30 top-0 left-0 right-0 bg-white border-b border-gray-200 px-5 py-4">
        <h2 className="text-lg font-semibold text-gray-900">
          Chatting with {user?.coach.profile.name}{' '}
          <span className="text-gray-400 text-sm">(Coach @ Hupo)</span>
        </h2>

        {isCoachTyping && (
          <p className="text-sm text-gray-400 mt-1">Typing...</p>
        )}
      </div>

      <div className="max-h-[92vh] min-h-[92vh] overflow-y-auto px-5 pt-20 pb-36 space-y-3">
        {messages.map((message) => (
          <ChatBubble
            key={message.id}
            side={message.sentByUser ? 'right' : 'left'}
            user={
              message.sentByUser
                ? {
                    name: user?.profile.name,
                    picture: user?.profile.picture,
                  }
                : {
                    name: user?.coach.profile.name,
                    picture: user?.coach.profile.picture,
                  }
            }
            date={parseISO(message.dateSent)}
            message={message.message}
            type={message.file.name ? 'file' : 'text'}
            file={message.file}
          />
        ))}

        {messages.length === 0 && (
          <p className="text-center text-gray-400">{t('no_messages_yet')}</p>
        )}

        {messages.length > 5 && (
          <span
            ref={endOfChatRef}
            className="block mt-5 text-center text-sm text-gray-400 select-none"
            tabIndex={-1}
          >
            End of chat
          </span>
        )}
      </div>

      <form
        ref={formRef}
        className="bg-white absolute left-0 right-0 bottom-0 border-t min-h-[70px] px-4 py-4 flex gap-3"
        onSubmit={onSubmit}
      >
        <label htmlFor="message" className="sr-only">
          Message
        </label>
        <div className="flex flex-col gap-2">
          <input
            ref={fileInputRef}
            type="file"
            className="hidden"
            onChange={handleFile}
            tabIndex={-1}
          />
          <button
            type="button"
            className="rounded-full bg-gray-50 p-1.5 text-gray-900 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-50"
            onClick={() => fileInputRef.current?.click()}
          >
            <PaperClipIcon aria-hidden="true" className="h-5 w-5" />
          </button>
          {selectedFile && (
            <button
              type="button"
              className="rounded-full bg-gray-50 p-1.5 text-gray-900 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-50"
              onClick={() => setSelectedFile(null)}
              title="Remove file"
            >
              <TrashIcon aria-hidden="true" className="text-red-500 h-5 w-5" />
            </button>
          )}
        </div>
        <div className="flex-1">
          <textarea
            id="message"
            name="message"
            rows={3}
            placeholder="Type a message..."
            className="bg-gray-100 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
            onKeyDown={(event) => {
              if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
                dispatchSubmit()
              }
            }}
          />
        </div>
        <div>
          <button
            type="button"
            className="inline-flex items-center gap-x-1.5 rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
            onClick={dispatchSubmit}
          >
            Send
            <PaperAirplaneIcon aria-hidden="true" className="-mr-0.5 h-5 w-5" />
          </button>
        </div>
      </form>
    </>
  )
}
