import type { LoaderFunction } from 'react-router-dom'
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { api } from '~/app/api'

export interface Response {
  summaries: Summary[]
  sections: string[]
  enabled: boolean
}

interface Summary {
  eventId: string
  start: string
  end: string
  title: string
  coachName: string
  content: string
  verified?: boolean
}

const fetchData = () =>
  queryOptions({
    queryKey: ['session_summaries'],
    queryFn() {
      return api.url('/user/session-summaries').get().json<Response>()
    },
  })

export const loader = (queryClient: QueryClient): LoaderFunction => () => {
  return queryClient.fetchQuery(fetchData())
}
