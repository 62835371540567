import { styled } from '@mui/material'

export const ProgressWrapper = styled('div')(() => ({
  // display: "grid",
  gap: '12px',
}))

export const ProgressContent = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 500px',
  gap: '12px',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr',
  },
}))

export const ProgressLeft = styled('div')(() => ({
  display: 'grid',
  gap: '14px',
  alignContent: 'flex-start',
  alignItems: 'flex-start',
}))

export const ProgressRight = styled('div')(() => ({
  display: 'grid',
  gap: '14px',
  alignContent: 'flex-start',
  alignItems: 'flex-start',
}))

export const LeadershipValueTable = styled('table')(() => ({
  width: '100%',
  marginTop: '10px',
  'td,th': {
    padding: '12px',
    font: '400 14px Poppins, sans-serif',
    textAlign: 'center',
  },
  // Border aroudn table
  borderCollapse: 'collapse',
  'th,td': {
    border: '1px solid #E0E0E0',
  },
  // 'th:not(:first-of-type),td:not(:first-of-type)': {
  //   fontWeight: '600',
  //   width: '70px',
  // },
  thead: {
    background: '#F2F4F5',
    th: {
      // color: theme.palette.primary.main,
      font: '600 14px Poppins, sans-serif',
      // [theme.breakpoints.down("md")]: {
      //   fontSize: "12px !important",
      // },
    },
    'th:first-of-type': {
      borderRadius: '12px 0 0 12px',
    },
    'th:last-of-type': {
      borderRadius: '0 12px 12px 0',
    },
  },
}))
