import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Center,
  Flex,
  HStack,
  IconButton,
  Spacer,
  Spinner,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Tag,
  Text,
  Textarea,
  TextareaProps,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import moment from 'moment-timezone'
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Trans } from 'react-i18next'
import { IoIosReturnLeft, IoMdAttach, IoMdSend } from 'react-icons/io'
import { MdOutlineDelete } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { APP_NAME, MAX_FILE_SIZE } from '../../app/constants'
import {
  api,
  useFetchMessagesQuery,
  useFetchUserQuery,
} from '../../app/services/api'
import { RootState, store } from '../../app/store'
import { socket } from '../../hooks/socket'
import { useAuth } from '../../hooks/useAuth'
import { useLocale } from '../../hooks/useLocale'
import { useMixpanel } from '../../utils/MixpanelContext'
import { updateSocket } from '../connection/socketSlice'
import './Chat.css'
import ChatBubble from './ChatBubble'
import { addMessage, Message, setMessages } from './chatSlice'
import ChatUpload from './ChatUpload'

const TYPING_TIMEOUT = 3000
const TYPING_SEND_INTERVAL = 1000

interface Props {
  onSendNewMessage?: any
  onMessageRead?: any
}

const Chat: React.FC<Props> = (props) => {
  const { onMessageRead, onSendNewMessage } = props
  const { user } = useAuth()
  if (!user) throw new Error('User is not defined')
  const { t } = useLocale()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [message, setMessage] = useState('')
  const socketId = useSelector((state: RootState) => state.socket.socketId)

  // State handlers for typing
  const [altKey, setAltKey] = useState(false)
  const [typingUserId, setTypingUserId] = useState('')
  const [typingTimeoutId, setTypingTimeoutId] = useState<any>()
  const lastSentRef = useRef<Date>(new Date())
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  // Add sending file/message state to show spinner
  const [sending, setSending] = useState(false)
  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure()

  const mixpanel = useMixpanel()
  const toast = useToast()

  const messages: any = useSelector((state: RootState) => state.chat.messages)

  const onReturnClick = useCallback(() => {
    navigate(-1)
  }, [navigate])

  useEffect(() => {
    mixpanel.track('chat_screen_open')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  // username -> userMeId
  // userId -> userOtherId
  const userMeId = user._id ?? (user as any)?.id
  const userOtherId = user.coach._id
  const chatRoomId = `${userOtherId}_${userMeId}`

  const { data: userData, isLoading: getUserLoading } =
    useFetchUserQuery(userOtherId)
  const { data, error, isLoading } = useFetchMessagesQuery({
    userId: userOtherId,
  })

  useEffect(() => {
    console.log(
      'socket.connected:',
      socket.connected,
      ', socket.id:',
      socket.id
    )
    if (!socket.connected) {
      socket.connect()
    } else if (!socketId && socket.id) {
      store.dispatch(updateSocket(socket.id))
    }
  }, [socketId])

  useEffect(() => {
    if (!socketId) return
    socket.emit('room:join', { socketId, userId: userMeId, chatRoomId })
    console.log(
      'room:join  socketId:',
      socketId,
      ', userId:',
      userMeId,
      ', chatRoomId:',
      chatRoomId
    )
  }, [socketId, userMeId, chatRoomId])

  useEffect(() => {
    if (data) {
      dispatch(setMessages(data.data))
      scrollToBottom()
    }
  }, [dispatch, data])

  useEffect(() => {
    const newMessageListener = (data: any) => {
      const dateSent = new Date().toUTCString()
      const newMessage: Message = {
        id: data.id,
        coach: data.coach,
        user: data.user,
        sentByUser: data.sentByUser,
        message: data.text,
        dateSent: dateSent,
        read: data.read,
        file: {
          url: data.fileUrl,
          name: data.fileName,
          type: data.fileType,
          size: data.fileSize,
        },
      }
      dispatch(addMessage(newMessage))
      scrollToBottom()
    }

    // Function to receive typing events
    const typingListener = (data: any) => {
      if (data.senderId === userMeId) return
      if (data.typing) {
        setTypingUserId(data.senderId)
      } else {
        setTypingUserId('')
      }
    }

    const refreshListener = (data: any) => {
      console.log(
        'refreshListener',
        data,
        ', userMeId:',
        userMeId,
        ', userOtherId:',
        userOtherId
      )
      if (data.senderId === userOtherId) {
        dispatch(api.util.invalidateTags(['messages']))
      }
    }

    socket.on('message', newMessageListener)
    socket.on('typing', typingListener)
    socket.on('refresh', refreshListener)
    return () => {
      socket.off('message', newMessageListener)
      socket.off('typing', typingListener)
      socket.off('refresh', refreshListener)
    }
  }, [dispatch, socketId, userMeId, userOtherId])

  const messagesEndRef = useRef() as MutableRefObject<HTMLDivElement>
  const scrollToBottom = () => {
    if (messagesEndRef?.current)
      messagesEndRef.current.scrollIntoView({ behavior: 'auto' })
  }

  const onChange = useCallback((e: any) => {
    setMessage(e.target.value)
  }, [])

  const clearTypingStatus = useCallback(() => {
    socket.emit('room:typing', {
      socketId,
      typing: false,
    })
  }, [socketId])

  const onMessage = useCallback(
    (e: any) => {
      e.preventDefault()
      setSending(true)
      if (message !== '') {
        socket.emit('room:chat', {
          socketId,
          receiverId: userOtherId,
          text: message,
        })
        mixpanel.track('chat_message_sent')
        setMessage('')
        onSendNewMessage && onSendNewMessage()
        clearTypingStatus()
        setSelectedFile(null)
      }
      if (selectedFile !== null) {
        if (selectedFile.size > MAX_FILE_SIZE) {
          toast({
            title: t('file_too_large'),
            description: t('file_too_large_description'),
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
          return
        }
        socket.emit('room:file', {
          socketId,
          receiverId: userOtherId,
          file: {
            name: selectedFile.name,
            type: selectedFile.type,
            size: selectedFile.size,
            data: selectedFile,
          },
        })
        mixpanel.track('chat_file_sent')
        setSelectedFile(null)
      }
      setSending(false)
    },
    [
      message,
      userOtherId,
      mixpanel,
      socketId,
      clearTypingStatus,
      onSendNewMessage,
      selectedFile,
      toast,
      t,
    ]
  )

  const handleFile = (file: File | null) => {
    setSelectedFile(file)
    onUploadClose()
    console.log('handleFile', file)
  }

  useEffect(() => {
    return () => clearTypingStatus()
  }, [socketId, clearTypingStatus])

  // Function to call sendTypingStatus and stopTypingStatus based on keypress
  const onKeyPress = useCallback(
    (e: any) => {
      if (e.key !== 'Enter') {
        const now = new Date()
        const diffInMs = moment(now).diff(lastSentRef.current, 'millisecond')

        if (diffInMs > TYPING_SEND_INTERVAL) {
          socket.emit('room:typing', {
            socketId,
            typing: true,
          })
          if (typingTimeoutId) {
            clearTimeout(typingTimeoutId)
          }

          const timeoutId: ReturnType<typeof setTimeout> = setTimeout(() => {
            clearTypingStatus()
          }, TYPING_TIMEOUT)
          setTypingTimeoutId(timeoutId)
          lastSentRef.current = now
        }
      }
    },
    [socketId, lastSentRef, typingTimeoutId, clearTypingStatus]
  )

  const onKeyUp = useCallback(
    (e: any) => {
      if (e.key === 'Alt' || e.code === 'MetaLeft' || e.code === 'MetaRight') {
        setAltKey(false)
      }
    },
    [setAltKey]
  )

  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // on enter send message, if shift and enter or alt and enter are pressed don't send message
    if (
      event.altKey ||
      event.code === 'MetaLeft' ||
      event.code === 'MetaRight'
    ) {
      setAltKey(true)
    }
    // Add a newline if alt and enter are pressed for mac
    if (event.key === 'Enter' && altKey) {
      event.preventDefault()
      setMessage(`${message}\n`)
    } else if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      onMessage(event)
    }
  }

  const renderSwitch = (messages: Array<Message>, isLoading: boolean) => {
    if ((!isLoading && error) || (!getUserLoading && !userData)) {
      return (
        <Alert
          status="error"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="calc(100vh - 250px)"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Error while loading messages!
          </AlertTitle>
        </Alert>
      )
    } else if (isLoading) {
      return (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="primary.200"
          color="primary.500"
          size="xl"
        />
      )
    } else if (messages && messages.length === 0) {
      return <Center h="calc(100vh - 250px)">{t('no_messages_yet')}</Center>
    } else {
      return (
        <Box overflowY="scroll" h="calc(100vh - 250px)">
          {messages.map(
            ({
              id,
              message,
              user,
              coach,
              sentByUser,
              dateSent,
              read,
              file,
            }) => (
              <ChatBubble
                id={id}
                userMeId={userMeId}
                // Set key to random number if index is null
                key={id || Math.random()}
                message={file && file.name ? file.name : message}
                user={user}
                coach={coach}
                sentByUser={sentByUser}
                read={read}
                dateSent={dateSent}
                onMessageRead={onMessageRead}
                file={file}
              />
            )
          )}
          <span ref={messagesEndRef}></span>
        </Box>
      )
    }
  }

  const personName = `${userData?.profile.name}`
  const personCompany = `${
    userData?.company ? userData?.company.name : APP_NAME
  }`
  // if (usersCoach) {
  //   personName = `${usersCoach.profile.name}`
  //   personCompany = APP_NAME
  // }

  return (
    <Flex
      w="full"
      flexDirection="column"
      bg={useColorModeValue('white', 'gray.900')}
    >
      <ChatUpload
        isOpen={isUploadOpen}
        onClose={onUploadClose}
        handleFile={handleFile}
      />
      <Flex px={6} overflowY="auto" flexDirection="column" flex={1}>
        <Stack mt={8} direction={'row'} spacing={4}>
          <IconButton
            onClick={onReturnClick}
            icon={<IoIosReturnLeft />}
            aria-label={t('go_back')}
          />
          <Stat mt={6}>
            <Trans
              i18nKey="chatting_with"
              values={{ personName, personCompany }}
            >
              <StatLabel color="gray.500">Chatting with</StatLabel>
              <StatNumber>
                {personName} @ {personCompany}
              </StatNumber>
            </Trans>{' '}
            {typingUserId && (
              <Tag size={'md'} variant="solid" colorScheme="teal">
                {t('typing...')}
              </Tag>
            )}
          </Stat>
        </Stack>
        {renderSwitch(messages, isLoading)}
      </Flex>
      <form>
        <Flex pl={4} py={2} borderTopColor="gray.100" borderTopWidth={1}>
          {!selectedFile ? (
            <AutoResizeTextarea
              autoFocus={true}
              overflow="hidden"
              w="100%"
              resize="none"
              variant="unstyled"
              placeholder={t('type_your_message')}
              value={message}
              onChange={onChange}
              onKeyPress={onKeyPress}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
            />
          ) : (
            <HStack
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              ml={2}
              fontSize="sm"
            >
              <Text>{selectedFile.name}</Text>
            </HStack>
          )}
          {selectedFile ? (
            <Tooltip
              label={t('remove_file')}
              aria-label={t('remove_file')}
              placement="top"
            >
              <IconButton
                colorScheme="red"
                aria-label={t('remove_file')}
                variant="ghost"
                onClick={() => setSelectedFile(null)}
                icon={<MdOutlineDelete />}
              />
            </Tooltip>
          ) : (
            <Tooltip
              label={t('attach_file')}
              aria-label={t('attach_file')}
              placement="top"
            >
              <IconButton
                colorScheme="blue"
                aria-label={t('attach_file')}
                variant="ghost"
                onClick={onUploadOpen}
                icon={<IoMdAttach />}
              />
            </Tooltip>
          )}
          <Spacer />
          {sending ? (
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="primary.200"
              color="primary.500"
              size="xl"
            />
          ) : (
            <IconButton
              colorScheme="blue"
              aria-label={t('send_message')}
              variant="ghost"
              className="send_button"
              onClick={onMessage}
              icon={<IoMdSend />}
              disabled={(message === '' && !selectedFile) || sending}
            />
          )}
        </Flex>
      </form>
    </Flex>
  )
}

function AutoResizeTextarea(props: TextareaProps) {
  const [height, setHeight] = useState<string>('30px')

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    setHeight(event.target.scrollHeight + 'px')
    event.target.scrollTop = event.target.scrollHeight + 200

    return props.onChange?.(event)
  }

  return (
    <Textarea
      minH="unset"
      maxH="1000px"
      style={{ height }}
      {...props}
      onChange={handleChange}
      onKeyDown={(e) => {
        if (e.keyCode === 8) {
          setHeight(e.currentTarget.value.length === 0 ? '30px' : 'auto')
        }

        return props.onKeyDown?.(e)
      }}
    />
  )
}

export default Chat
