/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useToast } from '@chakra-ui/react'
import { DyteDialogManager, DyteSetupScreen } from '@dytesdk/react-ui-kit'
import { DyteProvider, useDyteClient } from '@dytesdk/react-web-core'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { useGetMeetingInfoQuery } from '../../app/services/api'
import { RootState } from '../../app/store'
import { useLocale } from '../../hooks/useLocale'
import { useMixpanel } from '../../utils/MixpanelContext'
import { Facetime } from './components/facetime/Facetime'
import Initializing from './components/Initializing'
import { useSpeechRecognition } from './hooks/useSpeechRecognition'
import { useHideChatlio } from '../../hooks/useHideChatlio'

const ROOM_JOINING_TRACK_INTERVAL_SEC = 5

interface Props {
  participant: 'user' | 'coach'
}
const InAppMeeting = ({ participant }: Props) => {
  const { eventId } = useParams() as any
  const toast = useToast()

  const meetingInitialized = useRef(false)
  const chatSocketId = useSelector((state: RootState) => state.socket.socketId)
  const mixpanel = useMixpanel()
  const { data, isLoading, error } = useGetMeetingInfoQuery({
    eventId,
    participant,
  })
  const { t } = useLocale()

  const [micAccess, setMicAccess] = useState(false)
  const [cameraAccess, setCameraAccess] = useState(false)
  const [meeting, initMeeting] = useDyteClient()
  const authToken = useMemo(
    () => data?.meeting?.authToken,
    [data?.meeting?.authToken]
  )
  const useStt = useMemo(() => data?.meeting?.useStt, [data?.meeting?.useStt])
  const roomName = useMemo(
    () => data?.meeting?.roomName,
    [data?.meeting?.roomName]
  )
  const user = useMemo(() => data?.user, [data?.user])
  const coach = useMemo(() => data?.coach, [data?.coach])

  const [roomJoined, setRoomJoined] = useState(false)
  const [roomLeft, setRoomLeft] = useState(false)

  const { speechSocket, onStartSpeechRecognition, onStopSpeechRecognition } =
    useSpeechRecognition({
      meeting,
      eventId,
      useStt,
      user,
      coach,
      participantType: participant,
    })

  useEffect(() => {
    const micPermission = 'microphone' as PermissionName
    navigator.permissions.query({ name: micPermission }).then((result) => {
      setMicAccess(result.state === 'granted')

      if (result.state === 'prompt') {
        mixpanel.track('inapp_meeting_mic_permission_prompt', {
          eventId,
          roomName,
        })
        result.onchange = () => {
          setMicAccess(result.state === 'granted')
          mixpanel.track('inapp_meeting_mic_permission_change', {
            eventId,
            roomName,
            micAccess: result.state === 'granted',
          })
        }
      }
    })
    const cameraPermission = 'camera' as PermissionName
    navigator.permissions.query({ name: cameraPermission }).then((result) => {
      setCameraAccess(result.state === 'granted')

      if (result.state === 'prompt') {
        mixpanel.track('inapp_meeting_camera_permission_prompt', {
          eventId,
          roomName,
        })
        result.onchange = () => {
          setCameraAccess(result.state === 'granted')
          mixpanel.track('inapp_meeting_camera_permission_change', {
            eventId,
            roomName,
            cameraAccess: result.state === 'granted',
          })
        }
      }
    })
  }, [])

  useEffect(() => {
    if (error) {
      console.log('Error in useGetMeetingInfoQuery:', error)
      mixpanel.track('inapp_meeting_error', {
        eventId,
        roomName,
        error: JSON.stringify(error),
      })
      toast({
        status: 'error',
        title: t('error'),
        description: error.toString(),
        isClosable: true,
      })
    }
  }, [error, t, toast])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isLoading) {
        mixpanel.track('inapp_meeting_loading_timeout', {
          eventId,
          roomName,
        })
        window.location.reload()
      }
    }, 60000) // 1 minute
    return () => clearTimeout(timer)
  }, [isLoading])

  useEffect(() => {
    if (!authToken || meetingInitialized.current) return
    console.log('initMeeting, authToken:', authToken)
    initMeeting({ authToken })
    meetingInitialized.current = true

    // Mixpanel identification
    mixpanel.identify(participant === 'user' ? user?._id : coach?._id)
    mixpanel.people.set(
      'email',
      participant === 'user' ? user?.email : coach?.email
    )
    mixpanel.people.set('participant', participant)
    mixpanel.set_group('eventId', eventId)
    mixpanel.set_group('roomName', roomName)

    // Mixpanel tracking
    mixpanel.track('inapp_meeting_init', {
      eventId,
      roomName,
      micAccess,
      cameraAccess,
    })
  }, [
    authToken,
    eventId,
    initMeeting,
    mixpanel,
    roomName,
    user,
    coach,
    participant,
    micAccess,
    cameraAccess,
  ])

  useEffect(() => {
    if (!meeting || (!user && !coach)) return

    meeting.self.on('roomJoined', () => {
      setRoomJoined(true)
      onStartSpeechRecognition()
      mixpanel.track('inapp_meeting_joined', {
        eventId,
        roomName,
      })
    })
  }, [meeting, user, coach, eventId, onStartSpeechRecognition])

  useEffect(() => {
    if (!meeting || !chatSocketId || (!user && !coach)) return

    // When server restarts, chatSocketId changes and we need to restart speech recognition
    if (meeting.self.roomJoined) {
      console.log('restarting speech recognition')
      onStartSpeechRecognition()
      mixpanel.track('inapp_meeting_speech_recognition_restarted', {
        eventId,
        roomName,
      })
    }
    // Adding chatSocketId to dependencies so that we can trigger restart speech recognition when server restarts
  }, [meeting, user, coach, eventId, chatSocketId, onStartSpeechRecognition])

  useEffect(() => {
    if (!meeting || !speechSocket || (!user && !coach)) return

    meeting.self.on('roomLeft', () => {
      console.log('on::roomLeft')
      setRoomLeft(true)
      onStopSpeechRecognition()
      mixpanel.track('inapp_meeting_left', {
        eventId,
        roomName,
      })
    })
  }, [meeting, user, coach, eventId, speechSocket, onStopSpeechRecognition])

  useHideChatlio()
  const [initCountdown, setInitCountdown] = useState(0)
  const isInitializing = !meeting || isLoading

  useEffect(() => {
    if ((!isInitializing && roomJoined) || initCountdown > 120) {
      return
    }

    const intervalId: ReturnType<typeof setInterval> | null = setInterval(
      () => {
        setInitCountdown(
          (prevCountdown) => prevCountdown + ROOM_JOINING_TRACK_INTERVAL_SEC
        )
        mixpanel.track('inapp_meeting_init_countdown', {
          eventId,
          roomName,
          waiting: initCountdown + ROOM_JOINING_TRACK_INTERVAL_SEC,
        })
      },
      ROOM_JOINING_TRACK_INTERVAL_SEC * 1000
    )

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [initCountdown, isInitializing, roomJoined])

  if (isInitializing) {
    return (
      <Initializing
        eventId={eventId}
        roomName={roomName}
        micAccess={micAccess}
        cameraAccess={cameraAccess}
      />
    )
  }

  Object.assign(window, { meeting })

  if (!roomJoined) {
    mixpanel.track('inapp_meeting_setup', {
      eventId,
      roomName,
    })
    return (
      <div className="h-full w-full p-4 flex flex-col bg-black text-white overflow-hidden">
        <DyteDialogManager meeting={meeting} />
        <DyteSetupScreen meeting={meeting} style={{ height: '360px' }} />
      </div>
    )
  }

  return (
    <div id="dyte-meeting" className="h-full">
      <DyteProvider value={meeting} fallback={<div>{t('loading')}</div>}>
        <Facetime
          meeting={meeting}
          isCoach={participant === 'coach'}
          eventId={eventId}
          roomName={roomName}
          roomLeft={roomLeft}
        />
      </DyteProvider>
    </div>
  )
}

export default InAppMeeting
