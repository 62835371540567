import { Button, Flex } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { IoChevronBackOutline } from 'react-icons/io5'
import ProgressComponent from './ProgressLine'

interface OnboardingHeaderProps {
  currentStep: number
  totalSteps: number
  visible?: boolean
  isAssessment?: boolean
}

const OnboardingHeader: React.FC<OnboardingHeaderProps> = ({
  currentStep,
  totalSteps,
  visible = true,
  isAssessment = false,
}) => {
  const navigate = useNavigate()
  const params = useParams() as any

  return (
    <Flex
      style={{
        display: visible ? 'flex' : 'none',
        padding: 10,
        backgroundColor: '#fff',
        boxShadow: '0 0 10px rgba(0,0,0,0.2)',
        position: 'sticky',
        top: 0,
        zIndex: 11,
      }}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gridColumnGap={5}
    >
      <Button
        onClick={() =>
          isAssessment && params?.index === '0' ? navigate('/') : navigate(-1)
        }
        style={{ width: 40, height: 40, borderRadius: 100, padding: 0 }}
      >
        <IoChevronBackOutline size={25} />
      </Button>
      <ProgressComponent currentPage={currentStep} totalPages={totalSteps} />
    </Flex>
  )
}

export default OnboardingHeader
