import { useRef, useState } from 'react'
import { usePostHog } from 'posthog-js/react'
import { cn } from '~/utils/cn'
import { NextButton } from './NextButton'
import { useOnboardingContext } from '~/context/onboardingContext'

type Props = {
  id: string
  isMulti?: boolean
  options: {
    _id: string
    label: string
    description: string
    value: string
  }[]
  otherTextPlaceholder?: string
}

export const Select: React.FC<Props> = (props) => {
  const { id, options, otherTextPlaceholder, isMulti = false } = props
  const posthog = usePostHog()

  const { postOnboardingInfo, currentQuestionIndex } = useOnboardingContext()

  const otherRef = useRef<HTMLTextAreaElement>(null)
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])
  const [selected, setSelected] = useState<string>('')
  const [otherSelected, setOtherSelected] = useState(false)

  const isSelected = (id: string) => selectedOptions.includes(id)

  const onSubmit = () => {
    const other = otherRef.current?.value

    if (other) {
      postOnboardingInfo({
        index: currentQuestionIndex,
        answers: {
          [id]: { answers: isMulti ? selectedOptions : selected, other },
        },
      })
      posthog.capture('onboarding_select_other', { questionId: id, isMulti })
    } else {
      postOnboardingInfo({
        index: currentQuestionIndex,
        answers: { [id]: isMulti ? selectedOptions : selected },
      })
      posthog.capture('onboarding_select', { questionId: id, isMulti })
    }

    // Reset
    if (otherRef.current) {
      otherRef.current.value = ''
    }
    setOtherSelected(false)
    setSelectedOptions([])
    setSelected('')
  }

  return (
    <>
      <ul role="list" className="space-y-3 w-3/4 pb-20">
        {options.map((item) => {
          const itemSelected = isMulti
            ? isSelected(item.value)
            : selected === item.value

          return (
            <li
              key={item._id}
              className={cn(
                'overflow-hidden rounded-lg border border-gray-100 bg-white px-6 py-4 shadow cursor-pointer',
                itemSelected
                  ? 'border-primary-300 bg-primary'
                  : 'border-gray-100'
              )}
              onClick={() => {
                setOtherSelected(item.label.toLowerCase() === 'other')

                if (!isMulti) {
                  setSelected(item.value)
                  return
                }

                setSelectedOptions((prev) =>
                  prev.includes(item.value)
                    ? prev.filter((id) => id !== item.value)
                    : [...prev, item.value]
                )
              }}
            >
              <div className="flex items-center justify-center">
                <div
                  className={cn(
                    'text-sm font-medium',
                    itemSelected ? 'text-white' : 'text-gray-900'
                  )}
                >
                  {item.label}
                </div>
                {item.description?.length > 0 && (
                  <div
                    className={cn(
                      'mt-2 text-sm',
                      itemSelected ? 'text-gray-500' : 'text-gray-500'
                    )}
                  >
                    {item.description}
                  </div>
                )}
              </div>
            </li>
          )
        })}

        {otherSelected && (
          <li>
            <div className="mt-2">
              <textarea
                ref={otherRef}
                rows={4}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                defaultValue={''}
                placeholder={otherTextPlaceholder}
              />
            </div>
          </li>
        )}
      </ul>

      <NextButton className="fixed bottom-4 w-1/3" onClick={onSubmit} />
    </>
  )
}
