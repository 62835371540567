import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Box,
  Spinner,
  Center,
} from '@chakra-ui/react'
import React, { useState } from 'react'

interface Props {
  url: string
  onClose: () => void
  isOpen: boolean
}

export const VideoModal: React.FC<Props> = ({ isOpen, onClose, url }) => {
  const [videoStyle, setVideoStyle] = useState<React.CSSProperties>({
    display: 'none',
  })

  const handleVideoLoad = () => {
    console.log('Loading done')

    setVideoStyle({ display: 'block', maxHeight: '100vh' })
  }

  return (
    <Modal isOpen={isOpen} size="3xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bg="transparent"
        pos="relative"
        border="none"
        boxShadow="none"
        mt="0"
        mb="0"
      >
        <Box pos="absolute" right={-55}>
          <ModalCloseButton color="white" colorScheme="white" />
        </Box>
        <ModalBody as={Center} border="none" p="0">
          <video
            src={url}
            style={videoStyle}
            onLoadedData={handleVideoLoad}
            controls
            autoPlay
          />

          {videoStyle.display === 'none' && <Spinner />}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
