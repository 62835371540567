import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { useToast } from '@chakra-ui/react'

// import type { Response } from './peer-response-management.loader'
import { RespondentCard } from './_components/RespondentCard'
import { AlertDialog } from '~/components/alert-dialog'
import { api } from '~/app/api'
import { RespondentModal } from './_components/RespondentModal'

export const RespondentManagement: React.FC = () => {
  const { t } = useTranslation()
  const toast = useToast()
  const [showActionModal, setShowActionModal] = useState(false)
  const [showResendAlert, setShowResendAlert] = useState(false)
  const [email, setEmail] = useState('')
  const [type, setType] = useState('')

  const { mutate: resend } = useMutation({
    mutationFn(email: string) {
      return api.url('/v2/user/assessment/nudge-peer').post({ email }).json()
    },
    onSuccess() {
      toast({
        status: 'success',
        title: t('success'),
        description: t('nudge_successful'),
        isClosable: true,
      })
    },
    onError() {
      toast({
        status: 'error',
        title: t('error'),
        description: t('nudge_failed'),
        isClosable: true,
      })
    },
    onSettled() {
      setShowResendAlert(false)
    },
  })

  return (
    <>
      <div className="ml-auto">
        <button
          type="button"
          className="rounded bg-primary px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
          onClick={() => setShowActionModal(true)}
        >
          {t('add_respondent')}
        </button>
      </div>

      <div className="grid grid-cols-1 gap-4">
        <RespondentCard
          dataKey="managers"
          onResend={(email) => {
            setShowResendAlert(true)
            setEmail(email)
          }}
          onEdit={(email) => {
            setShowActionModal(true)
            setEmail(email)
            setType('manager')
          }}
        />
        <RespondentCard
          dataKey="peers"
          onResend={(email) => {
            setShowResendAlert(true)
            setEmail(email)
          }}
          onEdit={(email) => {
            setShowActionModal(true)
            setEmail(email)
            setType('peer')
          }}
        />
        <RespondentCard
          dataKey="reportees"
          onResend={(email) => {
            setShowResendAlert(true)
            setEmail(email)
          }}
          onEdit={(email) => {
            setShowActionModal(true)
            setEmail(email)
            setType('reportee')
          }}
        />
      </div>

      <AlertDialog
        title={t('resend_invitation')}
        description={t('resend_request_confirmation', { email })}
        isOpen={showResendAlert}
        onClose={() => setShowResendAlert(false)}
        confirmText={t('resend')}
        onConfirm={() => resend(email)}
      />

      <RespondentModal
        isOpen={showActionModal}
        onClose={() => setShowActionModal(false)}
        defaultValues={{ email, type }}
      />
    </>
  )
}
