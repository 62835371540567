import { QuestionProps } from '../components/PeerAssessmentArea'
import { ListQuestionItems } from './components/ListQuestionItems'
import { QuestionTitle } from './components/QuestionHeader'

export function ListQuestion({ data, user }: QuestionProps) {
  return (
    <>
      <QuestionTitle data={data} user={user} />
      <ListQuestionItems options={data.options} />
    </>
  )
}
