import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  Select,
  Spacer,
  Spinner,
  Text,
  VStack,
  VisuallyHiddenInput,
  useDisclosure,
  useToast,
  Divider,
} from '@chakra-ui/react'
import moment from 'moment-timezone'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { IoIosCheckmarkCircleOutline } from 'react-icons/io'
import {
  useGetRespondentStatusQuery,
  useNudgePeerAssessmentMutation,
  useUpdateRespondentMutation,
} from '../../app/services/api'
import { useTranslation } from 'react-i18next'

type RespondentType = 'peer' | 'manager' | 'reportee'
type RespondentStatus = 'pending' | 'completed' | 'started'

interface RespondentData {
  type: RespondentType
  email: string
  lastRequested: Date
  requestedCount: number
  status: RespondentStatus
}

interface RespondentGroupData {
  type: RespondentType
  labelSingular: string
  labelPlural: string
  respondents: RespondentData[]
  onEditRespondent: (respondent: any) => void
}

const RespondentGroup = (data: RespondentGroupData) => {
  return (
    <>
      <Heading as="h4" size="md" mb={4}>
        {data.respondents.length === 1 ? data.labelSingular : data.labelPlural}{' '}
        (
        {
          data.respondents.filter(
            (respondent) => respondent.status === 'completed'
          ).length
        }
        /{data.respondents.length})
      </Heading>
      {data.respondents.map((respondent) => (
        <Respondent
          type={data.type}
          data={respondent}
          onEditRespondent={data.onEditRespondent}
        />
      ))}
      <Spacer mt={8} />
    </>
  )
}

const Respondent = ({
  type,
  data,
  onEditRespondent,
}: {
  type: RespondentType
  data: RespondentData
  onEditRespondent: (respondent: any) => void
}) => {
  const [nudgePeer] = useNudgePeerAssessmentMutation()
  const toast = useToast()
  const { t } = useTranslation()

  const [isConfirmAlertOpen, setIsConfirmAlertOpen] = useState(false)

  const onNudge = useCallback(async () => {
    console.log('onNudge')
    await nudgePeer({ email: data.email })
    toast({
      status: 'success',
      title: t('success'),
      description: t('nudge_successful'),
      isClosable: true,
    })
    setIsConfirmAlertOpen(false)
  }, [data.email, nudgePeer, toast])
  return (
    <Flex
      bg={data.status === 'completed' ? '#ebfff0' : '#fff'}
      border={`1px solid`}
      borderColor={data.status === 'completed' ? '#14752e' : '#dcc'}
      borderRadius="md"
      mb={'2'}
      pb="0px"
    >
      <HStack spacing="24px" width={'100%'}>
        <Box
          textAlign={{
            base: 'center',
            sm: 'center',
            md: 'center',
            lg: 'center',
          }}
          verticalAlign="center"
          pl="5"
          pt="5"
          flexGrow={0}
        >
          <Avatar
            size={'lg'}
            mb={4}
            borderStyle="solid"
            borderWidth={'1px'}
            borderColor={'GrayText'}
            pos={'relative'}
          />
        </Box>
        <VStack align="stretch" spacing={0} width={'80%'}>
          <Heading pb={1} mb="0" as="h5" size="md">
            {data.email}
          </Heading>
          {data.status === 'completed' ? (
            <Text ml="5" mt={0} mb={0} fontSize="sm">
              {t('status')}: <i>{t('completed')}</i>
            </Text>
          ) : (
            <>
              <Text ml="5" mt={0} mb={0} fontSize="sm">
                {t('last_requested')}:{' '}
                <i>{moment(data.lastRequested).format('MMMM DD')}</i>
              </Text>
              <Text ml="5" mt={0} mb={0} fontSize="sm">
                {t('requests_sent')}: <i>{data.requestedCount}</i>
              </Text>
            </>
          )}
        </VStack>
        <Center width={'80%'} justifyContent={'flex-end'}>
          <Box p="5" flexDir={'row'}>
            {data.status === 'completed' ? (
              <IoIosCheckmarkCircleOutline size={40} />
            ) : (
              <>
                <Button
                  onClick={() => onEditRespondent({ email: data.email, type })}
                  variant="outline"
                  mx={2}
                >
                  {t('edit')}
                </Button>
                <Button
                  onClick={() => setIsConfirmAlertOpen(true)}
                  colorScheme="primary"
                  variant="outline"
                >
                  {t('resend')}
                </Button>
              </>
            )}
          </Box>
        </Center>
      </HStack>
      <Modal
        isOpen={isConfirmAlertOpen}
        onClose={() => setIsConfirmAlertOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> {t('resend_invitation')} </ModalHeader>
          <Divider />
          <ModalBody>
            <Text fontSize="18px">
              {t('resend_request_confirmation', {
                email: data.email,
              })}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setIsConfirmAlertOpen(false)} mr={5}>
              {t('cancel')}
            </Button>
            <Button onClick={onNudge} colorScheme="primary" type="submit">
              {t('resend')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

const UpdateRespondentModal: FC<any> = ({
  existingRespondent,
  isOpen,
  onClose,
  updateRespondent,
  isLoading,
}) => {
  const { t } = useTranslation()
  const form = useForm({
    defaultValues: {
      newEmail: existingRespondent?.email,
      previousEmail: existingRespondent?.email,
      type: existingRespondent?.type,
    },
  })

  useEffect(() => {
    form.reset({
      newEmail: existingRespondent?.email,
      previousEmail: existingRespondent?.email,
      type: existingRespondent?.type,
    })
  }, [existingRespondent, form])

  return (
    <Modal isOpen={isOpen} onClose={onClose} onEsc={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton onClick={onClose} />
        <ModalHeader>
          {existingRespondent?.email
            ? t('update_respondent')
            : t('add_respondent')}
        </ModalHeader>
        <ModalBody>
          <form onSubmit={form.handleSubmit(updateRespondent)}>
            <FormControl isInvalid={!!form.formState.errors.newEmail} mb={3}>
              <FormLabel>{t('email')}</FormLabel>
              <Input
                type="email"
                {...form.register('newEmail', { required: true })}
              />
              <FormErrorMessage>{t('required_field')}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!form.formState.errors.type} mb={3}>
              <FormLabel>{t('type')}</FormLabel>
              <Select {...form.register('type', { required: true })}>
                <option
                  key={'manager'}
                  value={'manager'}
                  selected={existingRespondent?.type === 'manager'}
                >
                  {t('manager')}
                </option>
                <option
                  key={'peer'}
                  value={'peer'}
                  selected={existingRespondent?.type === 'peer'}
                >
                  {t('peer')}
                </option>
                <option
                  key={'reportee'}
                  value={'reportee'}
                  selected={existingRespondent?.type === 'reportee'}
                >
                  {t('reportee')}
                </option>
              </Select>
              <FormErrorMessage>{t('required_field')}</FormErrorMessage>
            </FormControl>
            <VisuallyHiddenInput {...form.register('previousEmail')} />
            <Button
              isLoading={isLoading}
              width="100%"
              my={3}
              colorScheme="primary"
              type="submit"
            >
              {t('submit')}
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

const PeerManagement: React.FC<any> = () => {
  const { t } = useTranslation()
  const { data: respondentData, isLoading } = useGetRespondentStatusQuery({})

  const [existingRespondent, setExistingRespondent] = useState()
  const [updateRespondent] = useUpdateRespondentMutation()
  const {
    isOpen: isRespondentModalOpen,
    onOpen: onRespondentOpen,
    onClose: onRespondentClose,
  } = useDisclosure()

  const toast = useToast()

  const onCloseRespondentModal = useCallback(() => {
    setExistingRespondent(undefined)
    onRespondentClose()
  }, [onRespondentClose])

  const onUpdateRespondent = useCallback(
    async (data: any) => {
      try {
        await updateRespondent({
          previousEmail: data.previousEmail ? data.previousEmail : undefined,
          newEmail: data.newEmail,
          type: data.type,
        }).unwrap()

        toast({
          status: 'success',
          title: t('success'),
          description: t('respondent_updated'),
          isClosable: true,
        })
      } catch (err: any) {
        console.log('error updating respondent', err)
        toast({
          status: 'error',
          title: 'Error',
          description: err?.data?.message ?? t('respondent_update_failed'),
          isClosable: true,
        })
      }
      onCloseRespondentModal()
    },
    [updateRespondent, onCloseRespondentModal, toast]
  )
  const onAddRespondentOpen = useCallback(() => {
    const respondent: any = {
      email: '',
      type: 'peer',
    }
    setExistingRespondent(respondent)
    onRespondentOpen()
  }, [onRespondentOpen])

  if (isLoading) {
    return (
      <Center px={4} py={2} width={'100%'}>
        <Spinner />
      </Center>
    )
  }
  if (!respondentData) {
    return (
      <Center px={4} py={2}>
        <Text>{t('no_data')}</Text>
      </Center>
    )
  }
  const onEditRespondent = (respondent: any) => {
    setExistingRespondent(respondent)
    onRespondentOpen()
  }
  return (
    <Box px={4} py={2}>
      <Box justifyContent={'flex-end'} display="flex">
        {/* <Button
          leftIcon={<FaRegFilePdf />}
          aria-label="View detailed report"
          onClick={() => window.open(leadershipValueScores.reportUrl, '_blank')}
          disabled={!leadershipValueScores.reportUrl}
        >
          {t('view_detailed_report')}
        </Button> */}
        <Button colorScheme="primary" onClick={onAddRespondentOpen} ml={4}>
          {t('add_respondent')}
        </Button>
      </Box>
      <RespondentGroup
        type="manager"
        respondents={respondentData.managers as RespondentData[]}
        labelSingular={t('manager')}
        labelPlural={t('managers')}
        onEditRespondent={onEditRespondent}
      />
      <RespondentGroup
        type="peer"
        respondents={respondentData.peers as RespondentData[]}
        labelSingular={t('peer')}
        labelPlural={t('peers')}
        onEditRespondent={onEditRespondent}
      />
      <RespondentGroup
        type="reportee"
        respondents={respondentData.reportees as RespondentData[]}
        labelSingular={t('reportee')}
        labelPlural={t('reportees')}
        onEditRespondent={onEditRespondent}
      />
      <UpdateRespondentModal
        existingRespondent={existingRespondent}
        isOpen={isRespondentModalOpen}
        onClose={onCloseRespondentModal}
        updateRespondent={onUpdateRespondent}
        isLoading={isLoading}
      />
    </Box>
  )
}

export default PeerManagement
