import { Button, Flex, Link, Text } from '@chakra-ui/react'
import { t } from 'i18next'

import { useMixpanel } from '../../utils/MixpanelContext'
import { getUnifiedCallLink } from '../../utils/calllink'

const UnifiedCallLink = ({
  event,
  buttonSize = 'sm',
  label = t('join_the_call'),
  secondary = false,
  referrer = 'app-unified-default-link',
  isCoach = false,
  ...props
}: any) => {
  const mixpanel = useMixpanel()

  const handleClick = () => {
    mixpanel.track('unified_call_link_open')
  }

  const unifiedLink = getUnifiedCallLink({
    eventId: event._id,
    referrer,
    isCoach,
  })

  if (secondary) {
    return (
      <Flex direction={'column'} alignSelf={'center'} alignItems={'center'}>
        <Text mb={1}>{'or'}</Text>
        <Link
          isExternal={true}
          href={unifiedLink}
          target="_blank"
          _hover={{
            textDecoration: 'none',
          }}
          {...props}
        >
          <Text as="b">{label}</Text>
        </Link>
      </Flex>
    )
  }

  return (
    <Flex>
      <Link
        isExternal={true}
        href={unifiedLink}
        target="_blank"
        _hover={{
          textDecoration: 'none',
        }}
        {...props}
      >
        <Button
          bgColor="primary.600"
          color="#fff"
          variant="solid"
          size={buttonSize}
          onClick={handleClick}
          p={4}
          _hover={{
            textDecoration: 'none',
            bg: 'primary.400',
          }}
        >
          {label}
        </Button>
      </Link>
    </Flex>
  )
}

export default UnifiedCallLink
