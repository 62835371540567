import { useLoaderData, useRevalidator } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { usePostHog } from 'posthog-js/react'
import { CalendarIcon, UserIcon } from '@heroicons/react/24/outline'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { useToast } from '@chakra-ui/react'

import type { Response } from './hupohighlights.loader'
import { api } from '~/app/api'
import { cn } from '~/utils/cn'

const formatSummary = (summary: string, sections: string[]) => {
  if (summary.includes('. **')) {
    const lines = summary.split('\n')
    summary = lines
      .map((line) => {
        if (line.includes('. **') && line.includes(':**')) {
          return line.replace('. **', '. <b>').replace(':**', ':</b>')
        } else if (line.includes('- **') && line.includes(':**')) {
          return line.replace('- **', '- <i>').replace(':**', ':</i>')
        }
        return line
      })
      .join('\n')
  } else {
    for (const highlightedPart of sections) {
      const regex = new RegExp(highlightedPart, 'gi')
      summary = summary?.replace(regex, '<b>' + highlightedPart + '</b>')
    }
  }
  summary = summary.replace(/\\n/g, '<br/>')
  return summary
}

export const HupoHighlights: React.FC = () => {
  const data = useLoaderData() as Response
  const { t } = useTranslation()
  const posthog = usePostHog()
  const toast = useToast()
  const { revalidate } = useRevalidator()

  const { mutate: enable } = useMutation({
    mutationFn() {
      return api.url('/user/session-summaries/enable').post().json()
    },
    onSuccess() {
      toast({
        title: t('enable_hupo_highlights_success'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      posthog.capture('hupo_highlights_enable_success')
      revalidate()
    },
    onError() {
      toast({
        title: t('enable_hupo_highlights_fail'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      posthog.capture('hupo_highlights_enable_fail')
    },
  })

  if (!data.enabled) {
    return (
      <div className="rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:p-10">
          <h2 className="text-center text-lg">{t('hh_not_enabled')}</h2>

          <p className="text-center my-5 text-sm text-gray-500">
            <Trans i18nKey="hh_not_enabled_description">
              <strong>HupoHighlights</strong>
            </Trans>
          </p>

          <p className="text-center text-sm text-gray-500">
            <Trans i18nKey="hupo_highlights_only_english">
              <strong>HupoHighlights</strong>
            </Trans>
          </p>

          <div className="flex justify-center mt-5">
            <button
              type="button"
              className="rounded-md bg-primary px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
              onClick={() => enable()}
            >
              {t('hh_enable')}
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:p-6 divide-y divide-gray-200">
        {data.summaries.map((summary) => (
          <div key={summary.eventId} className="pt-4">
            <div className="flex justify-between">
              <p className="flex items-center gap-2 text-sm text-gray-600">
                <CalendarIcon className="h-5" />
                {format(parseISO(summary.start), 'MMM dd, yyyy h:mm a')}
              </p>

              <p
                className={cn(
                  'flex items-center gap-2 text-sm',
                  summary.verified ? 'text-green-600' : 'text-yellow-600'
                )}
              >
                <UserIcon className="h-5" />
                {summary.verified
                  ? t('verified_by_coach')
                  : t('not_verified_by_coach')}
              </p>
            </div>

            <div className="mb-4 mt-2">
              <p
                className="whitespace-pre-line text-gray-800"
                dangerouslySetInnerHTML={{
                  __html: formatSummary(summary.content, data.sections),
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
