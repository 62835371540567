import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Spinner,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Rating } from 'react-simple-star-rating'
import {
  firstSessionOptions,
  highOptions,
  lowOptions,
  midOptions,
} from '../../app/constants'
import { usePostSessionUserFeedbackMutation } from '../../app/services/api'
import { useAuth } from '../../hooks/useAuth'
import { useLocale } from '../../hooks/useLocale'
import { Logo } from '../../utils/Logo'

const REDIRECT_SECONDS = 3
const LANDING_PAGE = 'https://hupo.co/'
const HOME_PAGE = `${window.location.protocol}//${window.location.host}`

const SelectOption = ({ title, value, selected, onItemSelected }: any) => {
  const isItemSelected = selected.has(value)
  return (
    <Box
      key={value}
      boxShadow="0 0px 5px rgba(0,0,0,0.15)"
      mb={3}
      mx="auto"
      borderRadius={20}
      padding={3}
      width={360}
      bg={isItemSelected ? 'var(--chakra-colors-primary-400)' : '#fff'}
      cursor="pointer"
      onClick={() => onItemSelected(value)}
    >
      <Flex direction={'column'} alignItems="center">
        <Text
          color={isItemSelected ? '#fff' : '#000'}
          textAlign="center"
          fontWeight="400"
          fontSize={'l'}
        >
          {title}
        </Text>
      </Flex>
    </Box>
  )
}

const PostSessionUserFeedback = () => {
  const { t } = useLocale()
  const { user } = useAuth()
  const toast = useToast()
  const { eventId, coachName, rating: ratingParam } = useParams() as any
  const [submitting, setSubmitting] = useState(false)
  const [rating, setRating] = useState(parseInt(ratingParam ?? '0'))
  const [answers, setAnswers] = useState(new Set<string>())
  const otherTextRef = useRef('')
  const [postSessionUserFeedback, { isLoading }] =
    usePostSessionUserFeedbackMutation()
  const [isFirstSession, setIsFirstSession] = useState(false)
  const [isRedirecting, setIsRedirecting] = useState(false)

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const isFirstSessionParam = searchParams.get('isfirstsession')
    setIsFirstSession(isFirstSessionParam === '1')
  }, [])

  const getFollowUpQuestionTitle = useCallback(
    (rating: any) => {
      if (rating === 1 || rating === 2) {
        return t('session_review_followup_title_rating_low')
      } else if (rating === 3 || rating === 4) {
        return t('session_review_followup_title_rating_mid')
      } else if (rating === 5) {
        return t('session_review_followup_title_rating_high')
      }
      return ''
    },
    [t]
  )

  const onSetStarRating = async (rating: number) => {
    setRating(rating)
    try {
      await postSessionUserFeedback({
        eventId,
        rating,
      }).unwrap()
    } catch (err) {
      console.log(err)
    }
  }

  const optionsHandler = (rate: number) => {
    if (isFirstSession) {
      return firstSessionOptions
    }
    if (rate === 5) {
      return highOptions
    }
    if (rate === 3 || rate === 4) {
      return midOptions
    }
    if (rate === 1 || rate === 2) {
      return lowOptions
    }

    return []
  }

  const onSubmit = async () => {
    const answersArray = Array.from(answers)
    if (
      answersArray.length === 0 ||
      (answersArray.length === 1 &&
        answersArray[0] === 'other' &&
        !otherTextRef.current)
    ) {
      toast({
        title: t('session_review_select_at_least_one_or_fill_other'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      return
    }
    try {
      setSubmitting(true)
      await postSessionUserFeedback({
        eventId,
        rating,
        answers: Array.from(answers),
        other: otherTextRef.current,
      }).unwrap()

      toast({
        title: t('thanks_for_session_feedback'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (err) {
      toast({
        title: t('session_review_submit_error'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    } finally {
      setSubmitting(false)
      setIsRedirecting(true)
      setTimeout(() => {
        window.location.href = redirectPage
      }, REDIRECT_SECONDS * 1000)
    }
  }

  const onItemSelected = (item: any) => {
    const options = new Set(answers)
    if (options.has(item)) {
      options.delete(item)
    } else {
      options.add(item)
    }
    setAnswers(options)
  }

  const redirectTitle = user ? t('redirect_to_home') : t('redirect_to_landing')
  const redirectPage = user ? HOME_PAGE : LANDING_PAGE

  if (isRedirecting) {
    return (
      <Center h="200px" color="white">
        <Heading color="gray.500">{redirectTitle}</Heading>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Center>
    )
  }

  const renderOption = (child: string) => {
    if (child === 'other') {
      return (
        <Box maxW={{ lg: '60%' }} mx="auto">
          <Text>
            {t(
              isFirstSession
                ? 'other_feedback_label_first_session'
                : 'other_feedback_label_multiple_sessions'
            )}
          </Text>
          <Textarea
            fontSize={['sm', 'md', 'lg']}
            placeholder={t('other_feedback')}
            borderColor="gray.200"
            borderWidth="1px"
            borderRadius="md"
            width="360px"
            height="100px"
            resize="none"
            onChange={(e) => {
              otherTextRef.current = e.target.value
            }}
          />
        </Box>
      )
    }

    return (
      <SelectOption
        title={t(`${child}`)}
        value={child}
        selected={answers}
        onItemSelected={onItemSelected}
      />
    )
  }

  return (
    <Box flexDirection={'column'} textAlign="center" px={6} pb={10}>
      <Flex
        px={{ base: 4, md: 4 }}
        height="20"
        borderBottomWidth="1px"
        alignItems="center"
        justifyContent="center"
      >
        <Logo md={5} />
      </Flex>

      <Heading display="inline-block" as="h5" size="lg" pt={10}>
        {isFirstSession
          ? t('first_session_review_title', { coach: coachName })
          : t('session_review_title', { coach: coachName })}
      </Heading>
      {!isFirstSession && (
        <Box display="flex" justifyContent="center">
          <Rating
            onClick={onSetStarRating}
            size={50}
            transition
            SVGstyle={{ display: 'inline-block' }}
          />
        </Box>
      )}

      {!isLoading && (
        <Box mt={4}>
          <Box display="flex" justifyContent="center" alignItems="center">
            {rating >= 1 && rating <= 5 && !isFirstSession ? (
              <Box style={{ alignItems: 'center' }}>
                <Text
                  as="h6"
                  fontSize="lg"
                  fontWeight="bold"
                  textAlign="center"
                  mb={2}
                >
                  {getFollowUpQuestionTitle(rating)}
                </Text>
                {/* <Text>
                  {t('select_all_options_that_apply')}
                </Text> */}
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  {optionsHandler(rating).map((option) => renderOption(option))}
                </Box>
              </Box>
            ) : null}

            {isFirstSession && (
              <Box mx="auto">
                {optionsHandler(6).map((option) => renderOption(option))}
              </Box>
            )}
          </Box>

          <Button
            hidden={answers.size === 0 && otherTextRef.current === ''}
            mt="10"
            w={{ lg: '30%', sm: '90%' }}
            colorScheme="primary"
            onClick={onSubmit}
            disabled={submitting}
          >
            Submit
          </Button>
        </Box>
      )}

      {isLoading && (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      )}
    </Box>
  )
}

export default PostSessionUserFeedback
