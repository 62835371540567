import { Link } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import Linkify from 'react-linkify'

export const parseText = (text: string) => {
  if (!text) {
    return text
  }
  const textList = text.split('\n')
  const result = textList.map((str: string) => {
    return (
      <Linkify
        componentDecorator={(href, text, key) => (
          <Link href={href} key={key} isExternal>
            {text}
            <ExternalLinkIcon mx="2px" mb="4px" />
          </Link>
        )}
      >
        {str}
        <br />
      </Linkify>
    )
  })
  return result
}

export const parseFileSize = (size: number) => {
  const fileSize = size / 1000
  if (fileSize < 1000) {
    return `${fileSize.toFixed(2)} KB`
  } else {
    return `${(fileSize / 1000).toFixed(2)} MB`
  }
}

export const parseFileType = (name: string) => {
  const fileType = name && name.split('.').pop()
  if (fileType) {
    return fileType.toUpperCase()
  }
  return 'unknown'
}
