import { Stack, useToast } from '@chakra-ui/react'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState, store } from '../../../app/store'
import { useLocale } from '../../../hooks/useLocale'
import OnboardingFooter from '../components/OnboardingFooter'
import OnboardingHeader from '../components/OnboardingHeader'
import { itemSelect } from '../onboardingSlice'
import OnboardingContainer from '../OnboardingContainer'
import TitleDescription from './TitleDescription'
import { TextField } from '../../../utils/fields'
import { hasWords } from '../../../utils/text'

const InputTextQuestion: FC<any> = ({
  index,
  onSubmit,
  submitting = false,
}) => {
  const { t } = useLocale()
  const onboardingQuestions = useSelector(
    (state: RootState) => state.onboarding.questions
  )
  const latestAnswers: any = store.getState().onboarding?.answers
  const question = onboardingQuestions[index]
  const isLast = index === onboardingQuestions.length - 1

  const [value, setValue] = useState(latestAnswers[question.friendlyID] ?? '')

  const dispatch = useDispatch()
  const toast = useToast()

  const onLocalSubmit = () => {
    if (value && hasWords(value)) {
      dispatch(
        itemSelect({
          questionId: question.friendlyID,
          answers: value,
        })
      )
      onSubmit()
    } else {
      toast({
        status: 'error',
        title: 'Error',
        description: t('please_enter_value_field'),
        isClosable: true,
      })
    }
  }

  return (
    <>
      <OnboardingHeader
        currentStep={index}
        totalSteps={onboardingQuestions.length - 1}
      />
      <OnboardingContainer>
        <Stack gridRowGap={1} py={10} px={2} maxWidth={600}>
          <TitleDescription
            title={question.title}
            description={question.description}
          />
          <TextField
            placeholder={question.placeholder}
            onChange={(event: any) => setValue(event.target.value)}
            onSubmit={onLocalSubmit}
            value={value}
            data-testid="onboarding-input-text"
          />
        </Stack>
        <OnboardingFooter
          onSubmit={onLocalSubmit}
          submitText={t('next')}
          submitting={submitting}
          isLastStep={isLast}
        />
      </OnboardingContainer>
      <div
        data-testid="onboarding-flow-test-hidden"
        data-onboarding-flow-test-type={question.type}
        data-onboarding-flow-test-index={index}
        data-onboarding-flow-test-id={question.friendlyID}
        data-onboarding-flow-test-islast={isLast}
        style={{ display: 'none' }}
      />
    </>
  )
}

export default InputTextQuestion
