import {
  Box,
  Button,
  Center,
  Flex,
  FlexProps,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { MdChat, MdDateRange } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import moment from 'moment-timezone'
import { LateModifyAlert } from './features/call/components/LateModifyAlert'

import { APP_NAME } from './app/constants'
import {
  useGetBookingConfigQuery,
  useGetEventMutation,
  useLazyGetCoachBioQuery,
} from './app/services/api'
import CoachInfo from './features/coaches/CoachInfo'
import CoachAvatar from './features/profile/CoachAvatar'
import Book from './features/scheduler/Book'
import UnifiedCallLink from './features/scheduler/UnifiedCallLink'
import { useLocale } from './hooks/useLocale'
import BookButton from './utils/BookButton'
import { useMixpanel } from './utils/MixpanelContext'
import { parseEventDate, parseEventTime } from './utils/date'

interface Props extends FlexProps {
  user: any
  usersCoach: any
}

export const CardBooking = ({ event, user }: any) => {
  const { t } = useLocale()
  return (
    <Box textAlign={'center'} color={useColorModeValue('gray.700', 'gray.400')}>
      <Stack direction={'row'} justify={'center'} spacing={0}>
        <Stack spacing={0} align={'center'}>
          <Flex alignItems="center">
            <Text fontSize="23px">
              <MdDateRange />
            </Text>
            <Text fontSize="sm" pl={2} mr={5}>
              {parseEventDate(user, event.start)}
            </Text>
            <Text fontSize="23px">
              <AiOutlineClockCircle />
            </Text>
            <Text fontSize="sm" pl={2}>
              {parseEventTime(user, event.start, event.end)}
            </Text>
          </Flex>
          <Box pt={3}>
            {event.managerInvited && (
              <Text fontSize="md" pb={4}>
                ({t('manager_invited')})
              </Text>
            )}
            <UnifiedCallLink
              buttonSize={'md'}
              event={event}
              isCoach={false}
              referrer={'app-booking-card'}
            />
          </Box>
        </Stack>
      </Stack>
    </Box>
  )
}

export default function CardCoach({ user, usersCoach }: Props) {
  const { t } = useLocale()
  const navigate = useNavigate()
  const [getEvent] = useGetEventMutation()
  const [triggerGetCoachBio, { data: coachBio }] = useLazyGetCoachBioQuery()
  const [latestEvent, setLatestEvent] = useState<any>(null)
  const [coach, setCoach] = useState<any>(null)
  const toast = useToast()
  const mixpanel = useMixpanel()
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure()
  const {
    isOpen: isCoachBioOpen,
    onOpen: onCoachBioOpen,
    onClose: onCoachBioClose,
  } = useDisclosure()
  const {
    isOpen: isLateModifyingAlertOpen,
    onOpen: onLateModifyingAlertOpen,
    onClose: onLateModifyingAlertClose,
  } = useDisclosure()

  const getLatestEvent = useCallback(async () => {
    const date = new Date().toISOString()
    const response: any = await getEvent({ date })
    if (response && response.data) {
      setLatestEvent(response.data)
    }
  }, [getEvent])
  const { data: bookingConfig } = useGetBookingConfigQuery('config')

  useEffect(() => {
    if (user) {
      triggerGetCoachBio()
    }
  }, [user, triggerGetCoachBio])

  useEffect(() => {
    getLatestEvent()
    // Send home_screen_open event to mixpanel
    mixpanel.track('home_screen_open')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (usersCoach) setCoach(usersCoach)
  }, [usersCoach])

  const isLateModifying = useCallback(() => {
    if (!bookingConfig?.config || !latestEvent) return false

    return (
      bookingConfig.config.policyCancellationHours >
      moment(new Date(latestEvent.start)).diff(moment(), 'hours')
    )
  }, [bookingConfig?.config, latestEvent])

  const onClickChat = useCallback(() => {
    navigate(`/chat`)
  }, [navigate])
  const onClickBook = useCallback(() => {
    navigate(`/book`)
  }, [navigate])

  const onEventUpdate = useCallback(async () => {
    await onModalClose()
    getLatestEvent()
    toast({
      status: 'success',
      title: t('success'),
      description: t('the_session_is_successfully_updated'),
      isClosable: true,
    })
  }, [getLatestEvent, onModalClose, toast, t])

  useEffect(() => {
    if (isCoachBioOpen) {
      mixpanel.track('coach_bio_opened')
    }
  }, [isCoachBioOpen, mixpanel])

  const onClickReschedule = useCallback(() => {
    if (isLateModifying()) {
      onLateModifyingAlertOpen()
    } else {
      onModalOpen()
    }
  }, [onModalOpen, isLateModifying, onLateModifyingAlertOpen])

  return (
    <Center>
      <Box
        transition={'.2s'}
        _hover={{ shadow: 'lg' }}
        w={'full'}
        bg={'white'}
        rounded={'lg'}
        p={6}
        textAlign={'center'}
      >
        <Flex
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            textAlign={{
              base: 'right',
              sm: 'center',
              md: 'right',
              lg: 'right',
            }}
            verticalAlign="center"
          >
            {coach?.profile?.picture && (
              <CoachAvatar
                src={coach?.profile.picture}
                hideUpload
                videoUrl={coach?.profile.video}
                email={coach?.email}
              />
            )}
          </Box>
          <Link onClick={onCoachBioOpen}>
            <Box
              as={'header'}
              textAlign={{
                base: 'left',
                sm: 'center',
                md: 'left',
                lg: 'left',
              }}
              verticalAlign="center"
            >
              <Heading textAlign="center" fontSize={'2xl'} fontFamily={'body'}>
                {coach?.profile && coach?.profile.name}
              </Heading>
              <Text
                textAlign="center"
                fontWeight={600}
                color={'gray.500'}
                mb={4}
              >
                {t('coach')} @ {APP_NAME}
              </Text>
            </Box>
          </Link>
        </Flex>

        {latestEvent && (
          <>
            <Text fontWeight={600} mb={2}>
              {t('next_call')}
            </Text>
            <CardBooking user={user} event={latestEvent} />
          </>
        )}

        <Stack mt={8} direction={'row'}>
          <Button
            leftIcon={<MdChat />}
            onClick={onClickChat}
            flex={1}
            fontWeight={400}
            fontSize={'md'}
            bg={'primary2.500'}
            color={'white'}
            border="1px"
            _hover={{
              bg: 'primary2.200',
              color: 'primary2.400',
            }}
            _active={{
              bg: 'primary2.400',
              color: 'white',
            }}
            _focus={{
              bg: 'primary2.700',
            }}
          >
            {t('chat')}
          </Button>
          <BookButton
            event={latestEvent}
            onClickBook={onClickBook}
            onClickReschedule={onClickReschedule}
          />
        </Stack>
      </Box>
      <LateModifyAlert
        isOpen={isLateModifyingAlertOpen}
        onClose={onLateModifyingAlertClose}
        type={'reschedule'}
        onResume={() => {
          onModalOpen()
          onLateModifyingAlertClose()
        }}
      />
      <Modal isOpen={isModalOpen} onClose={onModalClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('reschedule')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Book
              user={user}
              coach={coach}
              event={latestEvent}
              onEventUpdate={onEventUpdate}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <CoachInfo
        isOpen={isCoachBioOpen}
        onClose={onCoachBioClose}
        coach={coachBio}
      />
    </Center>
  )
}
