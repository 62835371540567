import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Box,
  Center,
  Flex,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'

import { Logo } from '../../utils/Logo'
import ProgressComponent from '../onboarding/components/ProgressLine'
import {
  useAnswerSurveyQuestionMutation,
  useGetSurveyQuery,
  usePostSurveyAnswersMutation,
} from '../../app/services/api'
import FeedbackSurveyQuestionsStep from './FeedbackSurveyQuestionsStep'
import { setSurveyData, setDefaultAnswers } from './surveySlice'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import { useLocale } from '../../hooks/useLocale'

export const FeedbackSurvey: React.FC = () => {
  const { sessionId } = useParams<any>()
  const { t } = useLocale()
  const dispatch = useDispatch()

  const boxBg = useColorModeValue('gray.50', 'gray.800')
  const flexBg = useColorModeValue('white', 'gray.900')
  const borderColor = useColorModeValue('gray.200', 'gray.700')

  const {
    data: surveySession,
    isLoading: isFetching,
    isError,
    error,
  } = useGetSurveyQuery(sessionId)
  const [answerSurveyQuestion, { isLoading }] =
    useAnswerSurveyQuestionMutation()

  const [submit] = usePostSurveyAnswersMutation()
  const { surveyData, questions } = useSelector(
    (state: RootState) => state.survey
  )

  const [survey, setSurvey] = useState<any>(null)
  const [step, setStep] = useState(1)
  const [totalSteps, setTotalSteps] = useState(2)
  const [isSinglePage, setIsSinglePage] = useState(true)

  const submitSinglePage = async () => {
    await sendSinglePageAnswers()
    submit({ sessionId })
    setStep(totalSteps)
  }

  const sendSinglePageAnswers = useCallback(async () => {
    if (!isSinglePage) {
      return
    }

    for (const questionId in questions) {
      const answer = questions[questionId]
      if (
        !answer ||
        (answer.answers.length === 0 && answer.otherAnswer === '')
      ) {
        continue
      }
      await answerSurveyQuestion({
        sessionId,
        questionId,
        answers: answer.answers,
        otherAnswer: answer.other,
      })
    }
  }, [isSinglePage, questions, sessionId, answerSurveyQuestion])

  const onProceedNextStep = (newStep = 1) => {
    setStep((prev) => prev + newStep)
    if (newStep < 0 || isSinglePage) {
      return
    }
    const currentQuestionIndex = step - 1
    const currentQuestion = surveyData?.questions[currentQuestionIndex] || {}
    const answer = questions[currentQuestion._id]

    if (answer) {
      answerSurveyQuestion({
        sessionId,
        questionId: currentQuestion._id,
        answers: answer.answers,
        otherAnswer: answer.other,
      })
    }
  }

  useEffect(() => {
    if (!isSinglePage || step >= totalSteps) {
      return
    }
    const answers = Object.values(questions)

    const interval = setInterval(() => {
      if (answers.length > 0) {
        sendSinglePageAnswers()
      }
    }, 5000)

    return () => clearInterval(interval)
  }, [
    step,
    totalSteps,
    isSinglePage,
    questions,
    sessionId,
    answerSurveyQuestion,
    sendSinglePageAnswers,
  ])

  useEffect(() => {
    if (
      isError ||
      !survey ||
      !survey.questions ||
      survey.questions.length === 0
    ) {
      return
    }
    const totalSteps = survey.questions.length + 1
    setIsSinglePage(survey.singlePage || false)
    setTotalSteps(totalSteps)

    if (survey.singlePage) {
      setStep(1)
    }

    if (surveySession?.status === 'COMPLETED') {
      setStep(totalSteps)
    }
    dispatch(setSurveyData(survey))
  }, [dispatch, surveySession, survey, isError])

  useEffect(() => {
    if (
      !isSinglePage &&
      step === totalSteps &&
      !isLoading &&
      !isError &&
      sessionId !== ''
    ) {
      submit({ sessionId })
    }
  }, [
    step,
    totalSteps,
    questions,
    sessionId,
    submit,
    isLoading,
    isError,
    isSinglePage,
  ])

  useEffect(() => {
    if (!isFetching && !isError) {
      setSurvey(surveySession.survey)
      const answers: any = {}
      for (const a of surveySession.answers) {
        answers[a.question] = {
          answers: a.answers.map((i: any) => i._id),
          other: a.otherAnswer,
        }
      }
      dispatch(setDefaultAnswers(answers))

      if (isSinglePage) {
        return
      }

      const currentQuestion = surveySession.survey.questions.findIndex(
        (q: any) => q._id === surveySession.currentQuestion
      )
      const nextQuestion = currentQuestion < 0 ? 1 : currentQuestion + 2

      if (Object.keys(answers).length > 0) {
        setStep(nextQuestion)
      }
    }
  }, [dispatch, isFetching, isError, surveySession, isSinglePage])

  if (isError) {
    const message = (error as any)?.data?.message
    const is404 = (error as any)?.status === 404
    const invalidId = typeof message === 'object' && 'id' in message

    return (
      <Center flexDirection="column" gridGap={5} h="89vh">
        <Heading as="h2" size="2xl">
          {t('an_error_occurred')}
        </Heading>
        <Text fontSize="lg">
          {invalidId && !is404 && t('survey.invalid_id')}
          {!invalidId && is404 && t('survey.not_found')}
        </Text>
      </Center>
    )
  }

  return (
    <Box
      flexDirection={'column'}
      flex={1}
      overflow="auto"
      bg={boxBg}
      minH="100vh"
    >
      <Flex
        px={{ base: 4, md: 4 }}
        height="20"
        bg={flexBg}
        borderBottomWidth="1px"
        borderBottomColor={borderColor}
        alignItems="center"
        justifyContent={{ base: 'space-between', md: 'center' }}
      >
        <Logo md={5} />
      </Flex>
      {!isSinglePage && (
        <ProgressComponent currentPage={step} totalPages={totalSteps} />
      )}
      {isFetching && <div>{t('loading')}</div>}
      {step >= totalSteps ? (
        <Center flexDirection="column" gridGap={5} h="89vh">
          <Heading as="h2" size="2xl">
            {t('survey.thankYou')}
          </Heading>
          <Text fontSize="lg">{t('survey.thankYouDescription')}</Text>
        </Center>
      ) : (
        surveyData?.questions && (
          <FeedbackSurveyQuestionsStep
            key={`step#${step}`}
            isSinglePage={isSinglePage}
            step={step}
            canGoBack={step > 1}
            onProceedNextStep={onProceedNextStep}
            submitSinglePage={submitSinglePage}
          />
        )
      )}
    </Box>
  )
}
