import {
  Box,
  Button,
  Center,
  Divider,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import moment from 'moment'
import { FC, useRef, useState } from 'react'
import { BsInfoCircleFill } from 'react-icons/bs'

import empty from '../../assets/img/empty.png'
import { useGetAssessmentResultsQuery } from '../../app/services/api'
import { useAuth } from '../../hooks/useAuth'
import { useLocale } from '../../hooks/useLocale'
import { AssessmentItem } from '../../types/api'
import { leadershipValueScaleSelf, ratingScale } from '../../utils/ratings'

interface IRenderResult {
  result?: AssessmentItem[]
  onSelect: any
  useLeadershipValues: boolean
}

const RenderResult: FC<IRenderResult> = ({
  result,
  onSelect,
  useLeadershipValues = false,
}: any) => {
  const { t } = useLocale()

  const scale: any = useLeadershipValues
    ? leadershipValueScaleSelf(t)
    : ratingScale(t)
  return !result ? (
    <Box
      display="flex"
      alignItems={'center'}
      justifyContent={'center'}
      flexDirection="column"
      minHeight={200}
      bg="white"
      py={20}
    >
      <Image
        maxWidth="250px"
        src={empty}
        alt="empty"
        userSelect="none"
        draggable={false}
        onDrag={(e) => e.preventDefault()}
      />
      <Text
        userSelect="none"
        draggable={false}
        onDrag={(e) => e.preventDefault()}
        fontSize={20}
        mt={5}
        textAlign="center"
      >
        {' '}
        {t('assessmentResultEmpty')}{' '}
      </Text>
    </Box>
  ) : (
    result.map((assessment: any) => (
      <Box p={6} bg="white" rounded={20} shadow="sm" mb={4}>
        <Text fontSize="18px" fontWeight="600">
          {moment(assessment.assessmentDate).format('MMMM DD , YYYY')}
        </Text>
        <Divider my={3} />
        {assessment.ratings?.length > 0 && (
          <Box mb={10}>
            <Text fontWeight="700" fontSize={22}>
              {t('ratings')}
            </Text>
            <Box mt={4}>
              {assessment.ratings.map((rating: any, index: number) => (
                <Box mb={2} key={`rating_${index}`}>
                  <Text fontSize="16px" fontWeight="600">
                    {rating.focusArea}
                  </Text>
                  <Text fontSize={16} pl={3}>
                    - {rating.rating} / 5 (
                    {useLeadershipValues
                      ? scale[rating.rating - 1]?.level
                      : scale[rating.rating - 1]?.level.split(' - ')[1]}
                    )
                    {!useLeadershipValues && (
                      <Button
                        p={0}
                        ml={5}
                        borderRadius="100px"
                        minWidth="auto"
                        width="fit-content"
                        height="fit-content"
                        onClick={() => onSelect(scale[rating.rating - 1])}
                      >
                        <BsInfoCircleFill fontSize={20} />
                      </Button>
                    )}
                  </Text>
                </Box>
              ))}
            </Box>
          </Box>
        )}
        <Box>
          <Text fontWeight="700" fontSize={22}>
            {t('answers')}
          </Text>
          <Box mt={4}>
            {assessment.surveyAnswers.map((answer: any, index: number) => (
              <Box mb={2} key={`answer_${index}`}>
                <Text fontSize="16px" fontWeight="600">
                  {answer.question}
                </Text>
                <Box>
                  {answer.answers.map(
                    (answerItem: any, answerIndex: number) => (
                      <Text
                        fontSize={16}
                        pl={3}
                        key={`${answerItem}_${answerIndex}_${index}`}
                      >
                        - {answerItem}
                      </Text>
                    )
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    ))
  )
}

interface ISelectedItem {
  scale: number
  level: string
  description: string[]
}

const AssessmentResults: FC = () => {
  const { user } = useAuth()
  if (!user) throw new Error('User not found')
  const { data, isFetching } = useGetAssessmentResultsQuery(user._id)
  const [selectedItem, setSelectedItem] = useState<ISelectedItem | null>(null)
  const { t } = useLocale()
  const finalRef = useRef<any>(null)

  const hasManagerAssessment = !!data?.manager?.length
  const hasPeerAssessment = !!data?.peers?.length
  const useLeadershipValues = !!user?.values?.length

  return isFetching ? (
    <Center>
      {' '}
      <Spinner />{' '}
    </Center>
  ) : (
    <Box bg="white" borderRadius={'10px'}>
      <Tabs isFitted colorScheme="primary">
        <TabList>
          {hasManagerAssessment && <Tab>{t('manager')}</Tab>}
          {hasPeerAssessment && <Tab>{t('peers')}</Tab>}
          <Tab>{t('self')}</Tab>
        </TabList>
        <TabPanels>
          {hasManagerAssessment && (
            <TabPanel px={0}>
              <RenderResult
                onSelect={(item: ISelectedItem) => setSelectedItem(item)}
                result={data?.manager}
                useLeadershipValues={useLeadershipValues}
              />
            </TabPanel>
          )}
          {hasPeerAssessment && (
            <TabPanel px={0}>
              <RenderResult
                onSelect={(item: ISelectedItem) => setSelectedItem(item)}
                result={data?.peers}
                useLeadershipValues={useLeadershipValues}
              />
            </TabPanel>
          )}
          <TabPanel px={0}>
            <RenderResult
              onSelect={(item: ISelectedItem) => setSelectedItem(item)}
              result={data?.user}
              useLeadershipValues={useLeadershipValues}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Modal
        isCentered
        finalFocusRef={finalRef}
        isOpen={!!selectedItem}
        onClose={() => setSelectedItem(null)}
      >
        <ModalOverlay />
        {selectedItem && (
          <ModalContent>
            <ModalHeader> {selectedItem.level} </ModalHeader>
            <Divider />
            <ModalBody>
              {selectedItem.description.map((item) => (
                <Text fontSize="18px" key={item}>
                  {' '}
                  - {item}{' '}
                </Text>
              ))}
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => setSelectedItem(null)}
                colorScheme="primary"
              >
                {' '}
                {t('close')}{' '}
              </Button>
            </ModalFooter>
          </ModalContent>
        )}
      </Modal>
    </Box>
  )
}

export default AssessmentResults
