import {
  Box,
  Divider,
  Grid,
  Spinner,
  Text,
  useDisclosure,
  useToast,
  Wrap,
} from '@chakra-ui/react'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useGetOnboardingCoachOptionsQuery,
  useMatchWithCoachMutation,
} from '../../app/services/api'
import { useAuth } from '../../hooks/useAuth'
import CoachInfo from '../coaches/CoachInfo'
import { CoachSelectCard } from '../onboarding/questionTypes/CoachSelectionQuestion'

const ChangeCoach: React.FC = () => {
  const { user } = useAuth()
  const usersCoach = useMemo(() => user?.coach, [user])
  const params: any = {
    email: user?.email,
    companyId: user?.company._id,
    language: user?.language,
    departmentId: user?.department?._id,
  }
  const {
    data: coaches,
    isFetching,
    isError,
  } = useGetOnboardingCoachOptionsQuery(params)

  const [currentCoach, setCurrentCoach] = useState<any>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { t } = useTranslation()
  const [matchWithCoach] = useMatchWithCoachMutation()
  const toast = useToast()
  const handleSubmit = useCallback(
    async (coach: any) => {
      if (usersCoach && coach.coach._id === usersCoach._id) {
        toast({
          status: 'error',
          title: 'Error',
          description: t('coach_already_selected'),
          isClosable: true,
          duration: 3000,
        })
      } else {
        try {
          await matchWithCoach({ coachId: coach.coach._id }).unwrap()
          toast({
            status: 'success',
            title: 'Success',
            description: t('coach_updated'),
            isClosable: true,
            duration: 3000,
          })
          window.location.href = '/'
        } catch (error: any) {
          toast({
            status: 'error',
            title: 'Error',
            description: t('coach_update_error'),
            isClosable: true,
            duration: 3000,
          })
        }
      }
    },
    [matchWithCoach, t, toast, usersCoach]
  )

  return (
    <Grid gap="10px" bg={'white'} rounded={'lg'}>
      {isOpen && (
        <CoachInfo isOpen={isOpen} onClose={onClose} coach={currentCoach} />
      )}
      {isFetching ? (
        <Spinner />
      ) : isError ? (
        <Text>{t('there_was_an_error_while_loading_coaches')}</Text>
      ) : (
        <>
          <Box w={'full'} px={6} pt={4} pb={2}>
            <Text fontSize="22px" fontWeight="700">
              {t('select_coach')}
            </Text>
          </Box>
          <Divider />
          {/* <Grid
              gridGap="20px"
              gridTemplateColumns={[
                '1fr',
                '1fr',
                '1fr',
                '1fr 1fr',
                '1fr 1fr 1fr',
              ]}
            > */}

          <Wrap spacing="30px" justify="center">
            {coaches.map(
              (coachBio: any) =>
                coachBio.coach._id !== usersCoach?._id && ( // do not my coach
                  <CoachSelectCard
                    coachBio={coachBio}
                    key={coachBio._id}
                    onReadMore={(coach: any) => {
                      setCurrentCoach(coach)
                      onOpen()
                    }}
                    onSelect={handleSubmit}
                  />
                )
            )}
          </Wrap>
        </>
      )}
    </Grid>
  )
}

export default ChangeCoach
