import { createSlice } from '@reduxjs/toolkit'

export interface OnboardingState {
  questions: any[]
  steps: any[]
  answers: any
  companyId: any
  departmentId: any
  language: any
  email: any
  name: any
  password: any
  timezone: any
  preselectedCoachEmail: any
}

const initialState: OnboardingState = {
  questions: [],
  steps: [],
  answers: {},
  companyId: null,
  departmentId: null,
  language: null,
  email: null,
  name: null,
  password: null,
  timezone: null,
  preselectedCoachEmail: null,
}

const slice = createSlice({
  name: 'onboarding',
  initialState: initialState,
  reducers: {
    setOnboardingQuestions: (state, { payload }) => {
      console.log('setOnboardingQuestions payload:', payload)
      state.questions = payload.questions
      state.steps = payload.steps
    },
    itemSelect: (state, { payload }) => {
      if (payload.otherAnswer) {
        state.answers[payload.questionId] = {
          other: payload.otherAnswer,
          answers: payload.answers,
        }
      } else {
        state.answers[payload.questionId] = payload.answers
      }
      console.log('itemSelect(after) payload:', payload)
    },
    setUserEmail: (state, { payload }) => {
      console.log('setUserEmail payload:', payload)
      state.email = payload
    },
    clearOnboardingData: () => initialState,
    setName: (state, { payload }) => {
      console.log('setName payload:', payload)
      state.name = payload
    },
    setPassword: (state, { payload }) => {
      state.password = payload
    },
    setTimezone: (state, { payload }) => {
      console.log('setTimezone payload:', payload)
      state.timezone = payload
    },
    setCompanyId: (state, { payload }) => {
      console.log('setCompanyId payload:', payload)
      state.companyId = payload
    },
    setDepartmentId: (state, { payload }) => {
      console.log('setDepartmentId payload:', payload)
      state.departmentId = payload
    },
    setLanguage: (state, { payload }) => {
      console.log('setLanguage payload:', payload)
      state.language = payload
    },
    setPreselectedCoachEmail: (state, { payload }) => {
      console.log('setPreselectedCoachEmail payload:', payload)
      state.preselectedCoachEmail = payload
    },
  },
})

export default slice.reducer

export const {
  itemSelect,
  clearOnboardingData,
  setUserEmail,
  setName,
  setPassword,
  setTimezone,
  setCompanyId,
  setDepartmentId,
  setLanguage,
  setOnboardingQuestions,
  setPreselectedCoachEmail,
} = slice.actions
