import { useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePostHog } from 'posthog-js/react'
import { useToast } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import {
  EnvelopeIcon,
  ExclamationCircleIcon,
  TrashIcon,
} from '@heroicons/react/20/solid'

import { NextButton } from './NextButton'
import { useOnboardingContext } from '~/context/onboardingContext'

type Props = {
  id: string
  min?: number
  max?: number
}

const schema = z.object({
  emails: z.array(z.object({ value: z.string().email() })),
})

type FormValues = z.infer<typeof schema>

export const Emails: React.FC<Props> = (props) => {
  const { id, min = 1, max = -1 } = props
  const { t } = useTranslation()
  const posthog = usePostHog()
  const toast = useToast()
  const { postOnboardingInfo, currentQuestionIndex } = useOnboardingContext()

  const {
    control,
    register,
    formState: { isValid, errors },
    handleSubmit,
    setValue,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
  })

  useEffect(() => {
    if (min === 0) {
      setValue('emails', [{ value: '' }])
    } else {
      setValue(
        'emails',
        Array.from({ length: min }).map(() => ({ value: '' }))
      )
    }
  }, [setValue, min])

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'emails',
    rules: { required: true },
  })

  const onSubmit = handleSubmit((data) => {
    const values = data.emails

    if (values.length < min) {
      return toast({
        title: 'Error',
        description: t('min_emails_required', { minEmailsCount: min }),
        status: 'error',
        isClosable: true,
      })
    }

    postOnboardingInfo({
      index: currentQuestionIndex,
      answers: { [id]: values.map((v) => v.value).join(',') },
    })
    posthog.capture('onboarding_emails_input', { questionId: id })
  })

  return (
    <>
      <div className="w-3/4">
        <div className="full">
          {fields.map((field, index) => (
            <div key={index}>
              <label htmlFor={field.id} className="sr-only">
                Email
              </label>
              <div className="mt-2 flex rounded-md shadow-sm">
                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <EnvelopeIcon
                      aria-hidden="true"
                      className="h-5 w-5 text-gray-400"
                    />
                  </div>
                  <input
                    key={field.id}
                    type="email"
                    placeholder={`Colleague #${index + 1}'s email`}
                    className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                    {...register(`emails.${index}.value` as const)}
                  />
                  {errors.emails?.length && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <ExclamationCircleIcon
                        aria-hidden="true"
                        className="h-5 w-5 text-red-500"
                      />
                    </div>
                  )}
                </div>
                <button
                  type="button"
                  className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={() => remove(index)}
                >
                  <TrashIcon
                    aria-hidden="true"
                    className="-ml-0.5 h-5 w-5 text-gray-400"
                  />
                </button>
              </div>
              {/* {errors.emails?.length && (
                <p className="mt-2 text-sm text-red-600">
                  Not a valid email address.
                </p>
              )} */}
            </div>
          ))}
        </div>

        {(fields.length < max || max === -1) && (
          <button
            type="button"
            className="rounded-md mt-3 w-full bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={() => append({ value: '' })}
          >
            Add email
          </button>
        )}
      </div>

      <NextButton disabled={!isValid} onClick={onSubmit} />
    </>
  )
}
