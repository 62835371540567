import { OutlinedInput } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PeerAssessmentValue } from '../../../types/api'
import { leadershipValueScalePeer } from '../../../utils/ratings'
import { QuestionProps } from '../components/PeerAssessmentArea'
import { Animated } from './components/Animated'
import { QuestionTitle } from './components/QuestionHeader'
import { ValueScoreList } from './components/question-types.style'
import { styled } from '@mui/material/styles'

const ValueScoreQuestionWrapper = styled('div')(() => ({
  margin: '0 auto',
  display: 'grid',
  gap: '12px',
  marginTop: '28px',
  '@media (max-width: 600px)': {
    padding: '0 24px',
  },
}))

const ValueScoreOutlinedInput = styled(OutlinedInput)(() => ({
  width: '600px',
  margin: '0 auto',
  '@media (max-width: 600px)': {
    width: '100%',
  },
}))

export function ValueScoreQuestion({
  data,
  user,
  answers,
  name,
  setAnswers,
  values,
}: QuestionProps & { name: string; values: PeerAssessmentValue[] }) {
  const { t } = useTranslation()
  const options = leadershipValueScalePeer(t)
  const leadershipValue = values.find((item) => item.value === name)

  return (
    <>
      <QuestionTitle
        data={{
          ...data,
          description: leadershipValue?.label || '',
          info: leadershipValue?.descriptionHtml ?? leadershipValue?.description,
        }}
        highlightDescription={true}
        user={user}
      />
      <Animated key={data.friendlyID}>
        <ValueScoreList>
          {options.map((item) => {
            return (
              <div
                className={answers.ratings[name] === item.scale ? 'active' : ''}
                onClick={() =>
                  setAnswers({
                    ...answers,
                    ratings: {
                      ...answers.ratings,
                      [name]: item.scale,
                    },
                  })
                }
                key={item.level}
              >
                {item.level}
              </div>
            )
          })}
        </ValueScoreList>
        <ValueScoreQuestionWrapper>
          <ValueScoreOutlinedInput
            multiline
            minRows={3}
            placeholder={t('rating_comments_placeholder')}
            value={answers.ratingComments[name] ?? ''}
            onChange={(event) => {
              setAnswers({
                ...answers,
                ratingComments: {
                  ...answers.ratingComments,
                  [name]: event.target.value,
                },
              })
            }}
          />
        </ValueScoreQuestionWrapper>
      </Animated>
    </>
  )
}
