import type { ConfiguredMiddleware } from 'wretch'
import Cookies from 'js-cookie'
import wretch from 'wretch'
import QueryStringAddon from 'wretch/addons/queryString'

const authMiddleware: ConfiguredMiddleware = (next) => (url, opts) => {
  const token = Cookies.get('token')

  if (token) {
    opts.headers = {
      ...opts.headers,
      Authorization: `Bearer ${token}`,
    }
  }

  return next(url, opts)
}

export const rawApi = wretch(import.meta.env.VITE_API_ENDPOINT.replace(/\/$/, ''))

export const api = rawApi
  .addon(QueryStringAddon)
  .middlewares([authMiddleware])
