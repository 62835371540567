import { useMemo, useState } from 'react'
import { Navigate, useOutletContext, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { InformationCircleIcon } from '@heroicons/react/24/outline'

import type { Response } from './assessment-results.loader'
import { leadershipValueScaleSelf, ratingScale } from '~/utils/ratings'
import { AlertDialog } from '~/components/alert-dialog'
import { useAppSelector } from '~/app/hooks'

export const AssessmentResultsTab: React.FC = () => {
  const data = useOutletContext() as Response
  const { t } = useTranslation()
  const { tab } = useParams<{ tab: keyof Response }>()
  const [showInfo, setShowInfo] = useState(false)
  const user = useAppSelector((state) => state.auth.user)
  const [selectedRating, setSelectedRating] = useState<
    (typeof scale)[number] | null
  >(null)

  const useLeadershipValues = useMemo(
    () => typeof user?.values?.length === 'number' && user.values.length > 0,
    [user]
  )
  const scale = useMemo(
    () => (useLeadershipValues ? leadershipValueScaleSelf(t) : ratingScale(t)),
    [t, useLeadershipValues]
  )

  if (!tab || !data[tab]) {
    return <Navigate to=".." />
  }

  return (
    <>
      {data[tab].map((result) => (
        <div
          key={result.assessmentDate}
          className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow"
        >
          <div className="px-4 py-5 sm:px-6">
            {/* We use less vertical padding on card headers on desktop than on body sections */}
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {format(parseISO(result.assessmentDate), 'MMMM dd, yyyy')}
            </h3>
          </div>

          <div className="px-4 py-5 sm:p-6">
            {'ratings' in result &&
              Array.isArray(result.ratings) &&
              result.ratings.length > 0 && (
                <>
                  <h4 className="text-lg font-medium leading-6 text-gray-900">
                    Ratings
                  </h4>
                  <div className="mt-2">
                    <dl className="flex flex-col gap-4">
                      {result.ratings.map((rating) => (
                        <div key={rating.focusArea} className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-900">
                            {rating.focusArea}
                          </dt>
                          <dd className="mt-1 flex items-center gap-2 text-sm text-gray-500">
                            <p>
                              - {rating.rating} / 5{' '}
                              <span className="pl-1">
                                (
                                {useLeadershipValues
                                  ? scale[rating.rating - 1]?.level
                                  : scale[rating.rating - 1]?.level.split(
                                      ' - '
                                    )[1]}
                                )
                              </span>
                            </p>
                            {!useLeadershipValues && (
                              <button
                                type="button"
                                className="rounded-full p-1 text-gray-500 hover:bg-gray-100"
                                onClick={() => {
                                  setShowInfo(true)
                                  setSelectedRating(scale[rating.rating - 1])
                                }}
                              >
                                <InformationCircleIcon
                                  aria-hidden="true"
                                  className="h-5 w-5"
                                />
                              </button>
                            )}
                          </dd>
                        </div>
                      ))}
                    </dl>
                  </div>
                </>
              )}

            {result.surveyAnswers.length > 0 && (
              <>
                <h4 className="text-lg font-medium leading-6 text-gray-900">
                  Answers
                </h4>
                <div className="mt-2">
                  <dl className="flex flex-col gap-4">
                    {result.surveyAnswers.map((answer) => (
                      <div key={answer.question} className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-900">
                          {answer.question}
                        </dt>
                        {Array.isArray(answer.answers) ? (
                          <dd className="mt-1 text-sm text-gray-500">
                            - {answer.answers.join(', ')}
                          </dd>
                        ) : (
                          <dd className="mt-1 text-sm text-gray-500">
                            - {answer.answers}
                          </dd>
                        )}
                      </div>
                    ))}
                  </dl>
                </div>
              </>
            )}
          </div>
        </div>
      ))}

      <AlertDialog
        isOpen={showInfo}
        onClose={() => setShowInfo(false)}
        title={selectedRating?.level}
        description={selectedRating?.description.map((i: any) => (
          <p key={i}>- {i}</p>
        ))}
        cancelText="Close"
        onCancel={() => setShowInfo(false)}
        noIcon
      />
    </>
  )
}
