import { Logo } from '~/assets/Logo'

export const LoadingSplash: React.FC = () => (
  <div className="fixed h-full w-full flex flex-col gap-4 items-center justify-center">
    <Logo className="h-72 sm:h-80" />
  </div>
)

export const LoadingSpinner: React.FC = () => (
  <div className="flex w-full items-center justify-center select-none pointer-events-none">
    <div className="animate-spin border-4 w-10 h-10 rounded-full border-r-primary-500" />
  </div>
)
