import { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useLocale } from '../../../../hooks/useLocale'
import { useMixpanel } from '../../../../utils/MixpanelContext'

export const PostInAppCallUserFeedback = ({ eventId }: any) => {
  const [countdown, setCountdown] = useState(9)
  const mixpanel = useMixpanel()
  const navigate = useNavigate()
  const { t } = useLocale()

  const handleRedirect = () => {
    navigate('/book')
    mixpanel.track('post_call_redirected_to_booking', {
      eventId,
      autoRedirect: countdown === 0,
    })
  }

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval> | null = null

    intervalId = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1)
      if (countdown === 0) {
        handleRedirect()
      }
    }, 1000)

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [countdown])

  useEffect(() => {
    mixpanel.track('inapp_meeting_post_call_suggession_to_book_displayed', {
      eventId,
    })
  }, [mixpanel])

  return (
    <div className="bg-black text-white w-full h-full flex flex-col place-items-center justify-center text-center px-16">
      <p className="text-2xl">{t('post_call_booking_title')}</p>
      <p className="text-2xl mt-4">{t('post_call_booking_subtitle')}</p>
      <p className="text-2xl mt-1">{t('post_call_booking_message')}</p>
      <button
        className="bg-[#2160FD] hover:bg-[#3173FF] text-white  py-2 px-4 mt-6 rounded transition-colors duration-300"
        onClick={handleRedirect}
      >
        {t('post_call_booking_cta')}
      </button>
      <p className="mt-6 text-xs">
        {t('post_call_booking_footer').replace('{{countdown}}', '' + countdown)}
      </p>
    </div>
  )
}
