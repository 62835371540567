import type { LoaderFunction } from 'react-router-dom'
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { api } from '~/app/api'

export type Response = Root[]

interface Root {
  _id: string
  body: {
    text: string
  }
  user: string
  createdAt: string
  updatedAt: string
  __v: number
}

const fetchData = () =>
  queryOptions({
    queryKey: ['user_notes'],
    queryFn() {
      return api.url('/user/note').get().json<Response>()
    },
  })

export const loader = (queryClient: QueryClient): LoaderFunction => () => {
  return queryClient.fetchQuery(fetchData())
}
