import {
  Button,
  Checkbox,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from '@chakra-ui/react'

import { useLocale } from '../../hooks/useLocale'

const SessionNotesForm = ({
  isNoteOpen,
  handleNoteClose,
  onNoteSubmit,
  initialRef,
  updating,
  formState,
  handleChange,
  onShowedUpChange,
}: any) => {
  const { t } = useLocale()

  return (
    <Modal
      initialFocusRef={initialRef}
      isOpen={isNoteOpen}
      onClose={handleNoteClose}
      size="lg"
    >
      <form onSubmit={onNoteSubmit}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {updating ? t('update_session_note') : t('create_new_session_note')}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={3}>
            {/* User Showed Up Checkbox */}
            <FormControl mb={5}>
              <Checkbox
                name="userShowedUp"
                checked={formState.userShowedUp}
                defaultChecked={formState.userShowedUp}
                onChange={onShowedUpChange}
              >
                User showed up
              </Checkbox>
            </FormControl>

            {formState.userShowedUp && (
              <>
                {/* Session Notes Textarea */}
                <FormControl mt={5}>
                  <Textarea
                    value={formState.body}
                    placeholder={`Session notes (shared with client) ...`}
                    name="body"
                    onChange={handleChange}
                    size="lg"
                    height={'full'}
                    rows={8}
                  />
                </FormControl>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <Button onClick={handleNoteClose} mr={3}>
              {t('close')}
            </Button>
            <Button colorScheme="green" type="submit" onClick={onNoteSubmit}>
              {updating ? t('update') : t('create')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}

export default SessionNotesForm
