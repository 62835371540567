import { Flex, Text } from '@chakra-ui/react'
import { FC } from 'react'

import { useLocale } from '../../../hooks/useLocale'
import { processNewLineText } from '../../../utils/text'
import OnboardingContainer from '../../onboarding/OnboardingContainer'
import OnboardingFooter from '../../onboarding/components/OnboardingFooter'
import OnboardingHeader from '../../onboarding/components/OnboardingHeader'

const StepQuestion: FC<any> = ({ progress, question, onSubmit, isLast }) => {
  const { t } = useLocale()
  return (
    <>
      <OnboardingHeader
        currentStep={progress.currentStep}
        totalSteps={progress.totalSteps}
      />
      <OnboardingContainer
        direction="column"
        alignItems="center"
        justifyContent="center"
        py={5}
        px={2}
      >
        <Flex direction="column" py={5} gridRowGap={5} alignItems="center">
          <Text
            fontWeight="600"
            textAlign="center"
            lineHeight={8}
            fontSize={['2xl', '3xl']}
            mt={20}
          >
            {processNewLineText(question.title)}
          </Text>
          <Text
            fontWeight="400"
            maxWidth={600}
            lineHeight={1.3}
            fontSize={['xl']}
            textAlign="center"
            mt={2}
            mb={20}
          >
            {processNewLineText(question.description)}
          </Text>
        </Flex>
        <OnboardingFooter
          submitText={isLast ? t('finish') : t('continue')}
          onSubmit={onSubmit}
        />
      </OnboardingContainer>
    </>
  )
}

export default StepQuestion
