import { Box, Flex, Spinner, Text, useToast } from '@chakra-ui/react'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

import {
  useLazyGetAssessmentQuestionsQuery,
  useLazyGetManagerAssessmentQuestionsQuery,
  useLazyGetPeerAssessmentQuestionsQuery,
  useLazyGetReporteeAssessmentQuestionsQuery,
} from '../../app/services/api'
import { useAuth } from '../../hooks/useAuth'
import { maybeChangeLocale, useLocale } from '../../hooks/useLocale'
import { clearAssessmentData, setAssessmentQuestions } from './assessmentSlice'

type Props = any

const AssessmentIntro: React.FC<Props> = (props) => {
  const { isManager, isPeer, isReportee, type } = props
  const { user } = useAuth()
  const { userId, language, authorEmail } = useParams() as any
  const [searchParams] = useSearchParams()
  const { i18n } = useLocale()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const toast = useToast()
  const { t } = useLocale()

  const [
    fetchAssessments,
    {
      data: assessmentData,
      isLoading: isLoadingAssessment,
      error: errorAssessment,
    },
  ] = useLazyGetAssessmentQuestionsQuery()

  const [
    fetchManagerAssessments,
    {
      data: managerAssessmentData,
      isLoading: isManagerAssessmentLoading,
      error: managerAssessmentError,
    },
  ] = useLazyGetManagerAssessmentQuestionsQuery()

  const [
    fetchPeerAssessments,
    {
      data: peerAssessmentData,
      isLoading: isPeerAssessmentLoading,
      error: peerAssessmentError,
    },
  ] = useLazyGetPeerAssessmentQuestionsQuery()

  const [
    fetchReporteeAssessments,
    {
      data: reporteeAssessmentData,
      isLoading: isReporteeAssessmentLoading,
      error: reporteeAssessmentError,
    },
  ] = useLazyGetReporteeAssessmentQuestionsQuery()

  const isLoading = useMemo(
    () =>
      isLoadingAssessment ||
      isManagerAssessmentLoading ||
      isPeerAssessmentLoading ||
      isReporteeAssessmentLoading,
    [
      isLoadingAssessment,
      isManagerAssessmentLoading,
      isPeerAssessmentLoading,
      isReporteeAssessmentLoading,
    ]
  )

  const data = useMemo(
    () =>
      assessmentData ??
      managerAssessmentData ??
      peerAssessmentData ??
      reporteeAssessmentData,
    [
      assessmentData,
      managerAssessmentData,
      peerAssessmentData,
      reporteeAssessmentData,
    ]
  )

  const error = useMemo(
    () =>
      errorAssessment ??
      managerAssessmentError ??
      peerAssessmentError ??
      reporteeAssessmentError,
    [
      errorAssessment,
      managerAssessmentError,
      peerAssessmentError,
      reporteeAssessmentError,
    ]
  )

  useEffect(() => {
    if (language) {
      maybeChangeLocale(language, i18n.language)
    }
  }, [language, i18n.language])

  useEffect(() => {
    if (isPeer) {
      fetchPeerAssessments({
        userId,
        language,
        type,
        departmentId: user?.department?._id,
        authorEmail,
      })
    } else if (isReportee) {
      fetchReporteeAssessments({
        userId,
        language,
        type,
        departmentId: user?.department?._id,
        authorEmail,
      })
    } else if (isManager) {
      fetchManagerAssessments({
        userId,
        language,
        type,
        departmentId: user?.department?._id,
      })
    } else {
      fetchAssessments({
        companyId: user?.company._id,
        language: language ?? user?.language,
        type,
        departmentId: user?.department?._id,
      })
    }
  }, [
    isReportee,
    isManager,
    isPeer,
    authorEmail,
    userId,
    language,
    fetchAssessments,
    fetchManagerAssessments,
    fetchPeerAssessments,
    fetchReporteeAssessments,
    user,
    type,
  ])

  useEffect(() => {
    if (!error) return

    toast({
      status: 'error',
      title: 'Error',
      isClosable: true,
    })

    console.log(error)
  }, [error, toast])

  useEffect(() => {
    if (isLoading || !data || user?.skipAssessment) return
    dispatch(clearAssessmentData())
    dispatch(setAssessmentQuestions(data))
    const questionIndex = searchParams.get('questionIndex')

    navigate(`${questionIndex ?? 0}`, {
      replace: true,
    })
  }, [isLoading, data, searchParams, dispatch, navigate])

  if (user?.skipAssessment) {
    return (
      <Flex alignItems="center" justifyContent="center" h={'100vh'}>
        <Box textAlign="center" py={10} px={6}>
          <Text fontSize="18px" mt={3} mb={2}>
            {t('assessment_not_enabled')}{' '}
          </Text>
        </Box>
      </Flex>
    )
  }

  if (isLoading) {
    return (
      <Flex alignItems="center" justifyContent="center" h={'100vh'}>
        <Spinner size="4xl" />
      </Flex>
    )
  }

  return <Outlet />
}

export default AssessmentIntro
