import { Suspense, useContext, useEffect } from 'react'
import { Spinner } from '@chakra-ui/react'
import { RouterProvider } from 'react-router-dom'

import { useLazyGetCoachQuery } from './app/services/api'
import { useAppDispatch, useAppSelector } from './app/hooks'
import { updateSocket } from './features/connection/socketSlice'
import { SocketContext } from './hooks/socket'
import { maybeChangeLocale, useLocale } from './hooks/useLocale'
import { useLogout } from './hooks/useLogout'
import { useMixpanel } from './utils/MixpanelContext'
import { router } from './router'

const context = new AudioContext()

let firstTime = true

function App() {
  const user = useAppSelector((state) => state.auth.user)
  const [triggerGetCoach, { data: usersCoach, isLoading: isCoachInfoLoading }] =
    useLazyGetCoachQuery()
  const mixpanel = useMixpanel()
  const socketId = useAppSelector((state) => state.socket.socketId)
  const dispatch = useAppDispatch()
  const { i18n } = useLocale()
  const onLogout = useLogout()

  const socket: any = useContext(SocketContext)

  useEffect(() => {
    if (
      user &&
      !user.isOnboardingCompleted &&
      !window.location.href.includes('/onboarding')
    ) {
      onLogout()
      mixpanel.reset()
    }
    socket.emit('status:connect', {
      user: user?._id,
    })

    window.addEventListener('click', handleClick)
    return () => {
      window.removeEventListener('click', handleClick)
      socket.disconnect()
    }
  }, [])

  useEffect(() => {
    if (!user) return

    if (!socket.connected && user.isOnboardingCompleted) {
      socket.connect()
      return
    }

    if (socketId !== socket.id && user.isOnboardingCompleted) {
      // in case we logged out and log in within same session
      dispatch(updateSocket(socket.id))
    }
  }, [user, socketId])

  useEffect(() => {
    if (user?.language && user?.isOnboardingCompleted) {
      maybeChangeLocale(user.language, i18n.language)
    }
    if (user && user.isOnboardingCompleted && firstTime) {
      firstTime = false
      triggerGetCoach()
    }
  }, [user])

  useEffect(() => {
    if (
      usersCoach &&
      usersCoach.email &&
      mixpanel.get_property('coach') !== usersCoach.email
    ) {
      mixpanel.set_group('coach', usersCoach.email)
    }
  }, [usersCoach])

  const handleClick = (_event: any) => {
    try {
      context.resume()
    } catch (error) {
      console.log(error)
    }
  }

  if (isCoachInfoLoading) {
    return <Spinner />
  }
  //  else if (
  //   !isCoachInfoLoading &&
  //   user &&
  //   user.isOnboardingCompleted &&
  //   (coachLoadError || !usersCoach)
  // ) {
  //   console.log(
  //     'isCoachInfoLoading',
  //     isCoachInfoLoading,
  //     ', coachLoadError',
  //     coachLoadError,
  //     ', usersCoach',
  //     usersCoach
  //   )
  //   return <Error error={!usersCoach && t('coach_loading_error')} />
  // }

  return (
    <Suspense fallback={<Spinner />}>
      <SocketContext.Provider value={socket}>
        <RouterProvider router={router} />
      </SocketContext.Provider>
    </Suspense>
  )
}
export default App
