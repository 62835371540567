import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { FC } from 'react'
import { useLocale } from '../../hooks/useLocale'
import { Logo } from '../../utils/Logo'

const HupoHighlightsEditSuccess: FC<any> = () => {
  const { t } = useLocale()
  return (
    <Box flexDirection={'column'} textAlign="center" px={6} pb={10}>
      <Flex
        px={{ base: 4, md: 4 }}
        height="20"
        borderBottomWidth="1px"
        alignItems="center"
        justifyContent="center"
      >
        <Logo md={5} />
      </Flex>

      <Flex
        alignItems="center"
        justifyContent="center"
        padding={10}
        direction="column"
      >
        <Heading
          display="inline-block"
          as="h3"
          size="lg"
          textAlign={'center'}
          mb={10}
        >
          {t('summary-edit-success')}
        </Heading>
        <Text textAlign={'center'} mb={10}>
          {t('summary-verified-close-tab')}
        </Text>
      </Flex>
    </Box>
  )
}

export default HupoHighlightsEditSuccess
