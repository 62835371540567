import { useMemo } from 'react'
import { PROD_ORIGIN } from '~/app/constants'

export const useE2Etest = () => {
  const isE2Etest = useMemo(() => !!window.isPlaywright, [])
  const isProd = useMemo(() => window.location.origin === PROD_ORIGIN, [])

  if (!isProd) return true

  return isE2Etest
}
