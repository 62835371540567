import { NavLink, Outlet } from 'react-router-dom'

const secondaryNavigation = [
  { name: 'Account', href: './account' },
  // { name: 'Notifications', href: '#' },
  // { name: 'Billing', href: '#' },
  // { name: 'Teams', href: '#' },
  // { name: 'Integrations', href: '#' },
]

export const Settings: React.FC = () => {
  return (
    <>
      <header className="border-b border-black/5">
        {/* Secondary navigation */}
        <nav className="flex overflow-x-auto py-4">
          <ul
            role="list"
            className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8"
          >
            {secondaryNavigation.map((item) => (
              <li key={item.name}>
                <NavLink
                  to={item.href}
                  className={({ isActive }) =>
                    isActive ? 'text-primary-400' : ''
                  }
                >
                  {item.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </header>

      <Outlet />
    </>
  )
}
