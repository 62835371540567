import { useCallback, useEffect, useMemo } from 'react'
import { useLoaderData } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { usePostHog } from 'posthog-js/react'
import { useMutation } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'

import type { OnboardingResponse } from './onboarding/onboarding.loader'
import { api } from '~/app/api'
import { useAppDispatch, useAppSelector } from '~/app/hooks'
import { Progress } from '~/components/progress'
import { Step } from './onboarding/_components/Step'
import { Email } from './onboarding/_components/Email'
import { useOnboardingContext } from '~/context/onboardingContext'
import { NextButton } from './onboarding/_components/NextButton'
import { Profile } from './onboarding/_components/Profile'
import { Text } from './onboarding/_components/Text'
import { Select } from './onboarding/_components/Select'
import { CoachSelection } from './onboarding/_components/CoachSelection'
import { Emails } from './onboarding/_components/Emails'
import { setCoachEmail } from '~/store/onboarding.slice'
import { saveUserToken, updateUser } from '~/features/auth/authSlice'
import { DEFAULT_LOCALE, LOCAL_TIMEZONE } from '~/app/constants'

export const Onboarding: React.FC = () => {
  const data = useLoaderData() as OnboardingResponse
  const { t } = useTranslation()
  const posthog = usePostHog()
  const { isAuthenticated } = useAuth0()
  const dispatch = useAppDispatch()
  const { coachEmail: preSelectedCoachEmail } = useAppSelector(
    (state) => state.onboardingV2
  )

  const {
    companyId,
    departmentId,
    email,
    lastQuestionIndex,
    currentQuestionIndex,
    currentStep,
    setSkipList,
    setCurrentStep,
    setLastQuestionIndex,
    onPrevQuestion,
  } = useOnboardingContext()

  const { mutate } = useMutation({
    mutationFn(data: { name: string; email: string; password?: string }) {
      return api
        .url('/onboarding/profile')
        .post({
          name: data.name,
          email: data.email,
          password: data.password,
          timezone: LOCAL_TIMEZONE,
          language: DEFAULT_LOCALE,
          interfaceLanguage: DEFAULT_LOCALE,
          companyId,
          departmentId,
        })
        .json<any>()
    },
    onSuccess(data) {
      dispatch(saveUserToken(data.token), 'local')
      dispatch(updateUser({ ...data.user, skipAccountSetup: true }))
    },
  })

  const question = useMemo(
    () => data.questions[currentQuestionIndex],
    [data.questions, currentQuestionIndex]
  )

  useEffect(() => {
    if (typeof question.step === 'number' && question.step !== currentStep) {
      setCurrentStep(question.step - 1)
    }
  }, [question, currentStep])

  const updateSkipList = useCallback(() => {
    for (let i = 0; i < data.questions.length; i++) {
      if (
        data.questions[i].type === 'profile-setup' ||
        data.questions[i].friendlyID === 'onboarding-email'
      ) {
        setSkipList((prev) => prev.add(i))
      }
    }
  }, [data.questions, setSkipList])

  useEffect(() => {
    setLastQuestionIndex(data.questions.length - 1)
    if (data.userInfo?.preselectedCoachEmail) {
      dispatch(setCoachEmail(data.userInfo.preselectedCoachEmail))
    }

    if (!email || isAuthenticated) return
    if (data.userInfo?.accountExists && data.userInfo?.token) {
      dispatch(saveUserToken(data.userInfo.token, 'local'))
      dispatch(updateUser({ ...data.userInfo.user, skipAccountSetup: true }))
      updateSkipList()
      posthog.capture('onboarding_profile_setup', {
        type: 'account_already_exists',
      })
    } else if (data.userInfo?.name && data.userInfo?.password) {
      mutate({
        name: data.userInfo.name,
        email: data.userInfo.email!,
        password: data.userInfo.password,
      })
      updateSkipList()
      posthog.capture('onboarding_profile_setup', {
        type: 'white_listed_with_default_password',
      })
    }
  }, [posthog, dispatch, isAuthenticated, email, data, updateSkipList])

  const renderQuestion = useCallback(
    (q: typeof question) => {
      switch (q.type) {
        case 'info':
          return <NextButton />
        case 'step':
          return <Step currentStep={currentStep} steps={data.steps} />
        case 'email':
          return <Email id={q.friendlyID} optional={q.optional} />
        case 'emails':
          return (
            <Emails
              id={q.friendlyID}
              min={q.minSelectionLimit}
              max={q.maxSelectionLimit}
            />
          )
        case 'profile-setup':
          return <Profile />
        case 'text':
          return <Text id={q.friendlyID} placeholder={q.placeholder} />
        case 'select':
          return (
            <Select
              id={q.friendlyID}
              options={q.options}
              otherTextPlaceholder={q.otherTextPlaceholder}
            />
          )
        case 'multiselect':
          return (
            <Select
              id={q.friendlyID}
              options={q.options}
              otherTextPlaceholder={q.otherTextPlaceholder}
              isMulti
            />
          )
        case 'coach-selection':
          return <CoachSelection id={q.friendlyID} />
        default:
          return <p>Unknown question type: {q.type}</p>
      }
    },
    [currentStep, data]
  )

  return (
    <>
      <div className="w-full sticky top-0 px-5 py-3.5 mx-auto bg-white shadow">
        <Progress
          width={(currentQuestionIndex * 100) / lastQuestionIndex}
          onPrevQuestion={onPrevQuestion}
        />
      </div>
      <div className="mt-5 mx-auto flex flex-col gap-10 items-center justify-center max-w-lg">
        <div className="text-center">
          <h1 className="text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
            {question.type === 'coach-selection' &&
            preSelectedCoachEmail.length > 0
              ? t('our_recommended_coach')
              : question.title}
          </h1>

          {question.description && (
            <p className="mx-auto mt-2 max-w-md text-gray-500">
              {question.description}
            </p>
          )}
        </div>

        {renderQuestion(question)}

        <input
          type="hidden"
          className="hidden"
          data-testid="question-type"
          value={question.type}
        />
      </div>
    </>
  )
}
