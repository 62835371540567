import moment from 'moment'
import { LOCAL_TIMEZONE } from '~/app/constants'

// @ts-expect-error - TS doesn't know about this yet
const timeZones = Intl.supportedValuesOf('timeZone') as string[]

export const timeZoneData = timeZones
  .map((timeZone) => {
    // const offset = format(new Date(), 'xxx', { timeZone })
    const offset = moment().tz(timeZone).format('Z')

    return { offset, name: timeZone }
  })
  .sort((a, b) => a.offset.localeCompare(b.offset))

export const getSelectedTimeZoneIndex = (savedTimeZone = LOCAL_TIMEZONE) => {
  const index = timeZoneData.findIndex((tz) => tz.name === savedTimeZone)
  return index > 0 ? index : 0
}
