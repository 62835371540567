import { useTranslation } from 'react-i18next'
import { useLoaderData } from 'react-router-dom'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { CheckCircleIcon, UserCircleIcon } from '@heroicons/react/24/outline'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import parseISO from 'date-fns/parseISO'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'

import type { Response } from '../respondent-management.loader'

type Keys = keyof Response

type Props = {
  dataKey: Keys
  onEdit: (email: string) => void
  onResend: (email: string) => void
}

export const RespondentCard: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const data = useLoaderData() as Response
  const { dataKey: key, onEdit, onResend } = props

  return (
    <div className="divide-y divide-gray-200 rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          {data[key].length === 1 ? t(key.replace(/s$/, '')) : t(key)} (
          {
            data[key].filter((respondent) => respondent.status === 'completed')
              .length
          }
          /{data[key].length})
        </h3>
      </div>

      <div className="px-4 py-5 sm:p-6">
        <ul role="list" className="divide-y divide-gray-100">
          {data[key].map((item) => (
            <li key={item.id} className="flex justify-between gap-x-6 py-5">
              <div className="flex min-w-0 gap-x-4">
                <UserCircleIcon
                  aria-hidden="true"
                  className="h-12 w-12 flex-none rounded-full text-gray-400"
                />
                {/* <img
              alt={item.email}
              src={item}
              className="h-12 w-12 flex-none rounded-full bg-gray-50"
            /> */}
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    {item.email}
                  </p>
                  <p className="mt-1 flex flex-col text-xs leading-5 text-gray-500">
                    {item.status === 'completed' ? (
                      <span>Status: {t('completed')}</span>
                    ) : (
                      <>
                        <span>
                          Last requested:{' '}
                          {formatDistanceToNow(parseISO(item.lastRequested), {
                            addSuffix: true,
                          })}
                        </span>
                        <span>Requests sent: {item.requestedCount}</span>
                      </>
                    )}
                  </p>
                </div>
              </div>
              <div className="flex shrink-0 items-center gap-x-6">
                {item.status === 'completed' ? (
                  <div className="flex gap-x-2.5">
                    <dt>
                      <span className="sr-only">Status</span>
                      <CheckCircleIcon
                        aria-hidden="true"
                        className="h-6 w-6 text-green-500"
                      />
                    </dt>
                    <dd className="text-sm leading-6 text-gray-900">
                      {t(item.status)}
                    </dd>
                  </div>
                ) : (
                  <Menu as="div" className="relative flex-none">
                    <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon
                        aria-hidden="true"
                        className="h-5 w-5"
                      />
                    </MenuButton>
                    <MenuItems
                      transition
                      className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                    >
                      <MenuItem>
                        <button
                          type="button"
                          className="w-full text-left px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                          onClick={() => onEdit(item.email)}
                        >
                          Edit
                          <span className="sr-only">, {item.email}</span>
                        </button>
                      </MenuItem>
                      <MenuItem>
                        <button
                          type="button"
                          className="w-full text-left px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                          onClick={() => onResend(item.email)}
                        >
                          Resend
                          <span className="sr-only">, {item.email}</span>
                        </button>
                      </MenuItem>
                    </MenuItems>
                  </Menu>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
