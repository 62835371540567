import moment from 'moment-timezone'

export const parseEventDate = (user: any, start: Date) => {
  let timezone = user?.timezone
  if (!timezone) {
    timezone = 'Asia/Singapore'
  }
  const startMoment = moment(start).tz(timezone)
  return startMoment.format('MMM Do')
}

export const parseEventTime = (
  user: any,
  start: Date,
  end: Date,
  showTZ = true
) => {
  let timezone = user?.timezone
  if (!timezone) {
    timezone = 'Asia/Singapore'
  }
  const startMoment = moment(start).tz(timezone)
  const endMoment = moment(end).tz(timezone)
  const startA = startMoment.format('A')
  const endA = endMoment.format('A')
  const timezoneOut = startMoment.format('([GMT]Z)')
  let datetime = `${startMoment.format('h:mm')} - ${endMoment.format('h:mma')}`
  if (startA !== endA) {
    datetime = `${startMoment.format('h:mma')} - ${endMoment.format('h:mma')}`
  }
  return showTZ ? `${datetime} ${timezoneOut}` : datetime
}
