import { IconButton, useTheme } from '@mui/material'
import { APWrapper } from './assessment-progress.style'
import { ArrowBack } from '@mui/icons-material'

export function AssessmentProgress({
  percent,
  onBack,
  hideBack,
  isMobileMenuOpen,
}: {
  percent: number
  onBack: () => void
  hideBack?: boolean
  isMobileMenuOpen?: boolean
}) {
  const theme = useTheme()
  return (
    <APWrapper
      className={hideBack ? 'noBack' : ''}
      sx={{
        ml: isMobileMenuOpen ? '0' : '30px',
      }}
    >
      {!hideBack && (
        <IconButton onClick={() => onBack()}>
          <ArrowBack />
        </IconButton>
      )}
      <div className="progressContent">
        <div
          className="progressBar"
          style={
            {
              '--progress-width': `${percent}%`,
              '--color':
                percent === 100
                  ? theme.palette.success.light
                  : theme.palette.primary.main,
            } as any
          }
        />
      </div>
    </APWrapper>
  )
}
