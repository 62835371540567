import i18next from 'i18next'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'

import { SUPPORTED_LOCALES } from '../app/constants'

const updateInterfaceLanguage = (newLocale: string, currentLocale: string) => {
  if (
    newLocale !== currentLocale &&
    SUPPORTED_LOCALES.includes(currentLocale) &&
    SUPPORTED_LOCALES.includes(newLocale)
  ) {
    i18next.changeLanguage(newLocale)
  }
}

export const updateMomentLocale = (locale: string) => {
  if (!SUPPORTED_LOCALES.includes(locale)) {
    return
  }
  switch (locale) {
    case 'en':
      moment.locale('en-us')
      break
    default:
      moment.locale('en-us')
      break
  }
}

export const maybeChangeLocale = (newLocale: string, currentLocale: string) => {
  const current = currentLocale?.split('-')[0]
  updateInterfaceLanguage(newLocale, current)
  updateMomentLocale(newLocale)
}

export const useLocale = () => {
  const { i18n, t } = useTranslation('translation')

  return {
    i18n,
    t,
  }
}
