import type { LoaderFunction } from 'react-router-dom'
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { api } from '~/app/api'

export interface Response {
  _id: string
  profile: Profile
  isOnline: boolean
  timezone: string
  language: string
  email: string
  company: Company
  callTool: string
}

interface Profile {
  name: string
  gender: string | null
  picture: string | null
  phone: string | null
  fullPhone: string | null
  nickName: string | null
}

interface Company {
  _id: string
  name: string
}


const fetchProfile = () =>
  queryOptions({
    queryKey: ['account'],
    queryFn() {
      return api.url('/users/profile').get().json<Response>()
    },
  })

export const loader = (queryClient: QueryClient): LoaderFunction => () => {
  return queryClient.fetchQuery(fetchProfile())
}
