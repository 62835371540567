import { ChakraProvider } from '@chakra-ui/react'
import { Close as CloseIcon, Menu as MenuIcon } from '@mui/icons-material'
import { Stack, ThemeProvider, useMediaQuery } from '@mui/material'
import { useEffect, useState } from 'react'
import Lottie from 'react-lottie'
import { useLocation, useParams } from 'react-router-dom'
import { useGetPeerAssessmentQuery } from '../../app/services/api'
import colleague from '../../assets/lottie/colleague.json'
import loadingAnimation from '../../assets/lottie/loading.json'
import noDataAnimation from '../../assets/lottie/no-data.json'
import { appTheme } from '../../theme/theme'
import { useMixpanel } from '../../utils/MixpanelContext'
import { LeadershipValues } from './components/LeadershipValues'
import {
  MobileMenuButton,
  NoAuthorWrapper,
  PeerAssessmentContainer,
  PeerAssessmentMobileContainer,
} from './components/peer-assessment.style'
import { PeerAssessmentUsers } from './components/PeerAsessmentUsers'
import { PeerAssessmentArea } from './components/PeerAssessmentArea'
import { useHideChatlio } from '../../hooks/useHideChatlio'

function PeerAssessment() {
  const params = useParams<{ author: string; user: string }>()
  const author = params.author
  const currentUser = params.user
  const { pathname } = useLocation()
  const { data, isFetching, isLoading, error } = useGetPeerAssessmentQuery(
    { author: author || '' },
    { skip: !author }
  )
  const isLeadershipPage = pathname.includes('/u/q/leadership-values')
  const mixpanel = useMixpanel()

  const currentQuestion = data?.requests.find(
    (item) => item.user._id === currentUser
  )

  const isMobile = useMediaQuery(appTheme.breakpoints.down('sm'))
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }

  useHideChatlio()

  // Hide Chatlio on meeting page
  useEffect(() => {
    mixpanel.track('peer_assessment_page_viewed', { author, currentUser })
  }, [])

  if (!author) {
    return (
      <NoAuthorWrapper>
        <Stack sx={{ pointerEvents: 'none' }}>
          <Lottie
            options={{
              animationData: noDataAnimation,
              autoplay: true,
              loop: true,
            }}
            width={200}
          />
        </Stack>
        <div className="info">
          <h1> No author </h1>
          <p>An author must be provided to access this page</p>
        </div>
      </NoAuthorWrapper>
    )
  }

  if ((isFetching || isLoading) && !data) {
    return (
      <NoAuthorWrapper>
        <Stack sx={{ pointerEvents: 'none' }}>
          <Lottie
            options={{
              animationData: loadingAnimation,
              autoplay: true,
              loop: true,
            }}
            width={200}
          />
        </Stack>
      </NoAuthorWrapper>
    )
  }

  if (!data) {
    mixpanel.track('peer_assessment_no_data', { author, currentUser, error })

    let errorTitle = 'No Data'
    let errorMessage = "We couldn't find any data for the provided email"

    if (error && 'status' in error && error.status === 'FETCH_ERROR') {
      const originalError = error.error
      if (
        originalError &&
        typeof originalError === 'string' &&
        originalError.includes('Failed to fetch')
      ) {
        errorTitle = 'Network Error'
        errorMessage =
          "It seems like your corporate IT system is blocking the request. Please contact your IT administrator to help whitelist the '*.hupo.co' domain or try accessing the link using your personal PC or phone."
      }
    } else if (error) {
      errorTitle = 'Error Loading Data'
      errorMessage =
        (error as any)?.data?.message ||
        'An error occurred while loading the data. Please try again later.'
    }

    return (
      <NoAuthorWrapper>
        <Stack sx={{ pointerEvents: 'none' }}>
          <Lottie
            options={{
              animationData: noDataAnimation,
              autoplay: true,
              loop: true,
            }}
            width={200}
          />
        </Stack>
        <div className="info">
          <h1>{errorTitle}</h1>
          <p>{errorMessage}</p>
        </div>
      </NoAuthorWrapper>
    )
  }
  mixpanel.track('peer_assessment_data_loaded', { author, currentUser })

  return (
    <PeerAssessmentContainer>
      {isMobile && (
        <MobileMenuButton
          onClick={handleMobileMenuToggle}
          sx={{
            right: !isMobileMenuOpen ? 'unset' : '16px',
          }}
        >
          {isMobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
        </MobileMenuButton>
      )}
      {(!isMobile || isMobileMenuOpen) && (
        <PeerAssessmentUsers
          company={data?.company.name}
          questions={data?.requests}
          isMobileMenuOpen={isMobileMenuOpen}
        />
      )}
      {isMobile ? (
        <PeerAssessmentMobileContainer>
          {currentQuestion ? (
            <PeerAssessmentArea
              data={currentQuestion}
              values={data?.leadershipValues.values}
              isMobileMenuOpen={isMobileMenuOpen}
            />
          ) : isLeadershipPage ? (
            <LeadershipValues data={data} />
          ) : (
            <NoAuthorWrapper>
              <Stack className="lottie">
                <Lottie
                  options={{
                    animationData: colleague,
                    autoplay: true,
                    loop: true,
                  }}
                  height={300}
                />
              </Stack>
              <div className="info">
                <h1
                  style={{
                    maxWidth: '500px',
                    fontWeight: 400,
                  }}
                >
                  Select your colleague to start their Hupo Perspectives
                  assessment
                </h1>
              </div>
            </NoAuthorWrapper>
          )}
        </PeerAssessmentMobileContainer>
      ) : (
        <>
          {currentQuestion ? (
            <PeerAssessmentArea
              data={currentQuestion}
              values={data?.leadershipValues.values}
              isMobileMenuOpen={isMobileMenuOpen}
            />
          ) : isLeadershipPage ? (
            <LeadershipValues data={data} />
          ) : (
            <NoAuthorWrapper>
              <Stack sx={{ pointerEvents: 'none' }}>
                <Lottie
                  options={{
                    animationData: colleague,
                    autoplay: true,
                    loop: true,
                  }}
                  height={300}
                />
              </Stack>
              <div className="info">
                <h1
                  style={{
                    maxWidth: '500px',
                    fontWeight: 400,
                  }}
                >
                  Select your colleague to start their Hupo Perspectives
                  assessment
                </h1>
              </div>
            </NoAuthorWrapper>
          )}
        </>
      )}
    </PeerAssessmentContainer>
  )
}

export function PeerAssessmentPage() {
  return (
    <ThemeProvider theme={appTheme}>
      <ChakraProvider>
        <PeerAssessment />
      </ChakraProvider>
    </ThemeProvider>
  )
}
