import { useMemo, useState } from 'react'
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import Cookies from 'js-cookie'
import { useMutation } from '@tanstack/react-query'
import { useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { WretchError } from 'wretch/resolver'
import {
  ChevronRightIcon,
  EyeIcon,
  EyeSlashIcon,
} from '@heroicons/react/20/solid'

import { useAppDispatch } from '~/app/hooks'
import { api } from '~/app/api'
import { cn } from '~/utils/cn'

import spinner from '~/assets/spinner.svg'
import { saveUserToken, updateUser } from '~/features/auth/authSlice'

type LoginPayload = {
  email: string
  password: string
  adminSecret?: string
}

export const Login: React.FC = () => {
  const { t } = useTranslation()
  const { isAuthenticated, loginWithRedirect } = useAuth0()
  const navigate = useNavigate()
  const toast = useToast()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const [displayPassword, setDisplayPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const returnTo = useMemo(() => {
    const param = searchParams.get('returnTo')
    return param ? decodeURIComponent(param) : '/'
  }, [searchParams, location])

  const { mutate: checkEmail, isPending: isCheckingEmail } = useMutation({
    mutationKey: ['check', 'email'],
    mutationFn(email: string) {
      return api
        .url('/v2/user/check')
        .query({ email })
        .get()
        .json<{ usesAuth0: boolean }>()
    },
    onSuccess(data, email) {
      const redirectUri = new URL(window.location.origin)

      const search = new URLSearchParams({ fromAuth0: 'true' })
      const returnTo = encodeURIComponent(`/?${search.toString()}`)

      redirectUri.searchParams.set('returnTo', returnTo)

      if (data.usesAuth0) {
        loginWithRedirect({
          appState: { returnTo },
          authorizationParams: {
            redirect_uri: redirectUri.toString(),
            screen_hint: 'login',
            login_hint: email,
          },
        })
      } else {
        setDisplayPassword(true)
      }
    },
    onError(error) {
      if (error instanceof WretchError) {
        toast({
          status: 'error',
          title: t('error'),
          description: error.json.message ?? t('there_was_an_error'),
          isClosable: true,
        })
      } else {
        toast({
          status: 'error',
          title: t('error'),
          description: t('there_was_an_error'),
          isClosable: true,
        })
      }
    },
  })

  const { mutate: login } = useMutation({
    mutationKey: ['login'],
    mutationFn(data: LoginPayload) {
      return api.url('/login').post(data).json<{ token: string; user: any }>()
    },
    onSuccess({ token, user }) {
      dispatch(saveUserToken(token, 'local'))
      dispatch(updateUser(user))
      if (!user.isOnboardingCompleted) {
        navigate(
          `/v2/onboarding/${user.language}/${user.company._id}${
            user.department?._id ? '/' + user.department?._id : ''
          }?questionIndex=${user.questionIndex}`
        )
        return
      }

      // Cookies.set('useV2', '1')
      location.href = returnTo
      // navigate(returnTo)
    },
    onError(error) {
      if (error instanceof WretchError) {
        toast({
          status: 'error',
          title: t('error'),
          description: error.json.message ?? t('there_was_an_error'),
          isClosable: true,
        })
      } else {
        toast({
          status: 'error',
          title: t('error'),
          description: t('there_was_an_error'),
          isClosable: true,
        })
      }
    },
  })

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    const email = formData.get('email') as string

    if (!displayPassword && email) {
      checkEmail(email.trim())
    }

    const password = formData.get('password') as string
    const adminSecret = formData.get('adminSecret') as string

    if (displayPassword && email && password) {
      login({
        email: email.trim(),
        password: password.trim(),
        adminSecret: adminSecret?.length > 0 ? adminSecret : undefined,
      })
    }
  }

  if (isAuthenticated) {
    return <Navigate to={returnTo} />
  }

  return (
    <div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-sm space-y-10">
        <div>
          <img
            alt="Hupo logo"
            src="/logos/non-trademarked/orange/logo.png"
            className="mx-auto h-10 w-auto"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>

          <p className="mt-2 text-center text-base leading-6 text-gray-500">
            Hupo is 100% secure and confidential, so you can attend sessions
            with complete peace of mind.
          </p>
        </div>
        <form className="space-y-6" onSubmit={onSubmit}>
          <input id="adminSecret" type="hidden" name="adminSecret" />
          <div className="relative -space-y-px rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <div
                className={cn(
                  'mt-2 flex rounded-t-md shadow-sm',
                  displayPassword ? 'rounded-t-md' : 'rounded-md'
                )}
              >
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  required
                  placeholder="Email address"
                  autoComplete="email"
                  className={cn(
                    'relative block w-full border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6',
                    displayPassword ? 'rounded-t-md' : 'rounded-s-md'
                  )}
                />
                {!displayPassword && (
                  <button
                    type="submit"
                    className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-e-md px-3 py-2 text-sm font-semibold text-gray-900 bg-gray-50 hover:bg-gray-100 outline-none ring-1 ring-inset ring-gray-100 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary"
                  >
                    {isCheckingEmail ? (
                      <img src={spinner} alt="spinner" className="h-5 w-5" />
                    ) : (
                      <ChevronRightIcon
                        aria-hidden="true"
                        className="-ml-0.5 h-5 w-5 text-gray-400"
                      />
                    )}
                  </button>
                )}
              </div>
            </div>
            {displayPassword && (
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <div className="relative rounded-md shadow-sm">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    autoComplete="current-password"
                    className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                    required
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center">
                    <button
                      type="button"
                      className="inline-flex items-center rounded-br-md px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 outline-none ring-1 ring-inset ring-gray-100 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      {showPassword ? (
                        <EyeSlashIcon
                          aria-hidden="true"
                          className="h-5 w-5 text-gray-400"
                        />
                      ) : (
                        <EyeIcon
                          aria-hidden="true"
                          className="h-5 w-5 text-gray-400"
                        />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          {displayPassword && (
            <>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-3 block text-sm leading-6 text-gray-900"
                  >
                    Remember me
                  </label>
                </div>

                <div className="text-sm leading-6">
                  <Link
                    to="/password_forgot"
                    className="font-semibold text-primary hover:text-primary-400"
                  >
                    Forgot password?
                  </Link>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                >
                  Sign in
                </button>
              </div>
            </>
          )}
        </form>

        {/* <p className="text-center text-sm leading-6 text-gray-500">
          Not a member?{' '}
          <a
            href="#"
            className="font-semibold text-primary hover:text-primary-400"
          >
            Start a 14-day free trial
          </a>
        </p> */}
      </div>
    </div>
  )
}
