import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Input,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { FC, MutableRefObject, useRef } from 'react'
import * as yup from 'yup'

import { useAppSelector, useAppDispatch } from '~/app/hooks'
import { api } from '../../../app/services/api'
import { useLocale } from '../../../hooks/useLocale'
import { useLogout } from '../../../hooks/useLogout'
import OnboardingContainer from '../OnboardingContainer'
import OnboardingFooter from '../components/OnboardingFooter'
import OnboardingHeader from '../components/OnboardingHeader'
import {
  itemSelect,
  setCompanyId,
  setDepartmentId,
  setPreselectedCoachEmail,
  setUserEmail,
} from '../onboardingSlice'
import TitleDescription from './TitleDescription'

const ONBOARDING_EMAIL = 'onboarding-email'

const EmailQuestion: FC<any> = ({ index, onSubmit, submitting = false }) => {
  const { t } = useLocale()
  const onboardingQuestions = useAppSelector(
    (state) => state.onboarding.questions
  )
  const companyId = useAppSelector((state) => state.onboarding.companyId)

  const language = useAppSelector((state) => state.onboarding.language)
  console.log('EmailQuestion, companyId:', companyId, ', language:', language)
  const isLast = index === onboardingQuestions.length - 1

  const question = onboardingQuestions[index]
  const inputRef: any = useRef()
  const dispatch = useAppDispatch()
  const onLogout = useLogout()

  const {
    isOpen: isGoToLoginModalOpen,
    onOpen: onGoToLoginModalOpen,
    onClose: onGoToLoginModalClose,
  } = useDisclosure()
  const cancelRef = useRef() as MutableRefObject<HTMLInputElement>

  const toast = useToast()

  const emailValidation = yup
    .string()
    .email(t('please_enter_valid_email'))
    .required(t('email_is_required'))

  const navigateToLogin = () => {
    onLogout()
  }

  const onLocalSubmit = async () => {
    try {
      const email: any = inputRef.current
      if (question.optional && !email) {
        onSubmit()
        return
      }
      emailValidation.validateSync(email)
      if (question.friendlyID === ONBOARDING_EMAIL) {
        console.log('isUserAllowed query params -- ', { email, companyId })
        const isUserAllowedPromise = dispatch(
          api.endpoints.isUserAllowed.initiate({ email, companyId })
        )
        const isUserAllowedResult: any = (await isUserAllowedPromise) as any
        isUserAllowedPromise.unsubscribe()
        console.log('isUserAllowedResult -- ', isUserAllowedResult)
        if (isUserAllowedResult.isError) {
          if (isUserAllowedResult.error.data?.action === 'go_to_login') {
            onGoToLoginModalOpen()
          } else {
            toast({
              status: 'error',
              title: 'Error',
              description: isUserAllowedResult.error.data?.message,
              isClosable: true,
            })
          }
          return
        } else if (isUserAllowedResult.data.allowlistUser) {
          const allowlistUser = isUserAllowedResult.data.allowlistUser
          dispatch(setCompanyId(allowlistUser.company._id))
          if (allowlistUser.coach) {
            dispatch(setPreselectedCoachEmail(allowlistUser.coach.email))
          }
          if (allowlistUser.department?._id) {
            dispatch(setDepartmentId(allowlistUser.department._id))
          }
        }

        dispatch(setUserEmail(email.toLowerCase()))
      } else {
        dispatch(
          itemSelect({
            questionId: question.friendlyID,
            answers: inputRef.current.toLowerCase(),
          })
        )
      }
      onSubmit()
    } catch (validationError: any) {
      toast({
        title: 'Error',
        description: validationError.errors,
        status: 'error',
        isClosable: true,
      })
    }
  }

  return (
    <>
      <OnboardingHeader
        currentStep={index}
        totalSteps={onboardingQuestions.length - 1}
      />
      <OnboardingContainer direction="column" alignItems="center">
        <Stack gridRowGap={1} py={10}>
          <TitleDescription
            title={question.title}
            description={question.description}
          />
          <Input
            placeholder={t('email')}
            onChange={(event) => (inputRef.current = event.target.value)}
            onSubmit={onLocalSubmit}
            autoFocus={true}
          />
          <AlertDialog
            isOpen={isGoToLoginModalOpen}
            leastDestructiveRef={cancelRef}
            onClose={() => {
              onGoToLoginModalClose()
            }}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  {t('user_already_exists')}
                </AlertDialogHeader>
                <AlertDialogBody>{t('should_go_to_login')}</AlertDialogBody>
                <AlertDialogFooter>
                  <Button
                    ref={cancelRef as any}
                    onClick={() => {
                      onGoToLoginModalClose()
                    }}
                  >
                    {t('keep_editing')}
                  </Button>
                  <Button colorScheme="red" onClick={navigateToLogin} ml={3}>
                    {t('go_to_login')}
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </Stack>
        <OnboardingFooter
          onSubmit={onLocalSubmit}
          submitText={t('next')}
          submitting={submitting}
          isLastStep={isLast}
        />
      </OnboardingContainer>
      <div
        data-testid="onboarding-flow-test-hidden"
        data-onboarding-flow-test-type={question.type}
        data-onboarding-flow-test-index={index}
        data-onboarding-flow-test-id={question.friendlyID}
        data-onboarding-flow-test-islast={isLast}
        style={{ display: 'none' }}
      />
    </>
  )
}

export default EmailQuestion
