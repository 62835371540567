import {
  DyteAudioVisualizer,
  DyteNameTag,
  DyteScreenshareView,
} from '@dytesdk/react-ui-kit'
import { useDyteMeeting } from '@dytesdk/react-web-core'
import { useEffect } from 'react'
import { useMixpanel } from '../../../utils/MixpanelContext'

function ScreenShareView({ participant }: { participant: any }) {
  const { meeting } = useDyteMeeting()
  const mixpanel = useMixpanel()
  useEffect(() => {
    mixpanel.track('inapp_meeting_screen_share')
  }, [mixpanel])

  return (
    <DyteScreenshareView
      meeting={meeting}
      participant={participant}
      style={{ width: '80%' }}
    >
      <DyteNameTag participant={participant} meeting={meeting} isScreenShare>
        <DyteAudioVisualizer
          participant={participant}
          isScreenShare
          slot="start"
        />
      </DyteNameTag>
    </DyteScreenshareView>
  )
}

export default ScreenShareView
