import type { LoaderFunction } from 'react-router-dom'
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { api } from '~/app/api'

export interface Response {
  user: User[]
  manager: Manager[]
  peers: Peer[]
}

interface User {
  assessmentDate: string
  surveyAnswers: any[]
  ratings: Rating[]
}

interface Rating {
  focusArea: string
  rating: number
}

interface Manager {
  assessmentDate: string
  surveyAnswers: SurveyAnswer[]
  ratings: any[]
}

interface SurveyAnswer {
  question: string
  answers: string[]
  description?: string
}

interface Peer {
  assessmentDate: string
  shareWithUser: boolean
  surveyAnswers: SurveyAnswer2[]
  ratings: Rating2[]
}

interface SurveyAnswer2 {
  question: string
  description?: string
  answers: string[]
}

interface Rating2 {
  focusArea: string
  rating: number
}



const fetchAssessmentResults = (params: string) =>
  queryOptions({
    queryKey: ['assessment_results'],
    queryFn() {
      return api.url('/assessment/results').query(params).get().json<Response>()
    },
  })

export const loader = (queryClient: QueryClient): LoaderFunction => (args) => {
  const url = new URL(args.request.url)
  return queryClient.fetchQuery(fetchAssessmentResults(url.searchParams.toString()))
}
