import { useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core'
import { useEffect, useState } from 'react'
import { TbScreenShare, TbScreenShareOff } from 'react-icons/tb'

import { useMediaQuery } from '@chakra-ui/react'
import Badge from '@mui/material/Badge'
import clsx from 'clsx'
import { t } from 'i18next'
import { MessageSquare, Mic, MicOff, Video, VideoOff, X } from 'react-feather'
import { useMixpanel } from '../../../../utils/MixpanelContext'

const debounce = (func: any, wait: number) => {
  let timeout: any
  return function executedFunction(...args: any) {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

export const Controlbar = ({
  disableShareButton,
  screenShareEnabled,
  setDisplayChat,
  displayChat,
}: {
  disableShareButton: boolean
  screenShareEnabled: boolean
  setDisplayChat: (displayChat: boolean) => void
  displayChat: boolean
}) => {
  const { meeting } = useDyteMeeting()
  const mixpanel = useMixpanel()

  const participants = useDyteSelector((m) => m.participants.joined)

  const { videoEnabled, audioEnabled } = useDyteSelector((m) => ({
    videoEnabled: m.self.videoEnabled,
    audioEnabled: m.self.audioEnabled,
  }))

  const [userMessageMap, setUserMessageMap] = useState<any>({})
  const [newMessageCount, setNewMessageCount] = useState<number>(0)

  useEffect(() => {
    if (meeting.chat.messages.length > 0) {
      const messageMap = { ...userMessageMap }
      for (const msg of meeting.chat.messages) {
        if (msg.userId !== meeting.self.userId) {
          if (!(msg.id in messageMap) && !displayChat) {
            setNewMessageCount((count) => count + 1)
          }
          messageMap[msg.id] = msg.userId
        }
      }
      setUserMessageMap(messageMap)
    }
  }, [meeting.chat.messages.length])

  const toggleCamera = debounce(() => {
    if (meeting.self.videoEnabled) {
      meeting.self.disableVideo()
    } else {
      meeting.self.enableVideo()
    }
    mixpanel.track('inapp_meeting_camera_toggled', {
      enabled: meeting.self.videoEnabled,
    })
  }, 300)

  const toggleMic = debounce(() => {
    if (meeting.self.audioEnabled) {
      meeting.self.disableAudio()
    } else {
      meeting.self.enableAudio()
    }
    mixpanel.track('inapp_meeting_mic_toggled', {
      enabled: meeting.self.audioEnabled,
    })
  }, 300)

  const leaveMeeting = () => {
    meeting.leaveRoom()
    mixpanel.track('inapp_meeting_leave')
  }

  const toggleScreenShare = async () => {
    if (screenShareEnabled) {
      await meeting.self.disableScreenShare()
    } else {
      await meeting.self.enableScreenShare()
    }
    mixpanel.track('inapp_meeting_screenshare_toggled', {
      enabled: screenShareEnabled,
    })
  }

  const toggleChat = async () => {
    setNewMessageCount(0)
    setDisplayChat(!displayChat)
    mixpanel.track('inapp_meeting_chat_toggled', {
      opened: displayChat,
    })
  }

  const isMobileScreen = useMediaQuery('(max-width: 640px)')[0]

  return (
    <div className="z-20 flex w-full max-w-sm sm:w-min pt-4 justify-center">
      <div className="w-full p-4 gap-4 bg-neutral-800 shadow-2xl shadow-black rounded-2xl md:rounded-3xl flex flex-col">
        <div className="text-sm">
          <h1>{meeting.meta.meetingTitle}</h1>
          <div className="text-stone-400">
            {t('n_people_active').replace(
              '{{count}}',
              '' + (participants.size + 1)
            )}
          </div>
        </div>
        <div className="flex items-center justify-evenly gap-6">
          <button
            className={clsx(
              'p-3 flex items-center justify-center rounded-full',
              audioEnabled
                ? 'bg-white text-black'
                : 'bg-neutral-700 text-red-500'
            )}
            onClick={toggleMic}
          >
            {audioEnabled ? <Mic /> : <MicOff />}
          </button>
          <button
            className={clsx(
              'p-3 flex items-center justify-center rounded-full',
              videoEnabled
                ? 'bg-white text-black'
                : 'bg-neutral-700 text-red-500'
            )}
            onClick={toggleCamera}
          >
            {videoEnabled ? <Video /> : <VideoOff />}
          </button>
          <button
            className={clsx(
              'p-3 flex items-center justify-center rounded-full',
              displayChat ? 'bg-white text-black' : 'bg-neutral-700 text-white'
            )}
            onClick={toggleChat}
          >
            <Badge badgeContent={newMessageCount} color="error">
              <MessageSquare size={24} />
            </Badge>
          </button>
          {!isMobileScreen ? (
            <button
              className={clsx(
                'p-3 flex items-center justify-center rounded-full',
                screenShareEnabled
                  ? 'bg-white text-black'
                  : 'bg-neutral-700 text-white',
                !screenShareEnabled && disableShareButton
                  ? 'cursor-not-allowed'
                  : 'cursor-pointer'
              )}
              onClick={toggleScreenShare}
              disabled={disableShareButton}
            >
              {screenShareEnabled ? (
                <TbScreenShareOff size={24} />
              ) : (
                <TbScreenShare size={24} />
              )}
            </button>
          ) : null}
          <button
            className="bg-red-500 text-white p-3 flex items-center justify-center rounded-full"
            onClick={leaveMeeting}
          >
            <X />
          </button>
        </div>
      </div>
    </div>
  )
}
