import { useNavigate } from 'react-router-dom'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { useOnboardingContext } from '~/context/onboardingContext'
import { cn } from '~/utils/cn'

type Props = {
  className?: string
  isLoading?: boolean
  onClick?: () => void
  disabled?: boolean
  manualNext?: boolean
}

export const NextButton: React.FC<Props> = (props) => {
  const { className, isLoading = false, disabled = false, manualNext } = props
  const navigate = useNavigate()
  const { onNextQuestion, currentQuestionIndex, lastQuestionIndex } =
    useOnboardingContext()

  const isLastQuestion = currentQuestionIndex === lastQuestionIndex

  const onClick = () => {
    if (isLastQuestion && !props.onClick) {
      navigate('/', { replace: true })
      return
    }

    props.onClick?.()
    if (!manualNext) {
      onNextQuestion()
    }
  }

  return (
    <button
      type="button"
      className={cn(
        'inline-flex w-3/4 items-center justify-center gap-x-2 rounded-xl bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary disabled:bg-gray-400 disabled:cursor-not-allowed',
        className
      )}
      onClick={onClick}
      disabled={disabled || isLoading}
      data-testid="onboarding-next-button"
    >
      {isLoading ? 'Loading...' : isLastQuestion ? 'Finish' : 'Next'}
      <ChevronRightIcon aria-hidden="true" className="-mr-0.5 h-5 w-5" />
    </button>
  )
}
