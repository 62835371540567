import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { FileUploader } from 'react-drag-drop-files'

import { useLocale } from '../../hooks/useLocale'

const ChatUpload = ({ isOpen, onClose, handleFile }: any) => {
  const { t } = useLocale()

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('upload_file_title')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={1}>
          <FileUploader
            multiple={false}
            handleChange={handleFile}
            name="file"
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>
            {t('close')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ChatUpload
