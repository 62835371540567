import { useCallback, useMemo } from 'react'
import format from 'date-fns/format'
import addDays from 'date-fns/addDays'
import addMonths from 'date-fns/addMonths'
import startOfMonth from 'date-fns/startOfMonth'
import isSameDay from 'date-fns/isSameDay'
import isSameMonth from 'date-fns/isSameMonth'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'

import { generateMiniCalendar } from '~/utils/calendar'
import { cn } from '~/utils/cn'

type Props = {
  minDate?: Date
  maxDate?: Date
  excludedDates?: Date[]
  selectedDate: Date | null
  setSelectedDate: (date: Date) => void
  timeZone?: string
}

const now = new Date()

export const BookCalendar: React.FC<Props> = (props) => {
  const {
    timeZone,
    selectedDate,
    setSelectedDate,
    excludedDates = [],
    minDate,
    maxDate,
  } = props
  const days = useMemo(
    () => generateMiniCalendar(selectedDate ?? now),
    [selectedDate]
  )

  const isExcluded = useCallback(
    (date: Date) => excludedDates.some((d) => isSameDay(d, date)),
    [excludedDates]
  )

  const isPrevDisabled = useMemo(() => {
    const currentDate = selectedDate ?? now
    const date = startOfMonth(addMonths(currentDate, -1))

    return minDate ? isBefore(date, minDate) : false
  }, [selectedDate, minDate])

  const isNextDisabled = useMemo(() => {
    const currentDate = selectedDate ?? now
    const date = startOfMonth(addMonths(currentDate, 1))

    return maxDate ? isAfter(date, maxDate) : false
  }, [selectedDate, maxDate])

  const onMonthChange = useCallback(
    (direction: 'prev' | 'next') => {
      const amount = direction === 'prev' ? -1 : 1
      const currentDate = selectedDate ?? now
      let date = startOfMonth(addMonths(currentDate, amount))

      if (minDate && isBefore(date, minDate)) {
        date = minDate
      }

      if (maxDate && isAfter(date, maxDate)) {
        date = maxDate
      }

      while (isExcluded(date)) {
        date = addDays(date, 1)
      }

      setSelectedDate(date)
    },
    [selectedDate, setSelectedDate, isExcluded]
  )

  return (
    <div className="text-center">
      <div className="flex items-center text-gray-900">
        <button
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500 disabled:cursor-not-allowed disabled:text-gray-200"
          onClick={() => onMonthChange('prev')}
          disabled={isPrevDisabled}
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <div className="flex-auto text-sm font-semibold">
          <h2 className="text-gray-900">
            {format(selectedDate ?? now, 'MMMM yyyy')}
          </h2>
          {timeZone && (
            <p className="mt-0.5 text-xs text-gray-500">{timeZone}</p>
          )}
        </div>
        <button
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500 disabled:cursor-not-allowed disabled:text-gray-200"
          onClick={() => onMonthChange('next')}
          disabled={isNextDisabled}
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
        <div>M</div>
        <div>T</div>
        <div>W</div>
        <div>T</div>
        <div>F</div>
        <div>S</div>
        <div>S</div>
      </div>
      <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
        {days.map((day, dayIdx) => {
          const iso = day.date.toISOString()
          const isSelected = selectedDate
            ? isSameDay(day.date, selectedDate)
            : false
          const isCurrentMonth = isSameMonth(day.date, selectedDate ?? now)
          const isToday = day.isToday
          const isDisabled =
            (minDate ? isBefore(day.date, minDate) : false) ||
            isExcluded(day.date) ||
            (maxDate ? isAfter(day.date, maxDate) : false)

          return (
            <button
              key={day.date.toISOString()}
              type="button"
              disabled={isDisabled}
              className={cn(
                'py-1.5 hover:bg-gray-100 hover:cursor-pointer focus:z-10 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400',
                isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                (isSelected || isToday) && 'font-semibold',
                isSelected && 'text-white',
                !isSelected && isCurrentMonth && !isToday && 'text-gray-900',
                !isSelected && !isCurrentMonth && !isToday && 'text-gray-400',
                isToday && !isSelected && 'text-primary',
                dayIdx === 0 && 'rounded-tl-lg',
                dayIdx === 6 && 'rounded-tr-lg',
                dayIdx === days.length - 7 && 'rounded-bl-lg',
                dayIdx === days.length - 1 && 'rounded-br-lg'
              )}
              onClick={() => setSelectedDate(day.date)}
            >
              <time
                dateTime={iso}
                className={cn(
                  'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                  isSelected && isToday && 'bg-primary',
                  isSelected && !isToday && 'bg-gray-900'
                )}
              >
                {format(day.date, 'd')}
              </time>
            </button>
          )
        })}
      </div>
    </div>
  )
}
