import { useAsyncValue } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const Credits: React.FC = () => {
  const data = useAsyncValue() as { total?: number; used?: number }
  const { t } = useTranslation()

  if (!data.total || !data.used) return null

  return (
    <div className="overflow-hidden rounded-lg bg-white shadow">
      <div className="p-6">
        <h2
          id="credits-indicator-title"
          className="text-base font-medium text-gray-900"
        >
          Credits
        </h2>
        <div className="mt-6 flow-root">
          <ul role="list" className="-my-5 divide-y divide-gray-200">
            <li className="py-4">
              <div className="flex items-center space-x-4">
                <div className="min-w-0 flex-1">
                  <p className="truncate text-sm font-medium text-gray-900">
                    {data.used} / {data.total}{' '}
                    <span className="truncate text-sm text-gray-500">
                      {t('sessions')}
                    </span>
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
