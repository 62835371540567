import { Close } from '@mui/icons-material'
import {
  Alert,
  Badge,
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { Search } from 'react-feather'
import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie'

import libraryLoading from '../../assets/lottie/library.json'
import noData from '../../assets/lottie/no-data.json'
import { ResourceCard, ResourceCardType } from './ResourceCard'
import {
  ResourceLoading,
  ResourcesList,
  SearchWrapper,
  SuggestionsWrapper,
} from './resource.style'
import moment from 'moment'
import {
  useGetResourceRecommendationsQuery,
  useGetResourcesQuery,
  usePinResourceMutation,
  useUnpinResourceMutation,
} from '../../app/services/api'
import { searchFromString } from '../../utils/searchFromString'
import { Resource } from '../../types/api'
import { appTheme } from '../../theme/theme'
import { useMixpanel } from '../../utils/MixpanelContext'

function ResourcesLibrary() {
  const { t } = useTranslation()
  const mixpanel = useMixpanel()
  const [tabIndex, setTabIndex] = useState(0)
  const [currentResource, setCurrentResource] = useState<string | null>(null)
  const {
    data: resources,
    refetch: refetchResources,
  } = useGetResourcesQuery()
  const [resourceList, setResourceList] = useState<Resource[]>([])
  const [toast, setToast] = useState({ open: false, message: '' })
  const [pinResource, { isLoading: pinLoading }] = usePinResourceMutation()
  const [unpinResource, { isLoading: unPinLoading }] =
    useUnpinResourceMutation()
  const [search, setSearch] = useState('')

  const onToggle = useCallback(
    async (resourceId: any, isPinned: any, type: ResourceCardType) => {
      setCurrentResource(resourceId)
      try {
        if (isPinned) {
          await unpinResource(resourceId).unwrap()
          setToast({
            message: t('resourceUnpinned'),
            open: true,
          })
          mixpanel.track('resource_unpinned', { resourceId, type })
        } else {
          await pinResource(resourceId).unwrap()
          setToast({
            message: t('resourcePinned'),
            open: true,
          })
          mixpanel.track('resource_pinned', { resourceId, type })
        }
        refetchResources()
      } catch (error) {
        console.error('Error toggling resource pin:', error)
      } finally {
        setCurrentResource(null)
      }
    },
    [pinResource, refetchResources, t, unpinResource]
  )

  useEffect(() => {
    setResourceList(() =>
      (resources || []).filter(
        (item) =>
          searchFromString(item.name, search) ||
          searchFromString(item.summary, search) ||
          searchFromString(item.tags.join(' '), search) ||
          searchFromString(item.author, search) ||
          searchFromString(item.type, search)
      )
    )
  }, [resources, search])

  useEffect(() => {
    if (resources) {
      setResourceList(resources)
    }
  }, [resources])

  useEffect(() => {
    mixpanel.track('resources_screen_open')
  }, [])

  function ResourceRecommendations() {
    const { data: resources, isFetching } = useGetResourceRecommendationsQuery()
    const { t } = useTranslation()

    if (isFetching && !resources) {
      return (
        <ResourceLoading>
          <Lottie
            options={{
              animationData: libraryLoading,
              loop: true,
              autoplay: true,
            }}
            width={250}
          />
          <h3>{t('loading')}</h3>
        </ResourceLoading>
      )
    }

    return (
      <Box>
        <Typography
          variant="body2"
          fontSize="16px"
          sx={{
            textAlign: 'center',
            px: '10px',
            py: '20px',
            border: '1px solid #e0e0e0',
            borderRadius: '8px',
          }}
          fontFamily="system-ui"
        >
          {t('recommendedResourcesDescription')}
        </Typography>
        <SuggestionsWrapper>
          {resources && Object.keys(resources).length >= 0 ? (
            <div className="body">
              {Object.entries(resources).map(
                ([eventId, recommendation]: any) => {
                  const event = recommendation[0].event
                  const summary = recommendation[0].summary
                  return (
                    <div key={eventId} className="summaryItem">
                      <div className="summaryHeader">
                        <h3> {event.title} </h3>
                        <p>
                          {moment(event.start).format('D-MMM, h:mm A')} -{' '}
                          {moment(event.end).format('h:mm A')}
                        </p>
                      </div>
                      <div className="summaryBody">
                        {summary &&
                          summary.split('\n').map((part: any, index: any) => (
                            <p
                              key={`${eventId}_${index}-part`}
                              style={{
                                paddingBottom: '15px',
                                paddingTop: '0px',
                              }}
                            >
                              {part}
                            </p>
                          ))}
                        <ResourcesList>
                          {recommendation.map((item: any) => (
                            <ResourceCard
                              currentResource={currentResource}
                              loading={pinLoading || unPinLoading}
                              data={item.resource}
                              key={item.resource._id}
                              onToggle={onToggle}
                              reason={item.reason}
                              chapters={item.chapters}
                              type="recommended"
                            />
                          ))}
                        </ResourcesList>
                      </div>
                    </div>
                  )
                }
              )}
            </div>
          ) : (
            <Typography
              variant="body2"
              sx={{
                textAlign: 'center',
                px: '10px',
                py: '20px',
                backgroundColor: 'background.paper',
                border: '1px solid #e0e0e0',
                borderRadius: '4px',
              }}
            >
              {t('noRecommendations')}
              <Lottie
                options={{
                  animationData: noData,
                  loop: true,
                  autoplay: true,
                }}
                width={150}
              />
            </Typography>
          )}
        </SuggestionsWrapper>
      </Box>
    )
  }
  const handleTabsChange = (index: number) => {
    setTabIndex(index)
    mixpanel.track('resources_screen_page_changed', { pageId: index })
  }
  return (
    <Box sx={{ gap: '6px' }} bgcolor="#FFF" borderRadius={'10px'}>
      <Stack
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.palette?.divider}`,
        })}
      >
        <Tabs
          variant="fullWidth"
          value={tabIndex}
          onChange={(_event, value) => handleTabsChange(value)}
          sx={{
            width: '100%',
            minHeight: '44px',
            height: '44px',
            marginTop: '-4px',
          }}
        >
          <Tab
            sx={{
              color: '#000',
            }}
            label={
              <Typography
                variant="body2"
                sx={{ fontSize: '16px' }}
                fontFamily="system-ui"
              >
                {t('recommended')}
              </Typography>
            }
          />
          <Tab
            sx={{
              color: '#000',
            }}
            label={
              <Typography
                variant="body2"
                sx={{ fontSize: '16px' }}
                fontFamily="system-ui"
              >
                {t('resources_all')}
              </Typography>
            }
          />
          <Tab
            sx={{
              color: '#000',
            }}
            label={
              <Badge
                badgeContent={resourceList?.filter((r) => r.pinned).length}
                color="primary"
              >
                <Typography
                  variant="body2"
                  sx={{ fontSize: '16px' }}
                  fontFamily="system-ui"
                >
                  {t('pinned')}
                </Typography>
              </Badge>
            }
          />
        </Tabs>
      </Stack>
      <Stack gap="6px" p={3}>
        {tabIndex === 0 ? (
          <ResourceRecommendations />
        ) : (
          <>
            <SearchWrapper>
              <OutlinedInput
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                placeholder={t('search')}
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
                endAdornment={
                  search.length > 0 && (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setSearch('')}>
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
            </SearchWrapper>
            <ResourcesList>
              {tabIndex === 1
                ? structuredClone(resourceList)
                    .sort((a) => (a.pinned ? -1 : 1))
                    .sort((a) => (a.type === 'internal' ? -1 : 1))
                    .map((item) => (
                      <ResourceCard
                        currentResource={currentResource}
                        loading={pinLoading || unPinLoading}
                        data={item}
                        key={item._id}
                        onToggle={onToggle}
                        type="all"
                      />
                    ))
                : structuredClone(resourceList)
                    .sort((a) => (a.type === 'internal' ? -1 : 1))
                    ?.filter((r) => r.pinned)
                    .map((item) => (
                      <ResourceCard
                        currentResource={currentResource}
                        loading={pinLoading || unPinLoading}
                        data={item}
                        key={`pinned_${item._id}`}
                        onToggle={onToggle}
                        type="pinned"
                      />
                    ))}
            </ResourcesList>
          </>
        )}
      </Stack>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={toast.open}
        autoHideDuration={5000}
        onClose={() => setToast({ ...toast, open: false })}
      >
        <Alert
          onClose={() => setToast({ ...toast, open: false })}
          severity={'success'}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {toast.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export function ResourcesLibraryPage() {
  return (
    <ThemeProvider theme={appTheme}>
      <ResourcesLibrary />
    </ThemeProvider>
  )
}
