import { useEffect, useState } from 'react'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'

import {
  Box,
  Icon,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import { useLocale } from '../../../../hooks/useLocale'
import { getUnifiedCallLink } from '../../../../utils/calllink'
import { useMixpanel } from '../../../../utils/MixpanelContext'

export const AlternativeLinkMenu = ({ eventId, isCoach }: any) => {
  const alternativeLinkFromMenu = getUnifiedCallLink({
    eventId,
    isCoach,
    referrer: 'inapp-alternative-app-menu',
    skipInApp: true,
  })
  const [menuItemExpanded, setMenuItemExpanded] = useState(false)
  const mixpanel = useMixpanel()
  const { t } = useLocale()
  useEffect(() => {
    mixpanel.track('inapp_meeting_open_alternative_link_menu')
  }, [mixpanel])

  return (
    <Box>
      <Popover
        placement="bottom"
        onOpen={() => setMenuItemExpanded(true)}
        onClose={() => setMenuItemExpanded(false)}
      >
        <PopoverTrigger>
          <Box>
            <Icon as={menuItemExpanded ? IoIosArrowUp : IoIosArrowDown} m="4" />
            <Text></Text>
          </Box>
        </PopoverTrigger>
        <PopoverContent bgColor={'#1f1f1f'} borderRadius={6} ml={3}>
          <PopoverArrow />
          <PopoverCloseButton alignSelf={'flex-end'} mt={4} me={4} />
          <PopoverBody pl={7} pe={7} pb={7}>
            <Text>{t('inapp_call_alternative_menu_title')}</Text>
            <Text>
              {t('inapp_call_alternative_menu_subtitle').replace(
                '{{other_person}}',
                isCoach ? t('coachee') : t('coach')
              ) + ':'}
            </Text>
            <Link
              href={alternativeLinkFromMenu}
              isExternal
              color={'blue.400'}
              onClick={() =>
                mixpanel.track('inapp_meeting_alternative_link_click', {
                  alternativeLink: alternativeLinkFromMenu,
                })
              }
            >
              {alternativeLinkFromMenu}
            </Link>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}
