import { Star, StarBorder } from '@mui/icons-material'
import { Button, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ResourceCardWrapper } from './resource.style'
import { Resource } from '../../types/api'
import { useMixpanel } from '../../utils/MixpanelContext'

export type ResourceCardType = 'all' | 'pinned' | 'recommended' | 'coach_edit'

export function ResourceCard({
  data,
  loading,
  currentResource,
  onToggle,
  reason,
  chapters,
  onRemove,
  isEditMode = false,
  type,
}: {
  data: Resource
  loading: boolean
  currentResource?: string | null
  onToggle?: (
    resourceId: any,
    isPinned: any,
    type: ResourceCardType
  ) => Promise<void>
  reason?: string
  chapters?: string
  onRemove?: (resourceId: any) => void
  isEditMode?: boolean
  type: ResourceCardType
}) {
  const { t } = useTranslation()
  const mixpanel = useMixpanel()

  const paidLabel = data.paid ? t('purchase_required') : t('free')
  console.log('ResourceCard -> data', data)

  return (
    <ResourceCardWrapper className={data.type === 'internal' ? 'internal' : ''}>
      <div
        className={data.paid ? 'resourceStatusPaid' : 'resourceStatusIncluded'}
      >
        <span>{paidLabel}</span>
      </div>
      <div className="resourceBody">
        <div className="resourceContent">
          <div className="resourceTop">
            <h3 className="lineClamp" style={{ '--line-count': 2 } as any}>
              {data.name}
            </h3>
            <p>{data.author}</p>
          </div>

          <div className="resourceBottom">
            <span className="badge">{t(data.type)}</span>
            {!reason && (
              <>
                <p
                  className="summary lineClamp"
                  style={{ '--line-count': 3 } as any}
                >
                  {data.summary}
                </p>
                <p className="category">
                  {data.tags ? data.tags.join(' • ') : ''}
                </p>
              </>
            )}
          </div>

          {reason && (
            <div className="resourceBottom">
              <p
                className="summary lineClamp"
                style={{ 'font-weight': 'bold' } as any}
              >
                {t('recommendationReason')}
              </p>
              <p className="summary lineClamp">{reason}</p>
            </div>
          )}
          {chapters &&
            !['video', 'podcast', 'article', 'worksheet', 'internal'].includes(
              data.type
            ) && (
              <div className="resourceBottom">
                <p
                  className="summary lineClamp"
                  style={{ 'font-weight': 'bold' } as any}
                >
                  {t('recommendationChapters')}
                </p>
                <p
                  className="summary lineClamp"
                  style={{ '--line-count': 3 } as any}
                >
                  {chapters}
                </p>
              </div>
            )}
        </div>

        <div
          className="resourceFooter"
          style={{ gridTemplateColumns: reason ? '1fr' : '1fr 1fr' }}
        >
          <Button
            onClick={() =>
              mixpanel.track('resource_viewed', {
                resourceId: data._id,
                type,
              })
            }
            href={data.link}
            target="_blank"
            variant="contained"
          >
            {t('view')}
          </Button>
          {!reason && !isEditMode && (
            <Button
              disabled={currentResource === data._id && loading}
              onClick={
                onToggle
                  ? () => onToggle(data._id, data.pinned, type)
                  : () => {}
              }
              startIcon={
                currentResource === data._id && loading ? (
                  <CircularProgress color="inherit" size="14px" />
                ) : data.pinned ? (
                  <Star />
                ) : (
                  <StarBorder />
                )
              }
              variant={data.pinned ? 'soft' : 'outlined'}
            >
              {t(data.pinned ? 'unpin' : 'pin')}
            </Button>
          )}
          {isEditMode ? (
            <Button onClick={() => onRemove?.(data._id)} variant="outlined">
              {t('remove')}
            </Button>
          ) : null}
        </div>
      </div>
    </ResourceCardWrapper>
  )
}
