import { Flex, Button } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import { useLocale } from '../../hooks/useLocale'
import { Question } from './Question'
import { itemSelect } from './surveySlice'

const FeedbackSurveyQuestionsStep = (props: any) => {
  const { step, onProceedNextStep, submitSinglePage, canGoBack, isSinglePage } = props

  const { t } = useLocale()
  const [errorMessage, setErrorMessage] = useState('')
  const [showError, setShowError] = useState(false)

  const surveyData = useSelector((state: RootState) => state.survey.surveyData)
  const questions = surveyData?.questions && surveyData.questions
  const question = (questions || [])[step - 1]
  const minSelectionLimit = question?.minSelectionLimit
  const maxSelectionLimit = question?.maxSelectionLimit

  const selectedQuestions = useSelector(
    (state: RootState) => state.survey.questions
  )
  const selectedCount =
    (question &&
      (selectedQuestions[question._id]?.answers.length ||
      typeof selectedQuestions[question._id]?.other === 'string'
        ? 1
        : 0)) ||
    0

  useEffect(() => {
    switch (question?.type) {
      case 'select':
        if (selectedCount === 0) {
          setErrorMessage(t('select_an_item'))
        } else {
          setErrorMessage('')
          setShowError(false)
        }
        break
      case 'multiselect':
        if (
          minSelectionLimit &&
          maxSelectionLimit &&
          (selectedCount < minSelectionLimit ||
            selectedCount > maxSelectionLimit)
        ) {
          setErrorMessage(
            minSelectionLimit === maxSelectionLimit
              ? t('select_x_items', {
                  count: minSelectionLimit,
                })
              : t('select_between_x_y_items', {
                  min: minSelectionLimit,
                  max: maxSelectionLimit,
                })
          )
        } else if (minSelectionLimit && selectedCount < minSelectionLimit) {
          setErrorMessage(
            t('select_at_least_x_items', { count: minSelectionLimit })
          )
        } else if (selectedCount === 0) {
          setErrorMessage(t('select_at_least_one_item'))
        } else {
          setErrorMessage('')
          setShowError(false)
        }
        break
      default:
        setErrorMessage('')
        setShowError(false)
        break
    }
  }, [selectedCount, minSelectionLimit, maxSelectionLimit, question, t])

  const onProceed = useCallback(
    (step = 1) => {
      if (step < 0) {
        onProceedNextStep(step)
        return
      }

      if (errorMessage) {
        setShowError(true)
      } else {
        onProceedNextStep()
        console.log('onProceedNextStep')
      }
    },
    [errorMessage, onProceedNextStep]
  )

  if (!question) {
    return <>SURVEY NOT FOUND</>
  }

  if (isSinglePage) {
    return (
      <>
        {(questions || []).map((question: any) => (
          <Question
            key={question._id}
            question={question}
            itemSelect={itemSelect}
            onProceed={() => {}}
            errorMessage={errorMessage}
            showError={showError}
            setErrorMessage={setErrorMessage}
            setShowError={setShowError}
            canGoBack={canGoBack}
            isSinglePage={true}
          />
        ))}

        <Flex align="center" justify="center" mt="10">
          <Button
            colorScheme="primary"
            onClick={submitSinglePage}
            w={{ lg: 'sm', base: '250px' }}
            type="submit"
          >
            {t('submit')}
          </Button>
        </Flex>
      </>
    )
  }

  return (
    <Question
      question={question}
      itemSelect={itemSelect}
      onProceed={onProceed}
      errorMessage={errorMessage}
      showError={showError}
      setErrorMessage={setErrorMessage}
      setShowError={setShowError}
      canGoBack={canGoBack}
    />
  )
}

export default FeedbackSurveyQuestionsStep
