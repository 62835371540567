import type { Response } from './sessions/sessions.loader'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import {
  Link,
  useLoaderData,
  useRevalidator,
  useSearchParams,
} from 'react-router-dom'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { StarIcon, UserCircleIcon } from '@heroicons/react/24/outline'
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid'
import { WretchError } from 'wretch/resolver'

import { cn } from '~/utils/cn'
import { getUnifiedCallLink } from '~/utils/calllink'
import { RatingModal } from './sessions/_components/rating-modal'
import { AlertDialog } from '~/components/alert-dialog'
import { api } from '~/app/api'
import { useE2Etest } from '~/hooks/useE2Etest'
import Book from '~/features/scheduler/Book'
import { USING_V2 } from '~/app/constants'

const tabs = [
  {
    id: 'upcoming',
    name: 'Upcoming sessions',
    href: '?type=upcoming',
  },
  {
    id: 'past',
    name: 'Past sessions',
    href: '?type=past',
  },
]

const BOOKING_STATUS: Record<string, string> = {
  COMPLETED: 'Completed',
  LATE_CANCELLATION: 'Late cancellation',
  NO_SHOW: 'No show',
  LATE_RESCHEDULE: 'Late reschedule',
}

interface Config {
  minDaysInFuture: number
  maxDaysInFuture: number
  policyCancellationHours: number
  shouldInviteManager: boolean
  cadenceOptions: string[]
}

export const Sessions: React.FC = () => {
  const data = useLoaderData() as { data: Response[] }
  const { t } = useTranslation()
  const { revalidate } = useRevalidator()
  const [searchParams] = useSearchParams()
  const toast = useToast()
  const [selectedSession, setSelectedSession] = useState<{
    id: string
    startDate: string
    coachName: string
    isFirstSession: boolean
  }>({ id: '', startDate: '', coachName: '', isFirstSession: false })
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [isRatingModalOpen, setIsRatingModalOpen] = useState(false)
  const [isLateRescheduleModalOpen, setIsLateRescheduleModalOpen] =
    useState(false)
  const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false)
  const isE2Etest = useE2Etest()

  const tabValue = useMemo(
    () => searchParams.get('type') ?? 'upcoming',
    [searchParams]
  )

  const { data: bookingConfig } = useQuery({
    queryKey: ['sessions', 'config'],
    queryFn() {
      return api.url('/scheduler/config').get().json<{ config: Config }>()
    },
  })

  const isLateModifying = useMemo(() => {
    if (!bookingConfig || selectedSession.startDate.length === 0) return false

    return (
      bookingConfig.config.policyCancellationHours >
      moment(selectedSession.startDate).diff(moment(), 'hours')
    )
  }, [bookingConfig, selectedSession])

  const { mutate: cancelSession } = useMutation({
    mutationFn(sessionId: string) {
      return api
        .url('/scheduler/event')
        .body({
          eventId: sessionId,
          isE2Etest,
        })
        .delete()
        .json()
    },
    onSuccess() {
      toast({
        status: 'success',
        title: t('success'),
        description: t('session_is_successfully_cancelled'),
        isClosable: true,
      })

      revalidate()
    },
    onError(error) {
      if (error instanceof WretchError) {
        toast({
          status: 'error',
          title: t('error'),
          description: error.json.message ?? t('something_went_wrong'),
          isClosable: true,
        })
      }
    },
  })

  return (
    <>
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            defaultValue={tabValue}
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-300 focus:outline-none focus:ring-primary-300 sm:text-sm"
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav aria-label="Tabs" className="-mb-px flex">
              {tabs.map((tab) => (
                <Link
                  key={tab.name}
                  to={tab.href}
                  aria-current={tabValue === tab.id ? 'page' : undefined}
                  className={cn(
                    tabValue === tab.id
                      ? 'border-primary-300 text-primary'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'flex-1 text-center whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium'
                  )}
                >
                  {tab.name}
                </Link>
              ))}
            </nav>
          </div>
        </div>
      </div>

      {data.data.length > 0 && (
        <ul role="list" className="mt-4 px-5 divide-y divide-gray-100">
          {data.data.map((item) => (
            <li key={item._id} className="relative flex justify-between py-5">
              <div className="flex gap-x-4">
                {item.coach.profile.picture ? (
                  <img
                    alt={item.coach.profile.name}
                    src={item.coach.profile.picture}
                    className="h-12 w-12 flex-none rounded-full bg-gray-50 object-cover"
                  />
                ) : (
                  <UserCircleIcon className="h-12 w-12 flex-none rounded-full text-gray-400 bg-gray-50" />
                )}
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    {item.coach.profile.name}

                    {item.reason && (
                      <span className="ml-3 inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
                        {BOOKING_STATUS[item.reason] ?? 'Cancelled'}
                      </span>
                    )}
                  </p>
                  <p className="mt-1 flex text-xs leading-5 text-gray-500">
                    {moment(item.start).format('dddd, MMMM D ⋅ h:mm')} -{' '}
                    {moment(item.end).format('h:mma')}
                  </p>
                </div>
              </div>
              {tabValue === 'upcoming' ? (
                <div className="flex flex-none items-center gap-x-4">
                  <a
                    href={getUnifiedCallLink({
                      eventId: item._id,
                      referrer: 'new-app-bookings-page',
                      isCoach: false,
                    })}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                  >
                    Join the call
                    <span className="sr-only">
                      with {item.coach.profile.name}
                    </span>
                  </a>

                  <Menu as="div" className="relative flex-none">
                    <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon
                        aria-hidden="true"
                        className="h-5 w-5"
                      />
                    </MenuButton>
                    <MenuItems
                      transition
                      className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                    >
                      <MenuItem>
                        <button
                          className="block text-left w-full px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                          onClick={() => {
                            setSelectedSession({
                              id: item._id,
                              startDate: item.start,
                              coachName: item.coach.profile.name,
                              isFirstSession: item.isFirstSession,
                            })
                            isLateModifying
                              ? setIsLateRescheduleModalOpen(true)
                              : setIsRescheduleModalOpen(true)
                          }}
                        >
                          Reschedule
                          <span className="sr-only">
                            the session with {item.coach.profile.name}
                          </span>
                        </button>
                      </MenuItem>
                      <MenuItem>
                        <button
                          className="block text-left w-full px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                          onClick={() => {
                            setSelectedSession({
                              id: item._id,
                              startDate: item.start,
                              coachName: item.coach.profile.name,
                              isFirstSession: item.isFirstSession,
                            })
                            setIsCancelModalOpen(true)
                          }}
                        >
                          Cancel
                          <span className="sr-only">
                            the session with {item.coach.profile.name}
                          </span>
                        </button>
                      </MenuItem>
                    </MenuItems>
                  </Menu>
                </div>
              ) : (
                <div
                  className={cn(
                    'flex flex-none items-center',
                    item.rated ? 'gap-x-0.5' : 'gap-x-4'
                  )}
                >
                  {item.rated ? (
                    Array.from({ length: 5 }, (_, i) =>
                      (item.rating ?? 0) >= i + 1 ? (
                        <StarIconSolid
                          key={i}
                          className="text-primary-500 h-5 w-5"
                        />
                      ) : (
                        <StarIcon key={i} className="text-gray-300 h-5 w-5" />
                      )
                    )
                  ) : (
                    <button
                      type="button"
                      className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      onClick={() => {
                        setIsRatingModalOpen(true)
                        setSelectedSession({
                          id: item._id,
                          startDate: item.start,
                          coachName: item.coach.profile.name,
                          isFirstSession: item.isFirstSession,
                        })
                      }}
                    >
                      <StarIcon
                        aria-hidden="true"
                        className="-ml-0.5 h-5 w-5"
                      />
                      Rate
                    </button>
                  )}
                </div>
              )}
            </li>
          ))}
        </ul>
      )}

      {data.data.length === 0 && (
        <div className="mt-10 text-center">
          <h3 className="mt-2 text-sm font-semibold text-gray-900">
            You don't have any sessions coming up
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Get started by booking a new session
          </p>
          <div className="mt-6">
            <Link
              to={USING_V2 ? '/v2/book' : '/book'}
              className="inline-flex items-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
            >
              Book a session
            </Link>
          </div>
        </div>
      )}

      <RatingModal
        sessionId={selectedSession.id}
        coachName={selectedSession.coachName}
        wasFirstSession={selectedSession.isFirstSession}
        isOpen={isRatingModalOpen}
        onClose={() => setIsRatingModalOpen(false)}
      />

      <AlertDialog
        title="Cancel session"
        description={
          isLateModifying ? (
            t('delete_booking_confirmation_late_cancellation')
          ) : (
            <>
              <span className="block">
                Are you sure you want to cancel this session?
              </span>
              <span className="block">
                You can't undo this action afterwards.
              </span>
            </>
          )
        }
        isOpen={isCancelModalOpen}
        confirmText="Yes, cancel"
        onClose={() => setIsCancelModalOpen(false)}
        onConfirm={() =>
          selectedSession.id.length > 0 && cancelSession(selectedSession.id)
        }
      />

      <AlertDialog
        title="Late modification warning"
        description={t('reschedule_booking_confirmation_late_cancellation')}
        isOpen={isLateRescheduleModalOpen && isLateModifying}
        confirmText="Proceed"
        onClose={() => setIsLateRescheduleModalOpen(false)}
        onConfirm={() => {
          setIsLateRescheduleModalOpen(false)
          setIsRescheduleModalOpen(true)
        }}
      />

      {/* TODO: Update this old logic */}
      <Modal
        isOpen={isRescheduleModalOpen}
        onClose={() => {
          setIsRescheduleModalOpen(false)
          setIsLateRescheduleModalOpen(false)
        }}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('reschedule')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Book
              event={{ id: selectedSession.id }}
              onEventUpdate={() => {
                setIsRescheduleModalOpen(false)
                revalidate()
                toast({
                  status: 'success',
                  title: t('success'),
                  description: t('the_session_is_successfully_updated'),
                  isClosable: true,
                })
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
