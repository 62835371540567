import { useAsyncValue } from 'react-router-dom'
import format from 'date-fns/format'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import parseISO from 'date-fns/parseISO'
import { CalendarIcon, UserIcon } from '@heroicons/react/24/outline'
import {
  FaAssistiveListeningSystems,
  FaBinoculars,
  FaFlagCheckered,
  FaHeartbeat,
  FaRunning,
  FaShieldAlt,
  FaUsers,
} from './note-icons'
import { cn } from '~/utils/cn'

const ICONS = {
  FaFlagCheckered: FaFlagCheckered,
  FaHeartbeat: FaHeartbeat,
  FaBinoculars: FaBinoculars,
  FaUsers: FaUsers,
  FaRunning: FaRunning,
  FaAssistiveListeningSystems: FaAssistiveListeningSystems,
  FaShieldAlt: FaShieldAlt,
} as const

type IconName = keyof typeof ICONS

const resolveIcon = (iconName: string) => {
  const Icon = ICONS[iconName as IconName]
  if (!Icon) return null

  return <Icon className="text-white h-6" />
}

export const SessionNotes: React.FC = () => {
  const data = useAsyncValue() as any[]

  if (data.length === 0) return null

  return (
    <div className="overflow-hidden rounded-lg bg-white px-6 py-4 shadow-md">
      <h3 className="text-lg font-bold text-gray-900 sm:text-xl">Notes</h3>

      <div className="mt-3 divide-y-2">
        {data.map((note) => {
          const start = parseISO(note.session.start)
          const end = parseISO(note.session.end)

          return (
            <div key={note._id} className="pt-4">
              <div className="flex justify-between">
                <p className="flex items-center gap-2 text-sm text-gray-600">
                  <CalendarIcon className="h-5" />
                  {format(start, 'MMM dd, yyyy h:mm a')} (
                  {differenceInMinutes(end, start)}
                  min)
                </p>

                <p className="flex items-center gap-2 text-sm text-gray-600">
                  <UserIcon className="h-5" />
                  {note.coach.profile.name}
                </p>
              </div>

              <div
                className={cn(
                  'mb-4 mt-2',
                  !note.body && 'flex items-center justify-between'
                )}
              >
                <p className="break-all whitespace-normal text-gray-800">
                  {!note.body ? 'No notes' : note.body.text}
                </p>
              </div>

              {note.badges.length > 0 && (
                <div className="flex flex-wrap justify-center gap-3 my-3">
                  {note.badges.map((badge: any) => (
                    <button
                      key={badge._id}
                      id={badge._id}
                      data-tooltip-id={badge._id}
                      aria-label={badge.name}
                      className="flex max-sm:p-2.5 items-center justify-center bg-primary-400 h-10 w-10 sm:h-12 sm:w-12 rounded-full"
                    >
                      {resolveIcon(badge.iconName)}
                      {/* <Tooltip id={badge._id} place="top">
                        <p className="max-w-sm">
                          {t(badge.description.replace('badge_', ''), {
                            ns: 'badges',
                          })}
                        </p>
                      </Tooltip> */}
                    </button>
                  ))}
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}
