import { useMutation } from '@tanstack/react-query'
import { rawApi } from '~/app/api'
import { useAppDispatch } from '~/app/hooks'
import { saveUserToken, updateUser } from '~/features/auth/authSlice'

export const useUserDataWithAuth0Token = () => {
  const dispatch = useAppDispatch()

  const { mutate, ...rest } = useMutation({
    mutationFn(token: string) {
      return rawApi
        .url('/v2/auth0/user/me')
        .auth(`Bearer ${token}`)
        .get()
        .json<{ token: string; user: any }>()
    },
    onSuccess(data) {
      dispatch(saveUserToken(data.token, 'local')) // Save custom token
      dispatch(updateUser(data.user))
    },
  })

  return {
    getUserDataWithAuth0Token: mutate,
    ...rest,
  }
}
