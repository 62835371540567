import { useEffect, useState } from 'react'
import { Box, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

import { useLocale } from '../../hooks/useLocale'
import HH from './HH'
import Notes from './Notes'
import SessionNotes from './SessionNotes'

const UserNotesWithSessionNotes: React.FC = () => {
  const [tabIndex, setTabIndex] = useState(0)
  const navigate = useNavigate()
  const { t } = useLocale()

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    const tab = query.get('tab')
    switch (tab) {
      case 'my-notes':
        setTabIndex(1)
        break
      case 'hupo-highlights':
        setTabIndex(2)
        break
      default:
        setTabIndex(0)
    }
  }, [])

  const handleTabsChange = (index: number) => {
    setTabIndex(index)
    switch (index) {
      case 1:
        navigate('/notes?tab=my-notes')
        break
      case 2:
        navigate('/notes?tab=hupo-highlights')
        break
      default:
        navigate('/notes?tab=coach-notes')
    }
  }

  return (
    <Box bg="white" borderRadius={'10px'}>
      <Tabs
        isFitted
        colorScheme="primary"
        onChange={handleTabsChange}
        index={tabIndex}
      >
        <TabList>
          <Tab>{t('coach_notes')}</Tab>
          <Tab>{t('my_notes')}</Tab>
          <Tab>{t('hupo-highlights')}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            <SessionNotes />
          </TabPanel>
          <TabPanel px={0}>
            <Notes />
          </TabPanel>
          <TabPanel px={0}>
            <HH />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}
export default UserNotesWithSessionNotes
