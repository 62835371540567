import { useState } from 'react'
import { usePostHog } from 'posthog-js/react'

import { useOnboardingContext } from '~/context/onboardingContext'
import { NextButton } from './NextButton'

type Props = {
  id: string
  placeholder?: string
}

export const Text: React.FC<Props> = (props) => {
  const { id, placeholder } = props
  const posthog = usePostHog()

  const [value, setValue] = useState('')
  const { postOnboardingInfo, currentQuestionIndex } = useOnboardingContext()

  const onSubmit = () => {
    if (!value) return
    postOnboardingInfo({
      index: currentQuestionIndex,
      answers: { [id]: value },
    })
    posthog.capture('onboarding_text_input', { questionId: id })
  }

  return (
    <>
      <div className="w-3/4">
        <label htmlFor={id} className="sr-only">
          {placeholder}
        </label>
        <input
          id={id}
          type="text"
          placeholder={placeholder}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          data-testid="onboarding-input-text"
          required
        />
      </div>

      <NextButton disabled={!value} onClick={onSubmit} />
    </>
  )
}
