import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Center,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'

import { useLocale } from '../../hooks/useLocale'
import HupoPerspectivesResult from './HupoPerspectivesResult'
import PeerManagement from './PeerManagement'
import { useGetLeadershipValueScoresQuery } from '../../app/services/api'

const HupoPerspectivesPage = () => {
  const { t } = useLocale()
  const navigate = useNavigate()

  const [tabIndex, setTabIndex] = useState(0)

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    const tab = query.get('tab')
    if (tab === 'results') {
      setTabIndex(1)
    }
  }, [])

  const {
    data: leadershipValueScores,
    isLoading,
    error,
  } = useGetLeadershipValueScoresQuery({})

  const handleTabsChange = (index: number) => {
    setTabIndex(index)
    if (index === 1) {
      navigate('/hupo-perspectives?tab=results')
    } else {
      navigate('/hupo-perspectives?tab=respondent-management')
    }
  }

  if (isLoading)
    return (
      <Center>
        <Spinner size="2xl" />
      </Center>
    )
  if (error)
    return (
      <Center>
        <Text>{t('error_fetching_data')}</Text>
      </Center>
    )

  if (
    leadershipValueScores?.scores &&
    Object.keys(leadershipValueScores.scores.self).length === 0
  ) {
    return (
      <Center>
        <Box textAlign="center" py={4} px={6}>
          <Text>{t('complete_hupo_perspectives_before_viewing_insights')}</Text>
          <Button
            mt={4}
            mb={6}
            colorScheme="primary"
            onClick={() => navigate('/assessment')}
          >
            {t('complete_hupo_perspectives')}
          </Button>
        </Box>
      </Center>
    )
  }

  return (
    <Box bg="white" borderRadius={'10px'}>
      <Tabs
        isFitted
        colorScheme="primary"
        onChange={handleTabsChange}
        index={tabIndex}
      >
        <TabList>
          <Tab>{t('peer_response_management')}</Tab>
          <Tab>{t('results')}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            <PeerManagement leadershipValueScores={leadershipValueScores} />
          </TabPanel>
          <TabPanel px={0}>
            <HupoPerspectivesResult
              leadershipValueScores={leadershipValueScores}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default HupoPerspectivesPage
