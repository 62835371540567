import type { LoaderFunction } from 'react-router-dom'
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { api } from '~/app/api'

export type Response = Root[]

interface Root {
  _id: string
  body: {
    text: string
  }
  coach: {
    profile: Profile
    _id: string
  }
  session: Session
  badges: Badge[]
}

interface Profile {
  name: string
  video: string
  title: string
  videos: any[]
  picture: string
  businessName: string
}

interface Session {
  userShowedUp: boolean
  _id: string
  start: string
  end: string
}

interface Badge {
  automatic: boolean
  _id: string
  name: string
  description: string
  iconName: string
  createdAt: string
  updatedAt: string
  __v: number
}

const fetchData = () =>
  queryOptions({
    queryKey: ['session_notes'],
    queryFn() {
      return api.url('/user/session-notes').get().json<Response>()
    },
  })

// const fetchData2 = () =>
//   queryOptions({
//     queryKey: ['session_notes_with_events'],
//     queryFn() {
//       return api.url('/scheduler/events_notes').get().json<Response>()
//     },
//   })

export const loader = (queryClient: QueryClient): LoaderFunction => () => {
  return queryClient.fetchQuery(fetchData())
}
