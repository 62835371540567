import type { LoaderFunction } from 'react-router-dom'
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { api } from '~/app/api'

export interface Response {
  managers: Manager[]
  peers: Peer[]
  reportees: Reportee[]
}

export interface Manager {
  id: string
  email: string
  lastRequested: string
  requestedCount: number
  status: string
}

export interface Peer {
  id: string
  email: string
  lastRequested: string
  requestedCount: number
  status: string
}

export interface Reportee {
  id: string
  email: string
  lastRequested: string
  requestedCount: number
  status: string
}


const fetchData = (params: string) =>
  queryOptions({
    queryKey: ['assessment_respondent_status'],
    queryFn() {
      return api.url('/v2/user/assessment/respondent-status').query(params).get().json<Response>()
    },
  })

export const loader = (queryClient: QueryClient): LoaderFunction => (args) => {
  const url = new URL(args.request.url)
  return queryClient.fetchQuery(fetchData(url.searchParams.toString()))
}
