import type { PayloadAction } from '@reduxjs/toolkit'
import { LOCAL_TIMEZONE } from '~/app/constants'
import { createAppSlice } from '~/app/createAppSlice'

export interface PreferencesSliceState {
  timeZone: string
  timeFormat: '12' | '24'
}

const initialState: PreferencesSliceState = {
  timeZone: LOCAL_TIMEZONE,
  timeFormat: '12',
}

export const preferencesSlice = createAppSlice({
  name: 'preferences',
  initialState,
  reducers: (create) => ({
    setData: create.reducer(
      (state, action: PayloadAction<PreferencesSliceState>) => {
        state.timeZone = action.payload.timeZone
        state.timeFormat = action.payload.timeFormat
      },
    ),
    setTimeZone: create.reducer(
      (state, action: PayloadAction<PreferencesSliceState['timeZone']>) => {
        state.timeZone = action.payload
      },
    ),
    setTimeFormat: create.reducer(
      (state, action: PayloadAction<PreferencesSliceState['timeFormat']>) => {
        state.timeFormat = action.payload
      },
    ),
    clearData: create.reducer(() => initialState),
  }),
  selectors: {
    getTimeZone: (state) => state.timeZone,
    getTimeFormat: (state) => state.timeFormat,
    getFormatToken: (state) => (state.timeFormat === '12' ? 'h:mm a' : 'HH:mm'),
  },
})

export const {
  setData: setPreferences,
  clearData: clearPreferences,
  setTimeZone,
  setTimeFormat,
} = preferencesSlice.actions

export const { getTimeFormat, getTimeZone, getFormatToken } =
  preferencesSlice.selectors
