import {
  DyteAudioVisualizer,
  DyteAvatar,
  DyteClock,
  DyteLogo,
  DyteNameTag,
  DyteParticipantsAudio,
  DyteParticipantTile,
  DyteRecordingIndicator,
  provideDyteDesignSystem,
} from '@dytesdk/react-ui-kit'
import { useDyteSelector } from '@dytesdk/react-web-core'
import { useEffect, useState } from 'react'

import { Box, useMediaQuery } from '@chakra-ui/react'
import Draggable from 'react-draggable'
import ChatWindow from '../ChatWindow'
import ScreenShareView from '../ScreenShareView'
import { AlternativeLinkMenu } from './AlternativeLinkMenu'
import { Controlbar } from './Controlbar'
import { Grid } from './Grid'
import MeetingEnded from './MeetingEnded'
import { PostInAppCallUserFeedback } from './PostInAppCallUserFeedback'

// Taken from Facetime sample
// https://github.com/dyte-io/react-samples/tree/main/samples/facetime
export const Facetime = ({
  meeting,
  isCoach,
  eventId,
  roomLeft,
  roomName,
}: any) => {
  const [displayChat, setDisplayChat] = useState(false)
  const [meetingHappened, setMeetingHappened] = useState(false)

  const { screenShareEnabled } = useDyteSelector((m) => ({
    screenShareEnabled: m.self.screenShareEnabled,
  }))
  const participants = useDyteSelector((m) => m.participants.joined)

  const screenShareParticipant: any = [
    ...meeting.participants.joined.toArray(),
    meeting.self,
  ].find((p: any) => p.screenShareEnabled)

  const displayScreenShareView: any = screenShareParticipant ? true : false
  const disableShareButton =
    screenShareParticipant && screenShareParticipant?.id !== meeting.self.id

  useEffect(() => {
    provideDyteDesignSystem(document.body, {
      colors: {
        'video-bg': '#333333',
      },
    })
  }, [])
  useEffect(() => {
    if (!meetingHappened && participants.size > 0) {
      setMeetingHappened(true)
    }
  }, [participants.size, meetingHappened])

  const isMobileScreen = useMediaQuery('(max-width: 640px)')[0]

  if (roomLeft) {
    if (isCoach || !meetingHappened) {
      return (
        <MeetingEnded
          eventId={eventId}
          roomName={roomName}
          meetingHappened={meetingHappened}
        />
      )
    } else {
      return <PostInAppCallUserFeedback eventId={eventId} />
    }
  }

  return (
    <div className="h-full w-full p-4 flex flex-col bg-black text-white overflow-hidden">
      <DyteParticipantsAudio meeting={meeting} />
      <div className="absolute top-4 right-4">
        <DyteClock meeting={meeting} />
      </div>
      <div className="pb-4 pt-2 flex items-center justify-center">
        <DyteLogo logoUrl="/images/hupo-logo.svg" style={{ height: '30px' }} />
      </div>
      <Box display="flex" flex={1}>
        <Box
          display={isMobileScreen && displayChat ? 'none' : 'flex'}
          flex={4}
          mr={4}
        >
          {displayScreenShareView ? (
            <ScreenShareView participant={screenShareParticipant} />
          ) : null}
          <Grid
            meeting={meeting}
            displayScreenShareView={displayScreenShareView}
          />
        </Box>
        {displayChat ? <ChatWindow setDisplayChat={setDisplayChat} /> : null}
      </Box>
      {!displayScreenShareView &&
      (!isMobileScreen || (isMobileScreen && !displayChat)) ? (
        <Draggable bounds="parent">
          <DyteParticipantTile
            participant={meeting.self}
            key={meeting.self.id}
            meeting={meeting}
            className="z-10 absolute bottom-44 right-4 sm:bottom-4 shadow-black shadow-2xl aspect-square w-28 sm:w-40 md:w-52 h-auto cursor-move duration-0"
          >
            <DyteAvatar
              participant={meeting.self}
              size={isMobileScreen ? 'sm' : 'md'}
            />
            <DyteNameTag participant={meeting.self} size="md">
              <DyteAudioVisualizer
                participant={meeting.self}
                size="md"
                slot="start"
              />
            </DyteNameTag>
          </DyteParticipantTile>
        </Draggable>
      ) : null}
      <div className="absolute top-4 left-4">
        {participants.size > 0 ? (
          <AlternativeLinkMenu eventId={eventId} isCoach={isCoach} />
        ) : null}
        <DyteRecordingIndicator meeting={meeting} />
      </div>

      <Controlbar
        disableShareButton={disableShareButton}
        screenShareEnabled={screenShareEnabled}
        setDisplayChat={setDisplayChat}
        displayChat={displayChat}
      />
    </div>
  )
}
