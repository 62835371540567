import * as Sentry from '@sentry/react'
import { USING_PROD } from './app/constants'

const options: Sentry.BrowserOptions = {
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    // Sentry.reactRouterV5BrowserTracingIntegration({ history }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [import.meta.env.VITE_API_ENDPOINT],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: import.meta.env.MODE,
  enabled: import.meta.env.PROD,
}

if (USING_PROD && !window.isPlaywright) {
  Sentry.init(options)
}

export { Sentry }
