import { useEffect } from 'react'

import CoachInfo from '../coaches/CoachInfo'
import { useLazyGetCoachBioQuery } from '../../app/services/api'

// Welcome popup for users who logged in for the first time
const WelcomePopup = (props: any) => {
  const { isOpen, onClose, user } = props
  const [triggerGetCoachBio, { data: coachBio }] = useLazyGetCoachBioQuery()

  useEffect(() => {
    if (user) {
      triggerGetCoachBio()
    }
  }, [user, triggerGetCoachBio])

  if (!coachBio) {
    return null
  }

  return <CoachInfo isOpen={isOpen} onClose={onClose} coach={coachBio} />
}

export default WelcomePopup
