import { AnimatePresence, motion } from 'framer-motion'
import { ReactNode } from 'react'

export function Animated(props: { children: ReactNode; key: any }) {
  return (
    <AnimatePresence key={props.key}>
      <motion.div initial={{ y: 50,opacity:0 }} animate={{ y: 0,opacity:1 }}>
        {props.children}
      </motion.div>
    </AnimatePresence>
  )
}
