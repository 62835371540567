import type { PostHogConfig } from 'posthog-js'
import ReactDOM from 'react-dom/client'
import { ChakraProvider } from '@chakra-ui/react'
import flagsmith from 'flagsmith'
import { PostHogProvider } from 'posthog-js/react'
import { FlagsmithProvider } from 'flagsmith/react'
import { Provider } from 'react-redux'
import { QueryClientProvider } from '@tanstack/react-query'
import { PersistGate } from 'redux-persist/integration/react'

import App from './App'
import { persistor, store } from './app/store'
import MixpanelProvider from './utils/MixpanelContext'
import { theme } from './utils/theme'
import { queryClient } from './query'

import './index.css'
import './i18n'
import './sentry'
import './chatlio'
import { ErrorBoundary } from './error-boundary'

const isCheckly = () => {
  return navigator.userAgent.includes('Checkly, https://www.checklyhq.com')
}

const posthogOptions: Partial<PostHogConfig> = {
  name: 'Core app',
  person_profiles: 'identified_only',
  opt_out_capturing_persistence_type: 'localStorage',
  api_host: import.meta.env.VITE_POSTHOG_HOST,
  debug: import.meta.env.DEV,
  disable_session_recording:
    import.meta.env.DEV || isCheckly() || window.isPlaywright,
  opt_out_capturing_by_default:
    import.meta.env.DEV || isCheckly() || window.isPlaywright,
  session_recording: {
    maskAllInputs: true,
    maskTextSelector: '*',
  },
}

const container = document.getElementById('root')
if (!container) throw new Error('Root element not found')
const root = ReactDOM.createRoot(container)

root.render(
  // <React.StrictMode>
  <ErrorBoundary>
    <FlagsmithProvider
      flagsmith={flagsmith}
      options={{ environmentID: import.meta.env.VITE_FLAGSMITH_ENVIRONMENT_ID }}
    >
      <PostHogProvider
        apiKey={import.meta.env.VITE_POSTHOG_KEY}
        options={posthogOptions}
      >
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <QueryClientProvider client={queryClient}>
              <ChakraProvider theme={theme}>
                <MixpanelProvider>
                  <App />
                </MixpanelProvider>
              </ChakraProvider>
            </QueryClientProvider>
          </PersistGate>
        </Provider>
      </PostHogProvider>
    </FlagsmithProvider>
  </ErrorBoundary>
  // </React.StrictMode>
)
