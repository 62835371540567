import { Text, Textarea, useToast } from '@chakra-ui/react'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import OnboardingFooter from '../../onboarding/components/OnboardingFooter'
import OnboardingHeader from '../../onboarding/components/OnboardingHeader'
import OnboardingContainer from '../../onboarding/OnboardingContainer'
import TitleDescription from '../../onboarding/questionTypes/TitleDescription'
import { itemSelect } from '../assessmentSlice'

const FillBlanksQuestion: FC<any> = ({
  progress,
  question,
  answer,
  onSubmit,
}) => {
  const dispatch = useDispatch()
  const toast = useToast()
  const [value, setValue] = useState(answer ?? '')
  const user = useSelector((state: RootState) => state.assessment.user)

  const onLocalSubmit = () => {
    if (value.length > 0) {
      dispatch(
        itemSelect({
          questionId: question.friendlyID,
          answers: value,
        })
      )
      onSubmit()
    } else {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Please enter a value to the input',
        isClosable: true,
      })
    }
  }

  return (
    <>
      <OnboardingHeader
        currentStep={progress.currentStep}
        totalSteps={progress.totalSteps}
        isAssessment
      />
      <OnboardingContainer>
        <TitleDescription
          title={question.title.replaceAll('{{username}}', user)}
          description={question.description?.replaceAll('{{username}}', user)}
        />
        {question.prefix ? (
          <Text
            fontWeight="400"
            maxWidth={600}
            lineHeight={1.3}
            fontSize={['md']}
            textAlign="center"
            mb={4}
            mt={4}
          >
            {question.prefix}
          </Text>
        ) : null}
        <Textarea
          value={value}
          onChange={(event) => setValue(event.target.value)}
          placeholder={question.placeholder}
          height="200px"
        />
        <OnboardingFooter onSubmit={onLocalSubmit} submitText={'Next'} />
      </OnboardingContainer>
    </>
  )
}

export default FillBlanksQuestion
