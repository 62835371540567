import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Transition,
  TransitionChild,
  Radio,
  RadioGroup,
  Field,
  Label,
  Switch,
} from '@headlessui/react'
import { useToast } from '@chakra-ui/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useFeatureFlagEnabled, usePostHog } from 'posthog-js/react'
import { useTranslation } from 'react-i18next'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import addMonths from 'date-fns/addMonths'
import addMinutes from 'date-fns/addMinutes'
import {
  CalendarDaysIcon,
  ClockIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline'

import { LoadingSpinner } from '~/components/loading'
import { api } from '~/app/api'
import { useE2Etest } from '~/hooks/useE2Etest'
import { useAppSelector } from '~/app/hooks'
import { BookCalendar } from './book/_components/calendar'
import { cn } from '~/utils/cn'
import { DEFAULT_DURATION_MINS, USING_V2 } from '~/app/constants'
import { AlertDialog } from '~/components/alert-dialog'
import { WretchError } from 'wretch/resolver'

export const Book: React.FC = () => {
  const posthog = usePostHog()
  const toast = useToast()
  const { t } = useTranslation()
  const showInviteManager = useFeatureFlagEnabled('show_invite_manager')
  const showRecurringBooking = useFeatureFlagEnabled('recurring_booking')
  const navigate = useNavigate()
  const user = useAppSelector((state) => state.auth.user)
  const timeZone = useAppSelector((state) => state.preferences.timeZone)
  const now = useMemo(() => new Date(), [])
  const sessionDuration = useMemo(
    () => user?.company.sessionDuration ?? DEFAULT_DURATION_MINS,
    [user]
  )
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [slots, setSlots] = useState<{ iso: string; date: Date }[]>([])
  const [showSlots, setShowSlots] = useState(false)
  const isE2Etest = useE2Etest()
  const [selectedSlot, setSelectedSlot] = useState<Date | null>(null)
  const [triedDatesCount, setTriedDatesCount] = useState(0)
  const [bookedNewSession, setBookedNewSession] = useState(false)
  const [inviteManager, setInviteManager] = useState(false)
  const [recurring, setRecurring] = useState(false)
  const [isCadenceChangeable, setIsCadenceChangeable] = useState(true)
  const [sessionCadenceOptions, setSessionCadenceOptions] = useState<string[]>(
    []
  )
  const [sessionCadence, setSessionCadence] = useState<string | null>(null)
  const [recurringSessions, setRecurringSessions] = useState<Date[]>([])

  const {
    data: { data: excludedDates },
  } = useQuery({
    initialData: { data: [] },
    queryKey: ['excluded_dates'],
    queryFn() {
      return api
        .url('/scheduler/excluded_dates')
        .query({
          coachId: user?.coach._id,
          duration: sessionDuration,
          timezone: timeZone,
          isE2Etest,
        })
        .get()
        .json<{ data: string[] }>()
    },
  })

  const {
    mutate: fetchAvailability,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn(date: string) {
      return api
        .url('/scheduler/availability')
        .query({
          coachId: user?.coach._id,
          duration: sessionDuration,
          date,
          timezone: timeZone,
          isE2Etest,
          // extraCoachIds: [],
        })
        .get()
        .json<{ data: string[]; futureEventsCount: number }>()
    },
    onSuccess({ data }, date) {
      const slots = data.map((slot) => ({
        iso: slot,
        date: parseISO(slot),
      }))
      setSlots(slots)

      posthog.capture('appointment_create_date_select', {
        date,
        slotsCount: data.length,
      })

      setTriedDatesCount((count) => count + 1)
    },
  })

  const { data: assessmentDates } = useQuery({
    initialData: {
      midpointAssessmentDue: false,
      initialAssessmentRequired: false,
      initialAssessmentNextQuestionIndex: 0,
      midpointAssessmentRequired: false,
    },
    queryKey: ['assessment_dates'],
    queryFn() {
      return api.url('/assessment/dates').get().json<{
        midpointAssessmentDue: boolean
        initialAssessmentRequired: boolean
        initialAssessmentNextQuestionIndex?: number
        midpointAssessmentRequired: boolean
      }>()
    },
  })

  const { mutate: getAvailableRecurringDates } = useMutation({
    mutationKey: ['recurring_dates'],
    mutationFn(data: {
      companyId: string
      coachId: string
      date: string
      time: string
      duration: number
      timezone: string
      sessionCadence?: string
    }) {
      return api
        .url('/scheduler/available-recurring-dates')
        .query(data)
        .get()
        .json<{
          recurringSessions: string[]
          recurringCadenceConfig: {
            cadence: string
            isCadenceChangeable: boolean
            cadenceOptions: string[]
          }
        }>()
    },
    onSuccess({ recurringSessions, recurringCadenceConfig }) {
      setSessionCadence(recurringCadenceConfig.cadence)
      setSessionCadenceOptions(recurringCadenceConfig.cadenceOptions)
      setIsCadenceChangeable(recurringCadenceConfig.isCadenceChangeable)
      setRecurringSessions(recurringSessions.map((date) => parseISO(date)))

      posthog.capture('switched_recurring_booking')
    },
  })

  const { mutate: report } = useMutation({
    mutationFn(type: string) {
      return api.url('/user/report').post({ type }).json()
    },
  })

  useEffect(() => {
    let timeout: number

    if (triedDatesCount === 3) {
      timeout = window.setTimeout(() => {
        if (!bookedNewSession) {
          report('NO_BOOKING_AFTER_3_ATTEMPTS')
        }
      }, 5e3)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [report, triedDatesCount, bookedNewSession])

  const fetchRecurringDates = useCallback(
    (sessionCadence: string | null) => {
      if (!recurring) return
      const companyId = user?.company._id
      const coachId = user?.coach._id
      if (!companyId || !coachId || !selectedDate || !selectedSlot) return

      getAvailableRecurringDates({
        companyId,
        coachId,
        date: selectedDate.toISOString(),
        time: selectedSlot.toISOString(),
        duration: sessionDuration,
        timezone: timeZone,
        ...(sessionCadence ? { sessionCadence } : {}),
      })
    },
    [
      getAvailableRecurringDates,
      selectedDate,
      selectedSlot,
      sessionDuration,
      timeZone,
      user,
      recurring,
    ]
  )

  const { mutate: bookAppointment } = useMutation({
    mutationFn(data: {
      coachId: string
      start: string
      end: string
      inviteManager: boolean
      recurringSessions: any[]
    }) {
      return api
        .url('/scheduler/events')
        .post({
          userId: data.coachId,
          bookedByUser: true,
          isE2Etest,

          start: data.start,
          end: data.end,
          inviteManager: data.inviteManager,
          recurringSessions: data.recurringSessions,
        })
        .json()
    },
    onSuccess() {
      setBookedNewSession(true)
      posthog.capture('session_booked')
      if (recurring && recurringSessions.length > 0) {
        posthog.capture('recurring_sessions_booked', {
          recurringSessions,
        })
      }

      navigate(USING_V2 ? '/v2/bookings' : '/bookings')
    },
    onError(error) {
      if (error instanceof WretchError) {
        const isEventAlredyBooked = error.json?.code === 'EVENT_ALREADY_BOOKED'
        toast({
          status: 'error',
          title: t('error'),
          description:
            error.json?.message ??
            t(
              isEventAlredyBooked
                ? 'this_time_is_not_available'
                : 'there_was_an_error'
            ),
          isClosable: true,
        })

        if (isEventAlredyBooked) {
          onReset()
        }
      }

      posthog.capture('session_booking_error')
    },
  })

  const onDaySelect = useCallback(
    (date: Date) => {
      setSelectedDate(date)
      setShowSlots(true)
      fetchAvailability(date.toISOString())
      fetchRecurringDates(sessionCadence)
    },
    [fetchAvailability, sessionCadence, fetchRecurringDates]
  )

  const onReset = useCallback(() => {
    setSelectedDate(null)
    setSelectedSlot(null)
    setShowSlots(false)
    setInviteManager(false)
    setRecurring(false)
    setSessionCadence('weekly')

    posthog.capture('appointment_reset')
  }, [posthog])

  const onConfirmBooking = () => {
    const coachId = user?.coach._id
    if (!coachId || !selectedSlot) return

    const recurringSessionsData = !(showRecurringBooking && recurring)
      ? []
      : recurringSessions.map((date) => ({
          cadence: sessionCadence,
          start: date.toISOString(),
        }))

    bookAppointment({
      coachId,
      start: selectedSlot.toISOString(),
      end: addMinutes(selectedSlot, sessionDuration).toISOString(),
      inviteManager,
      recurringSessions: recurringSessionsData,
    })
  }

  return (
    <>
      <div className="md:flex md:justify-center md:divide-x md:divide-gray-200">
        <div className="text-center md:pr-14 md:w-2/5">
          <BookCalendar
            selectedDate={selectedDate}
            setSelectedDate={onDaySelect}
            timeZone={timeZone}
            excludedDates={excludedDates.map((date) => parseISO(date))}
            minDate={now}
            maxDate={addMonths(now, 2)}
          />

          {showInviteManager && user?.managerEmail && (
            <div className="mt-10 relative flex items-start justify-center">
              <div className="flex h-6 items-center">
                <input
                  id="invite-manager"
                  name="invite-manager"
                  type="checkbox"
                  aria-describedby="invite-manager-description"
                  checked={inviteManager}
                  onChange={(e) => {
                    setInviteManager(e.target.checked)

                    if (e.target.checked) {
                      toast({
                        status: 'info',
                        description: t('invite_manager_extra_info'),
                        isClosable: true,
                      })
                    }
                  }}
                  className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label
                  htmlFor="invite-manager"
                  className="font-medium text-gray-900"
                >
                  Invite manager
                </label>{' '}
                <span id="invite-manager-description" className="text-gray-500">
                  <span className="sr-only">Invite </span> ({user.managerEmail})
                </span>
              </div>
            </div>
          )}

          <Transition show={!!selectedSlot}>
            <div className="mt-10 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow transition duration-300 data-[closed]:translate-y-full">
              <div className="px-4 py-5 space-y-5">
                <div className="border-b border-gray-200 pb-2">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    {sessionDuration} minutes call with{' '}
                    {user?.coach.profile.name}
                  </h3>

                  {inviteManager && (
                    <p className="text-sm text-gray-500">(Manager invited)</p>
                  )}
                </div>

                <div className="flex justify-center gap-5">
                  <p className="flex justify-center items-center w-full">
                    <CalendarDaysIcon className="h-5 w-5 text-gray-400" />
                    <span className="ml-2 text-sm text-gray-500">
                      <time dateTime={selectedSlot?.toISOString()}>
                        {selectedSlot && format(selectedSlot, 'MMMM d, yyyy')}
                      </time>
                    </span>
                  </p>

                  <p className="flex justify-center items-center w-full">
                    <ClockIcon className="h-5 w-5 text-gray-400" />
                    <span className="ml-2 text-sm text-gray-500">
                      <time dateTime={selectedSlot?.toISOString()}>
                        {selectedSlot && format(selectedSlot, 'h:mm a')}
                      </time>
                    </span>
                  </p>
                </div>

                {showRecurringBooking && (
                  <>
                    <Field className="flex justify-center items-center">
                      <Switch
                        checked={recurring}
                        onChange={(value) => {
                          setRecurring(value)
                          fetchRecurringDates(sessionCadence)
                        }}
                        className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 data-[checked]:bg-primary"
                      >
                        <span
                          aria-hidden="true"
                          className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                        />
                      </Switch>
                      <Label as="span" className="ml-3 text-sm">
                        <span className="font-medium text-gray-900">
                          Set recurring booking
                        </span>
                        {/* {' '}<span className="text-gray-500">(Save 10%)</span> */}
                      </Label>
                    </Field>

                    {recurring && (
                      <div>
                        <select
                          id="cadence"
                          name="cadence"
                          value={sessionCadence ? sessionCadence : ''}
                          onChange={(e) => {
                            const val = e.target.value
                            posthog.capture(
                              'selected_recurring_cadence_option',
                              {
                                cadence: val,
                              }
                            )
                            setSessionCadence(val)
                            fetchRecurringDates(val)
                          }}
                          disabled={!isCadenceChangeable}
                          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6 disabled:opacity-50 disabled:bg-gray-100"
                        >
                          <option value="" disabled>
                            Select cadence
                          </option>
                          {sessionCadenceOptions.map((option) => (
                            <option key={option} value={option}>
                              {t(option)}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className="px-4 py-4 sm:px-6 flex gap-4">
                {/* We use less vertical padding on card footers at all sizes than on headers or body sections */}
                <button
                  type="button"
                  className="flex-auto inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                  onClick={onReset}
                >
                  Reset
                </button>

                <button
                  type="button"
                  className="flex-auto inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  onClick={onConfirmBooking}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Transition>
        </div>

        <Transition show={showSlots}>
          <TransitionChild>
            <section className="mt-12 md:mt-0 md:pl-14 md:flex-1 transition duration-300 data-[closed]:-translate-x-full">
              <h2 className="text-base font-semibold leading-6 text-gray-900">
                Schedule for{' '}
                {selectedDate && (
                  <time dateTime={selectedDate.toISOString()}>
                    {format(selectedDate, 'MMMM d, yyyy')}
                  </time>
                )}
              </h2>
              <fieldset className="mt-5" aria-label="Privacy setting">
                {isPending ? (
                  <div className="min-h-[80%] flex items-center">
                    <LoadingSpinner />
                  </div>
                ) : isSuccess && slots.length === 0 ? (
                  <p className="text-center text-base text-gray-500">
                    No available slots for this day.
                  </p>
                ) : (
                  <RadioGroup
                    value={selectedSlot}
                    onChange={(val) => {
                      setSelectedSlot(val)
                      fetchRecurringDates(sessionCadence)
                    }}
                    className="-space-y-px rounded-md bg-white md:max-h-[75vh] overflow-y-auto pr-5"
                  >
                    {slots.map((slot, i) => (
                      <Radio
                        key={slot.iso}
                        value={slot.date}
                        className={cn(
                          i === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                          i === slots.length - 1
                            ? 'rounded-bl-md rounded-br-md'
                            : '',
                          'group relative flex cursor-pointer border border-gray-200 p-4 focus:outline-none data-[checked]:z-10 data-[checked]:border-red-200 data-[checked]:bg-red-50'
                        )}
                      >
                        {user?.coach.profile.picture ? (
                          <img
                            src={user.coach.profile.picture}
                            alt={user.coach.profile.name}
                            className="h-10 w-10 flex-none rounded-full border border-solid border-gray-300"
                          />
                        ) : (
                          <UserCircleIcon className="h-10 w-10 flex-none rounded-full text-gray-500" />
                        )}
                        {/* <span
                      aria-hidden="true"
                      className="mt-0.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border border-gray-300 bg-white group-data-[checked]:border-transparent group-data-[checked]:bg-primary group-data-[focus]:ring-2 group-data-[focus]:ring-primary group-data-[focus]:ring-offset-2"
                    >
                      <span className="h-1.5 w-1.5 rounded-full bg-white" />
                    </span> */}
                        <span className="ml-3 flex flex-col">
                          <span className="block text-sm font-medium text-gray-900 group-data-[checked]:text-gray-900">
                            {user?.coach.profile.name}
                          </span>
                          <span className="block text-sm text-gray-500 group-data-[checked]:text-gray-700">
                            <time dateTime={slot.iso}>
                              {format(slot.date, 'h:mm a')}
                            </time>{' '}
                            -{' '}
                            <time dateTime={slot.iso}>
                              {format(slot.date, 'h:mm a')}
                            </time>
                          </span>
                        </span>
                      </Radio>
                    ))}
                  </RadioGroup>
                )}
              </fieldset>
            </section>
          </TransitionChild>
        </Transition>
      </div>

      <AlertDialog
        isOpen={
          assessmentDates.initialAssessmentRequired ||
          assessmentDates.midpointAssessmentRequired ||
          assessmentDates.midpointAssessmentDue
        }
        canClose={false}
        title="Assessment required"
        description="You have an assessment due or required. Please complete it before booking a new session."
        confirmText="Go to assessment"
        cancelText="Go back"
        onCancel={() => {
          navigate(USING_V2 ? '/v2' : '/')
        }}
        onConfirm={() => {
          const index = assessmentDates.initialAssessmentNextQuestionIndex ?? 0
          let link = '/assessment'

          if (assessmentDates.midpointAssessmentRequired) {
            link = '/assessment/midpoint'
          }

          if (index > 0) {
            link = `/assessment?questionIndex=${index}`
          }

          navigate(link)
        }}
        onClose={() => {}}
      />
    </>
  )
}
