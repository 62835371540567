import { Button, Center, Spinner, Text } from '@chakra-ui/react'
import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router'

import { useGetSessionStatsQuery } from '../../app/services/api'
import { useLocale } from '../../hooks/useLocale'
import { UserProgress } from './UserProgress'
import { useFlags } from 'flagsmith/react'

export const Stats: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useLocale()
  const {
    data: userProgress,
    isLoading,
    error,
    refetch,
    isSuccess,
  } = useGetSessionStatsQuery()
  const flags = useFlags(['show_usage_status'])

  useEffect(() => {
    if (isSuccess) {
      console.log('userProgress', userProgress)
    }
  }, [isSuccess, userProgress])

  const onClickBook = useCallback(() => {
    navigate('/book')
  }, [navigate])

  const progress = useMemo(() => {
    if (!userProgress) return 0
    if (!userProgress.completed) return 0
    return Math.min(1, userProgress.completed / userProgress.available)
  }, [userProgress])

  const statusText = useMemo(() => {
    if (!userProgress || userProgress.allAccess) {
      return undefined
    }
    if (
      userProgress.recommended === 0 ||
      ((userProgress.completed * 1.0) / userProgress.recommended) * 100 < 60
    ) {
      return {
        status: t('off_track'),
        description: t('off_track_description'),
        color: 'red',
      }
    }
    if (userProgress.completed > userProgress.recommended) {
      return {
        status: t('ahead_of_schedule'),
        description: t('ahead_of_schedule_description'),
        color: 'blue',
      }
    }
    return {
      status: t('on_track'),
      description: t('on_track_description'),
      color: 'green',
    }
  }, [userProgress, t])

  if (isLoading) {
    return <Spinner />
  }

  if (error) {
    return (
      <Center flexDirection="column">
        <Text>{t('an_error_occured')}</Text>
        <Button onClick={refetch} mt="2.5">
          {t('retry')}
        </Button>
      </Center>
    )
  }

  return (
    <UserProgress
      userProgress={userProgress}
      progress={progress}
      statusText={
        statusText && flags.show_usage_status.enabled ? statusText : undefined
      }
      onClickBook={onClickBook}
    />
  )
}
