import { useAsyncValue } from 'react-router-dom'
import {
  Label,
  PolarGrid,
  PolarRadiusAxis,
  RadialBar,
  RadialBarChart,
} from 'recharts'

import { Card, CardContent, CardFooter } from '~/components/ui/card'
import { ChartConfig, ChartContainer } from '~/components/ui/chart'
import { getRatingPercentage } from '~/utils/getRatingpercentage'

const chartConfig = {
  visitors: {
    label: 'Visitors',
  },
  safari: {
    label: 'Safari',
    color: 'hsl(var(--chart-2))',
  },
} satisfies ChartConfig

export const FocusArea: React.FC = () => {
  const data = useAsyncValue() as {
    focusArea: string
    group: string
    midpoint: number
  }[]

  if (!data || data.length === 0) return null

  return (
    <div className="overflow-hidden rounded-lg bg-white px-6 py-4 shadow-md">
      <h3 className="text-lg font-bold text-gray-900 sm:text-xl">
        Focus areas
      </h3>

      <div className="flex gap-3 flex-1 mt-5">
        {data.map((data, index) => (
          <Card
            key={index}
            className="flex flex-1 flex-col shadow-none border-none"
          >
            <CardContent className="flex-1 pb-0">
              <ChartContainer
                config={chartConfig}
                className="mx-auto aspect-square max-h-[250px]"
              >
                <RadialBarChart
                  data={[
                    {
                      group: data.group,
                      value: getRatingPercentage(data as any),
                      fill: '#FF4B0A',
                    },
                    {
                      group: 'empty',
                      value: 100,
                      fill: '#fff',
                    },
                  ]}
                  barSize={15}
                  innerRadius={70}
                  outerRadius={90}
                >
                  <PolarGrid
                    gridType="circle"
                    radialLines={false}
                    stroke="none"
                    className="first:fill-muted last:fill-background"
                    polarRadius={[86, 74]}
                  />
                  <RadialBar dataKey="value" background cornerRadius={10} isAnimationActive={false} />
                  <PolarRadiusAxis
                    tick={false}
                    tickLine={false}
                    axisLine={false}
                  >
                    <Label
                      content={({ viewBox }) => {
                        if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                          return (
                            <text
                              x={viewBox.cx}
                              y={viewBox.cy}
                              textAnchor="middle"
                              dominantBaseline="middle"
                            >
                              <tspan
                                x={viewBox.cx}
                                y={viewBox.cy}
                                className="fill-foreground text-3xl font-bold"
                              >
                                {getRatingPercentage(data as any)}%
                              </tspan>
                            </text>
                          )
                        }
                      }}
                    />
                  </PolarRadiusAxis>
                </RadialBarChart>
              </ChartContainer>
            </CardContent>
            <CardFooter className="text-sm justify-center">
              <p className="text-center font-medium leading-none">
                {data.focusArea}
              </p>
            </CardFooter>
          </Card>
        ))}
        {data.length < 3 && <div className="flex-1"></div>}
      </div>
    </div>
  )

  // return (
  //   <Card className="flex flex-col">
  //     <CardHeader className="items-center pb-0">
  //       <CardTitle>Pie Chart - Stacked</CardTitle>
  //       <CardDescription>January - June 2024</CardDescription>
  //     </CardHeader>
  //     <CardContent className="flex-1 pb-0">
  //       <ChartContainer
  //         config={chartConfig}
  //         className="mx-auto aspect-square max-h-[250px]"
  //       >
  //         <PieChart>
  //           <ChartTooltip
  //             content={
  //               <ChartTooltipContent
  //                 labelKey="visitors"
  //                 nameKey="month"
  //                 indicator="line"
  //                 labelFormatter={(_: any, payload: any) => {
  //                   return chartConfig[
  //                     payload?.[0].dataKey as keyof typeof chartConfig
  //                   ].label
  //                 }}
  //               />
  //             }
  //           />
  //           {/* <Pie data={desktopData} dataKey="desktop" outerRadius={60} /> */}
  //           <Pie
  //             data={mobileData}
  //             dataKey="mobile"
  //             innerRadius={70}
  //             outerRadius={90}
  //           />
  //         </PieChart>
  //       </ChartContainer>
  //     </CardContent>
  //     <CardFooter className="flex-col gap-2 text-sm">
  //       <div className="flex items-center gap-2 font-medium leading-none">
  //         Trending up by 5.2% this month <TrendingUp className="h-4 w-4" />
  //       </div>
  //       <div className="leading-none text-muted-foreground">
  //         Showing total visitors for the last 6 months
  //       </div>
  //     </CardFooter>
  //   </Card>
  // )
}
