import { Flex, Spinner, useToast } from '@chakra-ui/react'
import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useGetOnboardingQuestionsQuery,
  usePostOnboardingProfileDataMutation,
} from '../../app/services/api'
import { maybeChangeLocale, useLocale } from '../../hooks/useLocale'
import {
  setCompanyId,
  setDepartmentId,
  setLanguage,
  setOnboardingQuestions,
  setPreselectedCoachEmail,
} from './onboardingSlice'
import { DEFAULT_LOCALE } from '../../app/constants'
import { saveUserToken, updateUser } from '../auth/authSlice'
import { useAppDispatch } from '~/app/hooks'

const OnboardingLoadingScreen: FC = () => {
  const { language, companyId, departmentId, email } = useParams() as any
  const { data, isSuccess, error } = useGetOnboardingQuestionsQuery({
    language,
    companyId,
    departmentId,
    email,
  })
  console.log(
    'OnboardingLoadingScreen, language:',
    language,
    ', companyId:',
    companyId,
    ', departmentId:',
    departmentId,
    ', data:',
    data,
    ', isSuccess:',
    isSuccess,
    ', error:',
    error
  )
  const toast = useToast()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { i18n, t } = useLocale()
  const [postOnboardingProfileData] = usePostOnboardingProfileDataMutation()

  useEffect(() => {
    if (companyId && language) {
      dispatch(setCompanyId(companyId))
      dispatch(setLanguage(language))
      maybeChangeLocale(language, i18n.language)
    }
    if (departmentId) {
      dispatch(setDepartmentId(departmentId))
    }
  }, [companyId, language, departmentId, dispatch, i18n])

  useEffect(() => {
    const loginOrCreateUser = async () => {
      if (!email) {
        return
      }
      try {
        if (data?.userInfo?.accountExists && data?.userInfo?.token) {
          dispatch(saveUserToken(data.userInfo.token))
          dispatch(
            updateUser({ ...data.userInfo.user, skipAccountSetup: true })
          )
        } else if (data?.userInfo) {
          const onboardingData = {
            name: data.userInfo.name,
            email: data.userInfo.email,
            password: data.userInfo.password,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            companyId,
            departmentId,
            language: DEFAULT_LOCALE,
            interfaceLanguage: DEFAULT_LOCALE,
          }
          const { token, user } = await postOnboardingProfileData(
            onboardingData
          ).unwrap()
          dispatch(saveUserToken(token))
          dispatch(updateUser({ ...user, skipAccountSetup: true }))
        }

        if (data?.userInfo?.preselectedCoachEmail) {
          dispatch(
            setPreselectedCoachEmail(data.userInfo.preselectedCoachEmail)
          )
        }
      } catch (error: any) {
        console.error(error)
        toast({
          status: 'error',
          title: t('error'),
          description: error.data?.message || t('there_was_an_error'),
          isClosable: true,
        })
      }
    }

    loginOrCreateUser()
  }, [
    data,
    isSuccess,
    email,
    dispatch,
    companyId,
    departmentId,
    postOnboardingProfileData,
    toast,
    t,
  ])

  useEffect(() => {
    if (isSuccess) {
      dispatch(setOnboardingQuestions(data))
      const search = window.location.search
      const params = new URLSearchParams(search)
      const questionIndex = params.get('questionIndex')
      navigate(
        `/onboarding/${language}/${companyId}${
          departmentId ? '/' + departmentId : ''
        }/step/${questionIndex ?? 0}`
      )
    } else if (error) {
      toast({
        title: 'Error',
        description: 'Error occured while getting onboarding questions',
      })
      navigate('/', { replace: true })
    }
  }, [data, isSuccess, error, dispatch, navigate, toast, language, companyId])

  return (
    <Flex align="center" justify="center">
      <Spinner />
    </Flex>
  )
}

export default OnboardingLoadingScreen
