const audio = new Audio('/send.wav')

export const playSound = () => {
  if (
    audio.currentTime > 0 &&
    !audio.paused &&
    !audio.ended &&
    audio.readyState > audio.HAVE_CURRENT_DATA
  ) {
    audio.pause()
  }

  audio.currentTime = 0
  audio.play().catch((err) => {
    // Can't play sound probably because of user gesture
    console.error(err)
  })
}
