import { Stack, Textarea, useToast } from '@chakra-ui/react'
import { FC, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../app/store'
import { useLocale } from '../../../hooks/useLocale'
import OnboardingFooter from '../components/OnboardingFooter'
import OnboardingHeader from '../components/OnboardingHeader'
import { itemSelect } from '../onboardingSlice'
import OnboardingContainer from '../OnboardingContainer'
import TitleDescription from './TitleDescription'

const TextareaQuestion: FC<any> = ({ index, onSubmit, submitting = false }) => {
  const { t } = useLocale()
  const onboardingQuestions = useSelector(
    (state: RootState) => state.onboarding.questions
  )
  const question = onboardingQuestions[index]
  const isLast = index === onboardingQuestions.length - 1

  const inputRef: any = useRef()
  const dispatch = useDispatch()
  const toast = useToast()

  const onLocalSubmit = () => {
    if (inputRef.current && inputRef.current.length > 0) {
      dispatch(
        itemSelect({
          questionId: question.friendlyID,
          answers: inputRef.current,
        })
      )
      onSubmit()
    } else {
      toast({
        status: 'error',
        title: 'Error',
        description: t('please_enter_value_field'),
        isClosable: true,
      })
    }
  }
  return (
    <>
      <OnboardingHeader
        currentStep={index}
        totalSteps={onboardingQuestions.length - 1}
      />
      <OnboardingContainer>
        <Stack gridRowGap={1} py={10} px={2} maxWidth={600}>
          <TitleDescription
            title={question.title}
            description={question.description}
          />
          <Textarea
            placeholder={question.placeholder}
            onChange={(event) => (inputRef.current = event.target.value)}
            onSubmit={onLocalSubmit}
            data-testid="onboarding-text-area"
          />
        </Stack>
        <OnboardingFooter
          onSubmit={onLocalSubmit}
          submitText={t('next')}
          submitting={submitting}
          isLastStep={isLast}
        />
      </OnboardingContainer>
      <div
        data-testid="onboarding-flow-test-hidden"
        data-onboarding-flow-test-type={'textarea'}
        data-onboarding-flow-test-index={index}
        data-onboarding-flow-test-id={question.friendlyID}
        data-onboarding-flow-test-islast={isLast}
        style={{ display: 'none' }}
      />
    </>
  )
}

export default TextareaQuestion
