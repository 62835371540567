import { CheckIcon } from '@heroicons/react/20/solid'
import { cn } from '~/utils/cn'
import { NextButton } from './NextButton'

type Props = {
  currentStep: number
  steps: {
    _id: string
    title: string
    step: number
    description?: string
  }[]
}

export const Step: React.FC<Props> = (props) => {
  const { currentStep, steps = [] } = props

  return (
    <>
      <div className="flex flex-col gap-5 justify-center">
        <nav aria-label="Progress" className="mx-auto">
          <ol role="list" className="overflow-hidden">
            {steps.map((step, stepIdx) => (
              <li
                key={step._id}
                className={cn(
                  stepIdx !== steps.length - 1 ? 'pb-10' : '',
                  'relative'
                )}
              >
                {stepIdx < currentStep ? (
                  <>
                    {stepIdx !== steps.length - 1 ? (
                      <div
                        aria-hidden="true"
                        className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-primary"
                      />
                    ) : null}
                    <a
                      // href={step.href}
                      className={cn(
                        'group relative flex',
                        !step.description ? 'items-center' : 'items-start'
                      )}
                    >
                      <span className="flex h-9 items-center">
                        <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-primary group-hover:bg-primary-600">
                          <CheckIcon
                            aria-hidden="true"
                            className="h-5 w-5 text-white"
                          />
                        </span>
                      </span>
                      <span className="ml-4 flex min-w-0 flex-col">
                        <span className="text-sm font-medium">
                          {step.title}
                        </span>
                        {step.description && (
                          <span className="text-sm text-gray-500">
                            {step.description}
                          </span>
                        )}
                      </span>
                    </a>
                  </>
                ) : currentStep === stepIdx ? (
                  <>
                    {stepIdx !== steps.length - 1 ? (
                      <div
                        aria-hidden="true"
                        className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                      />
                    ) : null}
                    <a
                      // href={step.href}
                      aria-current="step"
                      className={cn(
                        'group relative flex',
                        !step.description ? 'items-center' : 'items-start'
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className="flex h-9 items-center"
                      >
                        <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary bg-white">
                          <span className="h-2.5 w-2.5 rounded-full bg-primary" />
                        </span>
                      </span>
                      <span className="ml-4 flex min-w-0 flex-col">
                        <span className="text-sm font-medium text-primary">
                          {step.title}
                        </span>
                        {step.description && (
                          <span className="text-sm text-gray-500">
                            {step.description}
                          </span>
                        )}
                      </span>
                    </a>
                  </>
                ) : (
                  <>
                    {stepIdx !== steps.length - 1 ? (
                      <div
                        aria-hidden="true"
                        className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                      />
                    ) : null}
                    <a
                      // href={step.href}
                      className={cn(
                        'group relative flex',
                        !step.description ? 'items-center' : 'items-start'
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className="flex h-9 items-center"
                      >
                        <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                          <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                        </span>
                      </span>
                      <span className="ml-4 flex min-w-0 flex-col">
                        <span className="text-sm font-medium text-gray-500">
                          {step.title}
                        </span>
                        {step.description && (
                          <span className="text-sm text-gray-500">
                            {step.description}
                          </span>
                        )}
                      </span>
                    </a>
                  </>
                )}
              </li>
            ))}
          </ol>
        </nav>
      </div>

      <NextButton />
    </>
  )
}
