import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MapPinIcon, DocumentIcon, PlayIcon } from '@heroicons/react/24/outline'

import { VideoModal } from './video-modal'
import { CoachBioModal } from './coach-bio-modal'
import { DEFAULT_LOCALE } from '~/app/constants'
import { cn } from '~/utils/cn'

type Props = {
  bio: any
  coach: any
  selected: boolean
  onSelect: (email: string) => void
  className?: string
  readOnly?: boolean
}

export const CoachCard: React.FC<Props> = (props) => {
  const { className, bio, coach, selected, onSelect, readOnly } = props
  const { t } = useTranslation()
  const [videoModalOpen, setVideoModalOpen] = useState(false)
  const [videoUrl, setVideoUrl] = useState<string>('')
  const [isCoachBioModalOpen, setIsCoachBioModalOpen] = useState(false)

  const coachVideos = useMemo(() => {
    if (!coach.profile) return []
    const defaultVideo = coach.profile.video

    if (defaultVideo && coach.profile.videos.length <= 1) {
      return [{ language: t(DEFAULT_LOCALE), url: defaultVideo }]
    }

    return (coach.profile.videos ?? []).map((video: any) => ({
      language: t(video.language),
      url: video.url,
    }))
  }, [t, coach])

  return (
    <div
      className={cn(
        'divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow',
        selected && 'bg-gray-100',
        className
      )}
    >
      <div className="px-4 py-5 sm:px-6 flex items-center gap-3">
        {/* We use less vertical padding on card headers on desktop than on body sections */}
        <img
          alt={coach.profile.name}
          src={coach.profile.picture}
          className="h-14 w-14 rounded-full object-cover"
        />

        <h2 className="text-md font-medium text-slate-900">
          {coach.profile.name}
        </h2>
      </div>
      <div className={cn('px-4 py-5 sm:p-6', readOnly && 'sm:pb-10')}>
        <p className="text-sm font-medium text-slate-500">
          {bio.summary.length > 500
            ? `${bio.summary.substring(0, 500)}...`
            : bio.summary}
        </p>

        {coach.location && (
          <p className="text-center font-semibold mt-4">
            <span className="inline-block align-top mr-1">
              <MapPinIcon aria-hidden="true" className="h-5 w-5" />
            </span>
            {coach.location}
          </p>
        )}

        {coachVideos.map((video: any) => (
          <button
            type="button"
            className={cn(
              'rounded-md flex gap-1 items-center justify-center bg-white w-full my-3 mt-10 px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
              selected && 'bg-gray-100'
            )}
            onClick={() => {
              setVideoUrl(video.url)
              setVideoModalOpen(true)
            }}
          >
            <span>
              <PlayIcon aria-hidden="true" className="h-4 w-4" />
            </span>
            {coachVideos.length > 1 && '[' + video.language + '] '}
            {t('watch_coach_intro')}
          </button>
        ))}

        <button
          type="button"
          className={cn(
            'rounded-md flex gap-1 items-center justify-center bg-white w-full mt-3 px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
            selected && 'bg-gray-100',
            coachVideos.length === 0 && 'mt-10'
          )}
          onClick={() => setIsCoachBioModalOpen(true)}
        >
          <span>
            <DocumentIcon aria-hidden="true" className="h-4 w-4" />
          </span>
          {t('read_more_coach_bio', { name: coach.profile.name })}
        </button>
      </div>

      {!readOnly && (
        <button
          type="button"
          className="w-full px-4 py-4 sm:px-6 text-sm font-semibold text-gray-900"
          title="Select coach"
          onClick={() => onSelect(coach.email)}
        >
          Select
        </button>
      )}

      <VideoModal
        open={videoModalOpen}
        onClose={() => setVideoModalOpen(false)}
        src={videoUrl}
      />

      <CoachBioModal
        open={isCoachBioModalOpen}
        onClose={() => setIsCoachBioModalOpen(false)}
        bio={bio}
        coach={coach}
      />
    </div>
  )
}
