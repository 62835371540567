document.addEventListener('chatlio.collapsed', () => {
  const path = location.pathname
  if (
    path.startsWith('/login')
    || path.startsWith('/assessment')
    || path.startsWith('/peer-assessment')
    || path.startsWith('/onboarding')
    || path.startsWith('/v2/onboarding')
  ) return

  window._chatlio.hide()
})
