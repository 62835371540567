import { Box, Container, Heading, Stack, Text } from '@chakra-ui/react'
import { useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useGetInvitationMutation } from '../../app/services/api'
import Book from './Book'

const BookPublic = () => {
  const { invitationId } = useParams() as any

  const [getInvitation, { data: invitation }] = useGetInvitationMutation()

  useEffect(() => {
    const loadInvitation = async () => {
      await getInvitation(invitationId)
    }
    loadInvitation()
  }, [invitationId, getInvitation])

  if (!invitation) return null

  return (
    <Container maxW={'3xl'}>
      <Stack
        as={Box}
        textAlign={'center'}
        spacing={{ base: 4, md: 7 }}
        py={{ base: 10, md: 30 }}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: 'xl', sm: '2xl', md: '4xl' }}
          lineHeight={'110%'}
        >
          <Trans i18nKey="book_your_first_call_with">
            Book your first call with <br />
            <Text as={'span'} color={'green.400'}>
              our coach
            </Text>
          </Trans>
        </Heading>
        <Text color={'gray.500'}>
          <Trans i18nKey="all_sessions_are_completely_confidential">
            All sessions are completely <b>confidential</b>.
          </Trans>
        </Text>
        <Book coach={invitation.coach} invitation={invitation} />
      </Stack>
    </Container>
  )
}

export default BookPublic
