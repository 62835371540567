import { useEffect } from 'react'

export const useHideChatlio = () => {
  // Hide Chatlio on meeting page
  useEffect(() => {
    const chatlio = (window as any)._chatlio
    if (chatlio && chatlio.hide) {
      chatlio.hide()
    }
    return () => {
      const chatlio = (window as any)._chatlio
      if (chatlio && chatlio.show) {
        chatlio.show()
      }
    }
  }, [])

  return {}
}
