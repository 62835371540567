import { useRouteError } from 'react-router-dom'
import { Box, Heading, Text, Button, Center } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { FiRefreshCcw } from 'react-icons/fi'
import { IoMdConstruct } from 'react-icons/io'

import { useLocale } from '../hooks/useLocale'
import { useLogout } from '../hooks/useLogout'
import { useAppSelector } from '~/app/hooks'
import { useEffect } from 'react'

interface ReportPayload {
  uri: string
  time: string
  message: string
  stack?: string
  userEmail?: string
}

const Error = () => {
  const error = useRouteError() as any
  const user = useAppSelector((state) => state.auth.user)
  const { t } = useLocale()
  const logout = useLogout()

  const { mutate } = useMutation({
    mutationKey: ['report', 'error'],
    async mutationFn(data: ReportPayload) {
      return fetch('https://api.hupo.co/v2/error/client', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      }).then((res) => res.json())
    },
  })

  useEffect(() => {
    if (import.meta.env.DEV) return console.error

    const timeWithZoneOffset = new Date().toLocaleString('ru-RU', {
      timeZoneName: 'short',
    })

    mutate({
      uri: location.href,
      time: timeWithZoneOffset,
      message: error?.message,
      stack: error?.stack,
      userEmail: user?.email,
    })
  }, [mutate, error, user])

  return (
    <Box textAlign="center" py={10} px={6}>
      <Center mb={10}>
        <IoMdConstruct size={100} color="#E2E8F0" />
      </Center>
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        mb={6}
        fontWeight="bold"
        lineHeight="shorter"
        color="gray.900"
      >
        {t('error')}
      </Heading>
      <Text fontSize="18px" mt={3} mb={10}>
        {error ?? t('error_description')}
      </Text>

      <Button
        size="lg"
        colorScheme="primary"
        leftIcon={<FiRefreshCcw />}
        onClick={logout}
      >
        {t('error_button')}
      </Button>
    </Box>
  )
}

export default Error
