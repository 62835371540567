import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { useAppSelector } from '~/app/hooks'

interface ReportPayload {
  uri: string
  time: string
  message: string
  stack?: string
  userEmail?: string
}

export const NotFound: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user)

  const { mutate } = useMutation({
    mutationKey: ['report', 'error'],
    async mutationFn(data: ReportPayload) {
      return fetch('https://api.hupo.co/v2/error/client', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      }).then((res) => res.json())
    },
  })

  useEffect(() => {
    if (import.meta.env.DEV) return console.error

    const timeWithZoneOffset = new Date().toLocaleString('ru-RU', {
      timeZoneName: 'short',
    })

    mutate({
      uri: location.href,
      time: timeWithZoneOffset,
      message: '404 (not-found.tsx)',
      userEmail: user?.email,
    })
  }, [mutate, user])

  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-primary-600">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Page not found
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          Sorry, we couldn’t find the page you’re looking for.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link
            to="/"
            className="rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            replace
          >
            Go back home
          </Link>
          <a
            href="mailto:team@hupo.co"
            className="text-sm font-semibold text-gray-900"
          >
            Contact support <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
    </main>
  )
}
