import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { useToast } from '@chakra-ui/react'

import { api } from '~/app/api'
import { Modal } from '~/components/modal'
import { TimeZoneSelect } from '~/components/TimeZoneSelect'
import { LoadingSpinner } from '~/components/loading'
import { LOCAL_TIMEZONE } from '~/app/constants'
import { useAppDispatch, useAppSelector } from '~/app/hooks'
import { setTimeZone } from '~/store/preferences.slice'

export const TimeZoneChangeAlert: React.FC = () => {
  const savedTimeZone = useAppSelector((state) => state.preferences.timeZone)
  const { t } = useTranslation()
  const toast = useToast()
  const dispatch = useAppDispatch()
  const [selected, setSelected] = useState(LOCAL_TIMEZONE)
  const [newTimezoneDetected, setNewTimezoneDetected] = useState(
    savedTimeZone !== LOCAL_TIMEZONE
  )

  const onClose = () => {
    setNewTimezoneDetected(false)
  }

  const { mutate, isPending } = useMutation({
    mutationKey: ['account', 'timezone'],
    mutationFn(timeZone: string) {
      return api.url('/users/profile').post({ timezone: timeZone }).json()
    },
    onSuccess(_, tz) {
      toast({
        status: 'success',
        title: t('success'),
        description: t('your_profile_is_updated'),
        isClosable: true,
      })
      dispatch(setTimeZone(tz))
      onClose()
    },
  })

  const time = useMemo(
    () =>
      new Date().toLocaleTimeString('en-US', {
        timeZone: selected,
        hour: '2-digit',
        minute: '2-digit',
      }),
    [selected]
  )

  if (isPending)
    return (
      <Modal
        open={newTimezoneDetected}
        onClose={onClose}
        title="Timezone detected"
      >
        <LoadingSpinner />
      </Modal>
    )

  return (
    <Modal
      open={newTimezoneDetected}
      onClose={onClose}
      title="Timezone detected"
      confirmText="Save"
      onConfirm={() => mutate(selected)}
    >
      <div className="space-y-2">
        <p>
          We detected your timezone as <strong>{LOCAL_TIMEZONE}</strong>. If
          this is incorrect, please change it.
        </p>

        <TimeZoneSelect defaultValue={LOCAL_TIMEZONE} onChange={setSelected} />

        <p className="text-sm text-gray-500 mt-1.5">
          Current time is: <span className="text-gray-600">{time}</span>
        </p>
      </div>
    </Modal>
  )
}
