import { ChevronLeftIcon } from '@heroicons/react/20/solid'
import { cn } from '~/utils/cn'

type Props = {
  width: number
  className?: string
  colorClass?: string
  onPrevQuestion?: () => void
}

export const Progress: React.FC<Props> = (props) => {
  const { className, colorClass: color, width, onPrevQuestion } = props

  return (
    <div
      aria-hidden="true"
      className={cn('flex items-center gap-3', className)}
    >
      {onPrevQuestion && (
        <button
          type="button"
          className={cn(
            'rounded-full p-2 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
            width === 100
              ? 'bg-green-500 hover:bg-green-600 focus-visible:outline-green-500'
              : 'bg-primary hover:bg-primary-600 focus-visible:outline-primary'
          )}
          onClick={onPrevQuestion}
        >
          <ChevronLeftIcon aria-hidden="true" className="h-5 w-5" />
        </button>
      )}

      <div className="overflow-hidden h-3 w-full rounded-full bg-gray-200">
        <div
          style={{ width: `${width}%` }}
          className={cn(
            'h-full rounded-full',
            width === 100 ? 'bg-green-500' : 'bg-primary',
            color
          )}
        />
      </div>
    </div>
  )
}
