import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import addDays from 'date-fns/addDays'
import isSameDay from 'date-fns/isSameDay'

const now = new Date()

export const generateMiniCalendar = (date: Date) => {
  const monthStart = startOfMonth(date)
  const monthEnd = endOfMonth(monthStart)

  const calendarMonthStart = startOfWeek(monthStart, { weekStartsOn: 1 })
  const calendarMonthEnd = endOfWeek(monthEnd, { weekStartsOn: 1 })

  const data = []
  let currentDay = new Date(calendarMonthStart)

  while (currentDay <= calendarMonthEnd) {
    const isToday = isSameDay(currentDay, now)

    data.push({
      isToday,
      date: currentDay,
    })

    currentDay = addDays(currentDay, 1)
  }

  return data
}
