import { useMemo } from 'react'
import { Link, useLoaderData } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFeatureFlagEnabled } from 'posthog-js/react'

import type { Response } from './progress/progress.loader'
import { Progress as ProgressBar } from '~/components/progress'
import { USING_V2 } from '~/app/constants'

export const Progress: React.FC = () => {
  const data = useLoaderData() as Response
  const { t } = useTranslation()
  const statusEnabled = useFeatureFlagEnabled('show_usage_status')

  const progress = useMemo(
    () => (!data.completed ? 0 : Math.min(1, data.completed / data.available)),
    [data]
  )

  const status = useMemo(() => {
    if (data.allAccess) return

    if (
      data.recommended === 0 ||
      ((data.completed * 1.0) / data.recommended) * 100 < 60
    ) {
      return {
        status: t('off_track'),
        description: t('off_track_description'),
        bgColor: 'bg-red-500',
        textColor: 'text-red-500',
      }
    }

    if (data.completed > data.recommended) {
      return {
        status: t('ahead_of_schedule'),
        description: t('ahead_of_schedule_description'),
        bgColor: 'bg-blue-500',
        textColor: 'text-blue-500',
      }
    }

    return {
      status: t('on_track'),
      description: t('on_track_description'),
      bgColor: 'bg-green-500',
      textColor: 'text-green-500',
    }
  }, [data, t])

  return (
    <>
      <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:px-6">
          {/* We use less vertical padding on card headers on desktop than on body sections */}
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {t('my_progress')}
          </h3>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <div className="space-y-2">
            <p>
              {t('sessions_completed')}:{' '}
              <span className="text-gray-500">{data.completed}</span>
            </p>
            <p>
              {t('total_sessions_available')}:{' '}
              <span className="text-gray-500">
                {Math.round(data.available)}
              </span>
            </p>
          </div>

          {progress > 0 && (
            <ProgressBar
              width={Math.round(progress * 100)}
              colorClass={status?.bgColor}
              className="my-5"
            />
          )}

          {status && statusEnabled && (
            <>
              <p className="mt-2">
                {t('status')}:{' '}
                <span className={`${status.textColor} font-semibold`}>
                  {status.status}
                </span>
              </p>

              <p className="text-center">{status.description}</p>

              {status.status === t('off_track') && (
                <Link
                  to={USING_V2 ? '/v2/book' : '/book'}
                  className="block mt-4 max-w-40 mx-auto px-4 py-2 text-center text-sm font-medium text-white bg-primary border border-transparent rounded-md shadow-sm hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  {t('make_appointment')}
                </Link>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}
