import { Auth0Provider, type AppState } from '@auth0/auth0-react'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = React.PropsWithChildren

export const Auth0ProviderWithRedirectCallback: React.FC<Props> = (props) => {
  const { children } = props
  const navigate = useNavigate()

  const onRedirectCallback = useCallback(
    (appState?: AppState) => {
      navigate(
        (appState?.returnTo && decodeURIComponent(appState.returnTo)) ||
          window.location.pathname,
        { replace: true }
      )
    },
    [navigate]
  )

  return (
    <Auth0Provider
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      authorizationParams={{
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
        redirect_uri: window.location.origin,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}
