import { Box, Flex, Spinner, useToast } from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetOnboardingLinkQuery } from '../../app/services/api'
import { useLocale } from '../../hooks/useLocale'
import { Logo } from '../../utils/Logo'

const OnboardingLink: FC<any> = () => {
  const { t } = useLocale()
  const toast = useToast()
  const navigate = useNavigate()

  const { companyFriendlyId } = useParams() as any
  const [errorMessage, setErrorMessage] = useState()

  const { data, isSuccess, isLoading, error } = useGetOnboardingLinkQuery({
    companyFriendlyId,
  })
  console.log(
    'OnboardingLink, companyFriendlyId:',
    companyFriendlyId,
    ', data:',
    data,
    ', isLoading:',
    isLoading,
    ', isSuccess:',
    isSuccess
  )
  useEffect(() => {
    if (error) {
      setErrorMessage((error as any).data?.message)
    }
  }, [error])

  useEffect(() => {
    if (errorMessage) {
      toast({
        status: 'error',
        title: t('error'),
        description: errorMessage,
        isClosable: true,
      })
    }
  }, [t, toast, errorMessage])

  useEffect(() => {
    if (data?.redirect) {
      navigate(data.redirect, { replace: true })
    }
  }, [data, navigate])

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      padding={10}
      direction="column"
    >
      <Logo />
      <Box mt={6}>
        <Spinner />
      </Box>
    </Flex>
  )
}

export default OnboardingLink
