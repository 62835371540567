import { Spinner, useToast } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { useAppSelector, useAppDispatch } from '~/app/hooks'
import { useNavigate, useParams } from 'react-router-dom'
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from '../../app/constants'
import {
  usePostOnboardingInfoMutation,
  usePostOnboardingProfileDataMutation,
} from '../../app/services/api'
import { store } from '../../app/store'
import { useMixpanel } from '../../utils/MixpanelContext'
import { saveUserToken } from '../auth/authSlice'
import { clearOnboardingData } from './onboardingSlice'
import CoachPreselectedQuestion from './questionTypes/CoachPreselectedQuestion'
import CoachSelectionQuestion from './questionTypes/CoachSelectionQuestion'
import EmailQuestion from './questionTypes/EmailQuestion'
import InfoQuestion from './questionTypes/InfoQuestion'
import SelectionQuestion from './questionTypes/SelectionQuestion'
import SetupProfile from './questionTypes/SetupProfile'
import StepInfo from './questionTypes/StepInfo'
import TextareaQuestion from './questionTypes/TextareaQuestion'
import InputTextQuestion from './questionTypes/InputTextQuestion'
import MultiEmailQuestion from './questionTypes/MultiEmailQuestion'
import { useAuth } from '../../hooks/useAuth'
import { useE2Etest } from '../../hooks/useE2Etest'

const OnboardingFlow: any = () => {
  const { user } = useAuth()
  const params = useParams() as any
  const questionIndex = parseInt(params.questionIndex)
  const { language, companyId, departmentId } = params
  const onboardingQuestions = useAppSelector(
    (state) => state.onboarding.questions
  )
  const preselectedCoachEmail = useAppSelector(
    (state) => state.onboarding.preselectedCoachEmail
  )
  const [postOnboardingProfileData] = usePostOnboardingProfileDataMutation()
  const [postOnboardingInfo] = usePostOnboardingInfoMutation()
  const [submitting, setSubmitting] = useState(false)
  const toast = useToast()
  const dispatch = useAppDispatch()
  const isE2Etest = useE2Etest()

  const navigate = useNavigate()
  const mixpanel = useMixpanel()
  useEffect(() => {
    mixpanel.track('onboarding_survey_questions', {
      questionIndex,
      questionCount: onboardingQuestions.length,
    })
  }, [mixpanel, onboardingQuestions.length, questionIndex])

  useEffect(() => {
    if (onboardingQuestions.length === 0) {
      navigate(
        `/onboarding/${language}/${companyId}${
          departmentId ? '/' + departmentId : ''
        }?questionIndex=${Number(questionIndex)}`
      )
    } else if (user && user.skipAccountSetup) {
      const questionType = onboardingQuestions[questionIndex]?.type
      if (questionType === 'email' || questionType === 'profile-setup') {
        navigate(
          `/onboarding/${language}/${companyId}${
            departmentId ? '/' + departmentId : ''
          }/step/${Number(questionIndex) + 1}`
        )
      }
    }
  }, [
    onboardingQuestions,
    questionIndex,
    user,
    navigate,
    language,
    companyId,
    departmentId,
  ])

  const onSaveInfo = useCallback(async () => {
    try {
      setSubmitting(true)
      const latestOnboarding = store.getState().onboarding
      const questionId = onboardingQuestions[questionIndex]?.friendlyID
      const isFinalStep = questionIndex === onboardingQuestions.length - 1
      const onboardingData = {
        userId: user?._id,
        questionIndex: questionIndex + 1,
        isFinalStep,
        answers: { [questionId]: latestOnboarding.answers[questionId] },
        isE2Etest,
      }

      const payload = await postOnboardingInfo(onboardingData).unwrap()

      if (payload.message) {
        toast({
          title: 'Error',
          status: 'error',
          description: payload.message,
          isClosable: true,
        })
      } else {
        if (isFinalStep) {
          dispatch(clearOnboardingData())
          location.href = '/'
        } else {
          navigate(
            `/onboarding/${language}/${companyId}${
              departmentId ? '/' + departmentId : ''
            }/step/${Number(questionIndex) + 1}`
          )
        }
      }
      setSubmitting(false)
    } catch (error: any) {
      console.log('onSubmitOnboardingInfo error:', error)
      mixpanel.track('onboarding_response_query_fail', {
        message: JSON.stringify(error),
      })
      if (error.data?.message) {
        toast({
          title: 'Error',
          description: error.data.message,
          status: 'error',
          isClosable: true,
        })
      }
      setSubmitting(false)
    }
  }, [
    companyId,
    departmentId,
    navigate,
    language,
    mixpanel,
    questionIndex,
    toast,
    isE2Etest,
    postOnboardingInfo,
    dispatch,
    user,
    onboardingQuestions,
  ])

  const onSubmitProfile = useCallback(async () => {
    try {
      setSubmitting(true)
      const latestOnboarding = store.getState().onboarding

      const onboardingData = {
        name: latestOnboarding.name,
        email: latestOnboarding.email,
        password: latestOnboarding.password,
        timezone: latestOnboarding.timezone,
        companyId: latestOnboarding.companyId,
        departmentId: latestOnboarding.departmentId,
        language: latestOnboarding.language,
        interfaceLanguage: SUPPORTED_LOCALES.includes(latestOnboarding.language)
          ? latestOnboarding.language
          : DEFAULT_LOCALE,
        preselectedCoachEmail: latestOnboarding.preselectedCoachEmail,
        questionIndex: questionIndex + 1,
        isE2Etest,
      }
      const payload = await postOnboardingProfileData(onboardingData).unwrap()

      if (payload.message) {
        toast({
          title: 'Error',
          status: 'error',
          description: payload.message,
          isClosable: true,
        })
      } else {
        // Save user token
        dispatch(saveUserToken(payload.token))
        navigate(
          `/onboarding/${language}/${companyId}${
            departmentId ? '/' + departmentId : ''
          }/step/${Number(questionIndex) + 1}`
        )
        mixpanel.track('onboarding_profile_added')
      }
      setSubmitting(false)
    } catch (err: any) {
      console.log('onSubmitOnboardingProfileForm error:', err)
      mixpanel.track('onboarding_setup_profile_fail', {
        message: err.data?.message,
      })
      if (err.data?.message) {
        toast({
          title: 'Error',
          description: err.data.message,
          status: 'error',
          isClosable: true,
        })
      }
      setSubmitting(false)
    }
  }, [
    companyId,
    departmentId,
    navigate,
    language,
    mixpanel,
    questionIndex,
    toast,
    isE2Etest,
    postOnboardingProfileData,
    dispatch,
  ])

  const onSubmit = useCallback(async () => {
    const questionType = onboardingQuestions[questionIndex]?.type
    if (
      ['info', 'step', 'email'].includes(questionType) &&
      questionIndex < onboardingQuestions.length - 1
    ) {
      navigate(
        `/onboarding/${language}/${companyId}${
          departmentId ? '/' + departmentId : ''
        }/step/${Number(questionIndex) + 1}`
      )
    } else if (questionType === 'profile-setup') {
      await onSubmitProfile()
    } else {
      await onSaveInfo()
    }
  }, [
    questionIndex,
    language,
    companyId,
    navigate,
    onSubmitProfile,
    onSaveInfo,
    departmentId,
    onboardingQuestions,
  ])

  if (questionIndex < onboardingQuestions.length) {
    const question = onboardingQuestions[questionIndex]
    switch (question.type) {
      case 'step':
        return <StepInfo index={questionIndex} onSubmit={onSubmit} />
      case 'textarea':
        return (
          <TextareaQuestion
            index={questionIndex}
            onSubmit={onSubmit}
            submitting={submitting}
          />
        )
      case 'info':
        return (
          <InfoQuestion
            index={questionIndex}
            onSubmit={onSubmit}
            submitting={submitting}
          />
        )
      case 'profile-setup':
        return (
          <SetupProfile
            index={questionIndex}
            onSubmit={onSubmit}
            submitting={submitting}
          />
        )
      case 'multiselect':
      case 'select':
        return (
          <SelectionQuestion
            index={questionIndex}
            onSubmit={onSubmit}
            submitting={submitting}
          />
        )
      case 'text':
        return (
          <InputTextQuestion
            index={questionIndex}
            onSubmit={onSubmit}
            submitting={submitting}
          />
        )
      case 'email':
        return (
          <EmailQuestion
            index={questionIndex}
            onSubmit={onSubmit}
            submitting={submitting}
          />
        )
      case 'emails':
        return (
          <MultiEmailQuestion
            index={questionIndex}
            onSubmit={onSubmit}
            submitting={submitting}
          />
        )

      case 'coach-selection':
        return preselectedCoachEmail ? (
          <CoachPreselectedQuestion index={questionIndex} onSubmit={onSubmit} />
        ) : (
          <CoachSelectionQuestion index={questionIndex} onSubmit={onSubmit} />
        )
      default:
        return <div>Unknown question type</div>
    }
  } else {
    return <Spinner />
  }
}

export default OnboardingFlow
