import { createSlice } from '@reduxjs/toolkit'

export interface Message {
  id: string
  message: string
  dateSent: string
  user: string
  coach: string
  sentByUser: boolean
  read: string
  file?: any
}

interface chatState {
  messages: Array<Message>
}

const initialState: chatState = {
  messages: []
}

const slice = createSlice({
  name: 'chat',
  initialState: initialState,
  reducers: {
    setMessages: (state, action) => {
      console.log('Set messages')
      console.log(action.payload)
      state.messages = action.payload
    },
    addMessage: (state, action) => {
      console.log('Adding a message')
      console.log(action.payload)
      state.messages.push(action.payload)
    }
  },
})

export default slice.reducer

export const { setMessages, addMessage } = slice.actions