export const processNewLineText = (text: string) => {
  if (!text || typeof text !== 'string') return ''
  return text.replace(/\\n/g, '\n')
}

export const getAnswer = (answer: any) => {
  if (!answer) return ''
  if (typeof answer === 'string') return answer
  if (typeof answer === 'object') return answer.other
  return ''
}

export const hasWords = (text: string) => {
  const hasWords = /\S/.test(text)
  return hasWords
}