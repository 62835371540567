import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Spinner,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { usePostSessionSummaryFeedbackMutation } from '../../app/services/api'
import { useAuth } from '../../hooks/useAuth'
import { useLocale } from '../../hooks/useLocale'
import { Logo } from '../../utils/Logo'
import Rating from '../customer/Rating'

const REDIRECT_SECONDS = 3
const LANDING_PAGE = 'https://hupo.co/'
const HOME_PAGE = `${window.location.protocol}//${window.location.host}`

const SessionSummaryFeedback = () => {
  const { t } = useLocale()
  const { user } = useAuth()
  const { eventId, authorId, rating: ratingParam } = useParams() as any
  const ratingValue = parseInt(ratingParam)
  const [rating, setRating] = useState(ratingValue)

  const [isRedirecting, setIsRedirecting] = useState(false)
  const [feedback, setFeedback] = useState('')
  const toast = useToast()

  const [postSessionSummaryFeedback, { isLoading }] =
    usePostSessionSummaryFeedbackMutation()

  const redirectTitle = user ? t('redirect_to_home') : t('redirect_to_landing')
  const redirectPage = user ? HOME_PAGE : LANDING_PAGE

  useEffect(() => {
    const submitInitialFeedback = async () => {
      try {
        await postSessionSummaryFeedback({
          eventId,
          authorId,
          rating,
          feedback,
        }).unwrap()
      } catch (err) {
        console.log(err)
      }
    }
    submitInitialFeedback()
  }, [])

  const onSubmit = useCallback(
    async (event: any) => {
      event.preventDefault()
      try {
        await postSessionSummaryFeedback({
          eventId,
          authorId,
          rating,
          feedback,
        }).unwrap()
        toast({
          title: t('feedback.success'),
          description: t('feedback.success_description'),
          status: 'success',
        })
      } catch (err: any) {
        console.log(err)
        toast({
          status: 'error',
          title: t('error'),
          description: err?.data?.message || t('there_was_an_error'),
          isClosable: true,
        })
      }
      setIsRedirecting(true)
      setTimeout(() => {
        window.location.href = redirectPage
      }, REDIRECT_SECONDS * 1000)
    },
    [
      eventId,
      postSessionSummaryFeedback,
      rating,
      t,
      toast,
      authorId,
      redirectPage,
      feedback,
    ]
  )

  if (isLoading || isRedirecting) {
    return (
      <Center h="200px" color="white">
        <Heading color="gray.500">{redirectTitle}</Heading>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Center>
    )
  }

  return (
    <Box flexDirection={'column'} textAlign="center" px={6} pb={10}>
      <Flex
        px={{ base: 4, md: 4 }}
        height="20"
        borderBottomWidth="1px"
        alignItems="center"
        justifyContent="center"
      >
        <Logo md={5} />
      </Flex>

      <Heading display="inline-block" as="h5" size="lg" pt={10} mx={20}>
        {t('rate_summarization')}
      </Heading>
      <Rating
        size={48}
        icon="star"
        scale={5}
        fillColor="gold"
        strokeColor="grey"
        rating={rating}
        setRating={(rating: number) => {
          console.log('Rating, set rating:', rating)
          setRating(rating)
        }}
      />
      <Flex width="100%" justifyContent="center" direction="column" mb={5}>
        <Heading display="inline-block" as="h5" size="lg" pt={6}></Heading>
        <Text fontSize="xl" mt={2} mb={5}>
          {t('rate_summarization_any_additional_feedback')}
        </Text>
        <Box>
          <Textarea
            fontSize={['sm', 'md', 'lg']}
            placeholder={t('additional_feedback_on_summary')}
            borderColor="gray.200"
            borderWidth="1px"
            borderRadius="md"
            width="400px"
            height="200px"
            resize="none"
            value={feedback}
            onChange={(event) => setFeedback(event.target.value)}
          />
        </Box>
      </Flex>
      {rating >= 1 && rating <= 5 ? (
        <Button
          onClick={onSubmit}
          colorScheme="primary"
          style={{ borderRadius: 100 }}
          px={20}
          display="block"
          margin={'0 auto'}
          height="50px"
          disabled={isLoading}
          fontSize="18px"
        >
          {t('submit')}
        </Button>
      ) : null}
    </Box>
  )
}

export default SessionSummaryFeedback
