import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  StackDivider,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import moment from 'moment-timezone'
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BsPlusCircleFill } from 'react-icons/bs'
import { FiEdit2, FiTrash2 } from 'react-icons/fi'
import { MdDateRange } from 'react-icons/md'

import {
  useDeleteUserNoteMutation,
  useGetNotesMutation,
  useGetUserNotesQuery,
  usePostNoteMutation,
  usePostUserNoteMutation,
  useUpdateNoteMutation,
  useUpdateUserNoteMutation,
} from '../../app/services/api'
import { useAuth } from '../../hooks/useAuth'
import { useMixpanel } from '../../utils/MixpanelContext'
import { parseText } from '../../utils/string'

const Notes = () => {
  const { user } = useAuth()
  if (!user) throw new Error('User is not authenticated')
  const userId = user._id
  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure()
  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure()
  const { t } = useTranslation()
  const [note, setNote] = useState('')
  const [updating, setUpdating] = useState(false)
  const [noteId, setNoteId] = useState<string | null>(null)
  const [getNotes, { data: notes, isLoading }] = useGetNotesMutation()
  const [postNote] = usePostNoteMutation()
  const [updateNote] = useUpdateNoteMutation()
  const { data: userNotes } = useGetUserNotesQuery()
  const [deleteUserNote] = useDeleteUserNoteMutation()
  const [updateUserNote] = useUpdateUserNoteMutation()
  const [createUserNote] = usePostUserNoteMutation()
  const mixpanel = useMixpanel()

  const form = useForm({ reValidateMode: 'onBlur' })

  const onClose = useCallback(() => {
    onModalClose()
    form.reset({
      session_length: undefined,
      topics: [],
      techniques: [],
      flags: undefined,
      action_items: undefined,
      follow_ups: undefined,
    })
    setUpdating(false)
  }, [form, onModalClose])

  useEffect(() => {
    if (user) {
      mixpanel.track('note_screen_open')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toast = useToast()
  const initialRef = useRef() as any
  const cancelRef = useRef() as MutableRefObject<HTMLInputElement>

  const retrieveNotes = useCallback(
    async (userId: string) => {
      await getNotes(userId)
    },
    [getNotes]
  )

  useEffect(() => {
    if (userId) {
      retrieveNotes(userId)
    }
  }, [retrieveNotes, userId])

  const onAdd = useCallback(() => {
    setNote('')
    onOpen()
    if (user) {
      mixpanel.track('note_create')
    }
  }, [onOpen, mixpanel, user])

  const onNoteChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setNote(e.target.value)
    },
    [setNote]
  )

  const onSubmit = useCallback(
    async (event: any) => {
      event.preventDefault()
      try {
        if (updating) {
          const updatedNote = {
            id: noteId,
            body: { body: note },
          }
          try {
            await updateUserNote(updatedNote).unwrap()
            mixpanel.track('note_update_success')
          } catch (error: any) {
            mixpanel.track('note_update_fail', {
              message: error?.data?.message,
            })
          }
        } else {
          try {
            const newNote = {
              body: note,
            }
            await createUserNote(newNote).unwrap()
            mixpanel.track('note_create_success')
          } catch (error: any) {
            mixpanel.track('note_create_fail', {
              message: error?.data?.message,
            })
          }
        }
        onClose()
        toast({
          status: 'success',
          description:
            'The note is successfully ' + (updating ? 'updated' : 'added'),
          isClosable: true,
        })
        setUpdating(false)
      } catch (err) {
        toast({
          status: 'error',
          title: 'Error',
          description: 'Error while saving a note',
          isClosable: true,
        })
      }
    },
    [
      updating,
      onClose,
      toast,
      noteId,
      note,
      updateUserNote,
      createUserNote,
      mixpanel,
    ]
  )

  const onCoachSubmit = useCallback(
    async () => {
      try {
        if (updating) {
          const updatedNote = {
            id: noteId!,
            body: { text: note },
          }
          await updateNote(updatedNote).unwrap()
        } else {
          const newNote = {
            userId,
            body: { text: note },
          }
          await postNote(newNote).unwrap()
        }
        await retrieveNotes(userId)
        onClose()
        toast({
          status: 'success',
          description:
            'The note is successfully ' + (updating ? 'updated' : 'added'),
          isClosable: true,
        })
        setUpdating(false)
      } catch (err) {
        toast({
          status: 'error',
          title: 'Error',
          description: 'Error while saving a note',
          isClosable: true,
        })
      }
    },
    [
      noteId,
      onClose,
      postNote,
      retrieveNotes,
      toast,
      updateNote,
      updating,
      userId,
      note,
    ]
  )

  const onEdit = useCallback(
    (e: any) => {
      const note = e.currentTarget.getAttribute('data-body')
      const noteId = e.currentTarget.getAttribute('data-id')
      setNote(note)
      setNoteId(noteId)
      setUpdating(true)
      onOpen()
      if (user) {
        mixpanel.track('note_update')
      }
    },
    [onOpen, mixpanel, user]
  )

  const onDelete = async () => {
    if (!noteId) return
    try {
      try {
        await deleteUserNote(noteId).unwrap()
        mixpanel.track('note_delete_success')
      } catch (error: any) {
        mixpanel.track('note_delete_fail', { message: error?.data?.message })
      }
      onAlertClose()
      toast({
        status: 'success',
        description: 'The note is successfully deleted',
        isClosable: true,
      })
    } catch (err) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Error while saving a note',
        isClosable: true,
      })
    }
  }

  const onDeleteClick = (e: any) => {
    setNoteId(e.currentTarget.getAttribute('data-id'))
    onAlertOpen()
  }

  return (
    <>
      <Grid gap="10px" bg={'white'} rounded={'lg'}>
        <Flex w={'full'} justifyContent={'space-between'}>
          <Box w={'full'} px={6} pt={4} pb={2}>
            <Text fontWeight="700" fontSize="22px">
              {user ? t('my_notes') : t('coach_private_notes')}
            </Text>
          </Box>
          <Box mt={3} mr={5}>
            <IconButton
              onClick={onAdd}
              colorScheme="primary"
              aria-label={t('create_new_note')}
              icon={<BsPlusCircleFill />}
              size={'sm'}
            />
          </Box>
        </Flex>
        <Divider />
        <Grid gap="10px" w={'full'} bg={'white'} px={6} py={3} rounded={'lg'}>
          {isLoading && (
            <Box textAlign="center" py={10} px={6}>
              <Spinner />
            </Box>
          )}
          {(notes && notes.length > 0) ||
          (userNotes && userNotes.length > 0) ? (
            <VStack
              divider={<StackDivider borderColor="gray.200" />}
              spacing={4}
              align="stretch"
            >
              {(notes ? notes : (userNotes as any[])).map((note: any) => (
                <Grid key={note._id} templateColumns="repeat(5, 1fr)" gap={4}>
                  <GridItem colSpan={7} my={2}>
                    <Flex my={2} color="gray">
                      <MdDateRange size="18px" />
                      <Text fontSize={'sm'} ml={1}>
                        {moment(note.createdAt).tz(user.timezone!).fromNow()}
                      </Text>
                    </Flex>
                    <Text>{parseText(note.body ? note.body.text : '')}</Text>
                  </GridItem>
                  <GridItem
                    colStart={8}
                    colEnd={11}
                    my={2}
                    alignSelf={'center'}
                  >
                    <IconButton
                      onClick={onEdit}
                      data-item={JSON.stringify(note.body)}
                      data-body={note.body && note.body.text}
                      data-id={note._id}
                      aria-label="Edit"
                      bg={'white'}
                      size="md"
                      icon={<FiEdit2 size="18px" />}
                    />
                    <IconButton
                      onClick={onDeleteClick}
                      data-id={note._id}
                      aria-label="Delete"
                      bg={'white'}
                      size="md"
                      icon={<FiTrash2 size="18px" />}
                    />
                  </GridItem>
                </Grid>
              ))}
            </VStack>
          ) : (
            <Box textAlign="center" py={10} px={6}>
              <Text fontSize="18px" mt={3} mb={2}>
                {t('no_notes_yet')}
              </Text>
            </Box>
          )}
        </Grid>
      </Grid>
      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
      >
        <form onSubmit={user ? onSubmit : form.handleSubmit(onCoachSubmit)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {updating ? t('update_note') : t('create_new_note')}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl isRequired mb={3}>
                <FormLabel>{t('note')}</FormLabel>
                <Textarea
                  isRequired
                  ref={initialRef}
                  value={note}
                  placeholder={`${t('notes')}...`}
                  onChange={onNoteChange}
                  size="lg"
                  height={'full'}
                  rows={8}
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button onClick={onClose} mr={3}>
                {t('cancel')}
              </Button>
              <Button colorScheme="green" type="submit">
                {updating ? t('update_note') : t('save')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
      <AlertDialog
        isOpen={isAlertOpen}
        onClose={onAlertClose}
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('are_you_sure')}
            </AlertDialogHeader>

            <AlertDialogBody>{t('are_you_sure_warning')}</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onAlertClose}>{t('cancel')}</Button>
              <Button colorScheme="red" onClick={onDelete} ml={3}>
                {t('continue')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default Notes
