import type { LoaderFunction } from 'react-router-dom'
import { type QueryClient, queryOptions } from '@tanstack/react-query'
import { api } from '~/app/api'

export interface Response {
  scores: Scores
  selfAnswers: SelfAnswer[]
  answers: Answer[]
  values: Values
  isComplete: boolean
  canShowLiveResults: boolean
  resultsPendingLabel?: string
  reportUrl?: string
}

interface Scores {
  self: Self
  peers: Peers
  reportees: Reportees
  managers: Managers
  range: Range
}

interface Self {
  "self-ownership-accountability": number
  "growth-mindset": number
  "nurturing-high-performing-teams": number
  "collaborative-excellence": number
  "aligning-with-the-business": number
  "driving-business-impact": number
}

interface Peers { }

interface Reportees {
  "self-ownership-accountability": number
  "growth-mindset": number
  "nurturing-high-performing-teams": number
  "collaborative-excellence": number
  "aligning-with-the-business": number
  "driving-business-impact": number
}

interface Managers { }

interface Range {
  "self-ownership-accountability": SelfOwnershipAccountability
  "growth-mindset": GrowthMindset
  "nurturing-high-performing-teams": NurturingHighPerformingTeams
  "collaborative-excellence": CollaborativeExcellence
  "aligning-with-the-business": AligningWithTheBusiness
  "driving-business-impact": DrivingBusinessImpact
}

interface SelfOwnershipAccountability {
  min: number
  max: number
}

interface GrowthMindset {
  min: number
  max: number
}

interface NurturingHighPerformingTeams {
  min: number
  max: number
}

interface CollaborativeExcellence {
  min: number
  max: number
}

interface AligningWithTheBusiness {
  min: number
  max: number
}

interface DrivingBusinessImpact {
  min: number
  max: number
}

interface SelfAnswer {
  question: string
  responses: string[]
}

interface Answer {
  question: string
  questionID: string
  descriptionItems: string[]
  responses: Responses
}

interface Responses {
  managers: any[]
  peers: any[]
  reportees: string[]
}

interface Values {
  "self-ownership-accountability": string
  "growth-mindset": string
  "nurturing-high-performing-teams": string
  "collaborative-excellence": string
  "aligning-with-the-business": string
  "driving-business-impact": string
}

const fetchLeadershipValueScores = (params: string) =>
  queryOptions({
    queryKey: ['leadership_value_scores'],
    queryFn() {
      return api.url('/v2/user/assessment/leadership-value-scores').query(params).get().json<Response>()
    },
  })

export const loader = (queryClient: QueryClient): LoaderFunction => (args) => {
  const url = new URL(args.request.url)
  return queryClient.fetchQuery(fetchLeadershipValueScores(url.searchParams.toString()))
}
