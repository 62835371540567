import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AlertDialog } from '~/components/alert-dialog'
import { useLogout } from '~/hooks/useLogout'

interface LogoutAlertProps {
  isOpen: boolean
  onClose: () => void
}

export const LogoutAlert: React.FC<LogoutAlertProps> = (props) => {
  const { isOpen = false, onClose } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const logout = useLogout()

  const logoutHandler = useCallback(() => {
    onClose()
    logout()
    navigate('/login')
  }, [navigate, logout, onClose])

  return (
    <AlertDialog
      title={t('logout.title')}
      isOpen={isOpen}
      onClose={onClose}
      confirmText={t('logout.confirm')}
      onConfirm={logoutHandler}
      description={t('logout.description')}
    />
  )
}
