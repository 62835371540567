import {
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Spacer,
  Spinner,
  StackDivider,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import moment from 'moment-timezone'
import { ChangeEvent, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsFillPersonFill } from 'react-icons/bs'
import { MdDateRange } from 'react-icons/md'
import * as FaIcons from 'react-icons/fa'

import {
  useGetEventsWithNotesQuery,
  useGetUserSessionNotesQuery,
  usePostSessionNoteMutation,
  useUpdateSessionNoteMutation,
} from '../../app/services/api'
import type { PostSessionNoteRequest } from '../../types/api'
import { parseText } from '../../utils/string'
import SessionNotesForm from './SessionNotesForm'
import { useLocale } from '../../hooks/useLocale'
import { useAuth } from '../../hooks/useAuth'

type IconType = keyof typeof FaIcons

const Badges = ({ badges }: any) => {
  const { t } = useLocale()

  return (
    <HStack>
      {badges.map((badge: any) => {
        const Icon = FaIcons[badge.iconName as IconType]
        return (
          <Box
            key={badge._id}
            as="button"
            bg="primary.400"
            color={'white'}
            borderRadius="full"
            p={3}
            w="50px"
            h="50px"
            _hover={{ bg: 'primary.500' }}
          >
            <Tooltip label={t(badge.description)} placement="auto">
              <VStack as="span" alignContent="center">
                <Icon size="24px" />
                <Text fontSize="xs" textAlign="center">
                  {t(badge.name)}
                </Text>
              </VStack>
            </Tooltip>
          </Box>
        )
      })}
    </HStack>
  )
}

const SessionNotes = () => {
  const { user } = useAuth()
  if (!user) throw new Error('User is not defined')
  const userId = user._id
  const { isOpen: isNoteOpen, onClose: onNoteClose } = useDisclosure()
  const { t } = useTranslation()
  const [updating, setUpdating] = useState(false)
  const [noteId, setNoteId] = useState<string | null>(null)
  const [postSessionNotes] = usePostSessionNoteMutation()
  const [updateSessionNotes] = useUpdateSessionNoteMutation()
  const { data: sessionNotes } = useGetUserSessionNotesQuery(userId)
  const { data: events, isLoading } = useGetEventsWithNotesQuery(userId)

  const { data } = events ? events : { data: [] }

  const [formState, setFormState] = useState<PostSessionNoteRequest>({
    userId,
    eventId: '',
    body: '',
    userShowedUp: true,
  })

  const toast = useToast()
  const initialRef = useRef() as any

  const handleNoteClose = useCallback(() => {
    setNoteId(null)
    setFormState((prev) => ({
      ...prev,
      eventId: '',
      body: '',
      userShowedUp: true,
    }))
    setUpdating(false)
    onNoteClose()
  }, [onNoteClose, setUpdating, setFormState, setNoteId])

  const onNoteSubmit = useCallback(
    async (event: any) => {
      event.preventDefault()
      try {
        if (updating) {
          const updatedNote = {
            id: noteId!,
            body: formState,
          }
          await updateSessionNotes(updatedNote).unwrap()
        } else {
          await postSessionNotes(formState).unwrap()
        }
        handleNoteClose()
        toast({
          status: 'success',
          description:
            'The note is successfully ' + (updating ? 'updated' : 'added'),
          isClosable: true,
        })
        setUpdating(false)
      } catch (err) {
        console.log(err)
        toast({
          status: 'error',
          title: 'Error',
          description: 'Error while saving a note',
          isClosable: true,
        })
      }
    },
    [
      noteId,
      setUpdating,
      toast,
      formState,
      updating,
      postSessionNotes,
      updateSessionNotes,
      handleNoteClose,
    ]
  )

  const handleChange = ({ target: { name, value } }: ChangeEvent<any>) =>
    setFormState((prev) => ({ ...prev, [name]: value }))

  const onShowedUpChange = useCallback(
    (ev: ChangeEvent<any>) => {
      setFormState((prev) => ({ ...prev, userShowedUp: ev.target.checked }))
    },
    [setFormState]
  )

  // Component to render session date with duration
  const SessionDate = ({
    event,
    coach: eventCoach,
  }: {
    event: any
    coach: any
  }) => {
    // Get session duration using start and end in minutes
    const duration = moment(event.end).diff(moment(event.start), 'minutes')
    const startDate = moment(event.start)
      .tz(user.timezone!)
      .format('MMM DD, YYYY h:mm A')
    return (
      <HStack w={'full'}>
        <Flex my={2} color="gray">
          <MdDateRange size="18px" />
          <Text fontSize={'sm'} ml={1}>
            {startDate} ({duration}min)
          </Text>
        </Flex>
        <Spacer />
        {eventCoach._id !== user._id && (
          <Flex my={2} color="gray" alignItems={'center'}>
            <BsFillPersonFill size="14px" />
            <Text fontSize={'sm'} ml={1}>
              {eventCoach.profile.name}
            </Text>
          </Flex>
        )}
      </HStack>
    )
  }

  // Show Spinner component if isLoading
  if (isLoading) {
    return <Spinner />
  }

  if (!data) {
    return null
  }

  const notesExist = data && data.length > 0 && (sessionNotes?.length ?? 0) > 0

  return (
    <>
      <Grid gap="5px" bg={'white'} rounded={'lg'} w={'full'}>
        {isLoading && (
          <Box textAlign="center" py={10} px={6}>
            <Spinner />
          </Box>
        )}
        <Grid gap="10px" w={'full'} bg={'white'} px={6} pt={3} rounded={'lg'}>
          {notesExist ? (
            <VStack
              divider={<StackDivider borderColor="gray.200" />}
              spacing={2}
              align="stretch"
            >
              {(sessionNotes || []).map((note: any) =>
                !note.session ? null : (
                  <Grid
                    key={note._id}
                    templateColumns="repeat(5, 1fr)"
                    gap={4}
                    bg={note ? 'white' : 'red.100'}
                  >
                    <GridItem colSpan={9} mt={2}>
                      <SessionDate event={note.session} coach={note.coach} />
                      {note ? (
                        <Text>{parseText(note.body.text)}</Text>
                      ) : (
                        <Text>{t('no_notes')}</Text>
                      )}
                      {note.badges && note.badges.length > 0 && (
                        <Box
                          mt={2}
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          {/* Header text: Received badges */}
                          {/* <Text fontWeight="bold" mb={2}>
                              {t('received_badges')}
                            </Text> */}
                          <Badges badges={note.badges} />
                        </Box>
                      )}
                    </GridItem>
                    <GridItem
                      colStart={8}
                      colEnd={11}
                      my={2}
                      alignSelf={'center'}
                    ></GridItem>
                  </Grid>
                )
              )}
            </VStack>
          ) : (
            <Box textAlign="center" py={10} px={6}>
              <Text fontSize="18px" mt={3} mb={2}>
                {t('no_notes_yet')}
              </Text>
            </Box>
          )}
        </Grid>
      </Grid>
      <SessionNotesForm
        isNoteOpen={isNoteOpen}
        handleNoteClose={handleNoteClose}
        onNoteSubmit={onNoteSubmit}
        initialRef={initialRef}
        updating={updating}
        formState={formState}
        handleChange={handleChange}
        onShowedUpChange={onShowedUpChange}
        eventId={formState.eventId}
      />
    </>
  )
}

export default SessionNotes
