import { Stack, Text, Textarea, useToast } from '@chakra-ui/react'
import { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState, store } from '../../../app/store'
import { useLocale } from '../../../hooks/useLocale'
import OnboardingFooter from '../components/OnboardingFooter'
import OnboardingHeader from '../components/OnboardingHeader'
import { itemSelect } from '../onboardingSlice'
import OnboardingContainer from '../OnboardingContainer'
import TitleDescription from './TitleDescription'
import _ from 'lodash'

const SelectionQuestion: FC<any> = ({
  index,
  onSubmit,
  submitting = false,
}) => {
  const { t } = useLocale()
  const onboardingQuestions = useSelector(
    (state: RootState) => state.onboarding.questions
  )
  const latestAnswers: any = store.getState().onboarding?.answers
  const question = onboardingQuestions[index]
  const multiselect = question.type === 'multiselect'
  const [selected, setSelected] = useState(
    latestAnswers[question.friendlyID]
      ? Array.isArray(latestAnswers[question.friendlyID])
        ? new Set(latestAnswers[question.friendlyID])
        : new Set(latestAnswers[question.friendlyID].answers)
      : new Set()
  )
  const [otherText, setOtherText] = useState(
    latestAnswers[question.friendlyID]?.other
      ? latestAnswers[question.friendlyID]?.other
      : ''
  )
  const dispatch = useDispatch()
  const toast = useToast()
  const isLast = index === onboardingQuestions.length - 1

  const showOtherOption = useMemo(
    () =>
      question.hasOtherText &&
      selected.size >= 1 && // can be more than one if multiselect
      selected.has(_.last(question.options as any[]).value),
    [selected, question]
  )

  useEffect(() => {
    if (showOtherOption) {
      setTimeout(() => window.scrollTo({ top: 5000 }), 500)
    }
  }, [showOtherOption])

  useEffect(() => {
    setSelected(
      latestAnswers[question.friendlyID]
        ? Array.isArray(latestAnswers[question.friendlyID])
          ? new Set(latestAnswers[question.friendlyID])
          : new Set(latestAnswers[question.friendlyID].answers)
        : new Set()
    )
    setOtherText(
      latestAnswers[question.friendlyID]?.other
        ? latestAnswers[question.friendlyID]?.other
        : ''
    )
  }, [latestAnswers, question])

  const onItemSelected = (item: any) => {
    const answers = new Set(selected)
    if (multiselect) {
      if (selected.has(item)) {
        answers.delete(item)
      } else {
        answers.add(item)
      }
    } else {
      answers.clear()
      answers.add(item)
    }
    setSelected(answers)
  }

  const onLocalSubmit = () => {
    if (selected.size === 0) {
      const errorMessage = multiselect
        ? t('select_at_least_one_option')
        : t('select_option')
      toast({
        status: 'error',
        title: 'Error',
        description: errorMessage,
        isClosable: true,
      })
    } else {
      dispatch(
        itemSelect({
          questionId: question.friendlyID,
          answers: Array.from(selected),
          otherAnswer: showOtherOption ? otherText : null,
        })
      )
      onSubmit()
    }
  }

  return (
    <>
      <OnboardingHeader
        currentStep={index}
        totalSteps={onboardingQuestions.length - 1}
      />
      <OnboardingContainer>
        <TitleDescription
          title={question.title}
          description={question.description}
        />
        <Stack my={30}>
          {question.options.map((option: any, optionIndex: number) => (
            <div
              key={option._id}
              style={{
                backgroundColor: selected.has(option.value)
                  ? 'var(--chakra-colors-primary-400)'
                  : '#fff',
                marginBottom: 20,
                borderRadius: 20,
                boxShadow: '0 5px 10px rgba(0,0,0,0.2)',
                padding: 20,
                cursor: 'pointer',
              }}
              data-testid={`onboarding-select-item-${optionIndex}`}
              onClick={() => onItemSelected(option.value)}
            >
              <Text
                style={{
                  color: selected.has(option.value) ? 'white' : 'black',
                }}
                align="center"
              >
                {option.label}
              </Text>
            </div>
          ))}
          {showOtherOption && (
            <Textarea
              onChange={(event: any) => setOtherText(event.target.value)}
              value={otherText}
              placeholder={question.otherTextPlaceholder}
              size="md"
              rows={4}
              style={{
                marginBottom: 20,
                borderRadius: 20,
                boxShadow: '0 5px 10px rgba(0,0,0,0.2)',
                padding: 20,
              }}
            />
          )}
        </Stack>
        <OnboardingFooter
          onSubmit={onLocalSubmit}
          submitText={t('next')}
          submitting={submitting}
          isLastStep={isLast}
        />
      </OnboardingContainer>
      <div
        data-testid="onboarding-flow-test-hidden"
        data-onboarding-flow-test-type={question.type}
        data-onboarding-flow-test-index={index}
        data-onboarding-flow-test-id={question.friendlyID}
        data-onboarding-flow-test-count={question.options.length}
        data-onboarding-flow-test-islast={isLast}
        style={{ display: 'none' }}
      />
    </>
  )
}

export default SelectionQuestion
