import { Component } from 'react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { TroubleshootDialog } from './components/troubleshoot-dialog'
import { store } from './app/store'

type Props = {
  children: React.ReactNode
}

type State = {
  hasError: boolean
  error: any
  troubleshootModalOpen: boolean
}

export class ErrorBoundary extends Component<Props> {
  state: State = {
    hasError: false,
    troubleshootModalOpen: false,
    error: null,
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error }
  }

  componentDidCatch(error: any, errorInfo: any) {
    if (import.meta.env.DEV) {
      console.log(error)
      console.log(errorInfo?.componentStack)
      return
    }

    const email = store.getState().auth.user?.email
    const date = new Date().toISOString()

    fetch('https://api.hupo.co/v2/error/client', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        uri: window.location.href,
        time: date,
        message: error.message,
        stack: error.stack,
        componentStack: errorInfo?.componentStack,
        userEmail: email,
      }),
    }).then((res) => res.json())
  }

  setTroubleshootModalOpen = (open: boolean) => {
    this.setState({ troubleshootModalOpen: open })
  }

  onTroubleshootingClick = () => {
    this.setTroubleshootModalOpen(true)
  }

  render() {
    if (!this.state.hasError && !this.state.error) {
      return this.props.children
    }

    const message = import.meta.env.PROD
      ? 'An error occurred. We are working on it.'
      : this.state.error?.message
    const stack = import.meta.env.PROD ? null : this.state.error?.stack

    return (
      <>
        <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
          <div className="text-center">
            <h1 className="mt-4 text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Application error
            </h1>
            <p className="mt-6 text-base leading-7 text-gray-600">{message}</p>
            {stack && (
              <pre className="mt-6 text-sm text-left text-gray-600 overflow-x-auto">
                {stack}
              </pre>
            )}
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="/"
                className="rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              >
                Go back home page
              </a>
              <button
                className="rounded-md px-3.5 py-2 text-sm outline outline-gray-500 flex items-center gap-2 font-semibold text-gray-900"
                onClick={this.onTroubleshootingClick}
              >
                Troubleshooting{' '}
                <span aria-hidden="true">
                  <ExclamationTriangleIcon className="w-5 h-5 inline-block" />
                </span>
              </button>
            </div>
          </div>
        </main>

        <TroubleshootDialog
          open={this.state.troubleshootModalOpen}
          setOpen={this.setTroubleshootModalOpen}
        />
      </>
    )
  }
}
