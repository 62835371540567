import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useRef } from 'react'
import { useTruncatedElement } from '~/hooks/useTruncatedElement'
import { cn } from '~/utils/cn'

type Props = {
  open: boolean
  onClose: () => void
  coach: any
  bio: any
}

export const CoachBioModal: React.FC<Props> = (props) => {
  const { coach, bio, onClose, open } = props
  const bioRef = useRef<HTMLParagraphElement>(null)
  const philosophyRef = useRef<HTMLParagraphElement>(null)
  const { isReadingMore, setIsReadingMore } = useTruncatedElement({
    ref: bioRef,
  })

  const {
    isReadingMore: isReadingMorePhilosophy,
    setIsReadingMore: setIsReadingMorePhilosophy,
  } = useTruncatedElement({ ref: philosophyRef })

  return (
    <Dialog open={open} onClose={onClose} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button
                type="button"
                onClick={onClose}
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
              >
                <span className="sr-only">Close</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>

            <div className="divide-y divide-gray-200">
              <div className="pb-3 px-4 flex items-center gap-3">
                <img
                  alt={coach.profile.name}
                  src={coach.profile.picture}
                  className="h-14 w-14 rounded-full object-cover"
                />

                <h2 className="text-md font-medium text-slate-900">
                  {coach.profile.name}
                </h2>
              </div>

              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  Biography
                </h3>
                <p
                  ref={bioRef}
                  className={cn(
                    'mt-1 text-sm text-gray-500 transition-all',
                    !isReadingMore && 'line-clamp-4'
                  )}
                >
                  {bio.full}
                </p>

                <button
                  onClick={() => setIsReadingMore((prev) => !prev)}
                  className="text-sm text-primary hover:underline focus:outline-none"
                >
                  {isReadingMore ? 'Read less' : 'Read more'}
                </button>
              </div>

              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  Coaching Philosophy
                </h3>
                <p
                  ref={philosophyRef}
                  className={cn(
                    'mt-1 text-sm text-gray-500 transition-all',
                    !isReadingMorePhilosophy && 'line-clamp-4'
                  )}
                >
                  {bio.philosophy}
                </p>

                <button
                  onClick={() => setIsReadingMorePhilosophy((prev) => !prev)}
                  className="text-sm text-primary hover:underline focus:outline-none"
                >
                  {isReadingMorePhilosophy ? 'Read less' : 'Read more'}
                </button>
              </div>

              {bio.licenses.length > 0 && (
                <div className="px-4 py-5 sm:px-6">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Professional Licenses
                  </h3>

                  <ul className="mt-1 list-disc text-sm text-gray-500">
                    {bio.licenses.map((item: any) => (
                      <li key={item} className="ml-4">
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {bio.industry.length > 0 && (
                <div className="px-4 py-5 sm:px-6">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Industry Expertise
                  </h3>

                  <ul className="mt-2 text-sm text-gray-500 flex gap-1.5 flex-wrap">
                    {bio.industry.map(({ _id, industry: item }: any) => (
                      <li key={_id}>
                        <span className="inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800">
                          {item}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {bio.languages.length > 0 && (
                <div className="px-4 py-5 sm:px-6">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Language
                  </h3>

                  <ul className="mt-2 text-sm text-gray-500 flex gap-1.5 flex-wrap">
                    {bio.languages.map((item: any) => (
                      <li key={item}>
                        <span className="inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800">
                          {item}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {bio.style.length > 0 && (
                <div className="px-4 py-5 sm:px-6">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Coaching Style
                  </h3>

                  <ul className="mt-2 text-sm text-gray-500 flex gap-1.5 flex-wrap">
                    {bio.style.map((item: any) => (
                      <li key={item}>
                        <span className="inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800">
                          {item}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            <button
              type="button"
              data-autofocus
              onClick={onClose}
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            >
              Close
            </button>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}
