import { useEffect } from 'react'
import { useLocale } from '../../../../hooks/useLocale'
import { useMixpanel } from '../../../../utils/MixpanelContext'

const MeetingEnded = ({ eventId, roomName, meetingHappened }: any) => {
  const { t } = useLocale()
  const mixpanel = useMixpanel()
  useEffect(() => {
    mixpanel.track('inapp_meeting_finish', {
      eventId,
      roomName,
    })
  }, [mixpanel, eventId, roomName])

  return (
    <div className="h-full w-full p-4 flex flex-col bg-black text-white overflow-hidden justify-center place-items-center">
      <p className="text-xl">
        {meetingHappened ? t('meeting_ended') : t('not_in_meeting_message')}
      </p>
    </div>
  )
}

export default MeetingEnded
