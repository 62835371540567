import { useToast } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { WretchError } from 'wretch/resolver'

import { api } from '~/app/api'
import { Modal } from '~/components/modal'

type Props = {
  isOpen: boolean
  onClose: () => void
  defaultValues?: Form
}

type Form = {
  email: string
  type: string
}

const types = ['manager', 'peer', 'reportee']

export const RespondentModal: React.FC<Props> = (props) => {
  const { isOpen, defaultValues } = props
  const { t } = useTranslation()
  const toast = useToast()
  const { register, handleSubmit, reset, setValue } = useForm<Form>()

  useEffect(() => {
    if (!defaultValues) return
    setValue('email', defaultValues?.email)
    setValue('type', defaultValues?.type)
  }, [setValue, defaultValues])

  const onClose = () => {
    reset()
    props.onClose()
  }

  const { mutate: updateRespondent } = useMutation({
    mutationFn(data: Form) {
      return api
        .url('/v2/user/assessment/update-respondent')
        .post({
          previousEmail: defaultValues?.email,
          newEmail: data.email,
          type: data.type,
        })
        .json()
    },
    onSuccess() {
      toast({
        status: 'success',
        title: t('success'),
        description: t('respondent_updated'),
        isClosable: true,
      })
    },
    onError(error) {
      if (error instanceof WretchError) {
        toast({
          status: 'error',
          title: 'Error',
          description: error?.json?.message ?? t('respondent_update_failed'),
          isClosable: true,
        })
      } else {
        toast({
          status: 'error',
          title: 'Error',
          description: t('respondent_update_failed'),
          isClosable: true,
        })
      }
    },
    onSettled() {
      onClose()
    },
  })

  const onSubmit = handleSubmit((data) => {
    updateRespondent(data)
  })

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={
        defaultValues?.email ? t('update_respondent') : t('add_respondent')
      }
      confirmText={t('submit')}
      onConfirm={onSubmit}
    >
      <form className="w-full space-y-4" onSubmit={onSubmit}>
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {t('email')}
          </label>
          <div className="mt-2">
            <input
              id="email"
              type="email"
              placeholder="you@example.com"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              required
              {...register('email', { required: true })}
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="type"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {t('type')}
          </label>
          <select
            id="type"
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6"
            required
            {...register('type', { required: true })}
          >
            <option value="" disabled>
              Select type
            </option>
            {types.map((type) => (
              <option key={type} value={type}>
                {t(type)}
              </option>
            ))}
          </select>
        </div>
      </form>
    </Modal>
  )
}
