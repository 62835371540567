import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { FC, useEffect } from 'react'
import { useLocale } from '../../hooks/useLocale'
import { useParams } from 'react-router-dom'
import { usePostVerifyHupoHighlightsSummaryMutation } from '../../app/services/api'
import { Logo } from '../../utils/Logo'

const HupoHighlightsVerify: FC<any> = () => {
  const { t } = useLocale()
  const { eventId } = useParams<{ eventId: string }>()
  console.log('hupohighlightsverify', eventId)
  const [postHupoHighlightsSummaryVerify] =
    usePostVerifyHupoHighlightsSummaryMutation()
  useEffect(() => {
    try {
      postHupoHighlightsSummaryVerify({ eventId }).unwrap()
    } catch (error) {
      console.log('error', error)
    }
  }, [eventId, postHupoHighlightsSummaryVerify])
  return (
    <Box flexDirection={'column'} textAlign="center" px={6} pb={10}>
      <Flex
        px={{ base: 4, md: 4 }}
        height="20"
        borderBottomWidth="1px"
        alignItems="center"
        justifyContent="center"
      >
        <Logo md={5} />
      </Flex>

      <Flex
        alignItems="center"
        justifyContent="center"
        padding={10}
        direction="column"
      >
        <Heading
          display="inline-block"
          as="h3"
          size="lg"
          textAlign={'center'}
          mb={10}
        >
          {t('summary-verified')}
        </Heading>
        <Text textAlign={'center'} mb={10}>
          {t('summary-verified-close-tab')}
        </Text>
      </Flex>
    </Box>
  )
}

export default HupoHighlightsVerify
