import { Flex, Text } from '@chakra-ui/react'
import { FC } from 'react'

interface TitleDescriptionProps {
  title: string
  description?: string | React.ReactNode
  attachment?: any
}

const TitleDescription: FC<TitleDescriptionProps> = ({
  title,
  description,
  attachment,
}) => {
  let attachmentElement = null
  if (attachment?.link) {
    if (attachment?.title.includes('<link>')) {
      const preText = attachment?.title.split('<link>')[0]
      const postText = attachment?.title.split('</link>')[1]
      const linkText = attachment?.title.split('</link>')[0].split('<link>')[1]
      attachmentElement = (
        <Text
          fontWeight="400"
          maxWidth={600}
          lineHeight={1.3}
          fontSize={['lg']}
          textAlign="center"
          as={'span'}
        >
          {preText}
          <a href={attachment?.link} target="_blank" rel="noreferrer">
            <Text
              py={2}
              fontSize={'lg'}
              color={'blue.600'}
              align={'center'}
              textDecoration={'underline'}
              as={'span'}
            >
              {linkText}
            </Text>
          </a>
          {postText}
        </Text>
      )
    } else {
      attachmentElement = (
        <a href={attachment?.link} target="_blank" rel="noreferrer">
          <Text
            py={2}
            fontSize={'lg'}
            color={'blue.600'}
            align={'center'}
            textDecoration={'underline'}
            as={'span'}
          >
            {attachment?.title}
          </Text>
        </a>
      )
    }
  }
  return (
    <Flex direction="column" py={5} gridRowGap={5} alignItems="center">
      <Text
        fontWeight="600"
        textAlign="center"
        lineHeight={8}
        fontSize={['2xl', '3xl']}
        key={'title'}
      >
        {title}
      </Text>
      {description && typeof description === 'string' ? (
        <Text
          fontWeight="400"
          maxWidth={600}
          lineHeight={1.3}
          fontSize={['lg']}
          textAlign="center"
          key={'subtitle'}
        >
          {description.split('\\n').map((str: string) => (
            <>
              {str}
              <br />
            </>
          ))}
        </Text>
      ) : (
        description
      )}
      {attachmentElement}
    </Flex>
  )
}

export default TitleDescription
