import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useToast } from '@chakra-ui/react'
import { useMutation, useQuery } from '@tanstack/react-query'

import { api } from '~/app/api'
import { useAppSelector } from '~/app/hooks'
import { CoachCard } from '~/components/coach-card'
import { AlertDialog } from '~/components/alert-dialog'

export const ChangeCoach: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user)
  const { t } = useTranslation()
  const toast = useToast()
  const navigate = useNavigate()
  const [selectedCoach, setSelectedCoach] = useState(user?.coach?.email ?? '')
  const [alertOpen, setAlertOpen] = useState(false)

  const { data, isLoading } = useQuery({
    initialData: [],
    queryKey: ['coach', 'options'],
    queryFn() {
      return api
        .url('/onboarding/coach_options')
        .query({
          email: user?.email,
          companyId: user?.company._id,
          language: user?.language ?? 'en',
        })
        .get()
        .json<any[]>()
    },
  })

  const { mutate: match } = useMutation({
    mutationFn(coachId: string) {
      return api.url('/users/match').post({ coachId }).json()
    },
    onSuccess() {
      toast({
        status: 'success',
        title: 'Success',
        description: t('coach_updated'),
        isClosable: true,
        duration: 3000,
      })

      navigate('/', { replace: true })
    },
    onError() {
      toast({
        status: 'error',
        title: 'Error',
        description: t('coach_update_error'),
        isClosable: true,
        duration: 3000,
      })
    },
  })

  const onSelect = (email: string) => {
    setSelectedCoach(email)
    setAlertOpen(true)
  }

  if (isLoading) return <div>Loading...</div>

  return (
    <>
      <h1 className="text-lg font-semibold leading-7 text-gray-900">
        Choose your coach
      </h1>

      <div className="mt-5 w-full flex items-start justify-center gap-4 flex-wrap pb-20">
        {data.map(({ coach, bio }) => {
          return (
            <CoachCard
              key={coach._id}
              className="w-[45%]"
              coach={coach}
              bio={bio}
              selected={selectedCoach === coach.email}
              onSelect={onSelect}
            />
          )
        })}
      </div>

      <AlertDialog
        title={`Change your coach`}
        description="Are you sure you want to change your coach?"
        isOpen={alertOpen}
        confirmText="Yes, change coach"
        onClose={() => setAlertOpen(false)}
        onConfirm={() => match(selectedCoach)}
      />
    </>
  )
}
