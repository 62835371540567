import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import Cookies from 'js-cookie'
import {
  ChangeEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { useGetMeMutation, useLoginMutation } from '../../app/services/api'
import { useAuth } from '../../hooks/useAuth'
import { useLocale } from '../../hooks/useLocale'
import type { LoginRequest } from '../../types/api'
import { useMixpanel } from '../../utils/MixpanelContext'
import { useFlagsmith } from 'flagsmith/react'

function PasswordInput({
  name,
  value,
  onChange,
}: {
  name: string
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}) {
  const { t } = useLocale()
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)

  return (
    <InputGroup size="md">
      <Input
        pr="4.5rem"
        type={show ? 'text' : 'password'}
        placeholder={t('enter_password')}
        name={name}
        value={value}
        onChange={onChange}
      />
      <InputRightElement width="4.5rem">
        <Button h="1.75rem" size="sm" onClick={handleClick}>
          {show ? t('hide') : t('show')}
        </Button>
      </InputRightElement>
    </InputGroup>
  )
}

export default function Login() {
  const { t } = useLocale()
  const navigate = useNavigate()
  const toast = useToast()
  const { user } = useAuth()
  const { state }: any = useLocation()
  const mixpanel = useMixpanel()
  const [getMe] = useGetMeMutation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef() as MutableRefObject<HTMLInputElement>
  const flagsmith = useFlagsmith()

  const redirectTo = state?.from ? state.from : '/'
  const [login] = useLoginMutation()

  const email = Cookies.get('AmiEmail') || ''
  const password = Cookies.get('AmiPassword') || ''
  const isCookiesSet = email && password

  useEffect(() => {
    mixpanel.track('login_open')
  }, [])

  const [formState, setFormState] = useState<LoginRequest>({
    email: isCookiesSet ? email : '',
    password: isCookiesSet ? password : '',
  })
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(
    !isCookiesSet
  )

  const submitForm = useCallback(async () => {
    try {
      const adminSecret = document.getElementById(
        'adminSecret'
      ) as HTMLInputElement

      const formStateWithSecret = {
        ...formState,
        adminSecret: adminSecret?.value,
      }
      const { user } = await login(formStateWithSecret).unwrap()
      const userData = await getMe(user._id).unwrap()
      mixpanel.identify(user._id)
      mixpanel.people.set('email', user.email)
      mixpanel.set_group('company', userData.company.name)
      mixpanel.set_group('coach', userData.coach.email)
      mixpanel.track('login_success')

      if (user && user.isOnboardingCompleted) {
        const setupFlagsmith = async () => {
          // Identify user in Flagsmith
          await flagsmith.identify(user._id)
          await flagsmith.setTrait('email', userData.email)
          await flagsmith.setTrait('company', userData.company.name)
          await flagsmith.setTrait(
            'companyId',
            userData.company?._id ?? userData.company
          )
          await flagsmith.setTrait('coach', userData.coach.email)

          if (userData.department?._id) {
            await flagsmith.setTrait('department', userData.department.name)
            await flagsmith.setTrait('departmentId', userData.department._id)
          }
        }
        setupFlagsmith()
        navigate(redirectTo)
      }
    } catch (err: any) {
      toast({
        status: 'error',
        title: t('error'),
        description: err.data?.message || t('there_was_an_error'),
        isClosable: true,
      })
      mixpanel.track('login_fail', { message: err.data?.message })
      if (err?.data?.canLoginAsCoach) {
        onOpen()
      }
    }
  }, [formState, login, navigate, toast, t, redirectTo])

  useEffect(() => {
    if (user && user.isOnboardingCompleted) {
      navigate(redirectTo)
    } else if (user && !user?.isOnboardingCompleted) {
      navigate(
        `/onboarding/${user.language}/${user.company._id}${
          user.department?._id ? '/' + user.department?._id : ''
        }?questionIndex=${user.questionIndex}`
      )
    } else if (isCookiesSet) {
      submitForm()
    }
  }, [navigate, user, submitForm, isCookiesSet, redirectTo])

  useEffect(() => {
    setIsButtonDisabled(!(formState?.email.trim() && formState?.password))
  }, [formState])

  const handleChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) =>
    setFormState((prev) => ({ ...prev, [name]: value }))

  const onSubmit = useCallback(
    async (event: any) => {
      event.preventDefault()
      submitForm()
    },
    [submitForm]
  )

  const onRedirectToCoachLogin = () => {
    navigate('/coach-login')
  }

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <form onSubmit={onSubmit}>
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
            <Heading fontSize={'4xl'}>{t('sign_in')}</Heading>
            <Text fontSize={'lg'} color={'gray.600'} align={'center'}>
              {t('ami_is_100%_secure_and_confidential_so')}
            </Text>
          </Stack>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}
          >
            <Stack spacing={4}>
              <FormControl id="email">
                <FormLabel>{t('email')}</FormLabel>
                <Input
                  onChange={handleChange}
                  name="email"
                  value={formState.email}
                  type="text"
                  placeholder={t('email')}
                  autoFocus
                />
              </FormControl>
              <FormControl id="password">
                <FormLabel>{t('password')}</FormLabel>
                <PasswordInput
                  onChange={handleChange}
                  name="password"
                  value={formState.password}
                />
              </FormControl>
              <FormControl id="adminSecret">
                <Input name="adminSecret" value="" type="hidden" />
              </FormControl>
              <Stack spacing={10}>
                <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  align={'start'}
                  justify={'space-between'}
                >
                  <Checkbox>{t('remember_me')}</Checkbox>
                  <Link href="/password_forgot" color={'blue.400'}>
                    {t('forgot_password?')}
                  </Link>
                </Stack>
                <Button
                  disabled={isButtonDisabled}
                  colorScheme="primary"
                  onClick={onSubmit}
                  type="submit"
                >
                  {t('sign_in')}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </form>
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('trying_to_login_as_coach')}
            </AlertDialogHeader>
            <AlertDialogFooter>
              <Button onClick={onClose}>{t('cancel')}</Button>
              <Button
                colorScheme="blue"
                onClick={onRedirectToCoachLogin}
                ml={3}
              >
                {t('go_to_coach_login')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  )
}
