import { useAsyncValue } from 'react-router-dom'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'

import { getUnifiedCallLink } from '~/utils/calllink'

export const UpcomingSessions: React.FC = () => {
  const data = useAsyncValue() as any

  return (
    <div className="overflow-hidden rounded-lg bg-white shadow">
      <div className="p-6">
        <h2
          id="upcoming-sessions-title"
          className="text-base font-medium text-gray-900"
        >
          Upcoming sessions
        </h2>
        <div className="mt-6 flow-root">
          <ul role="list" className="-my-5 divide-y divide-gray-200">
            <li className="py-5">
              {data && (
                <>
                  <div className="sm:flex sm:items-center sm:justify-between">
                    <div className="relative focus-within:ring-2 focus-within:ring-cyan-500">
                      <h4 className="text-sm font-semibold text-gray-800">
                        {data.title}
                      </h4>
                      <p className="mt-1 line-clamp-2 text-sm text-gray-600">
                        {data.start &&
                          format(parseISO(data.start), 'MMMM do, h:mm a')}
                      </p>
                    </div>

                    <div className="mt-5 flex justify-center sm:mt-0">
                      <a
                        href={getUnifiedCallLink({
                          eventId: data._id,
                          referrer: 'new-home-upcoming-sessions-section',
                          isCoach: false,
                        })}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        Join
                      </a>
                    </div>
                  </div>
                </>
              )}

              {!data && (
                <div>
                  <p>No upcoming sessions.</p>
                </div>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
