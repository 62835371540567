import {
  Box,
  Heading,
  SimpleGrid,
  Spacer,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { useCallback, useRef, useState } from 'react'

import { usePostMatchMutation } from '../../app/services/api'
import { ActionButton } from './ActionButton'
import { CoachCard } from './CoachCard'

const Coaches = () => {
  const [isCoachSelected, setIsCoachSelected] = useState(false)
  const [selectedCoachId, setSelectedCoachId] = useState<any>(null)

  const [postMatch] = usePostMatchMutation()

  const toast = useToast()

  const onSelect = useCallback((e: any) => {
    const coachId = e.currentTarget.getAttribute('data-id')
    setIsCoachSelected(true)
    setSelectedCoachId(coachId)
    scrollToBottom()
  }, [])

  const onConfirm = useCallback(async () => {
    if (!selectedCoachId) return
    try {
      await postMatch(selectedCoachId).unwrap()
      toast({
        status: 'success',
        description: 'You have sucessfully selected a coach',
        isClosable: true,
      })
    } catch (err) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Error while matching with the coach',
        isClosable: true,
      })
    }
  }, [postMatch, selectedCoachId, toast])

  const buttonEndRef = useRef<HTMLDivElement>(null)
  const scrollToBottom = () => {
    if (buttonEndRef?.current)
      buttonEndRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  return (
    <Box
      as="section"
      bg={useColorModeValue('gray.50', 'gray.800')}
      pt={6}
      pb="14"
      px={{ base: '4', md: '8' }}
    >
      <Heading mb={6} fontSize="2xl" textAlign={'center'}>
        Coach recommendations
      </Heading>
      <SimpleGrid
        columns={{ base: 1, lg: 3 }}
        spacing={{ base: '8', lg: '2' }}
        maxW="7xl"
        mx="auto"
        justifyItems="center"
        alignItems="stretch"
      >
        <CoachCard
          onClick={onSelect}
          selectedCoachId={selectedCoachId}
          data={{
            id: '1',
            biography:
              "Lauren is Hupo's Coaching Specialist with a background in Clinical Psychology and demonstrated experience in behavioral coaching and counseling. Lauren has worked with multinational (young) adult clients, mainly with anxiety, depression, managing emotion, stress, and motivation problems. Lauren uses the Cognitive-Behavioral and the Biopsychosocial models. Her previous clients have commended her for being a trustworthy, empathetic, and calm listener. Overall, Lauren is a lifelong learner who dedicates her time to supporting well-being.",
            name: 'Lauren',
            features: [
              'All application UI components',
              'Lifetime access',
              'Use on unlimited projects',
              'Free Updates',
            ],
          }}
        />
        <CoachCard
          onClick={onSelect}
          selectedCoachId={selectedCoachId}
          data={{
            id: '2',
            biography:
              'Hanna is an Indonesian Clinical Psychologist who works as a psychologist and a counselor. She used to handle teenager and adult clients. She has experience helping clients with mood problems, anxiety, emotional problems, work-related stress, relationship & family problems, also self-development concerns.\nHanna loves to work with her clients. She wants to have an opportunity to encourage those who are going through a rough time. Also, to allow them to express themselves openly in a safe environment that is free of judgment.',
            name: 'Hanna',
            features: [
              'All application UI components',
              'Lifetime access',
              'Use on unlimited projects',
              'Free Updates',
            ],
          }}
        />
        <CoachCard
          onClick={onSelect}
          selectedCoachId={selectedCoachId}
          data={{
            id: '3',
            biography:
              'Ima is an Indonesian Clinical Psychologist who has experiences with various adult clients and employees through Employee Assistance Program (EAP). Ima has experiences helping her clients with emotion, mood, & behavior issue, relationship & family problem, anxiety, work-related issue, and personal growth/development. It’s important for her to create a therapeutic relationship with her clients, with the aim of helping them to recognize their potential and helping them to handle their challenges in life.',
            name: 'Ima',
            features: [
              'All application UI components',
              'Lifetime access',
              'Use on unlimited projects',
              'Free Updates',
            ],
          }}
        />
        <Spacer />
        <ActionButton
          onClick={onConfirm}
          variant="outline"
          borderWidth="2px"
          mt={5}
          disabled={!isCoachSelected}
        >
          Confirm
        </ActionButton>
        <span ref={buttonEndRef}></span>
      </SimpleGrid>
    </Box>
  )
}

export default Coaches
