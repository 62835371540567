// @ts-expect-error - no types available
import GoogleSpeechRecognition from '@dytesdk/google-transcription'
import { useCallback, useState } from 'react'

import { API_ENDPOINT, LANGUAGE_CLASSNAME_MAP } from '../../../app/constants'

export const useSpeechRecognition = ({
  meeting,
  eventId,
  useStt,
  user,
  coach,
  participantType,
}: any) => {
  const [gsrSocket, setGsrSocket] = useState<any>(null)
  const participant = participantType === 'user' ? user : coach

  const onStartSpeechRecognition = useCallback(() => {
    if (!useStt || !meeting || !eventId || (!user && !coach)) return

    // if user has defaultCoachingLanguage and its in LANGUAGE_CLASSNAME_MAP then use it, otherwise set to 'en-US
    const languageCode = user?.defaultCoachingLanguage && LANGUAGE_CLASSNAME_MAP[user.defaultCoachingLanguage]
      ? LANGUAGE_CLASSNAME_MAP[user.defaultCoachingLanguage]
      : 'en-US'

    const googleSpeechConfig = {
      meeting,
      target: 'en',
      source: languageCode,
      baseUrl: API_ENDPOINT,
    }

    const speech = new GoogleSpeechRecognition(googleSpeechConfig)

    const gsrSocket = speech.socketClient.socket
    setGsrSocket(gsrSocket)
    gsrSocket.emit('speech:join', {
      socketId: gsrSocket.id,
      id: participant._id,
      email: participant.email,
      name: participant.profile.name,
      userId: participantType === 'user' ? user._id : undefined,
      coachId: participantType === 'coach' ? coach._id : undefined,
      eventId,
      languageCode,
    })

    speech.transcribe()
    console.log('Start transcribing')
  }, [meeting, user, coach, eventId, useStt, participant, participantType])

  const onStopSpeechRecognition = useCallback(() => {
    if (!useStt || !meeting || !gsrSocket || (!user && !coach)) return

    gsrSocket.emit('speech:leave', {
      socketId: gsrSocket.id,
      id: participant._id,
      eventId,
    })
    console.log('Stop transcribing')
  }, [meeting, gsrSocket, user, coach, eventId, useStt, participant])

  return {
    speechSocket: useStt ? gsrSocket : '<disabled>',
    onStartSpeechRecognition,
    onStopSpeechRecognition,
  }
}
