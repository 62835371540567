import { useEffect } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useRevalidator } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'

import { api } from '~/app/api'
import { Modal } from '~/components/modal'

type Props = {
  isOpen: boolean
  onClose: () => void
  defaultValues?: Form
}

type Form = {
  noteId: string
  body: string
}

export const MyNoteModal: React.FC<Props> = (props) => {
  const { isOpen, defaultValues, onClose } = props
  const { t } = useTranslation()
  const toast = useToast()
  const { register, handleSubmit, setValue } = useForm<Form>()
  const { revalidate } = useRevalidator()

  useEffect(() => {
    if (!defaultValues) return
    setValue('body', defaultValues.body)
    setValue('noteId', defaultValues.noteId)
  }, [setValue, defaultValues])

  const { mutate: updateNote } = useMutation({
    mutationFn(data: { id: string; body: string }) {
      return api.url(`/user/note/${data.id}`).put({ body: data.body }).json()
    },
    onSuccess() {
      toast({
        status: 'success',
        description: 'The note is successfully updated',
        isClosable: true,
      })
      revalidate()
    },
    onError() {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Error while saving a note',
        isClosable: true,
      })
    },
    onSettled() {
      onClose()
    },
  })

  const { mutate: createNote } = useMutation({
    mutationFn(data: string) {
      return api.url('/user/note').post({ body: data }).json()
    },
    onSuccess() {
      toast({
        status: 'success',
        description: 'The note is successfully added',
        isClosable: true,
      })
      revalidate()
    },
    onError() {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Error while saving a note',
        isClosable: true,
      })
    },
    onSettled() {
      onClose()
    },
  })

  const onSubmit = handleSubmit((data) => {
    if (data.noteId) {
      updateNote({ id: data.noteId, body: data.body })
    } else {
      createNote(data.body)
    }
  })

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={defaultValues?.noteId ? t('update_note') : t('create_new_note')}
      confirmText={defaultValues?.noteId ? t('update_note') : t('save')}
      onConfirm={onSubmit}
    >
      <form className="w-full space-y-4" onSubmit={onSubmit}>
        <div>
          <label
            htmlFor="body"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {t('note')}
          </label>
          <div className="mt-2">
            <textarea
              id="body"
              rows={4}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              placeholder={t('notes')}
              required
              {...register('body', { required: true })}
            />
          </div>
        </div>
      </form>
    </Modal>
  )
}
